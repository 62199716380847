import {
  CraneSideEnum,
  ForeAftEnum,
  IVesselPartBridge,
  IVesselPartCrane,
  IVesselPartSmokeStack,
  IVesselPartSpacer,
  IVesselParts,
  VesselPartTypeEnum,
} from "open-vessel-definition";
import {
  ITedivoShoelaceSelectProps,
  createSelectShoelace,
} from "@baplie-viewer2/tedivo-form";

import { IActionVesselPart } from "../partsReducer";
import { IGetBayLcgVcgAndPairingsResult } from "tedivo-bay-grid-pure";
import { IVesselOneBaySlot } from "../../../../../vessel3D/types/IVesselOneBaySlot";
import { PARTS_COLORS } from "./partsColors";
import { calculateSpaceAvailableNext } from "./helpers/calculateSpaceAvailableNext";
import { createModalForBridge } from "./modals/createModalForBridge";
import { createModalForCranes } from "./modals/createModalForCranes";
import { createModalForSmokeStacks } from "./modals/createModalForSmokeStacks";
import { createModalForSpacer } from "./modals/createModalForSpacer";
import { createSimpleAlert } from "packages/oss-editor/src/components/common/IntegratedDialog";
import { getTranslation } from "packages/oss-editor/src/app/i18/i18tn";

const commonOptions: PickCommonOptionsTedivoShoelace = {
  caret: false,
  ommitCheckSign: true,
  iconPrefix: "plus",
  buttonText: "",
  outlined: true,
  variant: "primary",
  size: "small",
  pill: true,
  hoist: true,
};

export function drawSlotsNodes({
  calculatedSlots,
  lcgVcgTcgAndPairings,
  applyChanges,
}: {
  calculatedSlots: Array<IVesselOneBaySlot>;
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  applyChanges: (data: IActionVesselPart) => void;
}): DocumentFragment {
  const mainHolder = document.createDocumentFragment();
  const vesselSlotsHolder = document.createElement("div");
  vesselSlotsHolder.className = "vessel-slots-holder";
  vesselSlotsHolder.dataset.bow = getTranslation("general:ship.bow");
  vesselSlotsHolder.dataset.stern = getTranslation("general:ship.stern");
  mainHolder.appendChild(vesselSlotsHolder);

  const editorActionOptions: { name: string; value: PartType }[] = [
    {
      name: getTranslation("view:view3D.actions.addBridge"),
      value: "addBridge",
    },
    { name: getTranslation("view:view3D.actions.addCrane"), value: "addCrane" },
    {
      name: getTranslation("view:view3D.actions.addSmokeStack"),
      value: "addSmoke",
    },
    {
      name: getTranslation("view:view3D.actions.addSpacer"),
      value: "addSpacer",
    },
  ];

  const currentLabels = calculatedSlots.reduce((acc, slot) => {
    acc[slot.id] = slot.label;
    return acc;
  }, {} as Record<string, string>);

  const last = calculatedSlots.length - 1;

  calculatedSlots
    .slice()
    .reverse()
    .forEach((slot, idx) => {
      const slotHolder = document.createElement("div");
      const slotBox = document.createElement("div");

      slotHolder.className = `slot-holder ${
        slot.type === VesselPartTypeEnum.BAY ? "bay" : ""
      }`;
      slotBox.className = `slot-box`;
      slotBox.style.borderColor = `var(${PARTS_COLORS[slot.type]})`;

      const slotActionAft = createSelectShoelace<PartType>({
        ...commonOptions,
        id: `${idx}-fwd`,
        options: editorActionOptions,
        onChange: (partType) => {
          launchPartLaunchModal({
            partType,
            slotRefId: slot.id,
            posRef: ForeAftEnum.AFT,
            currentLabels,
            calculatedSlots,
            lcgVcgTcgAndPairings,
            applyChanges,
          });
        },
      });

      slotHolder.appendChild(slotActionAft);
      slotHolder.appendChild(slotBox);

      if (slot.type !== VesselPartTypeEnum.BAY) {
        const editBnt = document.createElement("sl-icon-button");
        editBnt.className = "part-slot-edit-btn";
        editBnt.name = "pencil";
        editBnt.addEventListener("click", () => {
          launchPartLaunchModal({
            partType:
              slot.type === VesselPartTypeEnum.BRIDGE
                ? "addBridge"
                : slot.type === VesselPartTypeEnum.CRANE
                ? "addCrane"
                : slot.type === VesselPartTypeEnum.SMOKE
                ? "addSmoke"
                : "addSpacer",
            original: slot.original,
            slotRefId: slot.original.slotRefId,
            posRef: slot.original.posRef,
            currentLabels,
            calculatedSlots,
            lcgVcgTcgAndPairings,
            applyChanges,
          });
        });

        slotBox.innerHTML = slot.label;
        slotBox.appendChild(editBnt);
      } else {
        slotBox.innerHTML = slot.label.split("-").join("<br>");
      }

      if (idx === last) {
        const slotActionFwd = createSelectShoelace<PartType>({
          ...commonOptions,
          id: `${idx}-aft`,
          options: editorActionOptions,
          onChange: (partType) => {
            launchPartLaunchModal({
              partType,
              slotRefId: slot.id,
              posRef: ForeAftEnum.FWD,
              currentLabels,
              calculatedSlots,
              lcgVcgTcgAndPairings,
              applyChanges,
            });
          },
        });

        slotHolder.appendChild(slotActionFwd);
      }

      vesselSlotsHolder.appendChild(slotHolder);
    });

  return mainHolder;
}

/** Launches modals for creation, updation and deletion of parts */
function launchPartLaunchModal({
  partType,
  original,
  slotRefId,
  posRef,
  currentLabels,
  calculatedSlots,
  lcgVcgTcgAndPairings,
  applyChanges,
}: {
  partType: PartType;
  original?: IVesselParts;
  slotRefId: string;
  posRef: ForeAftEnum;
  currentLabels: Record<string, string>;
  calculatedSlots: Array<IVesselOneBaySlot>;
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  applyChanges: (data: IActionVesselPart) => void;
}) {
  const spaceAvailableCalcFn = (
    slotRefId: string,
    posRef: ForeAftEnum,
    id: string | undefined,
  ) =>
    calculateSpaceAvailableNext({
      slotRefId,
      posRef,
      calculatedSlots,
      lcgVcgTcgAndPairings,
      includeLenOfId: id,
    });

  const spaceAvailable = spaceAvailableCalcFn(slotRefId, posRef, original?.id);

  if (spaceAvailable !== undefined) {
    if (spaceAvailable <= 0) {
      createSimpleAlert(
        "general:common.error",
        "view:view3D.alerts.noSpaceAvailable",
      );
      return;
    }
    if (spaceAvailable < 1000) {
      createSimpleAlert(
        "general:common.attention",
        "view:view3D.alerts.notEnoughSpace",
        undefined,
        () => {
          launch();
        },
      );
      return;
    }
  }

  launch();

  function launch() {
    switch (partType) {
      case "addBridge":
        createModalForBridge({
          bridgeData: {
            id: original?.id,
            label:
              original?.label ||
              `Bridge ${
                calculatedSlots.filter(
                  (s) => s.type === VesselPartTypeEnum.BRIDGE,
                ).length || ""
              }`.trim(),
            slotRefId: original?.slotRefId || slotRefId,
            posRef: original?.posRef || posRef,
            len: original?.len || 0,
            heatSrcBelow:
              (original as IVesselPartBridge)?.heatSrcBelow || false,
            type: VesselPartTypeEnum.BRIDGE,
          },
          currentLabels,
          calculatedSlots,
          maxLen: spaceAvailable,
          defaultLen: 14000,
          onSubmitAction: applyChanges,
          spaceAvailableCalcFn,
        });
        break;

      case "addCrane":
        createModalForCranes({
          craneData: {
            id: original?.id,
            label:
              original?.label ||
              `Cranes ${
                calculatedSlots.filter(
                  (s) => s.type === VesselPartTypeEnum.CRANE,
                ).length + 1
              }`,
            slotRefId: original?.slotRefId || slotRefId,
            posRef: original?.posRef || posRef,
            len: original?.len || 0,
            cranes: (original as IVesselPartCrane)?.cranes || [
              CraneSideEnum.PORT,
            ],
            type: VesselPartTypeEnum.CRANE,
          },
          currentLabels,
          calculatedSlots,
          maxLen: spaceAvailable,
          defaultLen: 6096,
          onSubmitAction: applyChanges,
          spaceAvailableCalcFn,
        });
        break;

      case "addSmoke":
        createModalForSmokeStacks({
          smokeStacksData: {
            id: original?.id,
            label:
              original?.label ||
              `Smoke Stack ${
                calculatedSlots.filter(
                  (s) => s.type === VesselPartTypeEnum.SMOKE,
                ).length || ""
              }`,
            slotRefId: original?.slotRefId || slotRefId,
            posRef: original?.posRef || posRef,
            len: original?.len || 0,
            numberOfSmokeStacks:
              (original as IVesselPartSmokeStack)?.numberOfSmokeStacks ?? 1,
            type: VesselPartTypeEnum.SMOKE,
          },
          currentLabels,
          calculatedSlots,
          maxLen: spaceAvailable,
          defaultLen: 6096,
          onSubmitAction: applyChanges,
          spaceAvailableCalcFn,
        });
        break;

      case "addSpacer":
        createModalForSpacer({
          spacerData: {
            id: original?.id,
            label:
              original?.label ||
              `Gap ${
                calculatedSlots.filter(
                  (s) => s.type === VesselPartTypeEnum.SPACER,
                ).length + 1
              }`,
            slotRefId: original?.slotRefId || slotRefId,
            posRef: original?.posRef || posRef,
            len: original?.len || 0,
            heatSrcBelow:
              (original as IVesselPartSpacer)?.heatSrcBelow || false,
            type: VesselPartTypeEnum.SPACER,
          },
          currentLabels,
          calculatedSlots,
          maxLen: spaceAvailable,
          defaultLen: 6096,
          onSubmitAction: applyChanges,
          spaceAvailableCalcFn,
        });
        break;
    }
  }
}

type PartType = "addBridge" | "addCrane" | "addSmoke" | "addSpacer";

type PickCommonOptionsTedivoShoelace = Pick<
  ITedivoShoelaceSelectProps<PartType>,
  | "caret"
  | "ommitCheckSign"
  | "iconPrefix"
  | "buttonText"
  | "outlined"
  | "variant"
  | "size"
  | "pill"
  | "hoist"
>;
