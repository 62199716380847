/**
 * Creates an Object from Array for a given key function
 * @param arr Array of T
 * @param keyFn Function from T to generate key of object
 * @param warnDuplicates if true will console.warn duplicated keys
 * @returns Object
 */
export function createDictionary<T, U extends string>(
  arr: T[],
  keyFn: (d: T) => U,
  warnDuplicates = false,
): Record<U, T> {
  const dict = {} as Record<U, T>;
  arr.reduce((acc, sData) => {
    const key = keyFn(sData);
    if (warnDuplicates && acc[key]) {
      // console.warn(`key: "${key}" duplicated`);
    } else {
      acc[key] = sData;
    }
    return acc;
  }, dict);
  return dict;
}

/**
 * Creates a dictionary of grouped keys
 * @param arr Array of T
 * @param keyFn Function from T to generate key of object
 * @returns Object { name: T[] }
 */
export function createDictionaryMultiple<T, U extends string>(
  arr: T[],
  keyFn: (d: T) => U,
): Record<U, T[]> {
  const dict = {} as Record<U, T[]>;
  arr.reduce((acc, sData) => {
    const key = keyFn(sData);
    if (!acc[key]) acc[key] = [];
    acc[key].push(sData);
    return acc;
  }, dict);
  return dict;
}

export function flattenObject(
  ob: Record<string, unknown>,
  accessorSeparator = ".",
) {
  const toReturn: Record<string, unknown> = {};

  for (const i in ob) {
    // eslint-disable-next-line no-prototype-builtins
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === "object" && ob[i] !== null) {
      const flatObject = flattenObject(
        ob[i] as unknown as Record<string, unknown>,
      );
      for (const x in flatObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + accessorSeparator + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export function cloneObject<T>(obj: T): T | undefined {
  if (obj === undefined) return undefined;

  if ("structuredClone" in globalThis) {
    return globalThis.structuredClone(obj);
  }

  return JSON.parse(JSON.stringify(obj));
}

export function arraysAreEqual<T>(
  oldData: Array<T> | undefined | null,
  newData: Array<T> | undefined | null,
  omitUndefined = false,
): boolean {
  if (!oldData || !newData) return false;
  if (oldData.length !== newData.length) return false;

  for (let i = 0; i < oldData.length; i++) {
    if (!objectsAreEqual<T>(oldData[i], newData[i], omitUndefined))
      return false;
  }

  return true;
}

export function objectsAreEqual<T>(a: T, b: T, omitUndefined = false): boolean {
  if (a === null || b === null) return false;

  if (typeof a !== "object" || typeof b !== "object") {
    return a === b;
  }

  const aKeys = a
      ? Object.keys(a)
          .filter((k) => !omitUndefined || a[k as keyof T] !== undefined)
          .sort()
      : undefined,
    bKeys = b
      ? Object.keys(b)
          .filter((k) => !omitUndefined || b[k as keyof T] !== undefined)
          .sort()
      : undefined;

  if (a === undefined || b === undefined) return false;
  if (aKeys?.length !== bKeys?.length) return false;
  if (aKeys?.join("|") !== bKeys?.join("|")) return false;
  if (aKeys === undefined || bKeys === undefined) return false;

  for (let i = 0; i < aKeys.length; i++) {
    const key = aKeys[i] as keyof T;

    if (typeof a[key] !== typeof b[key]) return false;

    if (typeof a[key] === "object") {
      if (!objectsAreEqual(a[key], b[key], omitUndefined)) return false;
    } else if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}

/**
 * Calculates the difference between two arrays (added and removed elements) without order consideration
 * @param oldData T
 * @param newData T
 * @param omitUndefined boolean = false
 * @returns
 */
export function diffArrays<T>(
  oldData: Array<T> | undefined | null,
  newData: Array<T> | undefined | null,
  omitUndefined = false,
): { added?: Array<T>; removed?: Array<T> } {
  if (!oldData || !newData) return {};

  const added = newData.filter((newItem) => {
    return !oldData.some((oldItem) =>
      objectsAreEqual(oldItem, newItem, omitUndefined),
    );
  });

  const removed = oldData.filter((oldItem) => {
    return !newData.some((newItem) =>
      objectsAreEqual(newItem, oldItem, omitUndefined),
    );
  });

  return { added, removed };
}
