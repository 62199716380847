import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
  IMasterCGs,
  IOpenVesselDefinitionV1,
  TContainerLengths,
} from "open-vessel-definition";
import {
  IBayPattern,
  IRowPattern,
  ITierPattern,
  getRowsAndTiersFromSlotKeys,
  getSizesFromSlots,
  sortRowsArray,
} from "tedivo-bay-grid-pure";

import { IJoinedRowTierPattern } from "open-vessel-definition/build/src/models/base/types/IPositionPatterns";
import { sortNumericAsc } from "@baplie-viewer2/tedivo-pure-helpers";

export function calculateMissingCGs(
  json: IOpenVesselDefinitionV1,
): IMissingCGs {
  const missingLCGs: IMissingBayLCG[] = [];
  const missingBulkheadLCGs: IMissingBayBulkheadLCG[] = [];

  const baysData = json.baysData;
  const masterCGs = json.shipData.masterCGs || ({} as IMasterCGs);

  const rowsAbove = calculateRowsFromSlots(
    baysData.filter((bl) => bl.level === BayLevelEnum.ABOVE),
  );
  const rowsBelow = calculateRowsFromSlots(
    baysData.filter((bl) => bl.level === BayLevelEnum.BELOW),
  );

  // 1. TCGs ----------------------------------------------
  // Check only for Master TCGs
  const masterAboveTCGs = rowsAbove.reduce((acc, row) => {
    if (masterCGs.aboveTcgs[row] === undefined) acc.push(row);
    return acc;
  }, [] as string[]);

  const masterBelowTCGs = rowsBelow.reduce((acc, row) => {
    if (masterCGs.belowTcgs[row] === undefined) acc.push(row);
    return acc;
  }, [] as string[]);

  // 2. VCGs ----------------------------------------------
  // Check only for Master Bottom Bases
  const minBases = new Set<ITierPattern>();
  baysData.forEach((bl) => {
    const perSlotInfo = bl.perSlotInfo;
    if (perSlotInfo !== undefined) {
      const { minTier } = getRowsAndTiersFromSlotKeys(
        Object.keys(perSlotInfo) as IJoinedRowTierPattern[],
      );
      if (minTier) minBases.add(minTier);
    }
  });

  const masterVCGs = Array.from(minBases)
    .filter((tier) => Number(tier) < 200)
    .reduce((acc, tier) => {
      if (masterCGs.bottomBases[tier] === undefined) acc.push(tier);
      return acc;
    }, [] as string[]);

  // 3. LCGs ----------------------------------------------
  baysData.forEach((bl) => {
    const perSlotInfo = bl.perSlotInfo;
    if (perSlotInfo !== undefined) {
      const sizes = getSizesFromSlots(perSlotInfo).sizes;
      sizes.forEach((size) => {
        if (bl.infoByContLength?.[size]?.lcg === undefined) {
          missingLCGs.push({
            bay: bl.isoBay,
            level: bl.level,
            size,
          });
        }
      });
    }

    const bulkhead = bl.bulkhead;

    if (bulkhead !== undefined) {
      if (bulkhead.aft && bulkhead.aftLcg === undefined)
        missingBulkheadLCGs.push({
          bay: bl.isoBay,
          level: bl.level,
          pos: ForeAftEnum.AFT,
        });
      if (bulkhead.fore && bulkhead.foreLcg === undefined)
        missingBulkheadLCGs.push({
          bay: bl.isoBay,
          level: bl.level,
          pos: ForeAftEnum.FWD,
        });
    }
  });

  const totalMissingCGs =
    masterAboveTCGs.length +
    masterBelowTCGs.length +
    masterVCGs.length +
    missingLCGs.length +
    missingBulkheadLCGs.length;

  return {
    masterAboveTCGs: masterAboveTCGs.sort(sortRowsArray),
    masterBelowTCGs: masterBelowTCGs.sort(sortRowsArray),
    masterVCGs: masterVCGs.sort(sortNumericAsc),
    missingLCGs,
    missingBulkheadLCGs,
    totalMissingCGs,
  };
}

function calculateRowsFromSlots(baysData: IBayLevelData[] = []) {
  const rows = new Set<IRowPattern>();
  baysData.forEach((bl) => {
    const rowsFromSlotsInfo = bl.perSlotInfo
      ? Object.keys(bl.perSlotInfo).map((s) => s.substring(0, 2))
      : [];
    rowsFromSlotsInfo.forEach((r) => rows.add(r as IRowPattern));
  });
  return Array.from(rows);
}

interface IMissingBayLCG {
  bay: IBayPattern;
  level: BayLevelEnum;
  size: TContainerLengths;
}

interface IMissingBayBulkheadLCG {
  bay: IBayPattern;
  level: BayLevelEnum;
  pos: ForeAftEnum;
}

interface IMissingCGs {
  masterAboveTCGs: string[];
  masterBelowTCGs: string[];
  masterVCGs: string[];
  missingLCGs: IMissingBayLCG[];
  missingBulkheadLCGs: IMissingBayBulkheadLCG[];
  totalMissingCGs: number;
}
