import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlDropdown from "@shoelace-style/shoelace/dist/components/dropdown/dropdown";
import SlMenu from "@shoelace-style/shoelace/dist/components/menu/menu";
import SlMenuItem from "@shoelace-style/shoelace/dist/components/menu-item/menu-item";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";

export class SelectShoelace<TOut = string> extends HTMLElement {
  public static observedAttributes = [];

  public readonly isSelectShoelace = true;

  private _disabled = false;

  name = "";
  title = "";
  buttonText: string | HTMLElement = "";
  buttonNodeType: "sl-button" | "sl-icon-button" | undefined = "sl-button";
  caret = false;
  pill = false;
  size: "small" | "medium" | "large" = "medium";
  variant: ShoelaceVariant = "default";
  outlined = false;
  textAlignment?: "left" | "center" | "right" = undefined;
  placement?:
    | "top"
    | "top-start"
    | "top-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "right"
    | "right-start"
    | "right-end"
    | "left"
    | "left-start"
    | "left-end" = "bottom-start";
  options: ITedivoShoelaceSelectOptions[] = [];
  ommitCheckSign = false;
  iconPrefix?: string;
  iconSuffix?: string;
  autoScroll?: boolean;
  hoist?: boolean;
  onChange?: (
    value: TOut,
    oldValue: TOut | undefined,
    selectNode: SelectShoelace<TOut>,
  ) => void;
  updateButtonText: ((value: TOut) => string) | undefined = undefined;
  onNodesCreated?: (
    items: { [v: string]: SlMenuItem },
    button: SlButton,
  ) => void;

  valueInternal = "";

  private items: { [v: string]: SlMenuItem } = {};
  private button: SlButton;
  private menu: SlMenu;
  dropdown: SlDropdown;

  get value() {
    return this.valueInternal;
  }

  set value(s: string) {
    if (this.valueInternal === s) return;

    if (this.items[this.valueInternal] && !this.ommitCheckSign)
      this.items[this.valueInternal].checked = false;

    if (this.items[s] && !this.ommitCheckSign) this.items[s].checked = true;

    this.valueInternal = s;

    if (this.updateButtonText) {
      const text = this.updateButtonText(this.value as unknown as TOut);
      if (text && this.button) {
        this.button.innerHTML = text;

        if (this.iconPrefix) {
          const iconPrx = document.createElement("sl-icon");
          iconPrx.slot = "prefix";
          iconPrx.setAttribute("name", this.iconPrefix);
          this.button.appendChild(iconPrx);
        }

        if (this.iconSuffix) {
          const iconSfx = document.createElement("sl-icon");
          iconSfx.slot = "suffix";
          iconSfx.setAttribute("name", this.iconSuffix);
          this.button.appendChild(iconSfx);
        }
      }
    }
  }

  get disabled() {
    return this._disabled;
  }
  set disabled(d: boolean) {
    if (this._disabled === d) return;

    this._disabled = d;
    if (this.button) this.button.disabled = d;
  }

  constructor() {
    super();

    const dropdown = document.createElement("sl-dropdown");
    const button = document.createElement("sl-button");
    const menu = document.createElement("sl-menu");

    this.button = button;
    this.dropdown = dropdown;
    this.menu = menu;

    this.setAttribute("exportparts", "trigger-btn");
  }

  connectedCallback() {
    const dropdown = this.dropdown;
    const button = this.button;

    dropdown.id = this.name;
    dropdown.className = this.className;
    if (this.placement) dropdown.placement = this.placement;

    if (typeof this.buttonText === "string") button.innerHTML = this.buttonText;
    else button.appendChild(this.buttonText);

    if (this.iconPrefix) {
      const iconPrx = document.createElement("sl-icon");
      iconPrx.slot = "prefix";
      iconPrx.setAttribute("name", this.iconPrefix);
      button.appendChild(iconPrx);
    }

    if (this.iconSuffix) {
      const iconSfx = document.createElement("sl-icon");
      iconSfx.slot = "suffix";
      iconSfx.setAttribute("name", this.iconSuffix);
      button.appendChild(iconSfx);
    }

    if (this.hoist) dropdown.hoist = true;

    button.slot = "trigger";
    button.variant = this.variant;
    button.caret = this.caret;
    button.pill = this.pill;
    button.size = this.size;
    button.outline = this.outlined;
    button.disabled = !!this._disabled;
    button.setAttribute("part", "trigger-btn");

    if (this.title) button.title = this.title;

    const { menu, items } = createMenuList({
      menuNode: this.menu,
      options: this.options,
      ommitCheckSign: this.ommitCheckSign,
      currentValue: this.value,
      textAlignment: this.textAlignment,
    });

    this.items = items;

    dropdown.appendChild(button);
    dropdown.appendChild(menu);
    dropdown.setAttribute("exportparts", "trigger-btn");

    menu.addEventListener(
      "sl-select",
      (ev: Event) => {
        const prevValue = this.value;
        const item = (ev as CustomEvent).detail.item;
        this.value = item.value;

        if (this.onChange) {
          this.onChange(
            this.value as unknown as TOut,
            prevValue as unknown as TOut,
            this,
          );
        }
      },
      false,
    );

    if (this.autoScroll) {
      const autoScrollFn = () => {
        const isOpened = menu.getClientRects().length === 0;
        const itemSel = this.items[this.valueInternal];
        if (isOpened && itemSel)
          setTimeout(
            () =>
              itemSel.scrollIntoView({ block: "center", behavior: "smooth" }),
            100,
          );
      };
      button.addEventListener("click", autoScrollFn, false);
      button.addEventListener("keydown", autoScrollFn, false);
    }

    this.appendChild(dropdown);

    if (this.onNodesCreated) this.onNodesCreated(this.items, button);
  }
}

customElements.define("tf-select", SelectShoelace);

declare global {
  interface HTMLElementTagNameMap {
    "tf-select": SelectShoelace;
  }
}

export function createSelectShoelace<TOut = string>({
  id,
  title,
  buttonText,
  caret,
  pill,
  size,
  options,
  selectedValue,
  autoScroll,
  className,
  ommitCheckSign,
  variant,
  outlined,
  placement,
  iconPrefix,
  iconSuffix,
  disabled,
  hoist,
  buttonNodeType,
  textAlignment,
  onChange,
  updateButtonText,
  onNodesCreated,
}: ITedivoShoelaceSelectProps<TOut>): SelectShoelace<TOut> {
  const selectShoelace = new SelectShoelace<TOut>();

  selectShoelace.id = id;
  selectShoelace.buttonText = buttonText;
  selectShoelace.iconPrefix = iconPrefix;
  selectShoelace.iconSuffix = iconSuffix;
  selectShoelace.caret = !!caret;
  selectShoelace.pill = !!pill;
  selectShoelace.size = size || "medium";
  selectShoelace.options = options;
  selectShoelace.variant = variant || "default";
  selectShoelace.outlined = !!outlined;
  selectShoelace.autoScroll = !!autoScroll;
  selectShoelace.placement = placement;
  selectShoelace.disabled = disabled || false;
  selectShoelace.title = title || "";
  selectShoelace.hoist = hoist || false;
  selectShoelace.buttonNodeType = buttonNodeType;

  if (className) selectShoelace.className = className;
  if (textAlignment) selectShoelace.textAlignment = textAlignment;

  if (ommitCheckSign) selectShoelace.ommitCheckSign = true;

  if (selectedValue !== undefined) selectShoelace.value = String(selectedValue);

  if (onChange) selectShoelace.onChange = onChange;
  if (updateButtonText) selectShoelace.updateButtonText = updateButtonText;
  if (onNodesCreated) selectShoelace.onNodesCreated = onNodesCreated;

  return selectShoelace;
}

export function createMenuList({
  options,
  ommitCheckSign,
  currentValue,
  menuNode,
  textAlignment,
}: {
  options: ITedivoShoelaceSelectOptions[];
  ommitCheckSign?: boolean;
  currentValue?: string;
  menuNode?: SlMenu;
  textAlignment?: "left" | "right" | "center";
}) {
  const items: { [v: string]: SlMenuItem } = {};

  const menu = menuNode || document.createElement("sl-menu");
  if (menuNode) {
    removeChildren(menu);
    if (textAlignment) menu.style.textAlign = textAlignment;
  }

  options.forEach(({ name, value, divider, variant, icon }) => {
    if (divider !== undefined) {
      menu.appendChild(document.createElement("sl-divider"));
    } else {
      const val = String(value);
      const item = document.createElement("sl-menu-item");
      item.value = val;
      item.textContent = name || "";
      item.title = name || "";

      if (variant) item.classList.add(variant);
      if (icon) {
        const iconEl = document.createElement("sl-icon");
        iconEl.setAttribute("name", icon);
        iconEl.setAttribute("slot", "prefix");
        item.append(iconEl);
      }

      menu.appendChild(item);

      items[val] = item;

      if (!ommitCheckSign) {
        item.type = "checkbox";
        if (currentValue === val) {
          item.checked = true;
        }
      }
    }
  });

  return {
    menu,
    items,
  };
}

export interface ITedivoShoelaceSelectProps<TOut> {
  id: string;
  title?: string;
  className?: string;
  /** Button props */
  buttonText: string | HTMLElement;
  buttonNodeType?: "sl-button" | "sl-icon-button";
  caret?: boolean;
  pill?: boolean;
  size?: "small" | "medium" | "large";
  variant?: ShoelaceVariant;
  outlined?: boolean;
  disabled?: boolean;
  textAlignment?: "left" | "right" | "center";
  placement?:
    | "top"
    | "top-start"
    | "top-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "right"
    | "right-start"
    | "right-end"
    | "left"
    | "left-start"
    | "left-end";
  ommitCheckSign?: boolean;
  iconPrefix?: string;
  iconSuffix?: string;
  /** Options props */
  options: ITedivoShoelaceSelectOptions[];
  selectedValue?: TOut;
  autoScroll?: boolean;
  hoist?: boolean;
  onChange?: (
    value: TOut,
    oldValue: TOut | undefined,
    selectNode: SelectShoelace<TOut>,
  ) => void;
  updateButtonText?: (value: TOut) => string;
  onNodesCreated?: (
    items: { [v: string]: SlMenuItem },
    button: SlButton,
  ) => void;
}

export type ITedivoShoelaceSelectOptions =
  | {
      name: string;
      value: string | number;
      variant?: ShoelaceVariant;
      icon?: string;
      divider?: never;
    }
  | {
      name?: never;
      value?: never;
      variant?: never;
      icon?: never;
      divider: boolean;
    };

export type ShoelaceVariant =
  | "default"
  | "primary"
  | "success"
  | "neutral"
  | "warning"
  | "danger"
  | "text";
