import {
  BayLevelEnum,
  IBayLevelData,
  ILidData,
  IShipData,
  ISizeSummary,
  ISlotData,
  TContainerLengths,
} from "open-vessel-definition";
import {
  GRID_OPTIONS_BY_CELL_DIMENSION,
  GRID_OPTIONS_BY_CELL_GAP,
} from "./gridConfigConstants";
import {
  IBayPattern,
  ISlotCombinedPattern,
  createTiersFromConfig,
} from "tedivo-bay-grid-pure";
import {
  ICellPositions,
  ICreateGridFromConfigProps,
  INodesOptions,
  IReturnDrawFunction,
  createGridFromConfig,
} from "@baplie-viewer2/tedivo-bay-grid-core";
import {
  IntersectStatusEnum,
  createObserverWithCallback,
} from "../../../../helpers/createObserverWithCallback";

import CellSpacingEnum from "../../../../../app/enums/CellSpacingEnum";
import EditDrawer from "../../../../common/EditDrawer";
import { IValidationResult } from "../../../../../app/stores/validators/types";
import SizeSmallMidLargeEnum from "../../../../../app/enums/SizeSmallMidLargeEnum";
import { TEditBayButtonFunction } from "../../render/view-json-render";
import { getHeaderDetailPills } from "./getHeaderDetailPills";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";

const EXTRA_SPACE_TOP = 60;

class BayBoxComponent extends HTMLElement {
  private bayBox: HTMLDivElement;
  private data: IData | undefined = undefined;

  private nodesOptions: INodesOptions | undefined = undefined;

  private createEditBayButtonFunction: TEditBayButtonFunction | undefined;
  private createFnSlotCell:
    | ((
        cellSize: SizeSmallMidLargeEnum,
      ) => (slotData: ISlotData) => IReturnDrawFunction)
    | undefined;
  private drawStackWeights:
    | ((props: {
        bayAbove: IBayLevelData | undefined;
        bayBelow: IBayLevelData | undefined;
        cellPositions: ICellPositions;
        cellSize?: SizeSmallMidLargeEnum;
        cellPadding?: CellSpacingEnum;
      }) => HTMLElement)
    | undefined;

  private _holderIntersectionStatus: IntersectStatusEnum;

  constructor() {
    super();

    this._holderIntersectionStatus = IntersectStatusEnum.Initialized;

    this.bayBox = document.createElement("div");
    this.bayBox.setAttribute("part", "box");
  }

  get holderIntersectionStatus() {
    return this._holderIntersectionStatus;
  }

  setSize(width: number, height: number): this {
    const bayBox = this.bayBox;
    bayBox.style.width = `${width}px`;
    bayBox.style.height = `${height + EXTRA_SPACE_TOP}px`;
    return this;
  }

  async setData(data: IData): Promise<this> {
    this.setAttribute("data-bay", data.isoBay);
    this.data = data;
    this.drawWithObserver(this.shadowRoot || undefined);

    return this;
  }

  setDrawingColors(nodesOptions: INodesOptions) {
    this.nodesOptions = nodesOptions;
    return this;
  }

  setDrawingProps({
    createEditBayButtonFunction,
    createFnSlotCell,
    drawStackWeights,
  }: {
    createEditBayButtonFunction: TEditBayButtonFunction;
    createFnSlotCell: (
      cellSize: SizeSmallMidLargeEnum,
    ) => (slotData: ISlotData) => IReturnDrawFunction;
    drawStackWeights: (props: {
      bayAbove: IBayLevelData | undefined;
      bayBelow: IBayLevelData | undefined;
      cellPositions: ICellPositions;
      cellSize?: SizeSmallMidLargeEnum;
      cellPadding?: CellSpacingEnum;
    }) => HTMLElement;
  }) {
    this.createFnSlotCell = createFnSlotCell;
    this.createEditBayButtonFunction = createEditBayButtonFunction;
    this.drawStackWeights = drawStackWeights;
    return this;
  }

  /** Prepares the box to be drawn when visible */
  drawWithObserver(root?: ShadowRoot) {
    if (!this.data?.isoBay || !root) {
      return;
    }

    const bayBox = this.bayBox;
    removeChildren(bayBox);

    if (
      this.holderIntersectionStatus ===
      IntersectStatusEnum.WaitingForIntersection
    ) {
      return;
    }

    this._holderIntersectionStatus = IntersectStatusEnum.WaitingForIntersection;

    const ioObserver = createObserverWithCallback(
      this.createGridOfBay(bayBox),
      { delay: 1 },
    );

    ioObserver.observe(bayBox);

    root.appendChild(bayBox);
  }

  updateData(newData: Partial<IData>): this {
    this.data = {
      ...(this.data as IData),
      ...newData,
    };

    if (this.shadowRoot) {
      removeChildren(this.bayBox);
      this.drawWithObserver(this.shadowRoot);
    }
    return this;
  }

  connectedCallback() {
    const shadowRoot = this.attachShadow({ mode: "open" });
    this.drawWithObserver(shadowRoot);
  }

  private createGridOfBay = (bayBox: HTMLDivElement) => {
    return () => {
      if (!this.data) return;

      const {
        sizeSummary,
        shipData,
        lidData,
        bays,
        isoBay,
        bayAbove,
        bayBelow,
        enabledCells,
        slotsDataAbove,
        slotsDataBelow,
        slotsData,
        width,
        height,
        ssMaxRow,
        cellSize,
        cellSeparation,
        validationResult,
      } = this.data;

      this._holderIntersectionStatus = IntersectStatusEnum.Rendered;

      console.time(`createGridFromConfig ${isoBay}`);

      const top = document.createElement("div");
      top.setAttribute("part", "top");
      const h3 = document.createElement("h3");
      h3.setAttribute("part", "title");
      h3.innerHTML = bays.map((b, idx) => (idx === 0 ? b : `(${b})`)).join(" ");
      top.appendChild(h3);

      const editBtn = this.createEditBayButtonFunction?.(
        isoBay,
        sizeSummary,
        shipData,
        bayAbove,
        bayBelow,
        enabledCells,
        slotsDataAbove,
        slotsDataBelow,
        this.createFnSlotCell!,
      );

      if (editBtn) top.appendChild(editBtn);

      bayBox.appendChild(top);
      bayBox.appendChild(
        getHeaderDetailPills(bayAbove, bayBelow, validationResult),
      );

      /** In case we need to show as disabled the 00 row column for some bays */
      const prohibitedRow00: ISlotCombinedPattern[] = sizeSummary.centerLineRow
        ? get00RowCells(
            sizeSummary,
            !(bayAbove === undefined || bayAbove?.centerLineRow),
            !(bayBelow === undefined || bayBelow?.centerLineRow),
          )
        : [];

      const { svgGroup, symbols, cellPositions } =
        createGridFromConfig<ISlotData>({
          ...GRID_OPTIONS_BY_CELL_DIMENSION[cellSize],
          ...GRID_OPTIONS_BY_CELL_GAP[cellSeparation],
          ...this.data.sizeSummary,
          maxRow: ssMaxRow,
          centerLineRow:
            bayAbove?.centerLineRow || bayBelow?.centerLineRow ? 1 : 0,
          nodesOptions: this.nodesOptions || {},
          enabledCells,
          prohibitedCells: prohibitedRow00,
          cellsDrawFunction: this.createFnSlotCell!(cellSize),
          cellsToDraw: slotsData,
          lidData,
        });

      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
      svg.setAttribute("width", `${width}px`);
      svg.setAttribute("height", `${height}px`);
      svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
      svg.setAttribute("part", "svg-bay");
      svg.setAttribute("role", "figure");
      if (symbols)
        Object.keys(symbols).forEach((n) => svg.appendChild(symbols[n]));
      svg.appendChild(svgGroup);

      console.timeEnd(`createGridFromConfig ${isoBay}`);

      bayBox.appendChild(svg);

      if (cellSize === SizeSmallMidLargeEnum.XLARGE && this.drawStackWeights)
        bayBox.appendChild(
          this.drawStackWeights({
            bayAbove,
            bayBelow,
            cellPositions,
            cellSize,
            cellPadding: cellSeparation,
          }),
        );
    };
  };
}

export default BayBoxComponent;

customElements.define("bay-box-component", BayBoxComponent);

declare global {
  interface HTMLElementTagNameMap {
    "bay-box-component": BayBoxComponent;
  }
}

function get00RowCells(
  sizeSummary: ISizeSummary,
  aboveShouldHide00 = false,
  belowShouldHide00 = false,
): ISlotCombinedPattern[] {
  const tiers = [
    ...(aboveShouldHide00
      ? createTiersFromConfig(
          sizeSummary.minAboveTier,
          sizeSummary.maxAboveTier,
        )
      : []),
    ...(belowShouldHide00
      ? createTiersFromConfig(
          sizeSummary.minBelowTier,
          sizeSummary.maxBelowTier,
        )
      : []),
  ];
  return tiers.map((tier) => `00|${tier}` as ISlotCombinedPattern);
}

// async function createDataHash(data: IData): Promise<string> {
//   const { shipData, ...importantData } = data;

//   const d = flattenObject(importantData as unknown as Record<string, unknown>);
//   const fullStr = Object.keys(d)
//     .sort()
//     .map((k) => `${k}:${d[k]}`)
//     .join("|");

//   let hash = 0;
//   for (let i = 0, len = fullStr.length; i < len; i++) {
//     let chr = fullStr.charCodeAt(i);
//     hash = (hash << 5) - hash + chr;
//     hash |= 0; // Convert to 32bit integer
//   }

//   return String(hash);
// }

interface IData {
  sizeSummary: ISizeSummary;
  shipData: IShipData;
  lidData: Array<ILidData>;
  bays: IBayPattern[];
  isoBay: IBayPattern;
  bayAbove: IBayLevelData | undefined;
  bayBelow: IBayLevelData | undefined;
  enabledCells: ISlotCombinedPattern[];
  slotsDataAbove: ISlotData[];
  slotsDataBelow: ISlotData[];
  slotsData: ISlotData[];
  width: number;
  height: number;
  ssMaxRow: number;
  cellSize: SizeSmallMidLargeEnum;
  cellSeparation: CellSpacingEnum;
  validationResult: IValidationResult[];
}

export interface IBayEditProps {
  isoBay: IBayPattern;
  level: BayLevelEnum;
  availableLengths: Array<TContainerLengths>;
  bayData: IBayLevelData | undefined;
  sizeSummary: ISizeSummary;
  shipData: IShipData;
  gridData: ICreateGridFromConfigProps<ISlotData>;
  editDrawer?: EditDrawer;
  readOnlyMode: boolean;
}
