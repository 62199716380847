import { ForeAftEnum, IBayLevelData } from "open-vessel-definition";

import { IAllBaysWith40sArePaired } from "packages/oss-editor/src/app/stores/validators/allBaysWith40sArePaired";
import { IValidationResult } from "packages/oss-editor/src/app/stores/validators/types";
import { getTranslation } from "packages/oss-editor/src/app/i18/i18tn";

export function getHeaderDetailPills(
  blA: IBayLevelData | undefined,
  blB: IBayLevelData | undefined,
  validationResult: IValidationResult[],
): HTMLElement {
  const holder = document.createElement("div");
  holder.setAttribute("part", "details-holder");

  if (blA === undefined && blB === undefined) {
    return holder;
  }

  const foreAftTranslations: { [key in ForeAftEnum | 0]: string } = {
    [ForeAftEnum.AFT]: getTranslation(`enums:ForeAftEnum.AFT`),
    [ForeAftEnum.FWD]: getTranslation(`enums:ForeAftEnum.FWD`),
    [0]: getTranslation(`general:common.unknown`),
  };

  const arrowDirection: { [key in ForeAftEnum | 0]: string } = {
    [ForeAftEnum.AFT]: "→",
    [ForeAftEnum.FWD]: "←",
    [0]: "?",
  };

  const yesNoTranslations = (v: boolean) =>
    getTranslation(v ? "general:common.yes" : "general:common.no");

  const yesNoSymbols = (v: boolean) => (v ? "✓" : "&times;");

  const allBaysWith40sArePairedResult = validationResult.find(
    (v) => v.name === "allBaysWith40sArePaired",
  ) as IAllBaysWith40sArePaired | undefined;

  drawSingleDetail(blA, blB, {
    key: "pairedBay",
    labelKey: "view:details.paired",
    iconName: "link",
    textStrFn: (a) => foreAftTranslations[a?.pairedBay || 0],
    textIconFn: (a) => arrowDirection[a?.pairedBay || 0],
    warningFn: (a, b) => {
      if (allBaysWith40sArePairedResult) {
        return allBaysWith40sArePairedResult.invalidResults.some(
          (r) =>
            (r.bay === a?.isoBay && a.level === r.level) ||
            (r.bay === b?.isoBay && b.level === r.level),
        );
      }

      return false;
    },
  });

  drawSingleDetail(blA, blB, {
    key: "reeferPlugs",
    labelKey: "view:details.reeferPlugs",
    iconName: "plug",
    undefinedIsZero: true,
    textStrFn: (a) => foreAftTranslations[a?.reeferPlugs || 0],
    textIconFn: (a) => arrowDirection[a?.reeferPlugs || 0],
  });

  drawSingleDetail(blA, blB, {
    key: "doors",
    labelKey: "view:details.doors",
    iconName: "door-closed",
    undefinedIsZero: true,
    textStrFn: (a) => foreAftTranslations[a?.doors || 0],
    textIconFn: (a) => arrowDirection[a?.doors || 0],
  });

  drawSingleDetail(blA, blB, {
    key: "telescoping",
    labelKey: "view:details.telescoping",
    iconName: "arrow-left-right",
    ommitBothTrue: true,
    undefinedIsZero: true,
    textStrFn: (a) => yesNoTranslations(!!a?.telescoping),
    textIconFn: (a) => yesNoSymbols(!!a?.telescoping),
  });

  return holder;

  function drawSingleDetail(
    blA: IBayLevelData | undefined,
    blB: IBayLevelData | undefined,
    {
      iconName,
      labelKey,
      key,
      ommitBothTrue,
      undefinedIsZero = false,
      textStrFn,
      textIconFn,
      warningFn,
    }: {
      iconName: string;
      labelKey: string;
      key: keyof IBayLevelData;
      ommitBothTrue?: boolean;
      undefinedIsZero?: boolean;
      textStrFn: (a: IBayLevelData) => string;
      textIconFn: (a: IBayLevelData) => string;
      warningFn?: (
        a: IBayLevelData | undefined,
        b: IBayLevelData | undefined,
      ) => boolean;
    },
  ) {
    if (blA === undefined && blB === undefined) return;
    const label = getTranslation(labelKey);

    const valA = !undefinedIsZero ? blA?.[key] : blA?.[key] || 0;
    const valB = !undefinedIsZero ? blB?.[key] : blB?.[key] || 0;

    if (valA && valB && valA === valB) {
      const commonBl = blA ?? (blB as IBayLevelData);
      const title = `${label}: ${textStrFn(commonBl)}`;
      const inner = `${textIconFn(commonBl)}`;

      const badge = document.createElement("sl-badge");
      const icon = document.createElement("sl-icon");
      icon.setAttribute("name", iconName);
      icon.className = "icon-big";

      badge.title = title;
      badge.appendChild(icon);
      if (!ommitBothTrue) badge.appendChild(document.createTextNode(inner));
      badge.variant = "neutral";
      badge.pill = true;
      holder.appendChild(badge);

      if (warningFn && warningFn(blA, blB)) {
        badge.variant = "warning";
      } else {
        badge.variant = "neutral";
      }
    } else if (valA !== valB) {
      const title = [
        blA
          ? `${label} ${getTranslation(
              `enums:BayLevelEnum.ABOVE`,
            )}: ${textStrFn(blA)}`
          : undefined,
        blB
          ? `${label} ${getTranslation(
              `enums:BayLevelEnum.BELOW`,
            )}: ${textStrFn(blB)}`
          : undefined,
      ]
        .filter(Boolean)
        .join("\n");

      const inner = [
        blA ? `<sup>${textIconFn(blA)}</sup>` : undefined,
        blB ? `<sub>${textIconFn(blB)}</sub>` : undefined,
      ]
        .filter(Boolean)
        .join("/");

      const badge = document.createElement("sl-badge");
      const icon = document.createElement("sl-icon");
      icon.setAttribute("name", iconName);
      icon.className = "icon-big";

      const innerSpan = document.createElement("span");
      innerSpan.innerHTML = inner;

      badge.title = title;
      badge.appendChild(icon);
      badge.appendChild(innerSpan);

      if (warningFn && warningFn(blA, blB)) {
        badge.variant = "warning";
      } else {
        badge.variant = "neutral";
      }

      badge.pill = true;
      holder.appendChild(badge);
    }
  }
}
