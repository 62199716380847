/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
  IBaySlotData,
  ISlotData,
  TContainerLengths,
} from "open-vessel-definition";
import { IBayPattern, IRowPattern, ISlotPattern } from "tedivo-bay-grid-pure";
import {
  IBayRowInfo,
  IRowInfoByLength,
} from "open-vessel-definition/build/src/models/v1/parts/IBayLevelData";
import {
  cloneObject,
  createDictionary,
  pad3,
} from "@baplie-viewer2/tedivo-pure-helpers";

export function extract40sOfPairedBays({
  isoBay,
  level,
  bls,
  target40s,
  currentCellsData,
  setRestrictedOnEmptySlots,
}: {
  isoBay: IBayPattern;
  level: BayLevelEnum;
  bls: IBayLevelData[];
  target40s: ForeAftEnum | undefined;
  setRestrictedOnEmptySlots: boolean;
  currentCellsData?: ISlotData[];
}): ITempInventoryOutput | undefined {
  if (target40s === undefined) {
    return; // -> Fast return, no target of 40s selected
  }

  const blsClone = JSON.parse(JSON.stringify(bls)) as IBayLevelData[];

  const baseBay = blsClone.find(
    (bl) => bl.isoBay === isoBay && bl.level === level,
  );

  if (!baseBay || !baseBay.pairedBay) {
    return undefined; // -> Fast return, no paired bays
  }

  const pairedToIsoBay =
    Number(isoBay) + (baseBay.pairedBay === ForeAftEnum.FWD ? -2 : 2);

  const pairedBay = blsClone.find(
    (bl) => bl.isoBay === pad3(pairedToIsoBay) && bl.level === level,
  );

  if (!pairedBay) {
    return undefined; // -> Fast return, no paired bays
  }

  // Copy of BLs, sorted by pairedBay (FWD first)
  const pairedBls = [baseBay, pairedBay].sort(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (a, b) => b.pairedBay! - a.pairedBay!,
  );

  const pairedBlsDict = createDictionary(
    pairedBls,
    (b) => `${b.isoBay}-${b.level}`,
  );

  /** Temporary inventory of 40s. Extract first, co-locate after */
  const tempInventory4xs: { [slot: ISlotPattern]: ISlotData } = {};
  /** Temporary inventory of 40s. Extract first, co-locate after */
  const tempInventoryReefers: { [slot: ISlotPattern]: boolean } = {};
  /** Temporary inventory of LCGs of 40s. Extract first, co-locate after */
  const tempInventoryICL: TTempInventoryInfoByLength = {};
  /** Temporary inventory of LCGs of 40s. Extract first, co-locate after */
  const tempInventoryRowEach: { [row: IRowPattern]: IBayRowInfo } = {};
  /** Temporary inventory of Common Row Info. Extract first, co-locate after */
  //const tempInventoryRowCommon: TCommonBayInfo = {};

  let currentPerSlotInfo: IBaySlotData | undefined = undefined;

  // 0. Apply current edits of CellsData to corresponding BayLevelData, or use existing
  if (!pairedBlsDict[`${isoBay}-${level}`]) {
    return undefined; // -> Fast return, no paired bays
  }

  if (currentCellsData) {
    // Replace perSlotsData with currentCellsData
    currentPerSlotInfo = currentCellsData.reduce((acc, slotData) => {
      const clonedSlot = { ...slotData };
      if (slotData.sizes) clonedSlot.sizes = { ...slotData.sizes };

      acc[slotData.pos] = clonedSlot;
      return acc;
    }, {} as IBaySlotData);

    pairedBlsDict[`${isoBay}-${level}`].perSlotInfo = currentPerSlotInfo;
  }

  // A. Extract all 40s and CGs of 40s
  pairedBls.forEach((bl) => {
    const perSlotInfo = bl.perSlotInfo;

    // A.1. Find 40s in each slot and move it to temporary inventory
    if (perSlotInfo) {
      const slots = Object.keys(perSlotInfo) as ISlotPattern[];

      slots.forEach((pos) => {
        if (!perSlotInfo[pos].sizes) return;

        const sizes4x = Object.keys(perSlotInfo[pos].sizes)
          .map(Number)
          .filter((size) => size >= 40) as TContainerLengths[];

        if (sizes4x.length > 0) {
          if (!tempInventory4xs[pos]) {
            tempInventory4xs[pos] = { pos, sizes: {} };
          }

          sizes4x.forEach((size) => {
            // Add to inventory
            tempInventory4xs[pos].sizes[size] = perSlotInfo[pos].sizes[size];
            // Remove 40s from slot
            delete perSlotInfo[pos].sizes[size];
          });

          const remainingSizes = Object.keys(perSlotInfo[pos].sizes).length;

          if (setRestrictedOnEmptySlots && !remainingSizes) {
            perSlotInfo[pos].restricted = 1;
          }

          if (remainingSizes === 0 && perSlotInfo[pos].reefer) {
            delete perSlotInfo[pos].reefer;
            tempInventoryReefers[pos] = true;
          }
        }
      });
    }

    // A.2. Find BAY LCGs of each size and move it to temporary inventory
    const infoByContLength = bl.infoByContLength;
    if (infoByContLength) {
      const sizes4x = Object.keys(infoByContLength)
        .map(Number)
        .filter((size) => size >= 40) as TContainerLengths[];

      sizes4x.forEach((size) => {
        tempInventoryICL[size] = cloneObject(
          infoByContLength[size] || { size },
        );
        delete infoByContLength[size];
      });
    }

    // A.3. Find ROW LCGs of each size and move it to temporary inventory
    const perRowInfo = bl.perRowInfo;
    if (perRowInfo) {
      // A.3.1 Each row
      if (perRowInfo?.each !== undefined) {
        const rows = Object.keys(perRowInfo.each) as IRowPattern[];
        rows.forEach((row) => {
          const rowInfo = perRowInfo.each?.[row];

          // tcg?: number;
          // bottomBase?: number;
          // maxHeight?: number;

          if (rowInfo?.rowInfoByLength) {
            const sizes4x = Object.keys(rowInfo.rowInfoByLength)
              .map(Number)
              .filter((size) => size >= 40) as TContainerLengths[];

            if (!tempInventoryRowEach[row]) {
              tempInventoryRowEach[row] = {
                ...rowInfo,
                rowInfoByLength: {},
              };
            }

            sizes4x.forEach((size) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              tempInventoryRowEach[row].rowInfoByLength![size] = cloneObject(
                rowInfo.rowInfoByLength?.[size],
              );
              delete rowInfo.rowInfoByLength?.[size];
            });
          }
        });
      }

      // A.3.2 Common rows
      // if (perRowInfo.common !== undefined) {
      //   if (tempInventoryRowCommon.bottomBase === undefined) {
      //     tempInventoryRowCommon.bottomBase = perRowInfo.common.bottomBase;
      //   } else {
      //     tempInventoryRowCommon.bottomBase =
      //       Math.min(
      //         perRowInfo.common.bottomBase || Infinity,
      //         tempInventoryRowCommon.bottomBase,
      //       ) || Infinity;

      //     if (tempInventoryRowCommon.bottomBase === Infinity)
      //       delete tempInventoryRowCommon.bottomBase;
      //   }

      //   if (tempInventoryRowCommon.maxHeight === undefined) {
      //     tempInventoryRowCommon.maxHeight = perRowInfo.common.maxHeight;
      //   } else {
      //     tempInventoryRowCommon.maxHeight =
      //       Math.max(
      //         perRowInfo.common.maxHeight || 0,
      //         tempInventoryRowCommon.maxHeight,
      //       ) || undefined;
      //   }
      // }
    }

    // A.4 Re-calculate Bottom Bases according to bottom iso Tier
    //TODO: Implement
  });

  // B. Co-locate all 40s and CGs of 40s
  const targetBay = target40s === ForeAftEnum.FWD ? pairedBls[0] : pairedBls[1];
  const targetPerSlotInfo = targetBay.perSlotInfo || {};

  // B.1 Co-locate 40s slots
  (Object.keys(tempInventory4xs) as ISlotPattern[]).forEach((pos) => {
    const slotData = targetPerSlotInfo[pos];

    if (!slotData) {
      targetPerSlotInfo[pos] = tempInventory4xs[pos];
    } else {
      const sizesToAdd = Object.keys(tempInventory4xs[pos].sizes).map(
        Number,
      ) as TContainerLengths[];

      sizesToAdd.forEach((size) => {
        slotData.sizes[size] = tempInventory4xs[pos].sizes[size];
      });

      if (Object.keys(slotData.sizes).length > 0) delete slotData.restricted;

      targetPerSlotInfo[pos] = slotData;
    }
  });

  (Object.keys(tempInventoryReefers) as ISlotPattern[]).forEach((pos) => {
    targetPerSlotInfo[pos].reefer = 1;
  });

  targetBay.perSlotInfo = targetPerSlotInfo;

  // B.2 Co-locate 40s LCGs
  const targetInfoByContLength = targetBay.infoByContLength || {};
  (Object.keys(tempInventoryICL).map(Number) as TContainerLengths[]).forEach(
    (size) => {
      const infoBySize = targetInfoByContLength[size];
      if (!infoBySize) {
        targetInfoByContLength[size] = tempInventoryICL[size];
      } else {
        targetInfoByContLength[size] = {
          size,
          lcg: infoBySize.lcg ?? tempInventoryICL[size]?.lcg,
          rowWeight: infoBySize.rowWeight ?? tempInventoryICL[size]?.rowWeight,
          bottomWeight:
            infoBySize.bottomWeight ?? tempInventoryICL[size]?.bottomWeight,
        };
      }
    },
  );
  targetBay.infoByContLength = targetInfoByContLength;

  // B.3 Co-locate 40s ROW LCGs
  const targetPerRowInfo = targetBay.perRowInfo || {
    each: {},
    common: {},
  };

  //targetPerRowInfo.common = tempInventoryRowCommon;

  (Object.keys(tempInventoryRowEach) as IRowPattern[]).forEach((row) => {
    const sizesToAdd = Object.keys(
      tempInventoryRowEach[row].rowInfoByLength || {},
    ).map(Number) as TContainerLengths[];

    const rowInfo = targetPerRowInfo.each?.[row] || { isoRow: row };

    if (sizesToAdd.length > 0) {
      const rowInfoByLength = rowInfo.rowInfoByLength || {};

      sizesToAdd.forEach((size) => {
        if (!rowInfoByLength[size]) rowInfoByLength[size] = { size };

        if (rowInfoByLength[size]?.bottomWeight === undefined)
          rowInfoByLength[size]!.bottomWeight =
            tempInventoryRowEach[row].rowInfoByLength?.[size]?.bottomWeight;

        if (rowInfoByLength[size]?.lcg === undefined)
          rowInfoByLength[size]!.lcg =
            tempInventoryRowEach[row].rowInfoByLength?.[size]?.lcg;

        if (rowInfoByLength[size]?.rowWeight === undefined)
          rowInfoByLength[size]!.rowWeight =
            tempInventoryRowEach[row].rowInfoByLength?.[size]?.rowWeight;
      });

      rowInfo.rowInfoByLength = rowInfoByLength;
    }

    targetPerRowInfo.each![row] = rowInfo;
  });
  targetBay.perRowInfo = targetPerRowInfo;

  return {
    tempInventory4xs,
    tempInventoryICL,
    tempInventoryRowEach,
    currentPerSlotInfo,
    pairedBlsDict,
    pairedBls,
  };
}

type TTempInventoryInfoByLength = Partial<{
  [key in TContainerLengths]: IRowInfoByLength;
}>;

interface ITempInventoryOutput {
  tempInventory4xs: { [slot: ISlotPattern]: ISlotData };
  tempInventoryICL: Partial<{
    [key in TContainerLengths]: IRowInfoByLength;
  }>;
  tempInventoryRowEach: { [row: IRowPattern]: IBayRowInfo };
  currentPerSlotInfo: IBaySlotData | undefined;
  pairedBlsDict: { [isoBayLevel: string]: IBayLevelData };
  pairedBls: IBayLevelData[];
}
