import {
  GRID_OPTIONS_BY_CELL_DIMENSION,
  GRID_OPTIONS_BY_CELL_GAP,
  SLOT_OPTIONS_BY_CELL_DIMENSION,
} from "./gridConfigConstants";
import {
  IBayLevelData,
  ILidData,
  IOpenVesselDefinitionV1,
  ISlotData,
} from "open-vessel-definition";
import {
  IBayPattern,
  ISlotCombinedPattern,
  getBaySlots,
} from "tedivo-bay-grid-pure";
import {
  INodesOptions,
  IReturnDrawFunction,
  calculateGridFromConfigDimensions,
  createSlotCapabilitiesSvg,
} from "@baplie-viewer2/tedivo-bay-grid-core";
import {
  SelectShoelace,
  createSelectShoelace,
} from "@baplie-viewer2/tedivo-form";

import BayBoxComponent from "./bay-box.component";
import CellSpacingEnum from "../../../../../app/enums/CellSpacingEnum";
import { EnumHelpers } from "@baplie-viewer2/tedivo-pure-helpers";
import SizeSmallMidLargeEnum from "../../../../../app/enums/SizeSmallMidLargeEnum";
import { TEditBayButtonFunction } from "../../render/view-json-render";
import { createLegend } from "./createLegend";
import { drawBayStackWeights } from "./drawBayStackWeights";
import { getGridNodesOptions } from "./getGridNodesOptions";
import { getTranslation } from "packages/oss-editor/src/app/i18/i18tn";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { setPreferencesKeyAndValue } from "@baplie-viewer2/tedivo-preferences";

class BaysSection {
  mainNode: HTMLElement;
  baysNode: HTMLElement;
  legendNode: HTMLElement;
  helpersNode: HTMLElement;
  sizeSelShoelace: SelectShoelace<string>;
  cellpaddingSelShoelace: SelectShoelace<string>;

  private props: TBaysSectionProps;
  private nodesOptions: INodesOptions;
  private bayBoxesComponents: Map<string, BayBoxComponent> = new Map();

  constructor(props: TBaysSectionProps) {
    this.props = props;

    const {
      mainNode,
      legendNode,
      baysNode,
      helpersNode,
      sizeSelShoelace,
      cellpaddingSelShoelace,
    } = buildHtml({
      bayDrawOptions: [SizeSmallMidLargeEnum.SMALL, CellSpacingEnum.NO_SPACE],
    });

    this.mainNode = mainNode;
    this.baysNode = baysNode;
    this.legendNode = legendNode;
    this.helpersNode = helpersNode;
    this.sizeSelShoelace = sizeSelShoelace;
    this.cellpaddingSelShoelace = cellpaddingSelShoelace;

    this.nodesOptions = getGridNodesOptions();
  }

  draw(
    bayDrawOptions: [SizeSmallMidLargeEnum, CellSpacingEnum],
    baysToDraw: IBayPattern[] | undefined,
  ) {
    const json = ovdJsonStore.currentJson;
    if (!json) {
      return;
    }

    const shipData = json.shipData;
    this.nodesOptions = getGridNodesOptions();

    if (!baysToDraw) {
      // Legend
      removeChildren(this.legendNode);
      this.legendNode.appendChild(
        createLegend(shipData.containersLengths, shipData.featuresAllowed),
      );

      // Helpers
      removeChildren(this.helpersNode);
      if (this.props.bayHelpersButton)
        this.helpersNode.appendChild(this.props.bayHelpersButton);
    }

    this.sizeSelShoelace.value = String(bayDrawOptions[0]);
    this.cellpaddingSelShoelace.value = String(bayDrawOptions[1]);

    // Bays
    this.renderBays(json, bayDrawOptions, baysToDraw);
  }

  private renderBays(
    json: IOpenVesselDefinitionV1,
    bayDrawOptions: [SizeSmallMidLargeEnum, CellSpacingEnum],
    baysToDraw: IBayPattern[] | undefined,
  ) {
    const { shipData, baysData: bls, sizeSummary, lidData } = json;

    const maxRow = sizeSummary.maxRow;

    if (maxRow === undefined || maxRow === null) {
      return document.createElement("div");
    }

    const [cellSize, cellSeparation] = bayDrawOptions;

    this.baysNode.className = `bays-wrapper cSize-${SizeSmallMidLargeEnum[cellSize]} cSep-${CellSpacingEnum[cellSeparation]}`;

    const { width, height } = calculateGridFromConfigDimensions<ISlotData>({
      ...sizeSummary,
      ...GRID_OPTIONS_BY_CELL_DIMENSION[cellSize],
      ...GRID_OPTIONS_BY_CELL_GAP[cellSeparation],
      maxRow,
    });

    const validationResult = ovdJsonStore.ovdValidator.getLastResult();

    // Calculate the lids of each bay
    const lidsOfBay = createLidsDictByBay(lidData);

    getUniqueBays(bls)
      .filter((isoBay) => !baysToDraw || baysToDraw.includes(isoBay))
      .forEach((isoBay) => {
        let bayBoxComp = this.bayBoxesComponents.get(isoBay);

        if (!bayBoxComp) {
          // Create new bay box component
          bayBoxComp = document.createElement("bay-box-component");
          bayBoxComp.setAttribute("id", `bay-box-component-${isoBay}`);

          // Initialize the component
          bayBoxComp.setDrawingColors(this.nodesOptions).setDrawingProps({
            createFnSlotCell,
            createEditBayButtonFunction: this.props.createEditBayButtonFunction,
            drawStackWeights: drawBayStackWeights,
          });

          // Append the component to the bays node
          this.baysNode.appendChild(bayBoxComp);

          // Save the component for reuse in next render
          this.bayBoxesComponents.set(isoBay, bayBoxComp);
        }

        bayBoxComp
          .setDrawingColors(this.nodesOptions)
          .setSize(width, height)
          .setData({
            sizeSummary,
            shipData,
            lidData: lidsOfBay[isoBay] || [],
            isoBay,
            width,
            height,
            ssMaxRow: maxRow,
            cellSize,
            cellSeparation,
            validationResult,
            ...getBaySlots(isoBay, bls),
          });
      });
  }
}

export default BaysSection;

function buildHtml({
  bayDrawOptions,
}: {
  bayDrawOptions: [SizeSmallMidLargeEnum, CellSpacingEnum];
}): {
  mainNode: HTMLDivElement;
  baysNode: HTMLDivElement;
  legendNode: HTMLDivElement;
  helpersNode: HTMLDivElement;
  sizeSelShoelace: SelectShoelace<string>;
  cellpaddingSelShoelace: SelectShoelace<string>;
} {
  const mainNode = document.createElement("div");
  mainNode.className = `oss-card holder-bays`;

  const baysNode = document.createElement("div");
  const legendNode = document.createElement("div");
  const helpersNode = document.createElement("div");
  helpersNode.className = "helpers-holder helpers-bays";

  const titlePart = document.createElement("title-with-actions-component");
  titlePart.titleHtml = getTranslation("view:bayDataTitle");
  titlePart.titleNodeName = "h2";

  const [size, cellpadding] = bayDrawOptions;

  // Actions
  const sizeSelShoelace = createSelectShoelace({
    id: "baySize",
    caret: true,
    buttonText: getTranslation("view:bayDataSize"),
    options: EnumHelpers.getNamesAndValues(SizeSmallMidLargeEnum).map(
      ({ name, value }) => ({
        value,
        name: getTranslation(`enums:SizeSmallMidLargeEnum.${name}`),
      }),
    ),
    onChange: (newVal, oldVal) => {
      if (newVal === oldVal) return;

      const newBaySize = Number(newVal);

      setPreferencesKeyAndValue("bay-data-size", newBaySize);

      document.documentElement.dispatchEvent(
        new CustomEvent<IDisplaySizeChangedEventDetail>(
          "bayDisplaySizeChanged",
          {
            detail: {
              newBaySize,
              newCellSeparation: undefined,
            },
          },
        ),
      );
    },
  });

  const cellpaddingSelShoelace = createSelectShoelace({
    id: "cellSeparation",
    caret: true,
    buttonText: getTranslation("view:bayCellSeparation"),
    options: EnumHelpers.getNamesAndValues(CellSpacingEnum).map(
      ({ name, value }) => ({
        value,
        name: getTranslation(`enums:CellSpacingEnum.${name}`),
      }),
    ),
    onChange: (newVal, oldVal) => {
      if (newVal === oldVal) return;

      const newCellSeparation = Number(newVal);

      setPreferencesKeyAndValue("bay-cell-separation", newCellSeparation);

      document.documentElement.dispatchEvent(
        new CustomEvent<IDisplaySizeChangedEventDetail>(
          "bayDisplaySizeChanged",
          {
            detail: {
              newBaySize: undefined,
              newCellSeparation,
            },
          },
        ),
      );
    },
  });

  sizeSelShoelace.value = String(size);
  cellpaddingSelShoelace.value = String(cellpadding);

  const actionsDiv = document.createElement("div");
  actionsDiv.appendChild(helpersNode);
  actionsDiv.appendChild(sizeSelShoelace);
  actionsDiv.appendChild(cellpaddingSelShoelace);
  actionsDiv.slot = "actions";

  titlePart.appendChild(actionsDiv);

  mainNode.appendChild(titlePart);
  mainNode.appendChild(legendNode);
  mainNode.appendChild(baysNode);

  return {
    mainNode,
    baysNode,
    legendNode,
    helpersNode,
    sizeSelShoelace,
    cellpaddingSelShoelace,
  };
}

function getUniqueBays(bls: IBayLevelData[]): IBayPattern[] {
  return bls
    .map((bl) => bl.isoBay)
    .filter((v, idx, arr) => arr.indexOf(v) === idx);
}

function createFnSlotCell(cellSize: SizeSmallMidLargeEnum) {
  return function createSlotCell(slotData: ISlotData): IReturnDrawFunction {
    const svgG = createSlotCapabilitiesSvg({
      ...SLOT_OPTIONS_BY_CELL_DIMENSION[cellSize],
      slotData,
      centered: true,
    });

    const p = slotData.pos;
    const pos = `${p.substring(0, 2)}|${p.substring(
      2,
    )}` as ISlotCombinedPattern;

    return {
      node: svgG,
      pos,
    };
  };
}

function createLidsDictByBay(lidData: ILidData[]) {
  return lidData.reduce((acc, v) => {
    if (!acc[v.startIsoBay]) acc[v.startIsoBay] = [];
    if (!acc[v.endIsoBay]) acc[v.endIsoBay] = [];

    acc[v.startIsoBay].push(v);
    if (v.startIsoBay !== v.endIsoBay) acc[v.endIsoBay].push(v);

    return acc;
  }, {} as Record<IBayPattern, ILidData[]>);
}

interface TBaysSectionProps {
  createEditBayButtonFunction: TEditBayButtonFunction;
  bayHelpersButton?: HTMLElement;
  readonlyMode: boolean;
  canOpenDrawer: boolean;
}

export interface IDisplaySizeChangedEventDetail {
  newBaySize: SizeSmallMidLargeEnum | undefined;
  newCellSeparation: CellSpacingEnum | undefined;
}
