import { ActionTypesEnum, IActionVesselPart } from "../../partsReducer";
import {
  IBaseModalForPartsProps,
  createBaseModalForParts,
  createBasePartValidator,
  extractEffectiveRefId,
} from "./createBaseModalForParts";
import {
  IVesselPartSmokeStack,
  VesselPartTypeEnum,
} from "open-vessel-definition";

import { getTranslation } from "packages/oss-editor/src/app/i18/i18tn";
import { z } from "zod";

type ICustomTypeSmokePartWithoutId = Omit<IVesselPartSmokeStack, "id"> & {
  type: VesselPartTypeEnum.SMOKE;
  id?: string; // For the edit
};

export function createModalForSmokeStacks({
  smokeStacksData,
  currentLabels,
  calculatedSlots,
  maxLen,
  defaultLen,
  onSubmitAction,
  spaceAvailableCalcFn,
}: {
  smokeStacksData: ICustomTypeSmokePartWithoutId;
  onSubmitAction: (data: IActionVesselPart) => void;
} & IBaseModalForPartsProps<ICustomTypeSmokePartWithoutId>) {
  function createValidator(
    maxLen: number | undefined,
  ): z.Schema<ICustomTypeSmokePartWithoutId> {
    return createBasePartValidator(maxLen).and(
      z.object({
        type: z.literal(VesselPartTypeEnum.SMOKE),
        numberOfSmokeStacks: z.number().min(1),
      }),
    );
  }

  const id = smokeStacksData.id;

  const dialog = createBaseModalForParts<ICustomTypeSmokePartWithoutId>({
    data: smokeStacksData,
    type: VesselPartTypeEnum.SMOKE,
    maxLen,
    defaultLen,
    calculatedSlots,
    partFields: [
      {
        name: "numberOfSmokeStacks",
        type: "number",
        label: "view:view3D.partsParams.numberOfSmokeStacks",
        initialValue: smokeStacksData.numberOfSmokeStacks || 1,
      },
    ],
    currentLabels: currentLabels,
    createValidator,
    spaceAvailableCalcFn,
    onSubmit: (data, cmd) => {
      if (data) {
        if (id) {
          const cmd: IActionVesselPart = {
            action: ActionTypesEnum.REPLACE_SMOKE_PART,
            id,
            details: {
              id,
              ...data,
            },
          };
          onSubmitAction(cmd);
        } else {
          const cmd: IActionVesselPart = {
            action: ActionTypesEnum.ADD_SMOKE_PART,
            slotRefId: extractEffectiveRefId(data.slotRefId, data.posRef),
            posRef: data.posRef,
            details: {
              ...data,
            },
          };
          onSubmitAction(cmd);
        }
      } else {
        if (cmd) onSubmitAction(cmd);
      }
      dialog.hide();
    },
  });

  dialog.label = getTranslation("view:view3D.actions.addSmokeStack");
  dialog.show();
}
