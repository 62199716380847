import i18n, { TFunction, TOptions } from "i18next";

export declare interface I18nReactive {
  addEventListener(
    event: "updateI18Consumers",
    listener: (
      ev: CustomEvent<{
        detail: I18nInstance;
      }>,
    ) => void,
  ): this;
  addEventListener(event: string, listener: () => void): this;
}

export class I18nReactive extends EventTarget {
  private consumers: Array<ITranslationConsumer> = [];
  private i18Instance: I18nInstance;
  private fns: { [name: string]: () => void } = {};

  constructor(i18Instance: typeof i18n | I18nInstance) {
    super();
    this.i18Instance = i18Instance;
  }

  /**
   * Adds a connection to update the DOM node attributes.
   * Don't use it directly. Use I18nComponentRegisterer instead
   * @param element
   * @param translationKey
   * @param attribute "innerHTML", "innerText", "value", "label", "dataset.*"
   * @returns
   */
  addConsumer(
    element: HTMLElement | SVGElement,
    translationKey: string,
    attribute: TAttributes,
    consumerId = "",
    options?: I18nReactiveOptions,
  ): void {
    this.consumers = [
      ...this.consumers.filter(
        (c) =>
          !(
            c.element === element &&
            c.attribute === attribute &&
            (!consumerId || c.consumerId === consumerId)
          ),
      ),
      {
        element,
        translationKey,
        attribute,
        consumerId,
        options,
      },
    ];

    updateConsumer({
      element,
      translationKey,
      attribute,
      options,
      t: this.i18Instance.t,
    });
  }

  removeConsumer(removeElement: HTMLElement | SVGElement) {
    this.consumers = this.consumers.filter(
      ({ element }) => element !== removeElement,
    );
  }

  removeConsumersOfConsumerId(consumerId: string) {
    this.consumers = this.consumers.filter((c) => c.consumerId !== consumerId);
  }

  /**
   * To be used by language selector change routine
   */
  updateI18Consumers(): void {
    const t = this.i18Instance.t;
    this.consumers.forEach(
      ({ element, translationKey, attribute, options }) => {
        updateConsumer({ element, translationKey, attribute, t, options });
      },
    );
    this.dispatchEvent(
      new CustomEvent<I18nInstance>("updateI18Consumers", {
        detail: this.i18Instance,
      }),
    );
  }

  getConsumers() {
    return this.consumers;
  }

  /**
   * Register callback for complex components or data
   */
  onLanguageChanged(name: string, fn: () => void) {
    this.fns[name] = fn;
  }
}

export class I18nComponentRegisterer {
  private i18nReactive: I18nReactive;
  private fns: { [name: string]: () => void } = {};
  id: string;

  constructor(i18nReactive: I18nReactive) {
    this.i18nReactive = i18nReactive;
    this.id = String(Math.round(Math.random() * 1000000));
  }

  addConsumer(
    element: HTMLElement | SVGElement | null,
    translationKey: string,
    attribute: TAttributes,
    options?: I18nReactiveOptions,
  ): this {
    if (!element) return this;
    this.i18nReactive.addConsumer(
      element,
      translationKey,
      attribute,
      this.id,
      options,
    );
    return this;
  }

  /**
   * Register callback for complex components or data
   */
  onLanguageChanged(name: string, fn: () => void) {
    this.fns[name] = fn;
  }

  disconnect() {
    this.i18nReactive.removeConsumersOfConsumerId(this.id);
  }
}

function updateConsumer({
  element,
  translationKey,
  attribute,
  options,
  t,
}: ITranslationConsumer & { t: TFunction }): void {
  if (!element) return;

  const val = t(translationKey, options || {}) as string;
  if (
    attribute === "innerHTML" ||
    attribute === "innerText" ||
    attribute === "title" ||
    attribute === "label" ||
    attribute === "helpText" ||
    attribute === "value"
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (element as any)[attribute] = val;
  } else if (
    attribute.indexOf("data-") === 0 ||
    attribute === "help-text" ||
    attribute === "text" ||
    attribute === "placeholder"
  ) {
    element.setAttribute(attribute, val);
  }
}

type TAttributes =
  | "innerHTML"
  | "innerText"
  | "label"
  | "value"
  | "title"
  | "help-text"
  | "helpText"
  | "placeholder"
  | "text"
  | `data-${string}`;

interface ITranslationConsumer {
  element: HTMLElement | SVGElement;
  translationKey: string;
  attribute: TAttributes;
  consumerId?: string;
  options?: I18nReactiveOptions;
}

export type I18nInstance = Omit<typeof i18n, "changeLanguage">;

type I18nReactiveOptions = TOptions;
