/* eslint-disable @typescript-eslint/no-explicit-any */
import { __awaiter } from "tslib";
import HttpClient from "../../services/HttpClient";
import securityModule from "../../security/SecurityModule";
const domainsBaseUrl = "logEvents";
class LogEventsBeaconServiceClass {
    constructor() {
        this.notify = (ev) => {
            var _a, _b;
            const blob = new Blob([JSON.stringify(ev)], {
                "Content-Type": "application/json",
                Authorization: `Bearer ${securityModule.idToken}`,
            });
            (_b = (_a = globalThis.navigator) === null || _a === void 0 ? void 0 : _a.sendBeacon) === null || _b === void 0 ? void 0 : _b.call(_a, `${this.baseUrl}/${domainsBaseUrl}`, blob);
        };
        this.notifyXhttp = (ev) => __awaiter(this, void 0, void 0, function* () {
            const client = new HttpClient();
            return yield client.request({
                method: "POST",
                url: `${domainsBaseUrl}`,
                data: ev,
            });
        });
        this.baseUrl = process.env.NX_PUBLIC_API_URL || "http://localhost:3000";
        //TODO: Pass the Authorization header to the beacon request
    }
}
const LogEventsBeaconService = new LogEventsBeaconServiceClass();
export default LogEventsBeaconService;
