import {
  IBayLevelAdjustedBottomBase,
  createSimpleSideView,
} from "@baplie-viewer2/tedivo-bay-grid-core";
import {
  IBayLevelData,
  ISizeSummary,
  IVesselParts,
} from "open-vessel-definition";

import { IGetBayLcgVcgAndPairingsResult } from "tedivo-bay-grid-pure";
import { getTranslation } from "../../../../app/i18/i18tn";

interface ICreateSideViewProps {
  sizeSummary: ISizeSummary;
  baysData: IBayLevelData[];
  vesselPartsData?: IVesselParts[];
  adjustedBottomBases?: Array<IBayLevelAdjustedBottomBase>;
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  editActionNode?: HTMLElement;
  bayNumbersDoubleClickAction?: (ev: SVGElement) => void;
}

export function createSideView({
  sizeSummary,
  baysData,
  vesselPartsData,
  adjustedBottomBases,
  lcgVcgTcgAndPairings,
  editActionNode,
  bayNumbersDoubleClickAction,
}: ICreateSideViewProps): HTMLElement {
  const root = getComputedStyle(document.body);

  const card = document.createElement("card-element");
  card.titleHtml = "view:sideView.title";
  card.name = "sideView";
  if (editActionNode) card.actions = editActionNode;

  try {
    if (lcgVcgTcgAndPairings.totalSlotsCount === 0) {
      const empty = document.createElement("div");
      empty.className = "no-slots-defined";
      empty.innerHTML = getTranslation(
        "view:graphicWillDisplaySlotsWhenDefined",
      );
      card.appendChild(empty);
    }

    const symbolsOptions = {
      strokeWidth: 1,
      strokeColor: root.getPropertyValue("--sl-color-neutral-400"),
      fontColor: root.getPropertyValue("--sl-color-neutral-700"),
      fillColor: root.getPropertyValue("--sl-color-neutral-50"),
      shipStrokeColor: root.getPropertyValue("--sl-color-primary-300"),
      pairedNoneContainerColor: root.getPropertyValue("--sl-color-violet-400"),
      pairedAftContainerColor: root.getPropertyValue("--sl-color-teal-400"),
      pairedFwdContainerColor: root.getPropertyValue("--sl-color-neutral-400"),
      warningColor: root.getPropertyValue("--sl-color-warning-700"),
    };

    const { sideViewSvg } = createSimpleSideView({
      sizeSummary,
      baysData,
      lcgVcgTcgAndPairings,
      symbolsOptions,
      vesselPartsData,
      adjustedBottomBases,
      bayNumbersDoubleClickAction,
    });

    sideViewSvg.classList.add("max-height-400");
    sideViewSvg.classList.add("width-100percent");
    card.appendChild(sideViewSvg);

    const legend = document.createElement("div");
    legend.className = "sideview-legend";

    const pairedNoneLabel = document.createElement("span"),
      pairedFwdLabel = document.createElement("span"),
      pairedAftLabel = document.createElement("span");

    pairedNoneLabel.className = "sideview-legend-pairedNone";
    pairedFwdLabel.className = "sideview-legend-pairedFwd";
    pairedAftLabel.className = "sideview-legend-pairedAft";

    pairedNoneLabel.innerHTML = getTranslation(
      "view:sideView.containersPairedNone",
    );
    pairedFwdLabel.innerHTML = getTranslation(
      "view:sideView.containersPairedFwd",
    );
    pairedAftLabel.innerHTML = getTranslation(
      "view:sideView.containersPairedAft",
    );

    legend.appendChild(pairedFwdLabel);
    legend.appendChild(pairedAftLabel);
    legend.appendChild(pairedNoneLabel);
    card.appendChild(legend);

    if (
      (lcgVcgTcgAndPairings.missingImportantLcgs ||
        lcgVcgTcgAndPairings.missingImportantVcgs) &&
      (lcgVcgTcgAndPairings.cgsStats.definedBottomBase > 0 ||
        lcgVcgTcgAndPairings.cgsStats.definedLcgs > 0 ||
        lcgVcgTcgAndPairings.cgsStats.definedTcgs > 0)
    ) {
      const note = document.createElement("sl-badge");
      note.variant = "warning";
      note.pill = true;
      note.innerHTML = getTranslation("view:errors.missingXcgs");
      card.appendChild(note);
    }
  } catch (e) {
    console.error(e);
  }

  return card;
}
