import {
  ForeAftEnum,
  IBayLevelData,
  ISizeSummary,
} from "open-vessel-definition";

import EditDrawer from "../../../../../common/EditDrawer";
import { IBayLevelPairing } from "./IBayLevelPairing";
import { ITable } from "../../../../../common/smartTable/ITable";
import PairingHelpersEnum from "packages/oss-editor/src/app/enums/PairingHelpersEnum";
import PairingsRowsFactory from "./PairingsRowsFactory";
import SmartTable from "../../../../../common/smartTable/smart-table.element";
import { createMultiEditButton } from "@baplie-viewer2/tedivo-form";
import { findParentBy } from "@baplie-viewer2/tedivo-dom-helpers";
import { getTranslation } from "../../../../../../app/i18/i18tn";
import goSquared from "../../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../../app/stores/OVDJsonStore";
import { pad3 } from "@baplie-viewer2/tedivo-pure-helpers";

export function createPairingsEdit({
  sizeSummary,
  baysData,
  usingAModal,
  editDrawer,
}: {
  sizeSummary: ISizeSummary;
  baysData: IBayLevelData[];
  usingAModal: (isOpened: boolean) => void;
  editDrawer: EditDrawer;
}): { node: HTMLElement; submitFunction: () => boolean } {
  goSquared.addEvent("Edit-OVD - Edit Bay Pairings");

  const holder = document.createElement("div");
  holder.id = "editPairingsHolder";
  holder.className = "edit-pairings-holder";

  const editingArea = document.createElement("section");
  editingArea.id = "editingArea";
  editingArea.className = "editing-area";

  const rowsFactory = new PairingsRowsFactory(editingArea, usingAModal);
  rowsFactory.setData(baysData);

  const { divTable, smartTable } = createEditingFields({
    sizeSummary,
    rowsFactory,
  });

  rowsFactory.smartTable = smartTable as any;

  const slHelper = createHelpers({
    onAction: (action) => {
      const newValues: IBayLevelPairing[] = [];

      for (let iBay = 1; iBay <= sizeSummary.isoBays; iBay += 2) {
        let pairedBay: ForeAftEnum | undefined = undefined;

        if (action === "BAY001_PAIRS_AFT") {
          if (iBay % 4 === 1) pairedBay = ForeAftEnum.AFT;
          else if (iBay % 4 === 3) pairedBay = ForeAftEnum.FWD;
        } else if (action === "BAY003_PAIRS_AFT" && iBay > 1) {
          if (iBay === 0) pairedBay = undefined;
          else if (iBay % 4 === 3) pairedBay = ForeAftEnum.AFT;
          else if (iBay % 4 === 1) pairedBay = ForeAftEnum.FWD;
        }

        newValues.push({ isoBay: pad3(iBay), pairedBay });
      }

      rowsFactory.setRawData(newValues);
      smartTable?.updateData(newValues);
    },
  });

  editingArea.appendChild(divTable);
  editingArea.appendChild(slHelper);
  holder.appendChild(editingArea);

  rowsFactory.onChange.set("TABLE", () => {
    editDrawer.dataIsDirty = true;
  });

  const submitFunction = () => {
    goSquared.addEvent("Edit-OVD - Edit Bay Pairings - Save");
    ovdJsonStore.setPairings(rowsFactory.getRawData());
    return true;
  };

  return { node: holder, submitFunction };
}

function createEditingFields({
  sizeSummary,
  rowsFactory,
}: {
  sizeSummary: ISizeSummary;
  rowsFactory: PairingsRowsFactory;
}) {
  const divTable = document.createElement("div");
  divTable.className = "lids-table-mainHolder";

  const data: IBayLevelPairing[] = rowsFactory.getRawData();
  const smartTableConfig: ITable<IBayLevelPairing> = {
    data,
    defaultSort: ["isoBay"],
    className:
      "tvd-table tvd-table-smart-table tvd-table-sticky tvd-table-tr-valign-middle tvd-table-with-direction",
    fields: [
      {
        name: "isoBay",
        label: getTranslation("general:grid.isoBay"),
        className: "centered",
      },
      {
        name: "pairedBay",
        label: getTranslation("view:details.paired"),
        type: "inlineRadioButtons",
        className: "centered",
        fixedWidth: "300px",
        radioOptions: [
          {
            value: String(ForeAftEnum.AFT),
            label: getTranslation("enums:ForeAftEnum.AFT"),
          },
          {
            value: String(ForeAftEnum.FWD),
            label: getTranslation("enums:ForeAftEnum.FWD"),
          },
          {
            value: "",
            label: getTranslation("general:common.none"),
          },
        ],
        isNumeric: true,
        valueFunction: (dta) => (dta.pairedBay ? String(dta.pairedBay) : ""),
        onChange: (dta, name, _, value, control) => {
          const pk = `${dta.isoBay}`;
          const record = rowsFactory.getSinglePrbData(pk);
          const newObject = {
            ...record,
            [name]:
              name === "pairedBay"
                ? value !== ""
                  ? Number(value)
                  : undefined
                : value,
          } as IBayLevelPairing;

          rowsFactory.updatePrbData(pk, newObject);

          if (control) {
            const tr = findParentBy(control, "TR") as HTMLElement;
            tr.classList.remove("paired-1", "paired-2");
            tr.classList.add(`paired-${value}`);
          }
        },
      },
    ],
    settings: {},
    initialRows: 300,
    rowClassFunction: (dta) =>
      dta.pairedBay !== undefined ? `paired-${dta.pairedBay}` : "",
    pkFunction: (dta) => `${dta.isoBay}`,
  };

  const smartTable = new SmartTable<IBayLevelPairing>().initialize(
    smartTableConfig,
  );

  divTable.appendChild(smartTable);

  return {
    divTable,
    smartTable,
  };
}

function createHelpers({ onAction }: { onAction: (action: string) => void }) {
  const helpersMainButton = createMultiEditButton({
    id: `helpersCGs`,
    title: getTranslation("view:edit.pairings.helpers"),
    size: "small",
    variant: "primary",
    showTitleAsTooltip: false,
    iconName: "command",
    options: [
      {
        value: PairingHelpersEnum.BAY001_PAIRS_AFT,
        name: getTranslation(`enums:PairingHelpersEnum.BAY001_PAIRS_AFT`),
        action: () => {
          onAction("BAY001_PAIRS_AFT");
        },
      },
      {
        value: PairingHelpersEnum.BAY003_PAIRS_AFT,
        name: getTranslation(`enums:PairingHelpersEnum.BAY003_PAIRS_AFT`),
        action: () => {
          onAction("BAY003_PAIRS_AFT");
        },
      },
    ],
  });

  return helpersMainButton;
}
