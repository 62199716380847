import "@shoelace-style/shoelace/dist/components/button/button";
import "@shoelace-style/shoelace/dist/components/divider/divider";
import "@shoelace-style/shoelace/dist/components/dropdown/dropdown";
import "@shoelace-style/shoelace/dist/components/icon/icon";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item";
import "@shoelace-style/shoelace/dist/components/menu/menu";

import {
  ITedivoShoelaceSelectOptions,
  createSelectShoelace,
} from "@baplie-viewer2/tedivo-form";
import {
  getPreferencesValue,
  setPreferencesKeyAndValue,
} from "@baplie-viewer2/tedivo-preferences";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import SlIcon from "@shoelace-style/shoelace/dist/components/icon/icon";
import screenColorModeStore from "packages/oss-editor/src/app/stores/ScreenColorModeStore";

type TcolorMode = "dark" | "light" | "auto";

const slOptions: ITedivoShoelaceSelectOptions[] = [
  {
    name: "general:colorMode.lightMode",
    value: "light" as TcolorMode,
  },
  {
    name: "general:colorMode.darkMode",
    value: "dark" as TcolorMode,
  },
  { divider: true },
  { name: "general:colorMode.auto", value: "auto" as TcolorMode },
];

export class TVDColorModeElement extends HTMLElement {
  public static observedAttributes = [];

  private currentMode: TcolorMode = "auto";
  private buttonIcon: SlIcon;
  private i18nCR: I18nComponentRegisterer;

  get resolvedMode() {
    return this.currentMode === "auto"
      ? window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
      : this.currentMode;
  }

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);

    const colorModeTriggerIcon = document.createElement("sl-icon");
    this.buttonIcon = colorModeTriggerIcon;
  }

  get effectiveMode() {
    const savedColorMode = getPreferencesValue("color-mode") as TcolorMode;
    if (savedColorMode && savedColorMode !== "auto") return savedColorMode;

    const darkMode =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    return darkMode ? "dark" : "light";
  }

  connectedCallback() {
    const savedColorMode = getPreferencesValue("color-mode") as TcolorMode;
    if (savedColorMode) this.currentMode = savedColorMode;

    const darkMode =
      savedColorMode === "dark" ||
      ((!savedColorMode || savedColorMode === "auto") &&
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches);

    this.buttonIcon.setAttribute("name", darkMode ? "moon" : "sun");

    const colorModeSelect = createSelectShoelace<TcolorMode>({
      id: "color-selector",
      buttonText: this.buttonIcon,
      caret: true,
      pill: true,
      size: "small",
      options: slOptions.map((o) =>
        o.divider
          ? o
          : {
              value: o.value,
              name: getTranslation(String(o.name)),
            },
      ) as ITedivoShoelaceSelectOptions[],
      onChange: this.onModeChanged,
      onNodesCreated: (items) => {
        // Add i18 consumers of options
        slOptions.forEach(({ name, value, divider }) => {
          const item = value !== undefined && items[value];
          if (item && !divider) {
            this.i18nCR.addConsumer(item, String(name), "innerHTML");
          }
        });
      },
    });

    colorModeSelect.value = this.currentMode;

    this.appendChild(colorModeSelect);

    this.i18nCR.addConsumer(
      this.buttonIcon,
      "general:colorMode.title",
      "label",
    );

    this.setVisibleLightMode(darkMode ? "dark" : "light");

    screenColorModeStore.screenColorMode = this.effectiveMode;
  }

  private onModeChanged = (val: TcolorMode) => {
    if (this.currentMode === val) return;

    this.currentMode = val;
    const resolvedMode = this.resolvedMode;

    this.setVisibleLightMode(resolvedMode);

    setPreferencesKeyAndValue("color-mode", this.currentMode);

    const html = document.documentElement;
    html.dispatchEvent(
      new CustomEvent("colorModeChanged", { detail: { mode: resolvedMode } }),
    );
  };

  private setVisibleLightMode(mode: "dark" | "light") {
    const html = document.documentElement;

    if (mode === "light") {
      html.classList.remove("sl-theme-dark");
      this.buttonIcon.name = "sun";
    } else {
      if (!html.classList.contains("sl-theme-dark"))
        html.classList.add("sl-theme-dark");
      this.buttonIcon.name = "moon";
    }
  }

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }
}

customElements.define("tvd-color-mode-element", TVDColorModeElement);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-color-mode-element": TVDColorModeElement;
  }
}
