export function generateNewLabel(
  baseLabel: string,
  existingLabels: (string | number | undefined)[],
): string {
  let n = 1;
  let lbl = "";

  while (lbl === "") {
    if (existingLabels.includes(baseLabel + "A" + n)) {
      n += 1;
      continue;
    }

    lbl = baseLabel + "A" + n;
  }
  return lbl;
}
