import { BayLevelEnum, ForeAftEnum, } from "open-vessel-definition";
import { getRowsAndTiersFromSlotKeys, getSizesFromSlots, sortRowsArray, } from "tedivo-bay-grid-pure";
import { sortNumericAsc } from "@baplie-viewer2/tedivo-pure-helpers";
export function calculateMissingCGs(json) {
    const missingLCGs = [];
    const missingBulkheadLCGs = [];
    const baysData = json.baysData;
    const masterCGs = json.shipData.masterCGs || {};
    const rowsAbove = calculateRowsFromSlots(baysData.filter((bl) => bl.level === BayLevelEnum.ABOVE));
    const rowsBelow = calculateRowsFromSlots(baysData.filter((bl) => bl.level === BayLevelEnum.BELOW));
    // 1. TCGs ----------------------------------------------
    // Check only for Master TCGs
    const masterAboveTCGs = rowsAbove.reduce((acc, row) => {
        if (masterCGs.aboveTcgs[row] === undefined)
            acc.push(row);
        return acc;
    }, []);
    const masterBelowTCGs = rowsBelow.reduce((acc, row) => {
        if (masterCGs.belowTcgs[row] === undefined)
            acc.push(row);
        return acc;
    }, []);
    // 2. VCGs ----------------------------------------------
    // Check only for Master Bottom Bases
    const minBases = new Set();
    baysData.forEach((bl) => {
        const perSlotInfo = bl.perSlotInfo;
        if (perSlotInfo !== undefined) {
            const { minTier } = getRowsAndTiersFromSlotKeys(Object.keys(perSlotInfo));
            if (minTier)
                minBases.add(minTier);
        }
    });
    const masterVCGs = Array.from(minBases)
        .filter((tier) => Number(tier) < 200)
        .reduce((acc, tier) => {
        if (masterCGs.bottomBases[tier] === undefined)
            acc.push(tier);
        return acc;
    }, []);
    // 3. LCGs ----------------------------------------------
    baysData.forEach((bl) => {
        const perSlotInfo = bl.perSlotInfo;
        if (perSlotInfo !== undefined) {
            const sizes = getSizesFromSlots(perSlotInfo).sizes;
            sizes.forEach((size) => {
                var _a, _b;
                if (((_b = (_a = bl.infoByContLength) === null || _a === void 0 ? void 0 : _a[size]) === null || _b === void 0 ? void 0 : _b.lcg) === undefined) {
                    missingLCGs.push({
                        bay: bl.isoBay,
                        level: bl.level,
                        size,
                    });
                }
            });
        }
        const bulkhead = bl.bulkhead;
        if (bulkhead !== undefined) {
            if (bulkhead.aft && bulkhead.aftLcg === undefined)
                missingBulkheadLCGs.push({
                    bay: bl.isoBay,
                    level: bl.level,
                    pos: ForeAftEnum.AFT,
                });
            if (bulkhead.fore && bulkhead.foreLcg === undefined)
                missingBulkheadLCGs.push({
                    bay: bl.isoBay,
                    level: bl.level,
                    pos: ForeAftEnum.FWD,
                });
        }
    });
    const totalMissingCGs = masterAboveTCGs.length +
        masterBelowTCGs.length +
        masterVCGs.length +
        missingLCGs.length +
        missingBulkheadLCGs.length;
    return {
        masterAboveTCGs: masterAboveTCGs.sort(sortRowsArray),
        masterBelowTCGs: masterBelowTCGs.sort(sortRowsArray),
        masterVCGs: masterVCGs.sort(sortNumericAsc),
        missingLCGs,
        missingBulkheadLCGs,
        totalMissingCGs,
    };
}
function calculateRowsFromSlots(baysData = []) {
    const rows = new Set();
    baysData.forEach((bl) => {
        const rowsFromSlotsInfo = bl.perSlotInfo
            ? Object.keys(bl.perSlotInfo).map((s) => s.substring(0, 2))
            : [];
        rowsFromSlotsInfo.forEach((r) => rows.add(r));
    });
    return Array.from(rows);
}
