import { TedivoForm, translateTedivoForm } from "@baplie-viewer2/tedivo-form";
import { convertILidDataToLidDataStore, convertLidDataStoreToILidData, } from "./convertILidDataToLidDataStore";
import { findParentBy, removeChildren, } from "@baplie-viewer2/tedivo-dom-helpers";
import { generateRandomKey, numberIsOdd, pad2, } from "@baplie-viewer2/tedivo-pure-helpers";
import IntegratedDialogError from "packages/oss-editor/src/components/common/IntegratedDialogError";
import { generateNewLabel } from "./generateNewLabel";
import { getTranslation } from "packages/oss-editor/src/app/i18/i18tn";
import { z } from "zod";
export default class LidRowsFactory {
    constructor(editingArea, svgWrapperNode, usingAModal) {
        this.deleteConfirmationDialog = {};
        this.hoveredNode = undefined;
        this.highlightedRows = [];
        this.highlightedRowsPksHash = "";
        this.usingAModal = undefined;
        this.onChange = new Map();
        this._data = new Map();
        this._hoverIsActive = false;
        this.smartTable = undefined;
        this.hasBeenSubmitted = false;
        this.onLidOrRowHover = (e) => {
            const HTML_NODES_ALLOWED = [
                "TD",
                "SL-INPUT",
                "SL-CHECKBOX",
                "TF-INPUT-UNITS",
                "SL-SWITCH",
            ];
            const me = this;
            const target = e.target;
            // Don't pass if the same node
            if (me.hoveredNode === target)
                return;
            me.hoveredNode = target;
            // Check it's TD or PATH
            const nodeName = target.nodeName;
            const isSvgPath = nodeName === "path";
            if (HTML_NODES_ALLOWED.indexOf(nodeName) < 0 && nodeName !== "path") {
                me.smartTable.selectRows({ clearSelection: true });
                return;
            }
            let pk = undefined;
            let tableRowIndex = -1;
            // Find IDX/PK
            if (isSvgPath) {
                pk = target.dataset.idx;
            }
            else {
                const tr = findParentBy(target, "TR");
                pk = tr ? tr.dataset.pk : undefined;
            }
            if (pk === undefined) {
                me.smartTable.selectRows({ clearSelection: true });
                me.highlightPaths();
                return;
            }
            // Highlight the row in the table
            me.smartTable.selectRows({
                pk,
                scrollIntoView: isSvgPath ? "optimal" : false,
            });
            // Highlight the paths in the SVG
            me.highlightPaths([pk], !isSvgPath);
        };
        this.highlightPaths = (pks, scrollIntoView = false) => {
            // Check if the pks are the same as the last time
            const pksHash = (pks === null || pks === void 0 ? void 0 : pks.slice().sort().join(",")) || "";
            if (pksHash === this.highlightedRowsPksHash)
                return;
            // Un-highlight nodes
            this.highlightedRowsPksHash = (pks === null || pks === void 0 ? void 0 : pks.slice().sort().join(",")) || "";
            this.highlightedRows.forEach((node) => {
                node.classList.remove("row-highlighted");
            });
            if (!pks || pks.length === 0)
                return;
            const pathsToHighlight = document.querySelectorAll(pks.map((pk) => `.editing-area path[data-idx='${pk}']`).join(", "));
            pathsToHighlight.forEach((node, index) => {
                if (!node.classList.contains("row-highlighted")) {
                    if (scrollIntoView && index === 0) {
                        const box = node.getBoundingClientRect();
                        this._svgWrapperNode.scrollBy({
                            top: box.top - 350,
                            left: 0,
                            behavior: "smooth",
                        });
                    }
                    node.classList.add("row-highlighted");
                }
            });
            this.highlightedRows = Array.from(pathsToHighlight);
        };
        this.deleteConfirmationDialog = document.createElement("sl-dialog");
        this.deleteConfirmationDialog.classList.add("higher-modal-z-index");
        editingArea.appendChild(this.deleteConfirmationDialog);
        this.usingAModal = usingAModal;
        this._editingArea = editingArea;
        this._svgWrapperNode = svgWrapperNode;
        this.hoverIsActive = true;
    }
    set hoverIsActive(isActive) {
        if (this._hoverIsActive === isActive)
            return;
        this._hoverIsActive = isActive;
        if (isActive) {
            this._editingArea.addEventListener("mousemove", this.onLidOrRowHover, false);
        }
        else {
            this._editingArea.removeEventListener("mousemove", this.onLidOrRowHover, false);
        }
    }
    setData(data) {
        data.forEach((d) => {
            const key = generateRandomKey();
            this._data.set(key, convertILidDataToLidDataStore(d, key));
        });
    }
    getData() {
        return Array.from(this._data.values()).map((d) => convertLidDataStoreToILidData(d));
    }
    getRawData() {
        return Array.from(this._data.values());
    }
    addLidData(data) {
        const pk = generateRandomKey();
        const lidDataStore = Object.assign(Object.assign({}, data), { pk });
        this._data.set(pk, lidDataStore);
        return pk;
    }
    updateLidData(pk, data) {
        return new Promise((resolve) => {
            if (this._data.get(pk)) {
                this._data.set(pk, Object.assign(Object.assign({}, data), { pk }));
            }
            resolve();
        });
    }
    deleteLidData(pk) {
        this._data.delete(pk);
    }
    getSingleLidData(pk) {
        return this._data.get(pk);
    }
    copyPasteLids({ pks, cb, usingAModal, }) {
        var _a;
        const me = this;
        // Get the data to copy
        const lidsDataToCopy = pks
            .map((pk) => this.getSingleLidData(pk))
            .filter(Boolean);
        // If there is no data to copy, return
        if (lidsDataToCopy.length === 0)
            return;
        // Check if all lids have the same startIsoBay
        if (lidsDataToCopy
            .map((l) => l.startIsoBay)
            .filter((v, idx, arr) => arr.indexOf(v) === idx).length > 1) {
            usingAModal === null || usingAModal === void 0 ? void 0 : usingAModal(true);
            const modal = new IntegratedDialogError(me._editingArea, true);
            modal.show(getTranslation("general:common.attention"), getTranslation("view:lids.copyPasteSameStartIsoBay"));
            modal.onDismissed = () => {
                console.log("dismissed");
                usingAModal === null || usingAModal === void 0 ? void 0 : usingAModal(false);
            };
            return;
        }
        // Create the modal
        const confirmPaste = document.createElement("sl-button");
        confirmPaste.innerHTML = getTranslation("view:lids.pasteLids");
        confirmPaste.slot = "footer";
        confirmPaste.tabIndex = 0;
        confirmPaste.variant = "primary";
        confirmPaste.addEventListener("click", () => executeCopyPasteLids(this.getRawData()), false);
        removeChildren(this.deleteConfirmationDialog);
        this.deleteConfirmationDialog.label = getTranslation("general:common.copyPasteSelected");
        const minStartIsoBay = Math.min(...lidsDataToCopy.map((l) => l.startIsoBay));
        const maxEndIsoBay = Math.max(...lidsDataToCopy.map((l) => l.endIsoBay));
        const diffIsoBay = Math.abs(maxEndIsoBay - minStartIsoBay);
        const tedivoForm = new TedivoForm({
            fields: [
                {
                    name: "",
                    type: "title",
                    label: `${getTranslation("view:lids.plural")}: ${lidsDataToCopy
                        .map((l) => l.label || "")
                        .join(", ")}`,
                },
                [
                    {
                        label: "view:lids.startIsoBay",
                        name: "startIsoBay",
                        type: "number",
                        initialValue: maxEndIsoBay + 2,
                    },
                    {
                        label: "view:lids.endIsoBay",
                        name: "endIsoBay",
                        type: "number",
                        initialValue: maxEndIsoBay + 2 + diffIsoBay,
                    },
                ],
            ],
            onSubmit: () => null,
            formValidator: createCopyPasteValidator(),
            submitButton: confirmPaste,
            autoFocusOnFirstInput: true,
        });
        translateTedivoForm({
            tedivoForm,
            getTranslation,
        });
        this.deleteConfirmationDialog.appendChild(tedivoForm.form);
        this.deleteConfirmationDialog.appendChild(confirmPaste);
        (_a = this.usingAModal) === null || _a === void 0 ? void 0 : _a.call(this, true);
        this.deleteConfirmationDialog.show();
        /** This function creates the copy of data */
        function executeCopyPasteLids(data) {
            const validResult = tedivoForm.doSubmitForm();
            if (!validResult.success)
                return false;
            const values = tedivoForm.getValues();
            const loops = calculateLoops(values.endIsoBay, values.startIsoBay, diffIsoBay);
            const otherLabels = data.map((l) => l.label);
            const newPks = [];
            let addBaysInLoop = 0;
            for (let i = 0; i < loops; i++) {
                newPks.push(...lidsDataToCopy.map((lid) => {
                    const baseLabel = pad2(values.startIsoBay + addBaysInLoop);
                    const lbl = generateNewLabel(baseLabel, otherLabels);
                    otherLabels.push(lbl);
                    const lidData = {
                        label: lbl,
                        startIsoBay: values.startIsoBay + addBaysInLoop,
                        endIsoBay: values.startIsoBay + diffIsoBay + addBaysInLoop,
                        portIsoRow: lid.portIsoRow,
                        starboardIsoRow: lid.starboardIsoRow,
                        weight: lid.weight,
                        overlapPort: lid.overlapPort ? 1 : 0,
                        overlapStarboard: lid.overlapStarboard ? 1 : 0,
                    };
                    return me.addLidData(lidData);
                }));
                addBaysInLoop += diffIsoBay * 2;
            }
            me.onChange.forEach((fn) => fn());
            cb === null || cb === void 0 ? void 0 : cb(newPks);
            me.deleteConfirmationDialog.hide();
            return true;
        }
        function createCopyPasteValidator() {
            return z
                .object({
                startIsoBay: z
                    .number()
                    .min(1)
                    .refine(numberIsOdd)
                    .refine((v) => v !== minStartIsoBay),
                endIsoBay: z
                    .number()
                    .min(1)
                    .refine(numberIsOdd)
                    .refine((v) => v !== minStartIsoBay),
            })
                .superRefine((values, ctx) => {
                if (values.endIsoBay < values.startIsoBay) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "End Iso Bay must be greater than or equal to Start Iso Bay",
                        path: ["startIsoBay"],
                    });
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "End Iso Bay must be greater than or equal to Start Iso Bay",
                        path: ["endIsoBay"],
                    });
                }
            });
        }
    }
    deleteLids(pks) {
        var _a;
        const me = this;
        const confirmDelete = document.createElement("sl-button");
        confirmDelete.innerHTML = getTranslation("general:common.delete");
        confirmDelete.slot = "footer";
        confirmDelete.tabIndex = 0;
        confirmDelete.variant = "danger";
        removeChildren(this.deleteConfirmationDialog);
        this.deleteConfirmationDialog.label = getTranslation("view:lids.deleteLid");
        const lidsData = pks
            .map((pk) => this.getSingleLidData(pk))
            .filter(Boolean);
        this.deleteConfirmationDialog.innerHTML = lidsData
            .map((l) => l.label || "")
            .join(", ");
        me.deleteConfirmationDialog.appendChild(confirmDelete);
        confirmDelete.onclick = function () {
            var _a;
            (_a = me.usingAModal) === null || _a === void 0 ? void 0 : _a.call(me, false);
            me.deleteConfirmationDialog.hide();
            pks.forEach((pk) => {
                me.deleteLidData(pk);
            });
            me.onChange.forEach((fn) => fn());
        };
        (_a = me.usingAModal) === null || _a === void 0 ? void 0 : _a.call(me, true);
        me.deleteConfirmationDialog.show();
    }
}
function calculateLoops(end, start, diff) {
    if (start === end)
        return 1;
    if (diff === 2)
        return Math.ceil(Math.abs(end - start) / (diff + 1)) || 1;
    return Math.ceil(Math.abs(end - start) / 2) + 1;
}
