import { __rest } from "tslib";
import { BayLevelEnum } from "open-vessel-definition";
import { EnumHelpers, pad3, sortNumericAsc, } from "@baplie-viewer2/tedivo-pure-helpers";
import { getSizesFromSlots, } from "tedivo-bay-grid-pure";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import { getPreferencesValue, setPreferencesKeyAndValue, } from "@baplie-viewer2/tedivo-preferences";
import { getTranslation } from "../../../../../../../app/i18/i18tn";
import ovdJsonStore from "../../../../../../../app/stores/OVDJsonStore";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { z } from "zod";
export var CopyValuesEnum;
(function (CopyValuesEnum) {
    CopyValuesEnum[CopyValuesEnum["ONLY_EMPTIES"] = 1] = "ONLY_EMPTIES";
    CopyValuesEnum[CopyValuesEnum["YES"] = 5] = "YES";
    CopyValuesEnum[CopyValuesEnum["NO"] = 10] = "NO";
})(CopyValuesEnum || (CopyValuesEnum = {}));
export function createCopyBayCGsDialog(helpersCommonDialog, currentBay, currentLevel, json, dataStore) {
    if (!json)
        return false;
    const sizeSummary = json.sizeSummary;
    removeChildren(helpersCommonDialog);
    helpersCommonDialog.label = getTranslation("view:edit.cgHelpers.copyBayCGs");
    helpersCommonDialog.setAttribute("style", "--width: 900px");
    const btn = document.createElement("sl-button");
    btn.innerHTML = getTranslation("general:common.ok");
    btn.slot = "footer";
    btn.variant = "primary";
    btn.type = "submit";
    btn.addEventListener("click", doTheCopy, false);
    const options = [];
    const bayLabel = getTranslation("general:grid.bay");
    for (let b = 1; b <= sizeSummary.isoBays; b += 2) {
        if (sizeSummary.minAboveTier !== undefined &&
            sizeSummary.maxAboveTier !== undefined)
            options.push({
                value: `${b}-${BayLevelEnum.ABOVE}`,
                label: `${bayLabel} ${pad3(b)}, ${getTranslation("enums:BayLevelEnum.ABOVE")}`,
            });
        if (sizeSummary.minBelowTier !== undefined &&
            sizeSummary.maxBelowTier !== undefined)
            options.push({
                value: `${b}-${BayLevelEnum.BELOW}`,
                label: `${bayLabel} ${pad3(b)}, ${getTranslation("enums:BayLevelEnum.BELOW")}`,
            });
    }
    const defaultOption = `${currentBay === 1 ? 3 : currentBay - 2}-${currentLevel}`;
    const copyValuesEnumOptions = EnumHelpers.getNamesAndValues(CopyValuesEnum).map(({ value, name }) => ({
        value,
        label: getTranslation(`enums:CopyValuesEnum.${name}`),
    }));
    const initalValues = JSON.parse(getPreferencesValue("cgHelpers-copyBayCGs") || "{}");
    const fields = [
        {
            name: "fromBay",
            type: "select",
            label: "view:edit.helpers.fromBay",
            options,
            initialValue: defaultOption,
        },
        [
            {
                name: "copyTcgs",
                type: "radioButtonList",
                label: "view:edit.helpers.copyTcgs",
                fieldset: true,
                options: copyValuesEnumOptions,
                initialValue: (initalValues === null || initalValues === void 0 ? void 0 : initalValues.copyTcgs) || "1",
            },
            {
                name: "copyBottomBase",
                type: "radioButtonList",
                label: "view:edit.helpers.copyBottomBase",
                fieldset: true,
                options: copyValuesEnumOptions,
                initialValue: (initalValues === null || initalValues === void 0 ? void 0 : initalValues.copyBottomBase) || "1",
            },
            {
                name: "copyMaxHeight",
                type: "radioButtonList",
                label: "view:edit.helpers.copyMaxHeight",
                fieldset: true,
                options: copyValuesEnumOptions,
                initialValue: (initalValues === null || initalValues === void 0 ? void 0 : initalValues.copyMaxHeight) || "1",
            },
        ],
        [
            {
                name: "copyLcgs",
                type: "radioButtonList",
                label: "view:edit.helpers.copyLcgs",
                fieldset: true,
                options: copyValuesEnumOptions,
                initialValue: (initalValues === null || initalValues === void 0 ? void 0 : initalValues.copyLcgs) || "1",
            },
            {
                name: "copyStackWeight",
                type: "radioButtonList",
                label: "view:edit.helpers.copyStackWeight",
                fieldset: true,
                options: copyValuesEnumOptions,
                initialValue: (initalValues === null || initalValues === void 0 ? void 0 : initalValues.copyStackWeight) || "1",
            },
            {
                name: "copyBottomWeight",
                type: "radioButtonList",
                label: "view:edit.helpers.copyBottomWeight",
                fieldset: true,
                options: copyValuesEnumOptions,
                initialValue: (initalValues === null || initalValues === void 0 ? void 0 : initalValues.copyBottomWeight) || "1",
            },
        ],
    ];
    const validator = z.object({
        fromBay: z.string(),
        copyTcgs: z.number(),
        copyBottomBase: z.number(),
        copyLcgs: z.number(),
        copyMaxHeight: z.number(),
        copyStackWeight: z.number(),
        copyBottomWeight: z.number(),
    });
    const tedivoForm = new TedivoForm({
        fields,
        onSubmit: () => null,
        formValidator: validator,
        submitButton: btn,
    });
    translateTedivoForm({ tedivoForm, getTranslation });
    helpersCommonDialog.appendChild(tedivoForm.form);
    helpersCommonDialog.appendChild(btn);
    return true;
    function doTheCopy() {
        var _a;
        const options = tedivoForm.getValues();
        doPasteValuesToMemory(options, ovdJsonStore.currentJson, dataStore, pad3(currentBay), currentLevel);
        helpersCommonDialog.hide();
        (_a = dataStore.onChange) === null || _a === void 0 ? void 0 : _a.call(dataStore);
        dataStore.updateConsumers();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fromBay } = options, copyOptions = __rest(options, ["fromBay"]);
        setPreferencesKeyAndValue("cgHelpers-copyBayCGs", copyOptions);
    }
}
function doPasteValuesToMemory(options, json, dataStore, currentBay, currentLevel) {
    var _a, _b;
    if (!json)
        return false;
    const [bay, level] = options.fromBay.split("-").map(Number);
    const isoBay = pad3(bay);
    const targetBaseKey = `b${currentBay}-${currentLevel}`;
    const sourceBaseKey = `b${isoBay}-${level}`;
    const copyFromBayData = json.baysData.find((bd) => bd.isoBay === isoBay && bd.level === level);
    const targetBayData = json.baysData.find((bd) => bd.isoBay === currentBay && bd.level === currentLevel);
    // 1. Common
    const common = (_a = copyFromBayData === null || copyFromBayData === void 0 ? void 0 : copyFromBayData.perRowInfo) === null || _a === void 0 ? void 0 : _a.common;
    doPasteValue(`${targetBaseKey}.common.maxHeight`, dataStore.getValueAsNumber(`${sourceBaseKey}.common.maxHeight`) ||
        (common === null || common === void 0 ? void 0 : common.maxHeight), options.copyMaxHeight);
    doPasteValue(`${targetBaseKey}.common.bottomBase`, dataStore.getValueAsNumber(`${sourceBaseKey}.common.bottomBase`) ||
        (common === null || common === void 0 ? void 0 : common.bottomBase), options.copyBottomBase);
    // 1.1 Common from infoByContLength (LCG, Bottom Weight & Stack Weight)
    const containersLengths = getSizesFromSlots(targetBayData === null || targetBayData === void 0 ? void 0 : targetBayData.perSlotInfo).sizes;
    containersLengths.forEach((size) => {
        var _a, _b, _c, _d, _e, _f;
        doPasteValue(`${targetBaseKey}.infoByContLength.${size}.lcg`, dataStore.getValueAsNumber(`${sourceBaseKey}.infoByContLength.${size}.lcg`) || ((_b = (_a = copyFromBayData === null || copyFromBayData === void 0 ? void 0 : copyFromBayData.infoByContLength) === null || _a === void 0 ? void 0 : _a[size]) === null || _b === void 0 ? void 0 : _b.lcg), options.copyLcgs);
        doPasteValue(`${targetBaseKey}.infoByContLength.${size}.bottomWeight`, dataStore.getValueAsNumber(`${sourceBaseKey}.infoByContLength.${size}.bottomWeight`) || ((_d = (_c = copyFromBayData === null || copyFromBayData === void 0 ? void 0 : copyFromBayData.infoByContLength) === null || _c === void 0 ? void 0 : _c[size]) === null || _d === void 0 ? void 0 : _d.bottomWeight), options.copyBottomWeight);
        doPasteValue(`${targetBaseKey}.infoByContLength.${size}.rowWeight`, dataStore.getValueAsNumber(`${sourceBaseKey}.infoByContLength.${size}.rowWeight`) || ((_f = (_e = copyFromBayData === null || copyFromBayData === void 0 ? void 0 : copyFromBayData.infoByContLength) === null || _e === void 0 ? void 0 : _e[size]) === null || _f === void 0 ? void 0 : _f.rowWeight), options.copyStackWeight);
    });
    const perRowEach = (_b = copyFromBayData === null || copyFromBayData === void 0 ? void 0 : copyFromBayData.perRowInfo) === null || _b === void 0 ? void 0 : _b.each;
    const dataStoreEach = dataStore.getValuesOfParent(`${sourceBaseKey}.each`);
    // 2. Each rows
    const allRowsUnique = [
        ...Object.keys(perRowEach || {}),
        ...Object.keys(dataStoreEach || {}),
    ]
        .sort(sortNumericAsc)
        .filter((v, idx, arr) => arr.indexOf(v) === idx);
    allRowsUnique.forEach((row) => {
        var _a, _b;
        const rowValues = perRowEach === null || perRowEach === void 0 ? void 0 : perRowEach[row];
        doPasteValue(`${targetBaseKey}.each.${row}.maxHeight`, dataStore.getValueAsNumber(`${sourceBaseKey}.each.${row}.maxHeight`) ||
            (rowValues === null || rowValues === void 0 ? void 0 : rowValues.maxHeight), options.copyMaxHeight);
        doPasteValue(`${targetBaseKey}.each.${row}.bottomBase`, dataStore.getValueAsNumber(`${sourceBaseKey}.each.${row}.bottomBase`) ||
            (rowValues === null || rowValues === void 0 ? void 0 : rowValues.bottomBase), options.copyBottomBase);
        doPasteValue(`${targetBaseKey}.each.${row}.tcg`, dataStore.getValueAsNumber(`${sourceBaseKey}.each.${row}.tcg`) ||
            (rowValues === null || rowValues === void 0 ? void 0 : rowValues.tcg), options.copyTcgs);
        // 2.1 rowInfoByLength
        const allSizesUnique = [
            ...Object.keys((rowValues === null || rowValues === void 0 ? void 0 : rowValues.rowInfoByLength) || {}),
            ...Object.keys(((_a = dataStoreEach === null || dataStoreEach === void 0 ? void 0 : dataStoreEach[row]) === null || _a === void 0 ? void 0 : _a.rowInfoByLength) || {}),
        ]
            .sort(sortNumericAsc)
            .filter((v, idx, arr) => arr.indexOf(v) === idx)
            .map(Number);
        const rowByLengthValues = rowValues === null || rowValues === void 0 ? void 0 : rowValues.rowInfoByLength;
        const dataStoreByLenghtValues = (_b = dataStoreEach === null || dataStoreEach === void 0 ? void 0 : dataStoreEach[row]) === null || _b === void 0 ? void 0 : _b.rowInfoByLength;
        allSizesUnique.forEach((size) => {
            var _a, _b, _c, _d, _e, _f;
            doPasteValue(`${targetBaseKey}.each.${row}.rowInfoByLength.${size}.lcg`, ((_a = dataStoreByLenghtValues === null || dataStoreByLenghtValues === void 0 ? void 0 : dataStoreByLenghtValues[size]) === null || _a === void 0 ? void 0 : _a.lcg) || ((_b = rowByLengthValues === null || rowByLengthValues === void 0 ? void 0 : rowByLengthValues[size]) === null || _b === void 0 ? void 0 : _b.lcg), options.copyLcgs);
            doPasteValue(`${targetBaseKey}.each.${row}.rowInfoByLength.${size}.rowWeight`, ((_c = dataStoreByLenghtValues === null || dataStoreByLenghtValues === void 0 ? void 0 : dataStoreByLenghtValues[size]) === null || _c === void 0 ? void 0 : _c.rowWeight) ||
                ((_d = rowByLengthValues === null || rowByLengthValues === void 0 ? void 0 : rowByLengthValues[size]) === null || _d === void 0 ? void 0 : _d.rowWeight), options.copyStackWeight);
            doPasteValue(`${targetBaseKey}.each.${row}.rowInfoByLength.${size}.bottomWeight`, ((_e = dataStoreByLenghtValues === null || dataStoreByLenghtValues === void 0 ? void 0 : dataStoreByLenghtValues[size]) === null || _e === void 0 ? void 0 : _e.bottomWeight) ||
                ((_f = rowByLengthValues === null || rowByLengthValues === void 0 ? void 0 : rowByLengthValues[size]) === null || _f === void 0 ? void 0 : _f.bottomWeight), options.copyBottomWeight);
        });
    });
    /** Internal: Copies the value following replace criteria */
    function doPasteValue(targetKey, value, criteria) {
        const numCriteria = Number(criteria);
        if (numCriteria === CopyValuesEnum.NO)
            return;
        if (numCriteria === CopyValuesEnum.YES ||
            (numCriteria === CopyValuesEnum.ONLY_EMPTIES &&
                dataStore.getValue(targetKey) === undefined)) {
            dataStore.setValue(targetKey, value);
        }
    }
}
