import HttpClient, { IResponseModel } from "../HttpClient";

import { IResultVesselMasterByImoData } from "@baplie-viewer2/tedivo-api-models";

const VesselsMasterDataService = {
  searchVesselByImoCode: async (
    imoCode: string,
    forceApi = false,
  ): Promise<IResponseModel<IResultVesselMasterByImoData>> => {
    const client = new HttpClient();
    const url = [
      `vesselInfo/byImo/${encodeURI(imoCode)}`,
      forceApi ? "forceApi=true" : "",
    ]
      .filter(Boolean)
      .join("?");

    return await client.request<IResultVesselMasterByImoData>({
      method: "GET",
      url,
    });
  },
};

export default VesselsMasterDataService;
