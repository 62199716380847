import { FEET_10_IN_MM, FEET_20_IN_MM } from "../../consts";
import { I20Lcgs, IBayPattern } from "tedivo-bay-grid-pure";
import { roundDec, sortNumericAsc } from "@baplie-viewer2/tedivo-pure-helpers";

import { ForeAftEnum } from "open-vessel-definition";

export function fillMissingPlausibleData(lcgsBy20Bay: {
  [bay: IBayPattern]: I20Lcgs;
}) {
  const bays20Keys = (Object.keys(lcgsBy20Bay) as IBayPattern[]).sort(
    sortNumericAsc,
  ); // From 001 to maxIsoBay

  const bays20KeysCount = bays20Keys.length;

  // Missing Fore
  let idx = 1;
  while (idx < bays20KeysCount) {
    const bay = bays20Keys[idx];
    const prevBay = bays20Keys[idx - 1];

    if (
      lcgsBy20Bay[bay].paired === ForeAftEnum.FWD &&
      lcgsBy20Bay[prevBay].lcg === undefined
    ) {
      const lcg = lcgsBy20Bay[bay].lcg;
      if (lcg !== undefined) {
        // As LCG reference is AFT PP, prev LCG must be greater than current LCG
        const prevLcg20 = roundDec(lcg + FEET_20_IN_MM, 2);

        lcgsBy20Bay[prevBay] = {
          lcg: prevLcg20,
          aftLcg: roundDec(prevLcg20 - FEET_10_IN_MM, 2),
          foreLcg: roundDec(prevLcg20 + FEET_10_IN_MM, 2),
          paired: ForeAftEnum.AFT,
          maxSize: lcgsBy20Bay[prevBay]?.maxSize || 20,
        };
      }
    }

    idx++;
  }

  // Missing Aft
  idx = 0;
  while (idx < bays20KeysCount - 1) {
    const bay = bays20Keys[idx];
    const nextBay = bays20Keys[idx + 1];

    if (
      lcgsBy20Bay[bay].paired === ForeAftEnum.AFT &&
      lcgsBy20Bay[nextBay].lcg === undefined
    ) {
      const lcg = lcgsBy20Bay[bay].lcg;
      if (lcg !== undefined) {
        // As LCG reference is AFT PP, next LCG must be smaller than current LCG
        const nextLcg20 = roundDec(lcg - FEET_20_IN_MM, 2);

        lcgsBy20Bay[nextBay] = {
          lcg: nextLcg20,
          aftLcg: roundDec(nextLcg20 - FEET_10_IN_MM, 2),
          foreLcg: roundDec(nextLcg20 + FEET_10_IN_MM, 2),
          paired: ForeAftEnum.FWD,
          maxSize: lcgsBy20Bay[nextBay]?.maxSize || 20,
        };
      }
    }

    idx++;
  }
}
