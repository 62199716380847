import {
  IBayLevelData,
  ILidData,
  IMasterCGs,
  ISizeSummary,
  IVesselParts,
} from "open-vessel-definition";

import { IGetBayLcgVcgAndPairingsResult } from "tedivo-bay-grid-pure";
import { createSimpleDeckView } from "@baplie-viewer2/tedivo-bay-grid-core";
import { getTranslation } from "../../../../app/i18/i18tn";

interface ICreateDeckViewProps {
  sizeSummary: ISizeSummary;
  baysData: IBayLevelData[];
  masterCGs: IMasterCGs;
  lidData: ILidData[];
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  vesselPartsData: IVesselParts[];
  editActionNode?: HTMLElement;
  bayNumbersDoubleClickAction?: (ev: SVGElement) => void;
}

export function createDeckView({
  sizeSummary,
  baysData,
  masterCGs,
  lidData,
  lcgVcgTcgAndPairings,
  editActionNode,
  vesselPartsData,
  bayNumbersDoubleClickAction,
}: ICreateDeckViewProps): HTMLElement {
  const root = getComputedStyle(document.body);

  const card = document.createElement("card-element");
  card.titleHtml = "view:deckView";
  card.name = "deckView";

  if (editActionNode) card.actions = editActionNode;

  try {
    if (lcgVcgTcgAndPairings.totalSlotsCount === 0) {
      const empty = document.createElement("div");
      empty.className = "no-slots-defined";
      empty.innerHTML = getTranslation(
        "view:graphicWillDisplaySlotsWhenDefined",
      );
      card.appendChild(empty);
    }

    const { deckViewSvg, missingImportantXcgs, validStats } =
      createSimpleDeckView({
        sizeSummary,
        baysData,
        masterCGs,
        lidData,
        lcgVcgTcgAndPairings,
        symbolsOptions: {
          strokeWidth: 1,
          strokeColor: root.getPropertyValue("--sl-color-neutral-200"),
          dimmedStrokeColor: root.getPropertyValue("--sl-color-neutral-300"),
          fontColor: root.getPropertyValue("--sl-color-neutral-700"),
          fillColor: root.getPropertyValue("--sl-color-neutral-50"),
          shipStrokeColor: root.getPropertyValue("--sl-color-primary-300"),
          lidFillColor: root.getPropertyValue("--sl-color-green-200"),
          lidWarningFillColor: root.getPropertyValue("--sl-color-orange-200"),
          lidDangerFillColor: root.getPropertyValue("--sl-color-red-200"),
          lidTextColor: root.getPropertyValue("--sl-color-primary-400"),
          warningColor: root.getPropertyValue("--sl-color-warning-700"),
        },
        vesselPartsData,
        bayNumbersDoubleClickAction,
      });

    card.appendChild(deckViewSvg);

    const legend = document.createElement("div");
    legend.className = "deckview-legend";

    if (validStats.ok || validStats.warning || validStats.danger) {
      const validOk = document.createElement("span");
      validOk.className = "deckview-legend-ok";
      validOk.innerHTML = getTranslation("view:lids.validation.ok");
      legend.appendChild(validOk);
    }

    if (validStats.warning) {
      const validWarning = document.createElement("span");
      validWarning.className = "deckview-legend-warning";
      validWarning.innerHTML = getTranslation("view:lids.validation.warning");
      legend.appendChild(validWarning);
    }

    if (validStats.danger) {
      const validDanger = document.createElement("span");
      validDanger.className = "deckview-legend-danger";
      validDanger.innerHTML = getTranslation("view:lids.validation.danger");
      legend.appendChild(validDanger);
    }

    card.appendChild(legend);

    if (
      missingImportantXcgs &&
      (lcgVcgTcgAndPairings.cgsStats.definedBottomBase > 0 ||
        lcgVcgTcgAndPairings.cgsStats.definedLcgs > 0 ||
        lcgVcgTcgAndPairings.cgsStats.definedTcgs > 0)
    ) {
      const note = document.createElement("sl-badge");
      note.variant = "warning";
      note.pill = true;
      note.innerHTML = getTranslation("view:errors.missingXcgs");
      card.appendChild(note);
    }
  } catch (e) {
    console.error(e);
  }

  return card;
}
