import SlSpinner from "@shoelace-style/shoelace/dist/components/spinner/spinner";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";

export default class DivWithSpinner extends HTMLElement {
  private intSlot: HTMLSlotElement;
  private intSpinner: SlSpinner;
  private isShowingSpinner = false;

  constructor() {
    super();
    this.intSlot = document.createElement("slot");
    this.intSpinner = document.createElement("sl-spinner");
    this.intSpinner.style.display = "none";
    this.intSlot.style.display = "block";
  }

  get minHeight(): string {
    return this.intSlot.style.minHeight;
  }

  set minHeight(v: string) {
    this.intSlot.style.minHeight = v;
  }

  connectedCallback() {
    const root = this.attachShadow({ mode: "open" });

    root.appendChild(this.intSlot);
    root.appendChild(this.intSpinner);
  }

  setLoading(showSpinner = false, force = false): Promise<void> {
    return new Promise((resolve) => {
      if (this.isShowingSpinner === showSpinner && !force) {
        resolve();
        return;
      }

      this.isShowingSpinner = showSpinner;
      this.intSpinner.style.display = showSpinner ? "block" : "none";
      this.intSlot.style.display = showSpinner ? "none" : "contents";

      resolve();
    });
  }

  clearChildren() {
    removeChildren(this.intSlot);
  }

  preserveHeight(v: boolean): this {
    if (v) {
      globalThis.requestAnimationFrame(() => {
        const clientHeight = Math.ceil(this.getBoundingClientRect().height);
        this.style.minHeight = `${clientHeight}px`;
      });
    } else {
      this.style.minHeight = "auto";
    }

    return this;
  }

  withLoading = async <T>(fn: () => Promise<T>): Promise<T> => {
    await this.setLoading(true);
    const result = await fn();
    await this.setLoading(false);

    return result;
  };
}

customElements.define("div-spinner-element", DivWithSpinner);

declare global {
  interface HTMLElementTagNameMap {
    "div-spinner-element": DivWithSpinner;
  }
}
