import { IMasterCGs, IShipData, ISizeSummary } from "open-vessel-definition";
import {
  IRowPattern,
  ITierPattern,
  TCG_IN_MMM,
  createRowsFromConfig,
  createTiersFromConfig,
} from "tedivo-bay-grid-pure";
import {
  numberOrUndefined,
  pad2,
  roundDec,
  sortNumericAsc,
  sortNumericDesc,
} from "@baplie-viewer2/tedivo-pure-helpers";

import EditDrawer from "../../../../../components/common/EditDrawer";
import FieldsValuesStore from "../../../../../app/stores/FieldsValuesStore";
import VcgVerticalReferenceEnum from "../../../../../app/enums/VcgVerticalReferenceEnum";
import { createInputWithUnits } from "@baplie-viewer2/tedivo-form";
import { createSvgText } from "libs/tedivo-bay-grid-core/src/lib/shipViews/helpers/createSvgText";
import { getTranslation } from "../../../../../app/i18/i18tn";
import globalUnits from "../../../../../app/units/globalUnits";
import goSquared from "../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";

export function createCGsMasterEdit(
  shipData: IShipData,
  sizeSummary: ISizeSummary,
  editDrawer: EditDrawer,
): IEditReturn {
  goSquared.addEvent("Edit-OVD - Edit Master CGs");

  const holder = document.createElement("div");
  const dataStore = new FieldsValuesStore();

  holder.appendChild(
    createMasterCGsSection(sizeSummary, shipData, dataStore, editDrawer),
  );

  return {
    node: holder,
    submitFunction: submitPassedToEditDrawer,
  };

  function submitPassedToEditDrawer(): boolean {
    const masterCGs = dataStore.getValuesOfParent<IMasterCGs>("masterCGs");

    goSquared.addEvent("Edit-OVD - Edit Master CGs - Save");

    ovdJsonStore.setMasterCGs(masterCGs);
    return true;
  }
}

function createMasterCGsSection(
  sizeSummary: ISizeSummary,
  shipData: IShipData,
  dataStore: FieldsValuesStore,
  editDrawer: EditDrawer,
): HTMLElement {
  const holder = document.createElement("section");
  holder.className = "editing-area";

  const tablesHolder = document.createElement("div");

  tablesHolder.appendChild(
    createMasterTCGsTable(shipData, sizeSummary, dataStore),
  );

  tablesHolder.appendChild(
    createMasterBBsTable(shipData, sizeSummary, dataStore),
  );

  holder.appendChild(tablesHolder);

  const root = getComputedStyle(document.body);
  const svgOptions: ISvgOptions = {
    tcgAboveColor: root.getPropertyValue("--sl-color-orange-300"),
    tcgBelowColor: root.getPropertyValue("--sl-color-red-300"),
    bottomBasesColor: root.getPropertyValue("--sl-color-sky-300"),
    textColor: root.getPropertyValue("--sl-color-neutral-700"),
  };

  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  svg.classList.add("cgsBayViewMaster");
  holder.appendChild(svg);
  addDiagram(sizeSummary, dataStore, svg, svgOptions);

  dataStore.onChange = () => {
    removeChildren(svg);
    addDiagram(sizeSummary, dataStore, svg, svgOptions);
    editDrawer.dataIsDirty = true;
  };

  return holder;
}

function createMasterTCGsTable(
  shipData: IShipData,
  sizeSummary: ISizeSummary,
  dataStore: FieldsValuesStore,
): HTMLTableElement {
  // 1. TCGs
  const tableMasterTcgs = document.createElement("table");
  const tHead = document.createElement("thead");
  const tbody = document.createElement("tbody");
  tableMasterTcgs.className = "tvd-table";

  const masterCGs = shipData.masterCGs;

  const rows = createRowsFromConfig(
    !!sizeSummary.centerLineRow,
    sizeSummary.maxRow,
  ).sort(sortNumericAsc);

  const rowsParts: string[][] = [
    rows.filter((s) => Number(s) % 2 === 0),
    [...rows.filter((s) => Number(s) % 2 === 1)],
  ];

  if (sizeSummary.centerLineRow) {
    rowsParts[1].unshift("");
  }

  const maxCells = Math.max(rowsParts[0].length, rowsParts[1].length) + 1;

  const valuesSign: { [s: string]: "positive" | "negative" } = {
    "0_2": "negative",
    "0_1": "positive",
    "1_2": "positive",
    "1_1": "negative",
  };

  rowsParts.forEach((part, idx) => {
    const trHeader = document.createElement("tr");
    const trAbove = document.createElement("tr");
    const trBelow = document.createElement("tr");
    const trHP = document.createElement("tr");

    const tdSide = document.createElement("th");
    tdSide.colSpan = maxCells;
    tdSide.innerHTML = `${getTranslation(
      `enums:PortStarboardEnum.${idx === 0 ? "PORT" : "STARBOARD"}`,
    )} <small>(${getTranslation(
      `general:valuesSign.${valuesSign[`${idx}_${globalUnits.units.tcgDir}`]}`,
    )})</small>`;
    trHP.appendChild(tdSide);

    const tdT1 = document.createElement("th");
    tdT1.innerHTML = getTranslation("general:grid.row");
    trHeader.appendChild(tdT1);

    const tdT2 = document.createElement("td");
    tdT2.style.minWidth = "12ch";
    const sp2 = document.createElement("span");
    sp2.innerHTML = getTranslation("view:edit.editCGs.masterTcgsAbove");
    sp2.className = "view-edit-editCGs-masterTcgsAbove";
    tdT2.appendChild(sp2);
    trAbove.appendChild(tdT2);

    const tdT3 = document.createElement("td");
    const sp3 = document.createElement("span");
    sp3.innerHTML = getTranslation("view:edit.editCGs.masterTcgsBelow");
    sp3.className = "view-edit-editCGs-masterTcgsBelow";
    tdT3.appendChild(sp3);
    trBelow.appendChild(tdT3);

    part.forEach((rowName) => {
      const tdHeader = document.createElement("th");
      tdHeader.innerHTML = rowName;
      trHeader.appendChild(tdHeader);

      addTcgField(rowName, "aboveTcgs", trAbove);
      addTcgField(rowName, "belowTcgs", trBelow);

      function addTcgField(
        rowName: string,
        level: "aboveTcgs" | "belowTcgs",
        tr: HTMLTableRowElement,
      ) {
        const td = document.createElement("td");

        const inp = createInputWithUnits({
          name: `masterCGs.${level}.${rowName}`,
          value:
            numberOrUndefined(
              dataStore.getValue(`masterCGs.${level}.${rowName}`),
            ) || masterCGs[level][rowName as IRowPattern],
          converter: globalUnits.lengthUnits,
          transponser: globalUnits.tcgTransposer,
          noSpinButtons: true,
        });

        dataStore.registerInputField(inp);
        td.appendChild(inp);
        tr.appendChild(td);

        if (rowName !== "00") {
          inp.addEventListener("sl-change", () => {
            const nameParts = inp.name.split(".");
            if (nameParts.length > 2 && !isNaN(Number(nameParts[2]))) {
              const iRow = Number(nameParts[2]);
              const sisterIRow = iRow % 2 === 1 ? iRow + 1 : iRow - 1;
              const sisterFieldName = nameParts
                .map((p, idx) => (idx === 2 ? pad2(sisterIRow) : p))
                .join(".");

              if (dataStore.getValue(sisterFieldName) === undefined) {
                const value = -Number(inp.value);
                dataStore.setValue(sisterFieldName, value);
                dataStore.updateConsumers();
              }
            }
          });
        }
      }
    });

    tbody.appendChild(trHP);
    tbody.appendChild(trHeader);
    tbody.appendChild(trAbove);
    tbody.appendChild(trBelow);
  });

  tableMasterTcgs.appendChild(tHead);
  tableMasterTcgs.appendChild(tbody);
  return tableMasterTcgs;
}

function createMasterBBsTable(
  shipData: IShipData,
  sizeSummary: ISizeSummary,
  dataStore: FieldsValuesStore,
): HTMLTableElement {
  // 1. Above Tiers
  const tableMasterBBs = document.createElement("table");
  const tHead = document.createElement("thead");
  const tbody = document.createElement("tbody");
  tableMasterBBs.className = "tvd-table tvd-table-sticky";

  const labelVcg = `enums:VcgVerticalReferenceEnum.${
    VcgVerticalReferenceEnum[globalUnits.vcgTransposer.transposeTo]
  }`;

  const cols = ["view:aboveTiers", labelVcg, "view:belowTiers", labelVcg];

  const masterCGs = shipData.masterCGs;
  masterCGs.aboveTcgs;

  // 1.1 Headers
  const trH = document.createElement("tr");
  cols.forEach((colLabel) => {
    const td = document.createElement("th");
    const sp = document.createElement("span");
    sp.innerHTML = getTranslation(colLabel);
    sp.className = colLabel.replace(/[.|:]/gm, "-");
    td.appendChild(sp);
    trH.appendChild(td);
  });
  tHead.appendChild(trH);

  const belowTiers = createTiersFromConfig(
    sizeSummary.minBelowTier,
    sizeSummary.maxBelowTier,
  ).sort(sortNumericDesc);

  const aboveTiers = createTiersFromConfig(
    sizeSummary.minAboveTier,
    sizeSummary.maxAboveTier,
  ).sort(sortNumericDesc);

  const maxTiersNumber = Math.max(aboveTiers.length, belowTiers.length);

  for (let tIdx = 0; tIdx < maxTiersNumber; tIdx += 1) {
    const tr = document.createElement("tr");

    const label1 = document.createElement("td");
    label1.className = "centered";
    const td1 = document.createElement("td");
    const label2 = document.createElement("td");
    label2.className = "centered";
    const td2 = document.createElement("td");

    label1.style.minWidth = "12ch";
    label2.style.minWidth = "12ch";

    if (aboveTiers[tIdx]) {
      const tierAb = String(aboveTiers[tIdx]);
      label1.innerHTML = tierAb;
      const inp1 = createInputWithUnits({
        name: `masterCGs.bottomBases.${tierAb}`,
        value:
          numberOrUndefined(
            dataStore.getValue(`masterCGs.bottomBases.${tierAb}`),
          ) || masterCGs.bottomBases[tierAb as ITierPattern],
        converter: globalUnits.lengthUnits,
        transponser: globalUnits.vcgTransposer,
        noSpinButtons: true,
      });
      dataStore.registerInputField(inp1);
      td1.appendChild(inp1);
    }

    if (belowTiers[tIdx]) {
      const tierBe = String(belowTiers[tIdx]);
      label2.innerHTML = tierBe;
      const inp1 = createInputWithUnits({
        name: `masterCGs.bottomBases.${tierBe}`,
        value:
          numberOrUndefined(
            dataStore.getValue(`masterCGs.bottomBases.${tierBe}`),
          ) || masterCGs.bottomBases[tierBe as ITierPattern],
        converter: globalUnits.lengthUnits,
        transponser: globalUnits.vcgTransposer,
        noSpinButtons: true,
      });
      dataStore.registerInputField(inp1);
      td2.appendChild(inp1);
    }

    tr.appendChild(label1);
    tr.appendChild(td1);
    tr.appendChild(label2);
    tr.appendChild(td2);

    tbody.appendChild(tr);
  }

  tableMasterBBs.appendChild(tHead);
  tableMasterBBs.appendChild(tbody);
  return tableMasterBBs;
}

function addDiagram(
  sizeSummary: ISizeSummary,
  dataStore: FieldsValuesStore,
  svg: SVGElement,
  svgOptions: ISvgOptions,
): boolean {
  if (sizeSummary.maxRow === undefined) return false;

  const SVG_WIDTH = 400;
  const STROKE_WIDTH = "1";
  const FONT_SIZE = 6;

  const rows = createRowsFromConfig(
    !!sizeSummary.centerLineRow,
    sizeSummary.maxRow,
  );

  const aboveTiers = createTiersFromConfig(
    sizeSummary.minAboveTier,
    sizeSummary.maxAboveTier,
    true,
  );

  const belowTiers = createTiersFromConfig(
    sizeSummary.minBelowTier,
    sizeSummary.maxBelowTier,
    true,
  );

  let leftTcg = Infinity,
    rightTcg = -Infinity,
    maxBb = -Infinity,
    minBb = Infinity,
    maxBelowBb = -Infinity,
    minAboveBb = Infinity;

  type TInferred = {
    [row: string]: number;
  };

  const tcgValuesObjAbove =
    dataStore.getValuesOfParent<TInferred>(`masterCGs.aboveTcgs`);
  Object.keys(tcgValuesObjAbove).forEach((key) => {
    const cVal = numberOrUndefined(tcgValuesObjAbove[key]);
    if (cVal !== undefined) {
      if (cVal < leftTcg) leftTcg = cVal;
      if (cVal > rightTcg) rightTcg = cVal;
    }
  });

  const tcgValuesObjBelow =
    dataStore.getValuesOfParent<TInferred>(`masterCGs.belowTcgs`);
  Object.keys(tcgValuesObjBelow).forEach((key) => {
    const cVal = numberOrUndefined(tcgValuesObjBelow[key]);
    if (cVal !== undefined) {
      if (cVal < leftTcg) leftTcg = cVal;
      if (cVal > rightTcg) rightTcg = cVal;
    }
  });

  const bottomBasesValues = dataStore.getValuesOfParent<TInferred>(
    `masterCGs.bottomBases`,
  );
  Object.keys(bottomBasesValues).forEach((key) => {
    const cVal = numberOrUndefined(bottomBasesValues[key]);
    if (cVal !== undefined) {
      if (cVal < minBb) minBb = cVal;
      if (cVal > maxBb) maxBb = cVal;
      if (aboveTiers.indexOf(key as ITierPattern) >= 0 && cVal < minAboveBb)
        minAboveBb = cVal;
      if (belowTiers.indexOf(key as ITierPattern) >= 0 && cVal > maxBelowBb)
        maxBelowBb = cVal;
    }
  });

  const width = rightTcg - leftTcg + TCG_IN_MMM * 3;
  const scaled = (n: number) => roundDec((SVG_WIDTH / width) * n, 2);
  const height = scaled(maxBb - minBb + TCG_IN_MMM * 2);

  if (isNaN(width) || isNaN(height)) return false;

  const addX = roundDec(scaled(Math.abs(leftTcg) + TCG_IN_MMM));
  const addY = roundDec(height + scaled(minBb - TCG_IN_MMM));

  const aboveTcgPathParts: Array<string> = [];
  const aboveTcgPathTitles: Array<SVGElement> = [];
  const belowTcgPathParts: Array<string> = [];
  const belowTcgPathTitles: Array<SVGElement> = [];
  const bottomBasesParts: Array<string> = [];
  const bottomBasesTitles: Array<SVGElement> = [];

  rows.forEach((row) => {
    const tcgAbove = numberOrUndefined(tcgValuesObjAbove[row]);
    if (tcgAbove !== undefined) {
      const x = roundDec(scaled(tcgAbove + TCG_IN_MMM * 0.5) + addX, 2);
      aboveTcgPathParts.push(
        `M${x},${roundDec(addY - scaled(minAboveBb))} L${x},${roundDec(
          addY - scaled(maxBb + TCG_IN_MMM),
        )}`,
      );

      aboveTcgPathTitles.push(
        createSvgText({
          text: `${row}`,
          x: x,
          y: addY - scaled(minAboveBb - TCG_IN_MMM * 0.5),
          fontSize: FONT_SIZE,
          textColor: svgOptions.textColor,
        }),
      );
    }

    const tcgBelow = numberOrUndefined(tcgValuesObjBelow[row]);
    if (tcgBelow !== undefined) {
      const x = roundDec(scaled(tcgBelow + TCG_IN_MMM * 0.5) + addX, 2);
      belowTcgPathParts.push(
        `M${x},${roundDec(addY - scaled(maxBelowBb))} L${x},${roundDec(
          addY - scaled(minBb),
        )}`,
      );

      belowTcgPathTitles.push(
        createSvgText({
          text: `${row}`,
          x: x,
          y: addY - scaled(minBb - TCG_IN_MMM * 0.5),
          fontSize: FONT_SIZE,
          textColor: svgOptions.textColor,
        }),
      );
    }
  });

  const allTiers = [...belowTiers, ...aboveTiers];
  allTiers.forEach((tier) => {
    const bb = numberOrUndefined(bottomBasesValues[tier]);
    if (bb !== undefined) {
      const y = roundDec(addY - scaled(bb), 2);
      const x = scaled(TCG_IN_MMM * 0.5);
      bottomBasesParts.push(
        `M${scaled(TCG_IN_MMM)},${y} L${SVG_WIDTH - scaled(TCG_IN_MMM)},${y}`,
      );

      bottomBasesTitles.push(
        createSvgText({
          text: `${tier}`,
          x: x,
          y: y,
          fontSize: FONT_SIZE,
          textColor: svgOptions.textColor,
        }),
      );
    }
  });

  svg.setAttribute("width", `${SVG_WIDTH}px`);
  svg.setAttribute("height", `${height}px`);
  svg.setAttribute("viewBox", `0 0 ${SVG_WIDTH} ${height}`);

  const svgG = document.createElementNS("http://www.w3.org/2000/svg", "g");
  svgG.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  svgG.classList.add("cgsBayViewMaster");

  const pathAboveTcgs = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path",
  );
  pathAboveTcgs.setAttribute("stroke-width", STROKE_WIDTH);
  pathAboveTcgs.setAttribute("stroke", svgOptions.tcgAboveColor);
  pathAboveTcgs.setAttribute("d", aboveTcgPathParts.join(" "));
  svgG.appendChild(pathAboveTcgs);

  const pathBelowTcgs = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path",
  );
  pathBelowTcgs.setAttribute("stroke-width", STROKE_WIDTH);
  pathBelowTcgs.setAttribute("stroke", svgOptions.tcgBelowColor);
  pathBelowTcgs.setAttribute("d", belowTcgPathParts.join(" "));
  svgG.appendChild(pathBelowTcgs);

  const pathBottomBases = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path",
  );
  pathBottomBases.setAttribute("stroke-width", STROKE_WIDTH);
  pathBottomBases.setAttribute("stroke", svgOptions.bottomBasesColor);
  pathBottomBases.setAttribute("d", bottomBasesParts.join(" "));
  svgG.appendChild(pathBottomBases);

  aboveTcgPathTitles.forEach((sTxt) => svgG.appendChild(sTxt));
  belowTcgPathTitles.forEach((sTxt) => svgG.appendChild(sTxt));
  bottomBasesTitles.forEach((sTxt) => svgG.appendChild(sTxt));

  svg.appendChild(svgG);
  return true;
}

interface ISvgOptions {
  tcgAboveColor: string;
  tcgBelowColor: string;
  bottomBasesColor: string;
  textColor: string;
}

interface IEditReturn {
  node: HTMLElement;
  submitFunction: () => boolean;
}
