import { ONE_MILLIMETER_IN_FEET, roundDec, } from "@baplie-viewer2/tedivo-pure-helpers";
import { getPreferencesValue, setPreferencesKeyAndValue, } from "@baplie-viewer2/tedivo-preferences";
import LenUnitsEnum from "../enums/LenUnitsEnum";
import MassUnitsEnum from "../enums/MassUnitsEnum";
import { PortStarboardEnum } from "open-vessel-definition";
import UoLcgReferenceEnum from "../enums/UoLcgReferenceEnum";
import VcgVerticalReferenceEnum from "../enums/VcgVerticalReferenceEnum";
import i18n from "../i18/i18tn";
import ovdJsonStore from "../stores/OVDJsonStore";
class GlobalUnits {
    constructor() {
        this.units = {
            length: LenUnitsEnum.METERS,
            mass: MassUnitsEnum.TONNES,
            lcgRef: UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD,
            tcgDir: PortStarboardEnum.PORT,
            vcgRef: VcgVerticalReferenceEnum.BOTTOM_BASE,
        };
        this.setLengthUnits = (u) => {
            if (this.units.length === u)
                return false;
            this.units.length = u;
            setPreferencesKeyAndValue("units-length", u);
            return true;
        };
        this.setMassUnits = (u) => {
            if (this.units.mass === u)
                return false;
            this.units.mass = u;
            setPreferencesKeyAndValue("units-mass", u);
            return true;
        };
        this.setLcgRef = (u) => {
            if (this.units.lcgRef === u)
                return false;
            this.units.lcgRef = u;
            setPreferencesKeyAndValue("units-lcgRef", u);
            return true;
        };
        this.setTcgDir = (u) => {
            if (this.units.tcgDir === u)
                return false;
            this.units.tcgDir = u;
            setPreferencesKeyAndValue("units-tcgRef", u);
            return true;
        };
        this.setVcgRef = (u) => {
            if (this.units.vcgRef === u)
                return false;
            this.units.vcgRef = u;
            setPreferencesKeyAndValue("units-vcgRef", u);
            return true;
        };
        const lengthUnitsSaved = getPreferencesValue("units-length"), massUnitsSaved = getPreferencesValue("units-mass"), lcgRefSaved = getPreferencesValue("units-lcgRef"), tcgDirSaved = getPreferencesValue("units-tcgRef"), vcgRefSaved = getPreferencesValue("units-vcgRef");
        if (lengthUnitsSaved)
            this.units.length = Number(lengthUnitsSaved);
        if (massUnitsSaved)
            this.units.mass = Number(massUnitsSaved);
        if (lcgRefSaved)
            this.units.lcgRef = Number(lcgRefSaved);
        if (tcgDirSaved)
            this.units.tcgDir = Number(tcgDirSaved);
        if (vcgRefSaved)
            this.units.vcgRef = Number(vcgRefSaved);
    }
    get lpp() {
        var _a, _b, _c;
        return ((_c = (_b = (_a = ovdJsonStore.currentJson) === null || _a === void 0 ? void 0 : _a.shipData) === null || _b === void 0 ? void 0 : _b.lcgOptions) === null || _c === void 0 ? void 0 : _c.lpp) || 0;
    }
    get lengthUnits() {
        const length = this.units.length;
        return {
            units: length,
            fromDisplayToValue: lenghtUnitsFromDisplayToValue[length],
            fromValueToDisplay: lenghtUnitsFromValueToDisplay[length],
            fromValueToFormattedDisplay: (a) => {
                const nn = new Intl.NumberFormat(i18n.language, {
                    style: "decimal",
                });
                return nn.format(lenghtUnitsFromValueToDisplay[length](Number(a)));
            },
        };
    }
    get massUnits() {
        const mass = this.units.mass;
        return {
            units: mass,
            fromDisplayToValue: massUnitsFromDisplayToValue[mass],
            fromValueToDisplay: massUnitsFromValueToDisplay[mass],
            fromValueToFormattedDisplay: (a) => {
                const nn = new Intl.NumberFormat(i18n.language, {
                    style: "decimal",
                });
                return nn.format(massUnitsFromValueToDisplay[mass](Number(a)));
            },
        };
    }
    get lcgTransposer() {
        const lpp = this.lpp;
        const lcgRef = this.units.lcgRef;
        if (lpp !== undefined) {
            return {
                transposeTo: lcgRef,
                fromDisplayToValue: (a) => lcgTransponserFromDisplayToValue[lcgRef](a, lpp),
                fromValueToDisplay: (a) => lcgTransponserFromValueToDisplay[lcgRef](a, lpp),
            };
        }
        else {
            return {
                transposeTo: lcgRef,
                fromDisplayToValue: (a) => a,
                fromValueToDisplay: (a) => a,
            };
        }
    }
    get tcgTransposer() {
        const tcgDir = this.units.tcgDir;
        return {
            transposeTo: tcgDir,
            fromDisplayToValue: (a) => tcgTransponserFromDisplayToValue[tcgDir](a, 0),
            fromValueToDisplay: (a) => tcgTransponserFromValueToDisplay[tcgDir](a, 0),
        };
    }
    get vcgTransposer() {
        const vcgRef = this.units.vcgRef;
        return {
            transposeTo: vcgRef,
            fromDisplayToValue: (a) => vcgTransponserFromDisplayToValue[vcgRef](a, 0),
            fromValueToDisplay: (a) => vcgTransponserFromValueToDisplay[vcgRef](a, 0),
        };
    }
}
const globalUnits = new GlobalUnits();
export default globalUnits;
export const lenghtUnitsFromDisplayToValue = {
    [LenUnitsEnum.MILLIMETERS]: (a) => Math.round(a),
    [LenUnitsEnum.CENTIMETERS]: (a) => Math.round(a * 10),
    [LenUnitsEnum.METERS]: (a) => Math.round(a * 1000),
    [LenUnitsEnum.FEET]: (a) => Math.round(a / ONE_MILLIMETER_IN_FEET),
};
export const lenghtUnitsFromValueToDisplay = {
    [LenUnitsEnum.MILLIMETERS]: (a) => a,
    [LenUnitsEnum.CENTIMETERS]: (a) => a / 10,
    [LenUnitsEnum.METERS]: (a) => a / 1000,
    [LenUnitsEnum.FEET]: (a) => roundDec(a * ONE_MILLIMETER_IN_FEET, 2),
};
export const massUnitsFromDisplayToValue = {
    [MassUnitsEnum.GRAMS]: (a) => a,
    [MassUnitsEnum.KILOGRAMS]: (a) => Math.round(a * 1000),
    [MassUnitsEnum.TONNES]: (a) => Math.round(a * 1000000),
    [MassUnitsEnum.SHORT_TONS]: (a) => Math.round(a * 0.907185 * 1000000),
    [MassUnitsEnum.LONG_TONS]: (a) => Math.round(a * 1.01605 * 1000000),
    [MassUnitsEnum.POUNDS]: (a) => Math.round(a * 453.592),
    [MassUnitsEnum.POUNDS_1000]: (a) => Math.round(a * 453592),
};
export const massUnitsFromValueToDisplay = {
    [MassUnitsEnum.GRAMS]: (a) => a,
    [MassUnitsEnum.KILOGRAMS]: (a) => roundDec(a / 1000, 3),
    [MassUnitsEnum.TONNES]: (a) => roundDec(a / 1000000, 3),
    [MassUnitsEnum.SHORT_TONS]: (a) => roundDec(a / 0.907185 / 1000000, 3),
    [MassUnitsEnum.LONG_TONS]: (a) => roundDec(a / 1.01605 / 1000000, 3),
    [MassUnitsEnum.POUNDS]: (a) => roundDec(a / 453.592, 3),
    [MassUnitsEnum.POUNDS_1000]: (a) => roundDec(a / 453592, 3),
};
export const lcgTransponserFromValueToDisplay = {
    [UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD]: (a) => a,
    [UoLcgReferenceEnum.FWD_PERPENDICULAR_DAFT]: (a, lpp) => (lpp || 0) - a,
    [UoLcgReferenceEnum.MIDSHIPS_DFWD]: (a, lpp) => a - (lpp || 0) * 0.5,
    [UoLcgReferenceEnum.MIDSHIPS_DAFT]: (a, lpp) => (lpp || 0) * 0.5 - a,
};
export const lcgTransponserFromDisplayToValue = {
    [UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD]: (a) => a,
    [UoLcgReferenceEnum.FWD_PERPENDICULAR_DAFT]: (a, lpp) => (lpp || 0) - a,
    [UoLcgReferenceEnum.MIDSHIPS_DFWD]: (a, lpp) => (lpp || 0) * 0.5 + a,
    [UoLcgReferenceEnum.MIDSHIPS_DAFT]: (a, lpp) => (lpp || 0) * 0.5 - a,
};
export const tcgTransponserFromValueToDisplay = {
    [PortStarboardEnum.PORT]: (a) => -a,
    [PortStarboardEnum.STARBOARD]: (a) => a,
};
export const tcgTransponserFromDisplayToValue = {
    [PortStarboardEnum.PORT]: (a) => -a,
    [PortStarboardEnum.STARBOARD]: (a) => a,
};
const CONT_8_6_IN_MM = 8.5 / ONE_MILLIMETER_IN_FEET;
export const vcgTransponserFromValueToDisplay = {
    [VcgVerticalReferenceEnum.BOTTOM_BASE]: (a) => a,
    [VcgVerticalReferenceEnum.VCG_45_8_6]: (a) => Math.round(a + CONT_8_6_IN_MM * 0.45),
    [VcgVerticalReferenceEnum.VCG_50_8_6]: (a) => Math.round(a + CONT_8_6_IN_MM * 0.5),
};
export const vcgTransponserFromDisplayToValue = {
    [VcgVerticalReferenceEnum.BOTTOM_BASE]: (a) => a,
    [VcgVerticalReferenceEnum.VCG_45_8_6]: (a) => Math.round(a - CONT_8_6_IN_MM * 0.45),
    [VcgVerticalReferenceEnum.VCG_50_8_6]: (a) => Math.round(a - CONT_8_6_IN_MM * 0.5),
};
