import AccountsService from "./accounts/accounts";
import ApiKeysService from "./apiKeys/apiKeys";
import CommunitySearchService from "./files/community-search";
import DomainsService from "./domains/domains";
import FilesService from "./files/files";
import LogEventsBeaconService from "./log-events/logEvents";
import NotificationsService from "./notifications/notifications";
import OrganizationsService from "./admin/organizations";
import VesselsMasterDataService from "./vessels-master-data/vesselsMasterData";
import readOVDJsonFile from "./ovd-json/file/readOVDJson";
import stafFileConvertToOVD from "./import-staf/stafFileConvertToOVD";
import stafFileReadShipData from "./import-staf/stafFileReadShipData";

const Services = {
  ovdJson: {
    readFile: {
      readOVDJsonFile,
    },
  },
  importStaf: {
    stafFileConvertToOVD,
    stafFileReadShipData,
  },
  organizations: {
    ...OrganizationsService,
  },
  files: {
    ...FilesService,
  },
  vesselsMasterData: {
    ...VesselsMasterDataService,
  },
  accounts: {
    ...AccountsService,
  },
  domains: {
    ...DomainsService,
  },
  apiKeys: {
    ...ApiKeysService,
  },
  vesselSearch: {
    ...CommunitySearchService,
  },
  logEvents: {
    ...LogEventsBeaconService,
  },
  notifications: {
    ...NotificationsService,
  },
};

if (process.env.NX_PUBLIC_STAGE === "dev") window.tvdServices = Services;

export default Services;
