import {
  feetToMillimeters,
  roundDec,
} from "@baplie-viewer2/tedivo-pure-helpers";

import { IVesselOneBaySlotBridge } from "../../../../../../../tedivo-bay-grid-pure/src/lib/types/IVesselOneBaySlot";

const FLOOR_HEIGHT = 2400;
const WINDOW_WIDTH = 2200;
const WINDOW_HEIGHT = 900;
const WINDOW_SEPARATION = 500;
const WINDOW_CEILING_DISTANCE = 400;

export function drawSideBridge(
  bridge: IVesselOneBaySlotBridge,
  midVcg: number,
  svgSizeRatio: number,
): SVGPathElement[] {
  const mainPath = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path",
  );

  mainPath.setAttribute("stroke", "#888");
  mainPath.setAttribute("fill", "#fff");
  mainPath.setAttribute("stroke-width", "1");
  mainPath.setAttribute("fill-opacity", "0.2");
  mainPath.setAttribute("data-type", bridge.type);
  mainPath.setAttribute("data-id", bridge.id);

  const result: SVGPathElement[] = [mainPath];

  const w2 = roundDec(feetToMillimeters(bridge.len) * 0.5 * 0.8, 2);

  const w2Svg = w2 * svgSizeRatio;
  const top = roundDec(FLOOR_HEIGHT * svgSizeRatio);

  mainPath.setAttribute(
    "d",
    `M${-w2Svg},${top} L${-w2Svg},${midVcg} L${w2Svg},${midVcg} L${w2Svg},${top} L${-w2Svg},${top}`,
  );

  const heightOfBridge = roundDec(midVcg / svgSizeRatio, 1) - FLOOR_HEIGHT;
  const floors = Math.floor(heightOfBridge / FLOOR_HEIGHT);
  const windows = Math.floor((w2 * 2) / (WINDOW_WIDTH + WINDOW_SEPARATION));
  const windowsWidth = windows * (WINDOW_WIDTH + WINDOW_SEPARATION);
  const leftOver = (w2 * 2 - windowsWidth) * 0.5 + WINDOW_SEPARATION * 0.5;

  {
    // Draw windows
    const windowsPath = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );

    windowsPath.setAttribute("stroke", "#add8e6");
    windowsPath.setAttribute("fill", "#add8e6");
    windowsPath.setAttribute("stroke-width", "1");
    windowsPath.setAttribute("fill-opacity", "1");

    const pathD: string[] = [];
    for (let i = 0; i < floors; i++) {
      const yBelow =
        i * FLOOR_HEIGHT + WINDOW_CEILING_DISTANCE + FLOOR_HEIGHT * 1.5;

      for (let j = 1; j < windows; j++) {
        const x =
          -w2Svg +
          (leftOver + j * (WINDOW_WIDTH + WINDOW_SEPARATION)) * svgSizeRatio;
        const y = yBelow * svgSizeRatio;

        pathD.push(
          // Window
          `M${x},${y}
           L${x + WINDOW_WIDTH * svgSizeRatio},${y} 
           L${x + WINDOW_WIDTH * svgSizeRatio},${
            y - WINDOW_HEIGHT * svgSizeRatio
          } 
           L${x},${y - WINDOW_HEIGHT * svgSizeRatio} 
           L${x},${y}`,
        );
      }
    }

    windowsPath.setAttribute("d", pathD.join(" "));
    result.push(windowsPath);
  }

  if (bridge.heatSrcBelow) {
    const heatSrc = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );

    heatSrc.setAttribute("stroke", "#888");
    heatSrc.setAttribute("fill", "#3d2814");
    heatSrc.setAttribute("stroke-width", "1");
    heatSrc.setAttribute("fill-opacity", "1");

    const x = -w2Svg;
    const y = FLOOR_HEIGHT * svgSizeRatio - 1;

    heatSrc.setAttribute(
      "d",
      `M${x},${y} L${x + w2Svg},${y} L${x + w2Svg},${
        y - 1000 * svgSizeRatio
      } L${x},${y - 2000 * svgSizeRatio} L${x},${y}`,
    );

    result.push(heatSrc);
  }

  {
    const ceiling = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );

    ceiling.setAttribute("stroke", "#888");
    ceiling.setAttribute("fill", "#999");
    ceiling.setAttribute("stroke-width", "1");
    ceiling.setAttribute("fill-opacity", "1");

    const yBelow = FLOOR_HEIGHT - 1;
    const x1 = -w2Svg * 0.2;
    const x2 = w2Svg * 0.7;
    const y = yBelow * svgSizeRatio;

    ceiling.setAttribute(
      "d",
      `M${x1},${y} 
       L${x2},${y} 
       L${x2 + 2},${y - 7} 
       L${x1 - 2},${y - 7} L${x1},${y}`,
    );

    result.push(ceiling);
  }

  return result;
}
