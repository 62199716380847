/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { __awaiter } from "tslib";
import { getTranslation } from "../../app/i18/i18tn";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
export default class IntegratedDialog {
    constructor(options) {
        this.buttons = [];
        this.onButtonClicked = undefined;
        this.onDismissed = undefined;
        this.options = options;
    }
    updateOptions(options) {
        this.options = Object.assign(Object.assign({}, this.options), options);
        return this;
    }
    get isOpen() {
        var _a;
        return ((_a = this.dialog) === null || _a === void 0 ? void 0 : _a.open) || false;
    }
    show(title, message) {
        var _a, _b;
        let btnToFocus = undefined;
        const { dialog } = this.createDialog();
        dialog.label = title;
        const btnActionToFocus = (_b = (_a = this.options.buttonsAttrs) === null || _a === void 0 ? void 0 : _a.find((btnAttrs) => btnAttrs.autofocus)) === null || _b === void 0 ? void 0 : _b.value;
        if (btnActionToFocus) {
            const btn = this.buttons.find((f) => f.dataset.action === btnActionToFocus);
            if (btn)
                btnToFocus = btn;
        }
        if (typeof message === "string") {
            this.htmlContent.innerHTML = message;
        }
        else {
            removeChildren(this.htmlContent);
            this.htmlContent.appendChild(message);
        }
        dialog.show();
        setTimeout(() => {
            try {
                if (btnToFocus === null || btnToFocus === void 0 ? void 0 : btnToFocus.focus)
                    btnToFocus.focus();
            }
            catch (e) {
                console.warn(e);
            }
        }, 150);
    }
    setButtonEnabled(action, enabled) {
        const btn = this.buttons.find((f) => f.dataset.action === action);
        if (btn)
            btn.disabled = !enabled;
    }
    createDialog() {
        var _a;
        if (this.dialog) {
            return { dialog: this.dialog };
        }
        const dialog = document.createElement("sl-dialog");
        dialog.className = "integrated-dialog";
        this.htmlContent = document.createElement("div");
        const buttonsHolder = document.createElement("div");
        buttonsHolder.className = "dialog-buttons-holder";
        buttonsHolder.slot = "footer";
        if (this.options.width) {
            dialog.setAttribute("style", `--width: ${this.options.width}`);
        }
        const onClicked = (value) => __awaiter(this, void 0, void 0, function* () {
            if (this.onButtonClicked) {
                const res = yield this.onButtonClicked(value);
                if (res === false)
                    return;
            }
            dialog.hide();
        });
        (_a = this.options.buttonsAttrs) === null || _a === void 0 ? void 0 : _a.forEach((btnAttrs) => {
            const btn = document.createElement("sl-button");
            btn.variant = btnAttrs.variant || "primary";
            btn.outline = btnAttrs.outlined || false;
            btn.tabIndex = 0;
            btn.type = btnAttrs.type || "button";
            btn.dataset.action = btnAttrs.value;
            btn.innerHTML = getTranslation(btnAttrs.label);
            //btn.role = "button";
            btn.addEventListener("click", () => onClicked(btnAttrs.value), false);
            buttonsHolder.appendChild(btn);
            this.buttons.push(btn);
            if (btnAttrs.icon) {
                const icon = document.createElement("sl-icon");
                icon.name = btnAttrs.icon;
                icon.slot = "prefix";
                btn.appendChild(icon);
            }
        });
        dialog.appendChild(this.htmlContent);
        dialog.appendChild(buttonsHolder);
        this.options.parentNode.appendChild(dialog);
        dialog.addEventListener("sl-request-close", (event) => {
            if (this.options.preventClose ||
                (this.options.preventDefaultClose &&
                    event.detail.source === "overlay")) {
                event.preventDefault();
            }
        });
        if (this.options.removeDialogWhenHidden) {
            dialog.addEventListener("sl-after-hide", () => {
                var _a;
                dialog.remove();
                this.dialog = undefined;
                (_a = this.onDismissed) === null || _a === void 0 ? void 0 : _a.call(this);
            });
        }
        this.dialog = dialog;
        return { dialog };
    }
    hide() {
        var _a;
        (_a = this.dialog) === null || _a === void 0 ? void 0 : _a.hide();
    }
}
export function createSimpleAlert(title, message, width, onOk) {
    const dialog = new IntegratedDialog({
        parentNode: document.body,
        width,
        removeDialogWhenHidden: true,
        buttonsAttrs: [
            {
                value: "ok",
                label: "general:common.accept",
                type: "button",
                variant: "primary",
                autofocus: true,
            },
        ],
    });
    dialog.onButtonClicked = () => {
        dialog.hide();
        if (onOk)
            onOk();
    };
    dialog.show(getTranslation(title), getTranslation(message));
}
