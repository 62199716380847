import {
  IBayBlocksAndSizes,
  IBayPattern,
  IRowPattern,
  createRowsFromConfig,
} from "tedivo-bay-grid-pure";
import { ILidData, ISizeSummary } from "open-vessel-definition";

export function lidDataValidatorForBays({
  sizeSummary,
  lids,
  blockBaysAndSizesBy20Bay,
  rowTcgsByBay,
}: {
  sizeSummary: ISizeSummary;
  lids: ILidData[];
  blockBaysAndSizesBy20Bay: Record<
    `${number}${number}${number}`,
    IBayBlocksAndSizes
  >;
  rowTcgsByBay: IBayRowsTcgs;
}): ILidDataWithValidation[] {
  if (sizeSummary.maxRow === undefined) return [];

  const rowsArraySorted = createRowsFromConfig(
    !!sizeSummary.centerLineRow,
    sizeSummary.maxRow,
  );

  return lids.map((lid) => {
    const portIndex = rowsArraySorted.indexOf(lid.portIsoRow);
    const stbdIndex = rowsArraySorted.indexOf(lid.starboardIsoRow);

    let valid: "warning" | "danger" | undefined = undefined;
    let containersWithTcg = 0;

    const bays = [
      ...blockBaysAndSizesBy20Bay[lid.startIsoBay].allBays.split("-"),
      ...blockBaysAndSizesBy20Bay[lid.endIsoBay].allBays.split("-"),
    ].filter((b, idx, arr) => arr.indexOf(b) === idx) as IBayPattern[];

    for (let i = portIndex; i <= stbdIndex; i++) {
      const row = rowsArraySorted[i];
      const tcg = bays.reduce((acc, bay) => {
        return acc || rowTcgsByBay?.[bay]?.[row];
      }, undefined as number | undefined);

      if (tcg !== undefined) {
        containersWithTcg++;
      }
    }

    if (containersWithTcg === 0) {
      valid = "danger";
    } else if (containersWithTcg < Math.abs(stbdIndex - portIndex) + 1) {
      valid = "warning";
    }

    return { ...lid, valid };
  });
}

export interface ILidDataWithValidation extends ILidData {
  valid: "warning" | "danger" | undefined;
}

interface IRowsTcgs {
  [row: IRowPattern]: number;
}

interface IBayRowsTcgs {
  [bay: IBayPattern]: IRowsTcgs;
}
