import { ISlotCombinedPattern, ISlotPattern } from "tedivo-bay-grid-pure";
import { ISlotData, TContainerLengths } from "open-vessel-definition";
import { SlotDataReducer, SlotReversedActions } from "./SlotDataReducer";
import {
  TReducerISlotDataActions,
  TReducerIslotActionsWithoutClear,
} from "@baplie-viewer2/tedivo-bay-grid-core";

import EditToolsEnum from "../types/EditToolsEnum";
import { IToolsState } from "../types/IToolsState";
import { slotHasAttribute } from "./slotHasAttribute";

export function modifyCellsWithActions(
  toolsState: IToolsState,
  cells: ISlotCombinedPattern[],
  cellsData: ISlotData[],
): ISlotData[] {
  // Dictionary by pos
  const cellsDataByPos = cellsData.reduce((acc, v) => {
    acc[slotIdJoinedToMixed(v.pos)] = { ...v, sizes: { ...v.sizes } };
    return acc;
  }, {} as { [name: ISlotCombinedPattern]: ISlotData });

  const getCellDataByPosOrNew = (cellKey: ISlotCombinedPattern) =>
    cellsDataByPos[cellKey] || {
      sizes: {},
      pos: slotIdMixedToJoined(cellKey),
    };

  // 1. Add Many
  if (toolsState.activeTool === EditToolsEnum.ADD_MANY) {
    const actions = toolsState.tools[EditToolsEnum.ADD_MANY];
    if (!actions.length) return cellsData;

    // 1.1 Update with reducer
    cells.forEach((cellKey) => {
      cellsDataByPos[cellKey] = applyMultipleSlotDataActionsSequentially(
        getCellDataByPosOrNew(cellKey),
        toolsState.tools[EditToolsEnum.ADD_MANY],
      );
    });
    return cellsDataByKeyToArray(cellsDataByPos);
  }

  // 2. Add or Remove Single or 3. Set Voids
  if (
    toolsState.activeTool === EditToolsEnum.ADD_REMOVE_SINGLE ||
    toolsState.activeTool === EditToolsEnum.ADD_REMOVE_SPECIAL
  ) {
    const action = toolsState.tools[
      toolsState.activeTool
    ][0] as TReducerIslotActionsWithoutClear;
    const firstSelectedCell = cellsDataByPos[cells[0]];
    if (!action) return cellsData;

    // 2.1 Check if attribute exists in first cell to decide ADD/REMOVE (all the others)
    const finalAction: TReducerISlotDataActions =
      !firstSelectedCell || !slotHasAttribute(firstSelectedCell, action)
        ? action
        : SlotReversedActions[action] || undefined;

    // 2.1 Update with reducer
    if (finalAction)
      cells.forEach((cellKey) => {
        cellsDataByPos[cellKey] = SlotDataReducer[finalAction](
          getCellDataByPosOrNew(cellKey),
        );
      });

    return cellsDataByKeyToArray(cellsDataByPos);
  }

  if (toolsState.activeTool === EditToolsEnum.ADD_REMOVE_RESTRICTED) {
    const firstSelectedCell = cellsDataByPos[cells[0]];

    const finalAction: TReducerISlotDataActions =
      !firstSelectedCell ||
      !slotHasAttribute(firstSelectedCell, "ADD_RESTRICTED")
        ? "ADD_RESTRICTED"
        : SlotReversedActions["ADD_RESTRICTED"] || undefined;

    cells.forEach((cellKey) => {
      cellsDataByPos[cellKey] = SlotDataReducer[finalAction](
        getCellDataByPosOrNew(cellKey),
      );
    });

    return cellsDataByKeyToArray(cellsDataByPos);
  }

  // 4. Clear Slots
  if (toolsState.activeTool === EditToolsEnum.CLEAR) {
    return cellsData.filter(
      (slot) => cells.indexOf(slotIdJoinedToMixed(slot.pos)) < 0,
    );
  }

  return cellsData;
}

export function slotIdJoinedToMixed(pos: ISlotPattern): ISlotCombinedPattern {
  return `${pos.substring(0, 2)}|${pos.substring(2)}` as ISlotCombinedPattern;
}

export function slotIdMixedToJoined(pos: ISlotCombinedPattern): ISlotPattern {
  return pos.split("|").join("") as ISlotPattern;
}

function cellsDataByKeyToArray(slotDataById: {
  [name: ISlotCombinedPattern]: ISlotData;
}): ISlotData[] {
  const keys = Object.keys(slotDataById) as ISlotCombinedPattern[];
  return keys.map((key) => slotDataById[key]);
}

/**
 * Apply reducer actions to SlotData
 * @param reducerActionsKeys of selected options
 * @returns modified new ISlotData
 */
export function applyMultipleSlotDataActionsSequentially(
  slotData: ISlotData,
  reducerActionsKeys: TReducerISlotDataActions[],
): ISlotData {
  return reducerActionsKeys.reduce((acc, key) => {
    acc = SlotDataReducer[key](acc);
    return acc;
  }, slotData);
}

export function slotDataIsNotEmpty(slot: ISlotData): boolean {
  const keys = (Object.keys(slot) as (keyof ISlotData)[]).filter(
    (k) => slot[k] && k !== "pos" && (k as string) !== "position", // position is obsolete, but older data may have it
  );

  if (!keys.length) return false;

  if (keys.length === 1 && keys[0] === "sizes") {
    const sizeKeys = (
      Object.keys(slot.sizes) as unknown as TContainerLengths[]
    ).filter((size) => !!slot.sizes[size]);
    if (sizeKeys.length === 0) return false;
  }

  return true;
}
