import IntegratedDialogError from "packages/oss-editor/src/components/common/IntegratedDialogError";
import { SimpleObserver } from "@baplie-viewer2/tedivo-pure-helpers";
import { subscribe } from "diagnostics_channel";

type OnOffLineObserverType = [isOnline: boolean];

class OnOffLineObserver extends SimpleObserver<OnOffLineObserverType> {
  public isOnline = globalThis.navigator.onLine;

  constructor() {
    super();

    globalThis.addEventListener("online", () => {
      this.update(true).notify();
      this.isOnline = true;
    });

    globalThis.addEventListener("offline", () => {
      this.update(false).notify();
      this.isOnline = false;
    });
  }
}

const onOffLineObserver = new OnOffLineObserver();

export default onOffLineObserver;
