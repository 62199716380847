import { TContainerLengths } from "open-vessel-definition";
import { feetToMillimeters } from "@baplie-viewer2/tedivo-pure-helpers";

export const SVG_WIDTH = 1500;

export const LCG_SEPARATION = feetToMillimeters(13);
export const LCG_20 = feetToMillimeters(24);
export const SEPARATION_IN_BETWEEN = feetToMillimeters(3);
export const LCG_20_WITH_SEPARATION = LCG_20 + SEPARATION_IN_BETWEEN;
export const TCG_IN_MMM = 2520;

export const CONTAINER_HEIGHT_IN_FEET = 8.5;
export const CONTAINER_SEPARATION_IN_FEET = 0.5;
export const CONTAINER_HEIGHT_IN_MMM = feetToMillimeters(
  CONTAINER_HEIGHT_IN_FEET,
);
export const CONTAINER_SEPARATION_IN_MMM = feetToMillimeters(
  CONTAINER_SEPARATION_IN_FEET,
);

/** @deprecated We use millimeters */ export const ONE_METER_IN_FEET = 3.280839895;

export const ONE_MILLIMETER_IN_FEET = 0.003280839895;
export const CONT_LENS: TContainerLengths[] = [20, 24, 40, 45, 48, 53];

export const FEET_20_IN_MM = feetToMillimeters(20);
export const FEET_10_IN_MM = feetToMillimeters(10);
