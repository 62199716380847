import { ForeAftEnum } from "open-vessel-definition";
/** Gets the CG at the AFT or FWD of the composed/single bay
 * @param idRef The composed/single bay id
 * @param posRef The position to get the CG that we want to obtain
 * @param lcgVcgTcgAndPairings The calculated LCGs, VCGs and TCGs
 */
export function findReferenceLcg(idRef, posRef, lcgVcgTcgAndPairings) {
    const idParts = idRef.split("-");
    const [bayRefDir, bayRefPair] = idParts;
    // LCGs are always calculated from the STERN (0 is around the stern)
    // Therefore, when searching for CGs in between bays, we use the MAX "aftLcg" for the AFT and MIN "foreLcg" for the FWD
    const mathCompareFn = posRef === ForeAftEnum.AFT ? Math.min : Math.max;
    const mathNotFoundValue = posRef === ForeAftEnum.AFT ? Infinity : -Infinity;
    // 1. Find all the calculated sizes
    const iSizesAll = [
        ...lcgVcgTcgAndPairings.bayLevelPositionsAbove.filter((b) => b.isoBay === bayRefDir || b.isoBay === bayRefPair),
        ...lcgVcgTcgAndPairings.bayLevelPositionsBelow.filter((b) => b.isoBay === bayRefDir || b.isoBay === bayRefPair),
    ];
    const nearBayCgs = iSizesAll.map((b) => {
        var _a;
        if (b.missingImportantLcgs)
            return mathNotFoundValue;
        return ((_a = b.maxSizeLcg[posRef === ForeAftEnum.AFT ? "aftLcg" : "foreLcg"]) !== null && _a !== void 0 ? _a : mathNotFoundValue);
    });
    return mathCompareFn(...nearBayCgs);
}
