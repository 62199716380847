import Services from "../../../app/services";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import { getTranslation } from "../../../app/i18/i18tn";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";

export function createMtShipaNameDiffersDialog({
  fileData,
  goSquaredLabel,
  onAction,
}: {
  fileData: {
    name: string;
    fileId: string;
    organizationId: string;
    vmdShipNameLatest: string;
  };
  goSquaredLabel: string;
  onAction: (action: "replace" | "ignore") => Promise<void>;
}) {
  const modal = document.createElement("sl-dialog");
  document.documentElement.appendChild(modal);

  modal.label = getTranslation("view:shipNameDiffers.title");
  const text = `${getTranslation("view:shipNameDiffers.intro", {
    shipName: fileData.name,
    mtShipName: fileData.vmdShipNameLatest,
  })}`;

  const htmlContent = document.createElement("div");
  htmlContent.innerHTML = text;

  const rbtns = document.createElement("tf-radio-group");
  rbtns.name = "action";
  rbtns.style.marginTop = "2rem";
  rbtns.items = [
    {
      value: "ignore",
      label: getTranslation("view:shipNameDiffers.ignoreMtName"),
    },
    {
      value: "replace",
      label: getTranslation("view:shipNameDiffers.replaceWithMtName"),
    },
  ];
  rbtns.value = "ignore";
  rbtns.label = getTranslation("view:shipNameDiffers.actionQuestion");
  rbtns.fieldset = true;

  htmlContent.appendChild(rbtns);

  const closeBtn = document.createElement("sl-button");
  closeBtn.slot = "footer";
  closeBtn.variant = "primary";
  closeBtn.autofocus = true;
  closeBtn.tabIndex = 0;
  closeBtn.innerHTML = getTranslation("general:common.accept");

  closeBtn.addEventListener(
    "click",
    async () => {
      goSquared.addEvent(goSquaredLabel);
      removeChildren(htmlContent);
      closeBtn.disabled = true;
      closeBtn.loading = true;

      try {
        await onAction(rbtns.value as "replace" | "ignore");
        closeBtn.loading = false;
        modal.hide();
      } catch (err) {
        removeChildren(htmlContent);
        htmlContent.className = "text-danger";
        htmlContent.innerHTML = getTranslation(err as string);
      }
    },
    false,
  );

  modal.addEventListener("sl-after-hide", () => {
    modal.remove();
  });

  modal.appendChild(htmlContent);
  modal.appendChild(closeBtn);
  modal.show();
}
