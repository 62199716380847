import { pad2 } from "@baplie-viewer2/tedivo-pure-helpers";
import { sortRowsArray } from "./sortRowsArray";

export function createRowsFromConfig(
  centerLineRow: boolean,
  maxRow: number | `${number}${number}` | undefined,
): Array<`${number}${number}`> {
  const rows: `${number}${number}`[] = [];

  if (maxRow === undefined) return rows;

  const start = centerLineRow ? 0 : 1;

  for (let i = start; i <= Number(maxRow); i += 1) {
    rows.push(pad2(i));
  }

  return rows.sort(sortRowsArray);
}
