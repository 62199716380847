import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
  ISlotData,
} from "open-vessel-definition";
import {
  IBayPattern,
  ISlotCombinedPattern,
  ISlotPattern,
} from "tedivo-bay-grid-pure";

import { pad3 } from "@baplie-viewer2/tedivo-pure-helpers";

/**
 * Gets the Bays-Levels that correspond to the same isoBay, plus some other keys
 */
export function getBaySlots(
  isoBay: IBayPattern,
  bls: IBayLevelData[],
): IGetBaySolts {
  const blsBay = bls.filter((b) => b.isoBay === isoBay);
  const bays = [isoBay];

  let enabledCellsFromSlots: ISlotPattern[] = [];
  let slotsData: ISlotData[] = [];
  let slotsDataAbove: ISlotData[] = [];
  let slotsDataBelow: ISlotData[] = [];
  let bayAbove: IBayLevelData | undefined = undefined;
  let bayBelow: IBayLevelData | undefined = undefined;

  // Get paired bays
  blsBay.forEach((b) => {
    let bayHas40 = false;

    const perSlotInfo = b.perSlotInfo;
    if (perSlotInfo !== undefined) {
      const slotsKeys = Object.keys(perSlotInfo) as ISlotPattern[];

      enabledCellsFromSlots = enabledCellsFromSlots.concat(slotsKeys);

      slotsData = slotsData.concat(
        slotsKeys.map((slotKey) => {
          if (
            !bayHas40 &&
            perSlotInfo[slotKey].sizes &&
            Object.keys(perSlotInfo[slotKey].sizes)
              .map(Number)
              .some((size) => size >= 40)
          )
            bayHas40 = true;

          return perSlotInfo[slotKey];
        }),
      );

      if (b.level === BayLevelEnum.ABOVE)
        slotsDataAbove = slotsDataAbove.concat(
          slotsKeys.map((slotKey) => perSlotInfo[slotKey]),
        );

      if (b.level === BayLevelEnum.BELOW) {
        slotsDataBelow = slotsDataBelow.concat(
          slotsKeys.map((slotKey) => perSlotInfo[slotKey]),
        );
      }
    }

    if (b.pairedBay === ForeAftEnum.AFT && bayHas40) {
      const pBay = pad3(Number(isoBay) + 1);
      if (bays.indexOf(pBay) < 0) bays.push(pBay);
    }

    if (b.pairedBay === ForeAftEnum.FWD && bayHas40) {
      const pBay = pad3(Number(isoBay) - 1);
      if (bays.indexOf(pBay) < 0) bays.push(pBay);
    }

    if (b.level === BayLevelEnum.ABOVE) {
      bayAbove = b;
    } else if (b.level === BayLevelEnum.BELOW) {
      bayBelow = b;
    }
  });

  const enabledCells = enabledCellsFromSlots.map((s) => {
    return `${s.substring(0, 2)}|${s.substring(2)}`;
  }) as ISlotCombinedPattern[];

  return {
    bays,
    enabledCells,
    slotsData,
    slotsDataAbove,
    slotsDataBelow,
    bayAbove,
    bayBelow,
  };
}

interface IGetBaySolts {
  bays: IBayPattern[];
  enabledCells: ISlotCombinedPattern[];
  slotsData: ISlotData[];
  slotsDataAbove: ISlotData[];
  slotsDataBelow: ISlotData[];
  bayAbove: IBayLevelData | undefined;
  bayBelow: IBayLevelData | undefined;
}
