import { BayLevelEnum, ForeAftEnum, ValuesSourceEnum, } from "open-vessel-definition";
import { EditToolsComponent, } from "./edit-tools.component";
import { getSizesFromSlotDataArray, } from "tedivo-bay-grid-pure";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import { cloneObject, pad2, pad3 } from "@baplie-viewer2/tedivo-pure-helpers";
import { getPreferencesValue, setPreferencesKeyAndValue, } from "@baplie-viewer2/tedivo-preferences";
import { modifyCellsWithActions, slotDataIsNotEmpty, slotIdJoinedToMixed, } from "./serv/modifyCellsWithActions";
import ovdJsonStore, { OVDChangesEnum } from "../../../app/stores/OVDJsonStore";
import EditToolsEnum from "./types/EditToolsEnum";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import { InteractiveGrid } from "@baplie-viewer2/tedivo-bay-grid-interactive";
import { getTranslation } from "../../../app/i18/i18tn";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { z } from "zod";
const BAY_HELPERS_PREF_ID = "bayHelpers-copyBayData";
const BAY_HELPERS_PREF_FROM_ID = "bayHelpers-copyFromBayData";
export class BayEditComponent extends HTMLElement {
    get cellsData() {
        return this.internalCellsData;
    }
    set cellsData(cd) {
        this.internalCellsData = cd || [];
        this.cellsDataIsDirty = true;
    }
    constructor() {
        super();
        this.data = {};
        this.toolsState = {
            activeTool: EditToolsEnum.ADD_MANY,
            tools: {
                [EditToolsEnum.ADD_MANY]: [],
                [EditToolsEnum.ADD_REMOVE_SINGLE]: [],
                [EditToolsEnum.CLEAR]: [],
                [EditToolsEnum.ADD_REMOVE_RESTRICTED]: [],
                [EditToolsEnum.ADD_REMOVE_SPECIAL]: [],
            },
        };
        this.internalCenterLineRow = 0;
        this.internalCellsData = [];
        this.cellsDataIsDirty = false;
        this.editDrawer = undefined;
        this.postSaveActions = [];
        this.updateDetailsScreen = () => undefined;
        this.onToolsChanged = (ev) => {
            this.toolsState = ev.detail.state;
        };
        this.onUserDiagramSelected = (positionsSelected) => {
            if (!positionsSelected || !positionsSelected.length)
                return;
            const positionsSelectedToUse = this.mirrorSlotsDefinition
                ? mirrorSlots(positionsSelected)
                : positionsSelected;
            this.cellsData = modifyCellsWithActions(this.toolsState, positionsSelectedToUse, this.cellsData || [])
                .filter(slotDataIsNotEmpty)
                .filter((slotData) => !this.internalProhibitedCells ||
                this.internalProhibitedCells.indexOf(slotIdJoinedToMixed(slotData.pos)) < 0);
            this.updateGrid();
            if (this.onSlotsChanged) {
                this.onSlotsChanged(this.cellsData);
            }
        };
        this.updateGrid = () => {
            this.interactiveGrid.updateCellData(this.cellsData || [], this.internalCenterLineRow, this.prohibitedCells, getSlotPos, this.emphasizeCells);
        };
        this.emphasizeSlots = (doEmphasize) => {
            // 1. Get Bay data
            const bayData = this.data.bayData;
            const pairedBayAdd = this.internalPairedBay;
            if (!doEmphasize || !bayData || !pairedBayAdd) {
                this.emphasizeCells = [];
                this.updateGrid();
                return;
            }
            const bay = Number(this.data.isoBay) + (pairedBayAdd === ForeAftEnum.AFT ? 2 : -2);
            if (bay >= 1 && bay <= this.data.maxIsoBay) {
                const bayInfo = ovdJsonStore.findBayInfo(pad3(bay), this.data.level);
                if ((bayInfo === null || bayInfo === void 0 ? void 0 : bayInfo.perSlotInfo) !== undefined) {
                    this.emphasizeCells = Object.keys(bayInfo.perSlotInfo).map(slotIdJoinedToMixed);
                }
            }
            else {
                this.emphasizeCells = [];
            }
            // 2. Update
            this.updateGrid();
        };
        this.copyFromBay = () => {
            const json = ovdJsonStore.currentJson;
            if (json === undefined)
                return;
            const helpersCommonDialog = this.helpersCommonDialog;
            const isoBay = this.data.isoBay;
            const level = this.data.level;
            const updateDetailsScreen = this.updateDetailsScreen;
            const updateCellsData = (cellsData) => {
                this.cellsData = cellsData;
                this.updateGrid();
                if (this.onSlotsChanged) {
                    this.onSlotsChanged(this.cellsData);
                }
            };
            helpersCommonDialog.label = getTranslation("view:edit.helpers.copyFromBay.title");
            helpersCommonDialog.setAttribute("style", "--width: 900px");
            const btn = document.createElement("sl-button");
            btn.innerHTML = getTranslation("general:common.ok");
            btn.slot = "footer";
            btn.variant = "primary";
            btn.type = "submit";
            btn.addEventListener("click", execute, false);
            const { fields, validator } = createCopyDataFromBayFormFields(isoBay, level, json.sizeSummary, json.shipData.containersLengths, json.shipData.featuresAllowed);
            const tedivoForm = new TedivoForm({
                fields,
                onSubmit: () => null,
                formValidator: validator,
                submitButton: btn,
            });
            translateTedivoForm({ tedivoForm, getTranslation });
            helpersCommonDialog.appendChild(tedivoForm.form);
            helpersCommonDialog.appendChild(btn);
            helpersCommonDialog.show();
            function execute() {
                executeCopyFromData(tedivoForm.getValues(), updateDetailsScreen, updateCellsData);
                helpersCommonDialog.hide();
            }
        };
        this.copyToBays = () => {
            var _a;
            const execute = () => {
                var _a;
                const res = executeCopyToData(tedivoForm.getValues());
                if (res !== EnumUpdateResult.ERROR)
                    helpersCommonDialog.hide();
                if (res === EnumUpdateResult.OK)
                    (_a = this.editDrawer) === null || _a === void 0 ? void 0 : _a.drawer.hide();
            };
            const json = ovdJsonStore.currentJson;
            if (json === undefined)
                return;
            if ((_a = this.editDrawer) === null || _a === void 0 ? void 0 : _a.dataIsDirty) {
                const errDialog = new IntegratedDialogError(document.body);
                errDialog.show(getTranslation("view:edit.helpers.copyToBays.error"), getTranslation("view:edit.helpers.copyToBays.errorText"));
                return;
            }
            const helpersCommonDialog = this.helpersCommonDialog;
            const isoBay = this.data.isoBay;
            const level = this.data.level;
            helpersCommonDialog.label = getTranslation("view:edit.helpers.copyToBays.title");
            helpersCommonDialog.setAttribute("style", "--width: 900px");
            const btn = document.createElement("sl-button");
            btn.innerHTML = getTranslation("general:common.ok");
            btn.slot = "footer";
            btn.variant = "primary";
            btn.type = "submit";
            btn.addEventListener("click", execute, false);
            const { fields, validator } = createPasteToBaysFormFields(isoBay, level, json.sizeSummary);
            const tedivoForm = new TedivoForm({
                fields,
                onSubmit: () => null,
                formValidator: validator,
                submitButton: btn,
            });
            translateTedivoForm({ tedivoForm, getTranslation });
            helpersCommonDialog.appendChild(tedivoForm.form);
            helpersCommonDialog.appendChild(btn);
            helpersCommonDialog.show();
        };
        this.remapTiers = () => {
            if (ovdJsonStore.currentJson === undefined)
                return;
            const helpersCommonDialog = this.helpersCommonDialog;
            const cellsData = this.cellsData || [];
            const bayTiers = cellsData.map((s) => Number(s.pos.substring(2)));
            const minTier = Math.min(...bayTiers);
            const maxTier = Math.max(...bayTiers);
            const tierDiff = maxTier - minTier;
            const sizeSummary = ovdJsonStore.currentJson.sizeSummary;
            helpersCommonDialog.label = getTranslation("view:edit.helpers.remapTiers.title");
            helpersCommonDialog.setAttribute("style", "--width: 600px");
            const btn = document.createElement("sl-button");
            btn.innerHTML = getTranslation("general:common.ok");
            btn.slot = "footer";
            btn.variant = "primary";
            btn.type = "submit";
            btn.addEventListener("click", () => {
                const newCellsData = doTheTranslation();
                if (newCellsData) {
                    this.cellsData = newCellsData;
                    if (this.onSlotsChanged) {
                        this.onSlotsChanged(this.cellsData);
                    }
                    this.updateGrid();
                }
            }, false);
            const options = [];
            const tierFrom = Number(this.data.level === BayLevelEnum.ABOVE
                ? sizeSummary.minAboveTier
                : sizeSummary.minBelowTier);
            const tierTo = Number(this.data.level === BayLevelEnum.ABOVE
                ? sizeSummary.maxAboveTier
                : sizeSummary.maxBelowTier) - tierDiff;
            for (let i = tierTo; i >= tierFrom; i -= 2) {
                options.push({ value: pad2(i), label: pad2(i) });
            }
            const fields = [
                {
                    name: "newTier",
                    type: "select",
                    label: "view:tier",
                    options,
                    initialValue: pad2(minTier),
                    hoist: true,
                },
            ];
            const validator = z.object({
                newTier: z.number(),
            });
            const tedivoForm = new TedivoForm({
                fields,
                onSubmit: () => null,
                formValidator: validator,
                submitButton: btn,
            });
            translateTedivoForm({ tedivoForm, getTranslation });
            removeChildren(helpersCommonDialog);
            helpersCommonDialog.innerHTML = getTranslation("view:edit.helpers.remapTiers.intro", { minTier });
            tedivoForm.form.classList.add("tvd-form-margin-top");
            helpersCommonDialog.appendChild(tedivoForm.form);
            helpersCommonDialog.appendChild(btn);
            helpersCommonDialog.show();
            function doTheTranslation() {
                helpersCommonDialog.hide();
                const newDiff = Number(tedivoForm.getValues().newTier) - minTier;
                if (newDiff === 0)
                    return null;
                const newCellsData = JSON.parse(JSON.stringify(cellsData));
                newCellsData.forEach((slotData) => {
                    const row = Number(slotData.pos.substring(0, 2));
                    const tier = Number(slotData.pos.substring(2));
                    slotData.pos = `${pad2(row)}${pad2(tier + newDiff)}`;
                });
                return newCellsData;
            }
        };
        this.move40Definitions = () => {
            var _a, _b, _c;
            if (ovdJsonStore.currentJson === undefined)
                return;
            const helpersCommonDialog = this.helpersCommonDialog;
            const cellsData = this.cellsData || [];
            const sizeSummary = ovdJsonStore.currentJson.sizeSummary;
            const cellsDataHas40s = getSizesFromSlotDataArray(cellsData).some((size) => size >= 40);
            const shipData = ovdJsonStore.currentJson.shipData;
            const shipHasCGs = ((_a = shipData.lcgOptions) === null || _a === void 0 ? void 0 : _a.values) === ValuesSourceEnum.KNOWN ||
                ((_b = shipData.vcgOptions) === null || _b === void 0 ? void 0 : _b.values) === ValuesSourceEnum.KNOWN ||
                ((_c = shipData.tcgOptions) === null || _c === void 0 ? void 0 : _c.values) === ValuesSourceEnum.KNOWN;
            helpersCommonDialog.label = getTranslation("view:edit.helpers.move40Definitions.title");
            helpersCommonDialog.setAttribute("style", "--width: 600px");
            const btn = document.createElement("sl-button");
            btn.innerHTML = getTranslation("general:common.ok");
            btn.slot = "footer";
            btn.variant = "primary";
            btn.type = "submit";
            btn.addEventListener("click", () => {
                const movedCells = doTheMovement(this.data.isoBay, this.data.level, this.pairedBayValue, cellsDataHas40s);
                if (movedCells) {
                    this.cellsData = movedCells.newCellsData;
                    if (movedCells.postAction)
                        this.postSaveActions.push(movedCells.postAction);
                    this.updateGrid();
                    if (this.onSlotsChanged) {
                        this.onSlotsChanged(this.cellsData);
                    }
                }
            }, false);
            removeChildren(helpersCommonDialog);
            if (cellsDataHas40s) {
                if (this.pairedBayValue) {
                    const pairedBay = Number(this.data.isoBay) +
                        (this.pairedBayValue === ForeAftEnum.FWD ? -2 : 2);
                    if (pairedBay < 1 || pairedBay > sizeSummary.isoBays) {
                        helpersCommonDialog.innerHTML = getTranslation("view:edit.helpers.move40Definitions.incorrect");
                    }
                    else {
                        helpersCommonDialog.innerHTML = `${getTranslation("view:edit.helpers.move40Definitions.intro", { pairedBay: pad3(pairedBay) })}${shipHasCGs
                            ? "<br /><br /><strong>" +
                                getTranslation("view:edit.helpers.move40Definitions.reEnterCGs") +
                                "</strong>"
                            : ""}`;
                    }
                }
                else {
                    helpersCommonDialog.innerHTML = getTranslation("view:edit.helpers.move40Definitions.notPaired");
                }
            }
            else {
                helpersCommonDialog.innerHTML = getTranslation("view:edit.helpers.move40Definitions.no40s");
            }
            helpersCommonDialog.appendChild(btn);
            helpersCommonDialog.show();
            /** This function moves out the 40s and creates a postAction to add them to the pairedBay */
            function doTheMovement(isoBay, level, currentIsPaired, cellsDataHas40s) {
                helpersCommonDialog.hide();
                if (!currentIsPaired || !cellsDataHas40s)
                    return null;
                const pairedBay = Number(isoBay) + (currentIsPaired === ForeAftEnum.FWD ? -2 : 2);
                if (pairedBay < 1 || pairedBay > sizeSummary.isoBays)
                    return null;
                const newCellsData = JSON.parse(JSON.stringify(cellsData));
                /** Contains the 40s data for the paired bay */
                const infoOf40s = {};
                for (let i = 0; i < newCellsData.length; i++) {
                    const slotData = newCellsData[i];
                    const sizesInSlot = Object.keys(slotData.sizes).map(Number);
                    sizesInSlot.forEach((size) => {
                        if (size >= 40) {
                            // Delete from current slot
                            delete slotData.sizes[size];
                            // Save the data to move it later
                            if (!infoOf40s[slotData.pos])
                                infoOf40s[slotData.pos] = [];
                            infoOf40s[slotData.pos].push(size);
                        }
                    });
                    const remainingSizes = Object.keys(slotData.sizes).length;
                    if (!remainingSizes)
                        slotData.restricted = 1;
                }
                const positionsToComplete = Object.keys(infoOf40s);
                if (!positionsToComplete.length)
                    return { newCellsData };
                const postAction = {
                    isoBay: pad3(pairedBay),
                    level,
                    action: (bl) => {
                        const newPerSlotInfo = JSON.parse(JSON.stringify(bl.perSlotInfo || {}));
                        positionsToComplete.forEach((pos) => {
                            if (!newPerSlotInfo[pos])
                                newPerSlotInfo[pos] = { pos, sizes: {} };
                            if (!newPerSlotInfo[pos].sizes)
                                newPerSlotInfo[pos].sizes = {};
                            if (infoOf40s[pos]) {
                                infoOf40s[pos].forEach((size) => {
                                    newPerSlotInfo[pos].sizes[size] = 1;
                                });
                                delete newPerSlotInfo[pos].restricted;
                            }
                        });
                        return Object.assign(Object.assign({}, bl), { perSlotInfo: newPerSlotInfo });
                    },
                };
                return { newCellsData, postAction };
            }
        };
        this.keepDrawerOpened = (isOpened) => {
            if (this.editDrawer)
                this.editDrawer.shouldNotClose = isOpened;
        };
        this.interactiveGrid = new InteractiveGrid();
        this.interactiveGrid.className = "svg-bay-edit";
        this.interactiveGrid.onSelection = this.onUserDiagramSelected;
        this.interactiveTools = new EditToolsComponent();
        this.mirrorSlotsDefinition = this.interactiveTools.mirrorSlotsDefinition;
        this.helpersCommonDialog = document.createElement("sl-dialog");
        this.helpersCommonDialog.addEventListener("sl-after-hide", (ev) => {
            if (ev.target === this.helpersCommonDialog) {
                this.keepDrawerOpened(false);
                removeChildren(this.helpersCommonDialog);
            }
        }, false);
        this.helpersCommonDialog.addEventListener("sl-after-show", (ev) => {
            if (ev.target === this.helpersCommonDialog)
                this.keepDrawerOpened(true);
        }, false);
    }
    get prohibitedCells() {
        return this.internalProhibitedCells || [];
    }
    set prohibitedCells(pc) {
        this.internalProhibitedCells = pc;
        this.updateGrid();
    }
    get centerLineRowValue() {
        return this.internalCenterLineRow;
    }
    set centerLineRowValue(n) {
        this.internalCenterLineRow = n;
        this.updateGrid();
    }
    get pairedBayValue() {
        return this.internalPairedBay;
    }
    set pairedBayValue(v) {
        this.internalPairedBay = v;
        this.emphasizeSlots(this.interactiveTools.emphasizedValue);
        this.interactiveTools.emphasizeCheckboxDisabled = !v;
    }
    connectedCallback() {
        const div = document.createElement("div");
        div.className = "bec";
        this.interactiveTools.containersLengths = this.data.availableLengths;
        this.interactiveTools.featuresAllowed = this.data.featuresAllowed;
        this.interactiveTools.addEventListener("emphasizePairedSlotsChanged", ((ev) => {
            this.emphasizeSlots(ev.detail.isChecked);
        }), false);
        this.interactiveTools.addEventListener("mirrorSlotsDefinitionChanged", ((ev) => {
            this.mirrorSlotsDefinition = ev.detail.isChecked;
        }), false);
        this.interactiveTools.addEventListener("copyFromBay", this.copyFromBay, { capture: false });
        this.interactiveTools.addEventListener("copyToBays", this.copyToBays, { capture: false });
        this.interactiveTools.addEventListener("remapTiers", this.remapTiers, { capture: false });
        this.interactiveTools.addEventListener("move40Definitions", this.move40Definitions, { capture: false });
        this.interactiveTools.addEventListener("optionChanged", this.onToolsChanged, { capture: false });
        this.toolsState = this.interactiveTools.state;
        div.appendChild(this.interactiveTools);
        div.appendChild(this.interactiveGrid);
        this.appendChild(div);
        this.appendChild(this.helpersCommonDialog);
        this.mirrorSlotsDefinition = this.interactiveTools.mirrorSlotsDefinition;
    }
    initialize(isoBay, level, gridData, availableLengths, maxIsoBay, featuresAllowed, bayData, editDrawer) {
        var _a;
        this.data.isoBay = isoBay;
        this.data.level = level;
        this.data.gridData = gridData;
        this.data.availableLengths = availableLengths;
        this.data.maxIsoBay = maxIsoBay;
        this.data.featuresAllowed = featuresAllowed;
        this.data.bayData = bayData;
        this.internalCellsData = ((_a = gridData.cellsToDraw) === null || _a === void 0 ? void 0 : _a.map(cloneSlotData)) || [];
        this.interactiveGrid.initialize(gridData);
        this.internalCenterLineRow = gridData.centerLineRow;
        this.editDrawer = editDrawer;
        return this;
    }
}
customElements.define("tvd-bay-edit-component", BayEditComponent);
function mirrorSlots(positionsSelected) {
    const positionsSelectedToUse = [];
    positionsSelected.forEach((pos) => {
        const [row, tier] = pos.split("|").map(Number);
        if (row > 0) {
            // 1 Self
            positionsSelectedToUse.push(`${pad2(row)}|${pad2(tier)}`);
            // 2 Mirror
            positionsSelectedToUse.push(`${pad2(row + (row % 2 === 0 ? -1 : 1))}|${pad2(tier)}`);
        }
        else {
            positionsSelectedToUse.push(pos);
        }
        return positionsSelectedToUse;
    });
    return positionsSelectedToUse.filter((v, idx, arr) => arr.indexOf(v) === idx);
}
function cloneSlotData(slot) {
    const newData = Object.assign(Object.assign({}, slot), { sizes: Object.assign({}, slot.sizes) });
    return newData;
}
function getSlotPos(slotData) {
    return slotIdJoinedToMixed(slotData.pos);
}
function createCopyDataFromBayFormFields(isoBay, level, sizeSummary, availableLengths, featuresAllowed = {}) {
    const bayLabel = getTranslation("general:grid.bay");
    const options = generateBayLevelOptions({
        sizeSummary,
        level,
        isoBay,
        bayLabel,
        includeSelf: false,
    });
    const currentBay = Number(isoBay);
    const defaultOption = `${currentBay === 1 ? 3 : currentBay - 2}-${level}`;
    const sizes = availableLengths.map((size) => ({
        value: `size-${size}`,
        label: getTranslation(`general:slotCapabilities.size.${size}`),
    }));
    const sizesWithCones = featuresAllowed.slotConeRequired
        ? availableLengths.map((size) => ({
            value: `sizeC-${size}`,
            label: getTranslation(`general:slotCapabilities.size.${size}c`),
        }))
        : [];
    const slotOptions = [
        ...sizes,
        ...sizesWithCones,
        {
            value: "restricted",
            label: getTranslation("general:slotCapabilities.restricted.-"),
        },
        {
            value: "reefer",
            label: getTranslation("general:slotCapabilities.misc.r"),
        },
        (featuresAllowed === null || featuresAllowed === void 0 ? void 0 : featuresAllowed.slotHazardousProhibited)
            ? {
                value: "hazardousProhibited",
                label: getTranslation("general:slotCapabilities.misc.h"),
            }
            : undefined,
        (featuresAllowed === null || featuresAllowed === void 0 ? void 0 : featuresAllowed.slotCoolStowProhibited)
            ? {
                value: "coolStowProhibited",
                label: getTranslation("general:slotCapabilities.misc.k"),
            }
            : undefined,
    ].filter(Boolean);
    const bayOptions = [
        {
            value: "pairedBay",
            label: getTranslation("view:details.paired"),
        },
        {
            value: "reeferPlugs",
            label: getTranslation("view:details.reeferPlugs"),
        },
        {
            value: "doors",
            label: getTranslation("view:details.doors"),
        },
        {
            value: "telescoping",
            label: getTranslation("view:details.telescoping"),
        },
    ];
    const initalValues = JSON.parse(getPreferencesValue(BAY_HELPERS_PREF_ID) || "{}");
    const fields = [
        {
            name: "fromBay",
            type: "select",
            label: "view:edit.helpers.fromBay",
            options,
            initialValue: defaultOption,
            hoist: true,
        },
        {
            name: "bayOptions",
            label: "view:edit.helpers.copyFromBay.bayOptions",
            type: "checkboxesList",
            fieldset: true,
            options: bayOptions,
            initialValue: initalValues.bayOptions || [],
        },
        {
            name: "slotOptions",
            type: "checkboxesList",
            label: "view:edit.helpers.copyFromBay.slotOptions",
            fieldset: true,
            options: slotOptions,
            initialValue: initalValues.slotOptions || slotOptions.map((v) => v.value),
        },
    ];
    const validator = z.object({
        fromBay: z.string(),
        bayOptions: z.array(z.any()),
        slotOptions: z.array(z.string()),
    });
    return { fields, validator };
}
function executeCopyFromData({ fromBay: src, bayOptions, slotOptions }, updateDetailsScreen, updateCellsData) {
    const [fromIsoBay, level] = src.split("-").map(Number);
    const fromBayData = ovdJsonStore.findBayInfo(pad3(fromIsoBay), level);
    if (!fromBayData)
        return;
    // 1. Update Bay Details
    const bayDataToChange = {};
    bayOptions.forEach((option) => {
        bayDataToChange[option] =
            fromBayData[option];
    });
    if (Object.keys(bayDataToChange).length)
        updateDetailsScreen(bayDataToChange);
    // 2. Update Slots
    const srcPerSlotInfo = fromBayData.perSlotInfo;
    if (srcPerSlotInfo) {
        const slotNames = Object.keys(srcPerSlotInfo);
        const cellData = slotNames.map((slotName) => srcPerSlotInfo[slotName]);
        const slotOptionsAttrs = convertSlotOptionsToAttrs(slotOptions);
        if (cellData.length) {
            const copiedCellData = [];
            for (let i = 0; i < cellData.length; i += 1) {
                const newSlot = copySlotSelectedAttributes(cellData[i], slotOptionsAttrs);
                if (newSlot)
                    copiedCellData.push(newSlot);
            }
            updateCellsData(copiedCellData);
        }
    }
    // 3. Save preferences
    setPreferencesKeyAndValue(BAY_HELPERS_PREF_ID, {
        bayOptions,
        slotOptions,
    });
    function convertSlotOptionsToAttrs(slotOptions) {
        const attrs = { misc: [], sizes: [], cones: [] };
        slotOptions.forEach((option) => {
            const [type, value] = option.split("-");
            if (type === "size")
                attrs.sizes.push(value);
            else if (type === "sizeC")
                attrs.cones.push(value);
            else
                attrs.misc.push(type);
        });
        return attrs;
    }
    function copySlotSelectedAttributes(src, slotOptionsAttrs) {
        const cell = { pos: src.pos, sizes: {} };
        slotOptionsAttrs.sizes.forEach((size) => {
            if (src.sizes[size])
                cell.sizes[size] = 1;
        });
        slotOptionsAttrs.cones.forEach((size) => {
            if (src.sizes[size] &&
                typeof src.sizes[size] === "object" &&
                src.sizes[size].cone)
                cell.sizes[size] = { cone: 1 };
        });
        slotOptionsAttrs.misc.forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (key && src[key])
                cell[key] = src[key];
        });
        return cell;
    }
}
function createPasteToBaysFormFields(isoBay, level, sizeSummary) {
    const currentBay = Number(isoBay);
    const bayLabel = getTranslation("general:grid.bay");
    const options = generateBayLevelOptions({
        sizeSummary,
        level,
        isoBay,
        bayLabel,
        addLevelInLabel: false,
        includeSelf: true,
        disableSelf: true,
    });
    const bayOptions = [
        {
            value: "pairedBay",
            label: getTranslation("view:details.paired"),
            selected: true,
        },
        {
            value: "reeferPlugs",
            label: getTranslation("view:details.reeferPlugs"),
        },
        {
            value: "doors",
            label: getTranslation("view:details.doors"),
        },
        {
            value: "telescoping",
            label: getTranslation("view:details.telescoping"),
        },
    ];
    const initialValuesStr = getPreferencesValue(BAY_HELPERS_PREF_FROM_ID);
    const initalValues = (initialValuesStr
        ? JSON.parse(initialValuesStr)
        : { bayOptions: ["pairedBay"] });
    const fields = [
        {
            name: "fromBay",
            type: "hidden",
            initialValue: `${pad3(currentBay)}-${level}`,
        },
        {
            name: "toIsoBays",
            type: "checkboxesList",
            label: `${getTranslation("view:edit.helpers.copyToBays.toBays")} - ${getTranslation(`enums:BayLevelEnum.${BayLevelEnum[level]}`)}`,
            fixedOptionWidth: "100px",
            options,
            disabled: [`${currentBay}-${level}`],
            helpers: [
                {
                    label: getTranslation("general:common.selectAll"),
                    value: "all",
                    onClick: (_, me) => me.selectAll(),
                },
                {
                    label: getTranslation("general:common.selectNone"),
                    value: "none",
                    onClick: (_, me) => me.selectNone(),
                },
            ],
            fieldset: true,
        },
        {
            name: "bayOptions",
            label: "view:edit.helpers.copyFromBay.bayOptions",
            type: "checkboxesList",
            fieldset: true,
            options: bayOptions,
            initialValue: initalValues.bayOptions || [],
        },
    ];
    const validator = z.object({
        fromBay: z.string(),
        toIsoBays: z.array(z.any()).refine((data) => data && data.length > 0),
        bayOptions: z.array(z.any()),
    });
    return { fields, validator };
}
function executeCopyToData({ fromBay: src, toIsoBays, bayOptions, }) {
    const json = ovdJsonStore.currentJson;
    if (json === undefined)
        return EnumUpdateResult.OMMITED;
    const [fromIsoBay, levelStr] = src.split("-");
    const level = Number(levelStr);
    const fromBayLevelData = ovdJsonStore.findBayInfo(fromIsoBay, level);
    if (!fromBayLevelData)
        return EnumUpdateResult.OMMITED;
    if (!toIsoBays || !toIsoBays.length) {
        const errDialog = new IntegratedDialogError(document.body);
        errDialog.show(getTranslation("view:edit.helpers.copyToBays.error"), getTranslation("view:edit.helpers.copyToBays.noBaysSelected"));
        return EnumUpdateResult.ERROR;
    }
    const bayLevelDataToCopy = {};
    const toIsoBaysArray = toIsoBays
        .map((b) => b.split("-")[0])
        .map(pad3);
    bayOptions.forEach((option) => {
        bayLevelDataToCopy[option] =
            fromBayLevelData[option];
    });
    const srcPerSlotInfo = fromBayLevelData.perSlotInfo
        ? cloneObject(fromBayLevelData.perSlotInfo)
        : undefined;
    const baysToUpdate = json.baysData
        .filter((b) => b && toIsoBaysArray.includes(b.isoBay) && b.level === level)
        .map(cloneObject);
    for (let i = 0; i < toIsoBaysArray.length; i += 1) {
        const bayToUpdate = baysToUpdate[i];
        if (!bayToUpdate)
            continue;
        // 1. Update Bay Details
        bayOptions.forEach((option) => {
            bayToUpdate[option] = bayLevelDataToCopy[option];
        });
        // 2. Update Slots
        if (srcPerSlotInfo) {
            bayToUpdate.perSlotInfo = cloneObject(srcPerSlotInfo);
        }
    }
    baysToUpdate.forEach((b) => {
        ovdJsonStore.replaceBayInfo(b.isoBay, b.level, b, false);
    });
    ovdJsonStore.emitChange({
        type: OVDChangesEnum.BAY_INFO,
        data: baysToUpdate.map((b) => b.isoBay),
    });
    // 3. Save preferences
    setPreferencesKeyAndValue(BAY_HELPERS_PREF_FROM_ID, {
        bayOptions,
    });
    return EnumUpdateResult.OK;
}
function generateBayLevelOptions({ sizeSummary, level, isoBay, bayLabel, addLevelInLabel = true, includeSelf = false, disableSelf = false, }) {
    const options = [];
    for (let b = 1; b <= sizeSummary.isoBays; b += 2) {
        const pb = pad3(b);
        if (level === BayLevelEnum.ABOVE &&
            sizeSummary.minAboveTier !== undefined &&
            sizeSummary.maxAboveTier !== undefined &&
            (includeSelf || (!includeSelf && pb !== isoBay)))
            options.push({
                value: `${b}-${BayLevelEnum.ABOVE}`,
                label: `${bayLabel} ${pb} ${addLevelInLabel
                    ? `, ${getTranslation("enums:BayLevelEnum.ABOVE")}`
                    : ""}`,
                disabled: disableSelf && pb === isoBay,
            });
        if (level === BayLevelEnum.BELOW &&
            sizeSummary.minBelowTier !== undefined &&
            sizeSummary.maxBelowTier !== undefined &&
            (includeSelf || (!includeSelf && pb !== isoBay)))
            options.push({
                value: `${b}-${BayLevelEnum.BELOW}`,
                label: `${bayLabel} ${pb} ${addLevelInLabel
                    ? `, ${getTranslation("enums:BayLevelEnum.BELOW")}`
                    : ""}`,
                disabled: disableSelf && pb === isoBay,
            });
    }
    return options;
}
var EnumUpdateResult;
(function (EnumUpdateResult) {
    EnumUpdateResult[EnumUpdateResult["OMMITED"] = 0] = "OMMITED";
    EnumUpdateResult[EnumUpdateResult["OK"] = 1] = "OK";
    EnumUpdateResult[EnumUpdateResult["ERROR"] = 2] = "ERROR";
})(EnumUpdateResult || (EnumUpdateResult = {}));
