import { fillMissingPlausibleData } from "./fillMissingPlausibleData";
import { find20Lcgs } from "./find20Lcgs";
export function findForCompleteCGs(bayLevelSizesAndCgsBelow, bayLevelSizesAndCgsAbove, lcgsBy20Bay) {
    [...bayLevelSizesAndCgsBelow, ...bayLevelSizesAndCgsAbove]
        .filter((b) => {
        var _a, _b;
        return ((_a = lcgsBy20Bay[b.isoBay]) === null || _a === void 0 ? void 0 : _a.lcg) === undefined &&
            ((_b = b.maxSizeLcg) === null || _b === void 0 ? void 0 : _b.size) !== undefined;
    })
        .forEach(find20Lcgs(lcgsBy20Bay));
    fillMissingPlausibleData(lcgsBy20Bay);
}
