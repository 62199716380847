import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import { EnumHelpers } from "@baplie-viewer2/tedivo-pure-helpers";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import LenUnitsEnum from "../../../app/enums/LenUnitsEnum";
import MassUnitsEnum from "../../../app/enums/MassUnitsEnum";
import { PortStarboardEnum } from "open-vessel-definition";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import SlRadioGroup from "@shoelace-style/shoelace/dist/components/radio-group/radio-group";
import SlSwitch from "@shoelace-style/shoelace/dist/components/switch/switch";
import UoLcgReferenceEnum from "../../../app/enums/UoLcgReferenceEnum";
import VcgVerticalReferenceEnum from "../../../app/enums/VcgVerticalReferenceEnum";
import globalUnits from "../../../app/units/globalUnits";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import stafSaveOptions from "packages/oss-editor/src/app/stores/stafSaveOptions";

export class TVDUserOptionsElement extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private modalNode: SlDialog;
  private tempValues: IOptions = {
    length: globalUnits.lengthUnits.units,
    mass: globalUnits.massUnits.units,
    lcgRef: globalUnits.lcgTransposer.transposeTo,
    tcgDir: globalUnits.tcgTransposer.transposeTo,
    vcgRef: globalUnits.vcgTransposer.transposeTo,
    isFormattedJsonSaved: stafSaveOptions.isFormattedJsonSaved,
  };

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);

    const modal = document.createElement("sl-dialog");
    modal.label = getTranslation(`general:userOptions.title`);
    this.modalNode = modal;

    if (document.body.offsetWidth >= 500)
      modal.setAttribute("style", "--width: 80vw");

    modal.addEventListener(
      "sl-after-hide",
      () => {
        // Reset temp values
      },
      false,
    );
  }

  connectedCallback() {
    const icon = document.createElement("sl-icon");
    icon.name = "gear";
    const btn = document.createElement("sl-button");
    btn.appendChild(icon);
    btn.pill = true;
    btn.size = "small";

    this.appendChild(this.modalNode);

    // Add i18 consumer of button
    this.i18nCR.addConsumer(btn, `general:userOptions.title`, "title");

    this.appendChild(btn);

    btn.addEventListener("click", this.openOptionsModal, false);
  }

  openOptionsModal = () => {
    this.tempValues = {
      length: globalUnits.lengthUnits.units,
      mass: globalUnits.massUnits.units,
      lcgRef: globalUnits.lcgTransposer.transposeTo,
      tcgDir: globalUnits.tcgTransposer.transposeTo,
      vcgRef: globalUnits.vcgTransposer.transposeTo,

      isFormattedJsonSaved: stafSaveOptions.isFormattedJsonSaved,
    };

    removeChildren(this.modalNode);

    const holder = document.createElement("div");
    holder.className = "user-options-holder";

    const createRBGroup = ({
      label,
      name,
      enm,
      transKey,
      initialValue,
      isDisabled,
    }: ICreateRBGProps) => {
      const rbg = document.createElement("sl-radio-group");
      rbg.label = getTranslation(label);
      rbg.className = "with-fieldset";

      EnumHelpers.getNamesAndValues(enm).forEach(({ name, value }) => {
        const slRb = document.createElement("sl-radio");
        slRb.innerHTML = getTranslation(`${transKey}.${name}`);
        slRb.value = String(value);
        if (isDisabled) slRb.disabled = true;
        rbg.appendChild(slRb);
      });

      if (initialValue !== undefined) rbg.value = String(initialValue);

      rbg.addEventListener("sl-change", (ev) =>
        this.preSaveOptions(ev.target as SlRadioGroup, name),
      );

      return rbg;
    };

    // 1. Length
    holder.appendChild(
      createRBGroup({
        label: "general:userOptions.length",
        name: "length",
        enm: LenUnitsEnum,
        transKey: "enums:LenUnitsEnum",
        initialValue: this.tempValues.length,
      }),
    );

    // 2. Mass
    holder.appendChild(
      createRBGroup({
        label: "general:userOptions.mass",
        name: "mass",
        enm: MassUnitsEnum,
        transKey: "enums:MassUnitsEnum",
        initialValue: this.tempValues.mass,
      }),
    );

    // 3. LCG Reference
    const hLcgRbg = createRBGroup({
      label: "view:lcgReference",
      name: "lcgRef",
      enm: UoLcgReferenceEnum,
      transKey: "enums:UoLcgReferenceEnum",
      initialValue: this.tempValues.lcgRef,
      isDisabled: !globalUnits.lpp,
    });

    if (!globalUnits.lpp) {
      const pillLcgCannotUse = document.createElement("span");
      pillLcgCannotUse.className = "pillLcgCannotUse";
      pillLcgCannotUse.innerHTML = getTranslation(
        "general:userOptions.cannotUseLcg",
      );
      hLcgRbg.appendChild(pillLcgCannotUse);

      this.tempValues.lcgRef = UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD;
      globalUnits.setLcgRef(UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD);
    }

    holder.appendChild(hLcgRbg);

    // 4. TCG Direction
    const hTcgRbg = createRBGroup({
      label: "view:tcgDirection",
      name: "tcgDir",
      enm: PortStarboardEnum,
      transKey: "enums:PortStarboardEnum",
      initialValue: this.tempValues.tcgDir,
    });
    const tcgNegativeValues = document.createElement("span");
    tcgNegativeValues.className = "tcgDirectionMeaning";
    tcgNegativeValues.innerHTML = getTranslation("view:tcgDirectionMeaning");
    hTcgRbg.appendChild(tcgNegativeValues);
    holder.appendChild(hTcgRbg);

    // 5. VCG Reference
    const hVcgRbg = createRBGroup({
      label: "view:vcgReference",
      name: "vcgRef",
      enm: VcgVerticalReferenceEnum,
      transKey: "enums:VcgVerticalReferenceEnum",
      initialValue: this.tempValues.vcgRef,
    });

    holder.appendChild(hVcgRbg);

    // 5. Formatted JSON
    const formatedJsonSwitch = document.createElement("sl-switch");
    formatedJsonSwitch.className = "formated-json-switch";
    formatedJsonSwitch.innerHTML = getTranslation("general:saveFormattedJson");
    formatedJsonSwitch.checked = stafSaveOptions.isFormattedJsonSaved;
    formatedJsonSwitch.addEventListener("sl-change", (ev) =>
      this.preSaveOptions(ev.target as SlSwitch, "isFormattedJsonSaved"),
    );

    // Save button
    const btn = document.createElement("sl-button");
    btn.innerHTML = getTranslation("general:common.save");
    btn.variant = "primary";
    btn.slot = "footer";
    this.modalNode.appendChild(btn);
    btn.addEventListener("click", this.saveOptions, false);

    this.modalNode.appendChild(holder);
    this.modalNode.appendChild(formatedJsonSwitch);

    this.modalNode.show();
  };

  private preSaveOptions = (
    target: SlRadioGroup | SlSwitch,
    key: keyof IOptions,
  ) => {
    if (
      "checked" in target &&
      !target.checked &&
      key !== "isFormattedJsonSaved"
    )
      return;
    const val = Number(target.value);

    if (key === "length") {
      this.tempValues.length = val;
    } else if (key === "mass") {
      this.tempValues.mass = val;
    } else if (key === "lcgRef") {
      this.tempValues.lcgRef = val;
    } else if (key === "tcgDir") {
      this.tempValues.tcgDir = val;
    } else if (key === "vcgRef") {
      this.tempValues.vcgRef = val;
    } else if (key === "isFormattedJsonSaved") {
      this.tempValues.isFormattedJsonSaved = (target as SlSwitch).checked;
    }
  };

  private saveOptions = () => {
    const isDrityArr = [
      globalUnits.setLengthUnits(this.tempValues.length),
      globalUnits.setMassUnits(this.tempValues.mass),
      globalUnits.setLcgRef(this.tempValues.lcgRef),
      globalUnits.setTcgDir(this.tempValues.tcgDir),
      globalUnits.setVcgRef(this.tempValues.vcgRef),
      stafSaveOptions.setSaveFormattedJson(
        this.tempValues.isFormattedJsonSaved,
      ),
    ];

    const isDirty = isDrityArr.reduce((acc, v) => {
      acc = acc || v;
      return acc;
    }, false);

    if (isDirty) {
      document.documentElement.dispatchEvent(
        new CustomEvent("globalUnitsChanged", {
          detail: {
            length: this.tempValues.length,
            mass: this.tempValues.mass,
          },
        }),
      );
    }

    this.modalNode.hide();
  };
}

customElements.define("tvd-user-options-component", TVDUserOptionsElement);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-user-options-component": TVDUserOptionsElement;
  }
}

interface IOptions {
  length: LenUnitsEnum;
  mass: MassUnitsEnum;
  lcgRef: UoLcgReferenceEnum;
  tcgDir: PortStarboardEnum;
  vcgRef: VcgVerticalReferenceEnum;
  isFormattedJsonSaved: boolean;
}

interface ICreateRBGProps {
  label: string;
  name: keyof IOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enm: any;
  transKey: string;
  initialValue: number;
  isDisabled?: boolean;
}
