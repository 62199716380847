import {
  IFields,
  IFormReturn,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";
import { IShipData, ValuesSourceEnum } from "open-vessel-definition";

import { EnumHelpers } from "@baplie-viewer2/tedivo-pure-helpers";
import LenUnitsEnum from "../../../../../app/enums/LenUnitsEnum";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import UoLcgReferenceEnum from "../../../../../app/enums/UoLcgReferenceEnum";
import { getTranslation } from "../../../../../app/i18/i18tn";
import globalUnits from "../../../../../app/units/globalUnits";
import goSquared from "../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
import { z } from "zod";

const FormValidator: z.Schema<IEditLppFields> = z.object({
  lpp: z.number().min(1),
  loa: z.number().optional(),
  sternToAftPp: z.number().optional(),
  lcgValues: z.nativeEnum(ValuesSourceEnum),
  tcgValues: z.nativeEnum(ValuesSourceEnum),
  vcgValues: z.nativeEnum(ValuesSourceEnum),
  lcgReference: z.nativeEnum(UoLcgReferenceEnum),
});

export function createLppEdit(
  shipData: IShipData,
  submitButton: SlButton,
): IFormReturn<IEditLppFields> {
  goSquared.addEvent("Edit-OVD - Edit Lpp");

  const holder = document.createElement("div");

  const knownEstimatedOptions = [
    {
      value: ValuesSourceEnum.KNOWN,
      label: getTranslation(`enums:ValuesSourceEnum.KNOWN`),
    },
    {
      value: ValuesSourceEnum.ESTIMATED,
      label: getTranslation(`enums:ValuesSourceEnum.ESTIMATED`),
    },
  ];

  const lcgRefOptions = EnumHelpers.getNamesAndValues(UoLcgReferenceEnum).map(
    ({ value, name }) => ({
      value,
      label: getTranslation(`enums:UoLcgReferenceEnum.${name}`),
    }),
  );

  const formFields: IFields<IEditLppFields> = [
    [
      {
        name: "lpp",
        label: "view:lpp",
        helpText: `enums:LenUnitsEnum.${
          LenUnitsEnum[globalUnits.lengthUnits.units]
        }`,
        type: "numberWithUnits",
        converter: globalUnits.lengthUnits,
        initialValue: shipData.lcgOptions.lpp,
      },
      {
        name: "loa",
        label: "view:loa",
        helpText: `enums:LenUnitsEnum.${
          LenUnitsEnum[globalUnits.lengthUnits.units]
        }`,
        type: "numberWithUnits",
        converter: globalUnits.lengthUnits,
        initialValue: shipData.loa,
      },
      {
        name: "sternToAftPp",
        label: "view:sternToAftPp",
        helpText: `enums:LenUnitsEnum.${
          LenUnitsEnum[globalUnits.lengthUnits.units]
        }`,
        type: "numberWithUnits",
        converter: globalUnits.lengthUnits,
        initialValue: shipData.sternToAftPp,
      },
    ],
    [
      {
        name: "lcgValues",
        label: "view:lcgValues",
        fieldset: true,
        isNumericEnum: true,
        type: "radioButtonList",
        initialValue: shipData.lcgOptions.values,
        options: knownEstimatedOptions,
      },
      {
        name: "tcgValues",
        label: "view:tcgValues",
        fieldset: true,
        isNumericEnum: true,
        type: "radioButtonList",
        initialValue: shipData.tcgOptions.values,
        options: knownEstimatedOptions,
      },
      {
        name: "vcgValues",
        label: "view:vcgValues",
        fieldset: true,
        isNumericEnum: true,
        type: "radioButtonList",
        initialValue: shipData.vcgOptions.values,
        options: knownEstimatedOptions,
      },
    ],
    {
      name: "lcgReference",
      label: "view:lcgReferenceViewOnly",
      fieldset: true,
      isNumericEnum: true,
      type: "radioButtonList",
      initialValue: globalUnits.lcgTransposer.transposeTo,
      options: lcgRefOptions,
    },
  ];

  const tedivoForm = new TedivoForm<IEditLppFields>({
    fields: formFields,
    onSubmit: () => undefined,
    formValidator: FormValidator,
    submitButton: submitButton,
    autoFocusOnFirstInput: true,
  });

  translateTedivoForm<IEditLppFields>({
    tedivoForm,
    getTranslation: getTranslation,
  });

  const infoDiv = document.createElement("div");
  infoDiv.className = "info-div";
  infoDiv.innerHTML = getTranslation("general:lcgOVDInfo");

  holder.appendChild(tedivoForm.form);
  holder.appendChild(infoDiv);

  return {
    node: holder,
    tedivoForm,
    submitFunction: submitPassedToEditDrawer,
  };

  async function submitPassedToEditDrawer() {
    const validResult = tedivoForm.doSubmitForm();
    if (validResult.success) {
      const values = validResult.data;

      goSquared.addEvent("Edit-OVD - Edit Lpp - Save");

      ovdJsonStore.setCgOptions(
        values.lcgValues,
        values.tcgValues,
        values.vcgValues,
        values.loa,
        values.sternToAftPp,
        values.lpp,
      );

      if (globalUnits.lcgTransposer.transposeTo !== values.lcgReference) {
        globalUnits.setLcgRef(values.lcgReference);
        document.documentElement.dispatchEvent(
          new CustomEvent("globalUnitsChanged"),
        );
      }

      return true;
    }

    return false;
  }
}

interface IEditLppFields extends Record<string, unknown> {
  lpp: number;
  loa?: number;
  sternToAftPp?: number;
  lcgValues: ValuesSourceEnum;
  tcgValues: ValuesSourceEnum;
  vcgValues: ValuesSourceEnum;
  lcgReference: UoLcgReferenceEnum;
}
