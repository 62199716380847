import {
  feetToMillimeters,
  roundDec,
} from "@baplie-viewer2/tedivo-pure-helpers";

import { IVesselOneBaySlotBridge } from "../../../../../../../tedivo-bay-grid-pure/src/lib/types/IVesselOneBaySlot";

export function drawDeckBridge(
  bridge: IVesselOneBaySlotBridge,
  zRange: number,
  addZ: number,
  svgSizeRatio: number,
): SVGPathElement[] {
  const mainPath = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path",
  );

  mainPath.setAttribute("stroke", "#888");
  mainPath.setAttribute("fill", "#fff");
  mainPath.setAttribute("stroke-width", "1");
  mainPath.setAttribute("fill-opacity", "0.2");
  mainPath.setAttribute("data-type", bridge.type);
  mainPath.setAttribute("data-id", bridge.id);

  const result: SVGPathElement[] = [mainPath];

  const w2 = roundDec(feetToMillimeters(bridge.len) * 0.5 * 0.8, 2);

  const w2Svg = w2 * svgSizeRatio;

  mainPath.setAttribute(
    "d",
    `M${-w2Svg},${addZ} 
     L${-w2Svg},${zRange - addZ} 
     L${w2Svg},${zRange - addZ} 
     L${w2Svg},${addZ} 
     L${-w2Svg},${addZ}`,
  );

  {
    const ceiling = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );

    ceiling.setAttribute("stroke", "#888");
    ceiling.setAttribute("fill", "#999");
    ceiling.setAttribute("stroke-width", "1");
    ceiling.setAttribute("fill-opacity", "1");

    const x1 = -w2Svg * 0.2;
    const x2 = w2Svg * 0.7;

    ceiling.setAttribute(
      "d",
      `M${x1 - 2},${addZ} 
       L${x1 - 2},${zRange - addZ} 
       L${x2 + 2},${zRange - addZ} 
       L${x2 + 2},${addZ}
       L${x1 - 2},${addZ}`,
    );

    result.push(ceiling);
  }

  if (bridge.heatSrcBelow) {
    const heatSrc = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );

    heatSrc.setAttribute("stroke", "#888");
    heatSrc.setAttribute("fill", "#3d2814");
    heatSrc.setAttribute("stroke-width", "1");
    heatSrc.setAttribute("fill-opacity", "1");

    const x1 = -w2Svg;
    const x2 = 0;

    heatSrc.setAttribute(
      "d",
      `M${x1},${zRange * 0.5 - addZ} 
       L${x2},${zRange * 0.5 - addZ} 
       L${x2},${zRange * 0.5 + addZ}
       L${x1},${zRange * 0.5 + addZ}
       L${x1},${zRange * 0.5 - addZ}
       `,
    );

    result.push(heatSrc);
  }

  return result;
}
