import globalUnits from "../units/globalUnits";
import ovdJsonStore from "./OVDJsonStore";
import screenColorModeStore from "./ScreenColorModeStore";

class GlobalStore {
  private lastUserInteractionDate: Date | null = null;

  constructor() {
    ovdJsonStore.addEventListener("jsonUpdated", () => {
      this.touchLastUserInteraction();
    });
  }

  get screenColorMode() {
    return screenColorModeStore.screenColorMode;
  }

  get resolvedColorMode() {
    return screenColorModeStore.resolvedColorMode;
  }

  get units() {
    return globalUnits.units;
  }

  get lastUserInteraction(): Date | null {
    return this.lastUserInteractionDate;
  }

  touchLastUserInteraction() {
    this.lastUserInteractionDate = new Date();
  }
}

const globalStore = new GlobalStore();
export default globalStore;
