import { __awaiter } from "tslib";
import { FileNoteType, safeDateType, } from "@baplie-viewer2/tedivo-api-models";
import IntegratedDialog from "../../../common/IntegratedDialog";
import Services from "../../../../app/services";
import SmartTable from "../../../common/smartTable/smart-table.element";
import { formatDateInCurrentTimezone } from "@baplie-viewer2/tedivo-pure-helpers";
import { getTranslation } from "../../../../app/i18/i18tn";
import securityModule from "../../../../app/security/SecurityModule";
export function createModalToForNotes({ organizationId, fileId, fileName, modal, organizationUsersBySub, onUpdated, }) {
    if (!organizationId || !fileId)
        return;
    let lastEvaluatedNotesKey = undefined;
    let totalFileNotesData = [];
    const main = document.createElement("div");
    main.className = "add-note-container";
    const holder = document.createElement("div");
    holder.className = "add-note-header";
    const input = document.createElement("sl-textarea");
    input.name = "newNote";
    //input.label = getTranslation("general:fileNotes.addNote");
    input.placeholder = `${getTranslation("general:fileNotes.addNote")}...`;
    input.autofocus = true;
    const bntAddNote = document.createElement("sl-button");
    bntAddNote.innerText = getTranslation("general:fileNotes.addNote");
    bntAddNote.variant = "primary";
    bntAddNote.type = "submit";
    holder.appendChild(input);
    holder.appendChild(bntAddNote);
    main.appendChild(holder);
    const { notesLoadingHolder, smartTableNotes } = createTableNodes(fileId, organizationUsersBySub);
    notesLoadingHolder.setLoading(true);
    main.appendChild(notesLoadingHolder);
    loadNotes({
        organizationId,
        fileId,
        smartTableNotes,
        totalFileNotesData: [],
        lastEvaluatedNotesKey: undefined,
    }).then(({ lastEvaluatedKey, newList }) => {
        totalFileNotesData = newList;
        lastEvaluatedNotesKey = lastEvaluatedKey;
        notesLoadingHolder.setLoading(false);
        const minHeight = notesLoadingHolder.clientHeight;
        notesLoadingHolder.style.minHeight = `${minHeight}px`;
    });
    bntAddNote.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
        const note = input.value.trim();
        input.value = "";
        input.focus();
        if (!note)
            return;
        notesLoadingHolder.setLoading(true);
        yield Services.files.addFileNote(fileId, note);
        loadNotes({
            organizationId,
            fileId,
            smartTableNotes,
            totalFileNotesData: [], // Reset table data
            lastEvaluatedNotesKey: undefined, // Reset table data
        }).then(({ lastEvaluatedKey, newList }) => {
            lastEvaluatedNotesKey = lastEvaluatedKey;
            totalFileNotesData = newList;
            notesLoadingHolder.setLoading(false);
            const minHeight = notesLoadingHolder.clientHeight;
            notesLoadingHolder.style.minHeight = `${minHeight}px`;
        });
        if (onUpdated)
            onUpdated();
    }));
    modal.show(fileName, main);
    window.setTimeout(() => {
        input.focus();
    }, 150);
    function createTableNodes(fileId, organizationUsersBySub) {
        const holder = document.createElement("div-spinner-element");
        holder.className = "add-note-list";
        const smartTableNotes = createNotesSmartTable();
        smartTableNotes.style.display = "none";
        holder.appendChild(smartTableNotes);
        holder.setLoading(true);
        return { notesLoadingHolder: holder, smartTableNotes };
        function createNotesSmartTable() {
            const fields = [
                {
                    name: "date",
                    label: getTranslation("view:edit.fileHistory.fields.date"),
                    valueFunction: (dta) => formatDateInCurrentTimezone(dta.date),
                    className: "centered",
                    fixedWidth: "180px",
                },
                {
                    name: "note",
                    label: getTranslation("general:fileNotes.fileNotes"),
                    valueFunction: (dta) => {
                        const note = dta.note ? dta.note.replace(/\n/g, "<br />") : "-";
                        if (dta.type === FileNoteType.PUBLISHED) {
                            return `${getTranslation("general:fileNotes.published")}${note}`;
                        }
                        else {
                            return note;
                        }
                    },
                },
                {
                    name: "userSub",
                    label: getTranslation("general:common.author"),
                    valueFunction: (dta) => (organizationUsersBySub === null || organizationUsersBySub === void 0 ? void 0 : organizationUsersBySub[dta.userSub]) || dta.email || "-",
                },
                {
                    name: "fileId",
                    label: getTranslation("general:common.delete"),
                    type: "icon",
                    icon: "trash",
                    fixedWidth: "60px",
                    iconVariant: "danger",
                    disabled: (dta) => dta.type === FileNoteType.PUBLISHED ||
                        (dta.userSub !== securityModule.userSub &&
                            securityModule.userRole === "USER"),
                    onClick: (dta) => __awaiter(this, void 0, void 0, function* () {
                        const confirmDialog = new IntegratedDialog({
                            parentNode: holder,
                            removeDialogWhenHidden: true,
                            buttonsAttrs: [
                                {
                                    label: "general:common.yes",
                                    value: "ok",
                                    variant: "danger",
                                    type: "submit",
                                    icon: "trash",
                                },
                                {
                                    label: "general:common.no",
                                    value: "cancel",
                                    type: "button",
                                },
                            ],
                        });
                        confirmDialog.onButtonClicked = (action) => __awaiter(this, void 0, void 0, function* () {
                            if (action === "ok") {
                                totalFileNotesData = totalFileNotesData.filter((note) => note.date !== dta.date || note.type !== dta.type);
                                smartTableNotes.updateData(totalFileNotesData);
                                Services.files.deleteFileNote(fileId, safeDateType(dta.date).toISOString());
                                notesLoadingHolder.style.minHeight = "0";
                            }
                            confirmDialog.hide();
                            return true;
                        });
                        confirmDialog.show(getTranslation("general:fileNotes.deleteNote"), getTranslation("general:fileNotes.sureDeleteFileNote"));
                    }),
                },
            ];
            const smartTableLogs = new SmartTable().initialize({
                className: "tvd-table tvd-table-smart-table margin-bottom",
                data: [],
                fields,
                initialRows: 1000,
                settings: {
                    sortEnabled: false,
                    sortVisible: false,
                    filterEnabled: false,
                },
                pkFunction: (dta) => `${dta.fileId}#${new Date(dta.date).toISOString()}#${dta.type}`,
            });
            return smartTableLogs;
        }
    }
}
function loadNotes(_a) {
    return __awaiter(this, arguments, void 0, function* ({ organizationId, fileId, smartTableNotes, totalFileNotesData = [], lastEvaluatedNotesKey, }) {
        var _b;
        const { statusCode, data } = yield Services.files.getFileNotes(organizationId, fileId, lastEvaluatedNotesKey);
        if (statusCode !== 200) {
            return {
                lastEvaluatedKey: undefined,
                newList: totalFileNotesData,
            };
        }
        const sortedData = (_b = data === null || data === void 0 ? void 0 : data.notes) === null || _b === void 0 ? void 0 : _b.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        if (sortedData) {
            const list = totalFileNotesData.concat(sortedData || []);
            smartTableNotes.updateData(list);
            smartTableNotes.style.display = "block";
            return {
                lastEvaluatedKey: data === null || data === void 0 ? void 0 : data.lastEvaluatedKey,
                newList: list,
            };
        }
        else {
            return {
                lastEvaluatedKey: undefined,
                newList: totalFileNotesData,
            };
        }
    });
}
