import SlButton from "@shoelace-style/shoelace/dist/components/button/button.component";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog.component";
import SlIconButton from "@shoelace-style/shoelace/dist/components/icon-button/icon-button.component";
import { getTranslation } from "packages/oss-editor/src/app/i18/i18tn";
import { stylesFromStringToTag } from "@baplie-viewer2/tedivo-dom-helpers";

export class DeleteButtonWithConfirm extends HTMLElement {
  private confirmDelete: SlButton | SlIconButton | undefined = undefined;
  private deleteConfirmationDialog: SlDialog | undefined = undefined;

  public buttonText: string = getTranslation("general:common.delete");
  public confirmDeleteText: string = getTranslation(
    "general:common.confirmDelete",
  );
  public onConfirmDelete: () => void = () => {};

  constructor() {
    super();
  }

  connectedCallback() {
    const confirmDelete =
      this.slot === "header-actions"
        ? document.createElement("sl-icon-button")
        : document.createElement("sl-button");

    // First button
    if (this.slot === "header-actions") {
      confirmDelete.name = "trash";
      confirmDelete.slot = "header-actions";
      confirmDelete.title = this.buttonText;
      confirmDelete.classList.add("danger");
    } else {
      confirmDelete.slot = "footer";
      confirmDelete.innerHTML = this.buttonText;
      confirmDelete.tabIndex = 0;
      (confirmDelete as SlButton).variant = "danger";
      (confirmDelete as SlButton).type = "button";

      const icon = document.createElement("sl-icon");
      icon.name = "trash";
      icon.slot = "prefix";
      confirmDelete.appendChild(icon);
    }

    this.appendChild(confirmDelete);

    // Confirm dialog and confirm button
    const deleteConfirmationDialog = this.createConfirmDeleteDialog();

    confirmDelete.onclick = function () {
      deleteConfirmationDialog.show();
    };

    confirmDelete.onkeydown = function (ev: KeyboardEvent) {
      if (ev.key === " ") {
        confirmDelete.click();
      }
    };

    this.appendChild(stylesFromStringToTag(styles));
  }

  createConfirmDeleteDialog() {
    const deleteConfirmationDialog = document.createElement("sl-dialog");
    deleteConfirmationDialog.classList.add("higher-modal-z-index");

    deleteConfirmationDialog.label = this.buttonText;
    deleteConfirmationDialog.appendChild(
      document.createTextNode(this.confirmDeleteText),
    );

    const confirmDeleteButton = document.createElement("sl-button");
    confirmDeleteButton.slot = "footer";
    confirmDeleteButton.innerText = getTranslation("general:common.delete");
    confirmDeleteButton.variant = "danger";

    const icon = document.createElement("sl-icon");
    icon.name = "trash";
    icon.slot = "prefix";
    confirmDeleteButton.appendChild(icon);

    deleteConfirmationDialog.appendChild(confirmDeleteButton);

    confirmDeleteButton.onclick = () => {
      this.onConfirmDelete();
      deleteConfirmationDialog.hide();
    };

    const cancelDeleteButton = document.createElement("sl-button");
    cancelDeleteButton.slot = "footer";
    cancelDeleteButton.innerText = getTranslation("general:common.cancel");
    deleteConfirmationDialog.appendChild(cancelDeleteButton);

    cancelDeleteButton.onclick = () => {
      deleteConfirmationDialog.hide();
    };

    document.body.appendChild(deleteConfirmationDialog);
    this.deleteConfirmationDialog = deleteConfirmationDialog;

    return deleteConfirmationDialog;
  }

  disconnectedCallback() {
    this.confirmDelete?.remove();
    this.deleteConfirmationDialog?.remove();
  }
}

customElements.define("delete-button", DeleteButtonWithConfirm);

declare global {
  interface HTMLElementTagNameMap {
    "delete-button": DeleteButtonWithConfirm;
  }
}

const styles = `
  .danger {
    color: var(--sl-color-danger-500);
  }

`;
