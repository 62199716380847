import { __awaiter } from "tslib";
import { BayLevelEnum, ForeAftEnum, ValuesSourceEnum, } from "open-vessel-definition";
import { pad3, sanitizeString, } from "@baplie-viewer2/tedivo-pure-helpers";
import { createFormFields, createFormValidator, } from "./newJsonFields";
import { TedivoForm, translateTedivoForm } from "@baplie-viewer2/tedivo-form";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import { ImoFoundResultEnum } from "../import-staf/ImoFoundResultEnum";
import IntegratedDialog from "../../common/IntegratedDialog";
import PairingHelpersEnum from "../../../app/enums/PairingHelpersEnum";
import Services from "../../../app/services";
import TelescopingHelpersEnum from "../../../app/enums/TelescopingHelpersEnum";
import UoLcgReferenceEnum from "../../../app/enums/UoLcgReferenceEnum";
import { createFoundImoDialog } from "../import-staf/createFoundImoDialog";
import { createNotFoundImoDialog } from "../import-staf/createNotFoundImoDialog";
import { focusAndSelect } from "../import-staf/focusAndSelect";
import globalStore from "../../../app/stores/globalStore";
import globalUnits from "../../../app/units/globalUnits";
import goSquared from "../../../app/tracking/goSquared";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
export class TVDNewJsonComponent extends HTMLElement {
    constructor() {
        super();
        this.vmdShipNameLatest = undefined;
        this.imoApiState = ImoFoundResultEnum.INITIALIZED;
        this.ownFiles = {
            lastUpdated: 0,
            list: [],
        };
        this.submitButton = undefined;
        this.onSubmitDataForm = (values) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            (_a = this.submitButton) === null || _a === void 0 ? void 0 : _a.setAttribute("disabled", "true");
            (_b = this.submitButton) === null || _b === void 0 ? void 0 : _b.setAttribute("loading", "true");
            const filename = `${values.lineOperator ? `${values.lineOperator}.` : ""}${values.shipClass ? `${values.shipClass}.` : ""}${values.shipName}`;
            const filenameParts = {
                full: filename,
                path: "",
                filename,
                name: filename,
                extension: "",
            };
            const name = sanitizeString(filename);
            const jsonData = initializeOVDV1Json(values);
            const viewState = {
                filenameParts,
                source: "newOvd",
                json: jsonData,
                vmdShipNameLatest: this.vmdShipNameLatest,
            };
            if (values.lcgReference === UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD ||
                (values.lcgReference !== UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD &&
                    values.lpp > 0)) {
                globalUnits.setLcgRef(values.lcgReference);
                document.documentElement.dispatchEvent(new CustomEvent("globalUnitsChanged"));
            }
            yield this.getOwnFiles();
            if (this.ownFiles.list.some(({ name }) => name === values.shipName)) {
                const modal = new IntegratedDialog({
                    parentNode: this,
                    preventDefaultClose: true,
                    preventClose: true,
                    buttonsAttrs: [
                        {
                            label: "general:common.saveAnyway",
                            value: "saveAnyway",
                            variant: "danger",
                            icon: "exclamation-triangle",
                            type: "submit",
                        },
                        {
                            label: "general:common.cancel",
                            value: "cancel",
                            icon: "x-lg",
                            type: "button",
                            autofocus: true,
                        },
                    ],
                    removeDialogWhenHidden: true,
                });
                modal.show(getTranslation("general:common.attention"), getTranslation("errors:fileWithSameNameExists"));
                modal.onButtonClicked = (btnSelected) => {
                    if (btnSelected === "saveAnyway") {
                        goSquared.addEvent("New-OVD - Exec");
                        router.navigate(routeFns.ovdEdit(name), viewState);
                    }
                };
                (_c = this.submitButton) === null || _c === void 0 ? void 0 : _c.removeAttribute("disabled");
                (_d = this.submitButton) === null || _d === void 0 ? void 0 : _d.removeAttribute("loading");
            }
            else {
                goSquared.addEvent("New-OVD - Exec");
                router.navigate(routeFns.ovdEdit(name), viewState);
            }
        });
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        setAppTitle(getTranslation("menu:newOVD"));
    }
    connectedCallback() {
        if (securityModule.planIsReadOnly) {
            router.navigate(routeFns.myCloud());
            return;
        }
        goSquared.trackPage("New OVD");
        goSquared.addEvent("New-OVD - Show page");
        const wrapper = document.createElement("div"), h1 = document.createElement("h1");
        const box = document.createElement("div");
        box.className = "sections-oss-card";
        const submitButton = document.createElement("sl-button");
        submitButton.type = "submit";
        submitButton.innerHTML = getTranslation("general:common.submit");
        submitButton.variant = "primary";
        this.submitButton = submitButton;
        const tedivoForm = new TedivoForm({
            fields: createFormFields(false),
            formValidator: createFormValidator(false),
            onSubmit: this.onSubmitDataForm,
            submitButton: submitButton,
            autoFocusOnFirstInput: true,
            hiddenData: { loa: 0, lpp: 0 },
        });
        const loadingBlocker = document.createElement("loading-blocker-element");
        this.appendChild(loadingBlocker);
        translateTedivoForm({
            tedivoForm,
            i18nCR: this.i18nCR,
        });
        tedivoForm.onDataChange = (values, name) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            globalStore.touchLastUserInteraction();
            if (name !== "imoCode" ||
                !values.imoCode ||
                !tedivoForm.execValidation(["imoCode"]))
                return;
            loadingBlocker.show();
            const [nameInfo, searchResults] = yield Promise.all([
                Services.vesselsMasterData.searchVesselByImoCode(values.imoCode),
                Services.vesselSearch.search(`'${values.imoCode}'`, ["imoCode"]),
            ]);
            const tvlResults = ((_a = searchResults === null || searchResults === void 0 ? void 0 : searchResults.data) === null || _a === void 0 ? void 0 : _a.filter((r) => r.item.organizationId !== securityModule.currentOrganizationId)) || [];
            loadingBlocker.hide();
            console.log(nameInfo);
            if (((_b = nameInfo === null || nameInfo === void 0 ? void 0 : nameInfo.data) === null || _b === void 0 ? void 0 : _b.source) === "error") {
                if (tedivoForm.execValidation(["imoCode"])) {
                    this.imoApiState = ImoFoundResultEnum.NOT_FOUND_CONTINUE;
                    showOtherFields(this.i18nCR, this.imoApiState);
                }
            }
            const match = (_c = nameInfo === null || nameInfo === void 0 ? void 0 : nameInfo.data) === null || _c === void 0 ? void 0 : _c.match;
            if (!match) {
                createNotFoundImoDialog({
                    parentNode: this,
                    imoCode: values.imoCode || "",
                    onButtonClicked: (btnSelected) => {
                        switch (btnSelected) {
                            case "continue":
                                this.imoApiState = ImoFoundResultEnum.NOT_FOUND_CONTINUE;
                                showOtherFields(this.i18nCR, this.imoApiState);
                                break;
                            case "fix":
                                this.imoApiState = ImoFoundResultEnum.NOT_FOUND_FIX;
                                focusAndSelect(tedivoForm.getFormControlsByName().imoCode.field);
                                break;
                        }
                    },
                });
            }
            else {
                createFoundImoDialog({
                    parentNode: this,
                    imoCode: values.imoCode || "",
                    lastFetched: String(match.modifiedAt),
                    shipName: match.SHIPNAME,
                    btnSeeTvlSearchResults: tvlResults.length > 0,
                    onButtonClicked: (btnSelected) => {
                        switch (btnSelected) {
                            case "continue":
                                this.imoApiState = ImoFoundResultEnum.FOUND_CONTINUE;
                                this.vmdShipNameLatest = match.SHIPNAME;
                                showOtherFields(this.i18nCR, this.imoApiState);
                                tedivoForm
                                    .setValue("imoCode", String(match.IMO))
                                    .setValue("shipName", match.SHIPNAME || "")
                                    .setValue("yearBuilt", Number(match.BUILT || 0))
                                    .setValue("lpp", Number(match.LPP || 0))
                                    .setValue("loa", Number(match.LENGTH || 0))
                                    .setValue("callSign", match.CALLSIGN || "");
                                if (match.MANAGER)
                                    tedivoForm.setValue("lineOperator", match.MANAGER);
                                break;
                            case "fix":
                                this.imoApiState = ImoFoundResultEnum.FOUND_FIX_IMO;
                                showOtherFields(this.i18nCR, this.imoApiState);
                                setTimeout(() => {
                                    focusAndSelect(tedivoForm.getFormControlsByName().imoCode.field);
                                }, 150);
                                break;
                            case "doubleCheck":
                                this.imoApiState = ImoFoundResultEnum.FOUND_MANUAL_SET;
                                showOtherFields(this.i18nCR, this.imoApiState);
                                Services.vesselsMasterData
                                    .searchVesselByImoCode(values.imoCode || "", true)
                                    .then((doucleCheckNameInfo) => {
                                    var _a;
                                    const newM = (_a = doucleCheckNameInfo === null || doucleCheckNameInfo === void 0 ? void 0 : doucleCheckNameInfo.data) === null || _a === void 0 ? void 0 : _a.match;
                                    if (newM) {
                                        this.vmdShipNameLatest = newM.SHIPNAME;
                                        tedivoForm
                                            .setValue("imoCode", String(newM.IMO))
                                            .setValue("shipName", newM.SHIPNAME || "")
                                            .setValue("yearBuilt", Number(newM.BUILT || 0))
                                            .setValue("callSign", newM.CALLSIGN || "")
                                            .setValue("lpp", Number(newM.LPP || 0))
                                            .setValue("loa", Number(newM.LENGTH || 0));
                                    }
                                });
                                break;
                            case "seeSearchResults":
                                router.navigate(routeFns.communityFiles(), {
                                    searchText: values.imoCode,
                                    fields: ["imoCode"],
                                    data: searchResults.data,
                                });
                                break;
                        }
                    },
                });
            }
            function showOtherFields(i18nCR, imoApiState) {
                const doContinue = imoApiState === ImoFoundResultEnum.FOUND_CONTINUE ||
                    imoApiState === ImoFoundResultEnum.FOUND_MANUAL_SET ||
                    imoApiState === ImoFoundResultEnum.NOT_FOUND_CONTINUE;
                if (!doContinue)
                    return;
                const newFields = createFormFields(true);
                tedivoForm
                    .setFields(newFields)
                    .setFormValidator(createFormValidator(true));
                const divButtons = document.createElement("div");
                divButtons.className = "form-buttons";
                divButtons.appendChild(submitButton);
                tedivoForm.form.appendChild(divButtons);
                translateTedivoForm({
                    tedivoForm,
                    i18nCR,
                });
                submitButton.disabled = false;
            }
        });
        const nextButton = document.createElement("sl-button");
        nextButton.type = "button";
        nextButton.innerHTML = getTranslation("general:common.next");
        nextButton.outline = true;
        nextButton.variant = "primary";
        box.appendChild(tedivoForm.form);
        const divButtons = document.createElement("div");
        divButtons.className = "form-buttons";
        divButtons.appendChild(nextButton);
        tedivoForm.form.appendChild(divButtons);
        wrapper.appendChild(h1);
        wrapper.appendChild(box);
        this.appendChild(wrapper);
        // Translations
        this.i18nCR
            .addConsumer(h1, "view:createNewOVD", "innerHTML")
            .addConsumer(topMessageElement.element, "menu:newOVD", "innerHTML");
        nextButton.addEventListener("click", () => {
            var _a;
            (_a = tedivoForm.onDataChange) === null || _a === void 0 ? void 0 : _a.call(tedivoForm, tedivoForm.getValues(), "imoCode");
        });
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
    getOwnFiles() {
        return __awaiter(this, void 0, void 0, function* () {
            const lastUpdated = this.ownFiles.lastUpdated;
            //Fetch if first-time or cache is > 5s
            if (lastUpdated === 0 || Date.now() - lastUpdated > 5000) {
                const data = yield Services.files.getAll();
                if (data.statusCode === 200 && data.data !== undefined) {
                    this.ownFiles.list = data.data;
                    this.ownFiles.lastUpdated = Date.now();
                }
            }
        });
    }
}
TVDNewJsonComponent.observedAttributes = [];
customElements.define("tvd-new-json-component", TVDNewJsonComponent);
function initializeOVDV1Json(values) {
    var _a, _b, _c;
    const shipData = {
        shipClass: values.shipClass,
        lineOperator: values.lineOperator,
        shipName: values.shipName,
        callSign: values.callSign,
        imoCode: values.imoCode,
        yearBuilt: values.yearBuilt,
        positionFormat: values.positionFormat,
        containersLengths: values.containersLengths,
        masterCGs: { aboveTcgs: {}, belowTcgs: {}, bottomBases: {} },
        metaInfo: {},
        loa: (values.loa || 0) * 1000,
        lcgOptions: {
            values: ValuesSourceEnum.ESTIMATED,
            lpp: (values.lpp || 0) * 1000,
        },
        vcgOptions: { values: ValuesSourceEnum.ESTIMATED },
        tcgOptions: { values: ValuesSourceEnum.ESTIMATED },
        featuresAllowed: {
            slotCoolStowProhibited: ((_a = values.restrictions) === null || _a === void 0 ? void 0 : _a.indexOf("slotCoolStowProhibited")) >= 0,
            slotHazardousProhibited: ((_b = values.restrictions) === null || _b === void 0 ? void 0 : _b.indexOf("slotHazardousProhibited")) >= 0,
            slotConeRequired: ((_c = values.restrictions) === null || _c === void 0 ? void 0 : _c.indexOf("slotConeRequired")) >= 0,
        },
    };
    const sizeSummary = {
        isoBays: values.isoBays,
        maxRow: values.maxRow,
        centerLineRow: values.centerLineRow ? 1 : 0,
    };
    if (values.hasAboveDeck) {
        sizeSummary.maxAboveTier = values.maxAboveTier;
        sizeSummary.minAboveTier = values.minAboveTier;
    }
    if (values.hasBelowDeck) {
        sizeSummary.maxBelowTier = values.maxBelowTier;
        sizeSummary.minBelowTier = values.minBelowTier;
    }
    function createEmptyBayData(isoBay, level, centerLineRow, pairedBay, doors, reeferPlugs) {
        const bayData = {
            isoBay: pad3(isoBay),
            level: level,
            infoByContLength: {},
            perRowInfo: {
                common: {},
                each: {},
            },
            perSlotInfo: {},
            centerLineRow: centerLineRow ? 1 : 0,
            pairedBay,
            doors,
            reeferPlugs,
            meta: {},
        };
        return bayData;
    }
    const baysData = [];
    for (let i = 1; i <= values.isoBays; i += 2) {
        let pairedBay = undefined;
        if (values.prePopulateBayPairings === PairingHelpersEnum.BAY001_PAIRS_AFT) {
            if (i % 4 === 1)
                pairedBay = ForeAftEnum.AFT;
            else if (i % 4 === 3)
                pairedBay = ForeAftEnum.FWD;
        }
        else if (values.prePopulateBayPairings === PairingHelpersEnum.BAY003_PAIRS_AFT &&
            i > 1) {
            if (i % 4 === 3)
                pairedBay = ForeAftEnum.AFT;
            else if (i % 4 === 1)
                pairedBay = ForeAftEnum.FWD;
        }
        if (values.hasAboveDeck) {
            const bayDataAbove = createEmptyBayData(i, BayLevelEnum.ABOVE, !!values.centerLineRow, pairedBay, values.doors, values.reeferPlugs);
            if (values.prePopulateTelescoping === TelescopingHelpersEnum.ALL_TRUE ||
                values.prePopulateTelescoping === TelescopingHelpersEnum.ALL_ABOVE_TRUE)
                bayDataAbove.telescoping = 1;
            baysData.push(bayDataAbove);
        }
        if (values.hasBelowDeck) {
            const bayDataBelow = createEmptyBayData(i, BayLevelEnum.BELOW, !!values.centerLineRow, pairedBay, values.doors, values.reeferPlugs);
            if (values.prePopulateTelescoping === TelescopingHelpersEnum.ALL_TRUE ||
                values.prePopulateTelescoping === TelescopingHelpersEnum.ALL_BELOW_TRUE)
                bayDataBelow.telescoping = 1;
            baysData.push(bayDataBelow);
        }
    }
    const ovdJson = {
        $schema: "https://github.com/tedivo/OpenVesselDefinition/blob/master/schema.json",
        $schemaId: "IOpenVesselDefinitionV1",
        version: "1.0.0",
        shipData,
        sizeSummary,
        lidData: [],
        positionLabels: { bays: {} },
        baysData,
        vesselPartsData: [],
    };
    return ovdJson;
}
