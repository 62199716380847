import { __awaiter } from "tslib";
import "./view-json.component.scss";
import { BayLevelEnum, LcgReferenceEnum, ValuesSourceEnum, } from "open-vessel-definition";
import { GRID_OPTIONS_BY_CELL_DIMENSION, GRID_OPTIONS_BY_CELL_GAP, VCG_REF_TO_FACTOR, } from "./parts/bay-boxes/gridConfigConstants";
import { getBayLcgVcgTcgAndPairings, } from "tedivo-bay-grid-pure";
import { systemRights, } from "@baplie-viewer2/tedivo-api-models";
import { validateLidData, } from "@baplie-viewer2/tedivo-bay-grid-core";
import { createEditButton, createMultiEditButton, createSelectShoelace, } from "@baplie-viewer2/tedivo-form";
import ViewJsonRender from "./render/view-json-render";
import { ViewJsonTypeEnum, getViewTypesFromRouteKey } from "./ViewJsonTypeEnum";
import { createTranslateLCGs, generateTranslatedLCGsExampleData, } from "./parts/edits/helpers/editCGs/createTranslateLCGsDialog";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import ovdJsonStore from "../../../app/stores/OVDJsonStore";
import CellSpacingEnum from "../../../app/enums/CellSpacingEnum";
import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import EditDrawer from "../../common/EditDrawer";
import FileStateEnum from "../../../app/enums/FileStateEnum";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import { ImoFoundResultEnum } from "../import-staf/ImoFoundResultEnum";
import IntegratedDialog from "../../common/IntegratedDialog";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import SizeSmallMidLargeEnum from "../../../app/enums/SizeSmallMidLargeEnum";
import UoLcgReferenceEnum from "../../../app/enums/UoLcgReferenceEnum";
import { calculateMissingCGs } from "./parts/edits/helpers/editCGs/calculateMissingCGs";
import { create3DViewEdit } from "./parts/edits/create3DViewEdit";
import { createBulkheadsEdit } from "./parts/edits/createBulkheadsEdit";
import { createCGsEdit } from "./parts/edits/createCGsEdit";
import { createCGsMasterEdit } from "./parts/edits/createCGsMasterEdit";
import { createDownloadOVDJsonLink } from "./parts/createDownloadOVDJsonLink";
import { createFoundImoDialog } from "../import-staf/createFoundImoDialog";
import { createGeneralEdit } from "./parts/edits/createGeneralEdit";
import { createLidsEdit } from "./parts/edits/createLidsEdit/createLidsEdit";
import { createLppEdit } from "./parts/edits/createLppEdit";
import { createMissingCGsDialog } from "./parts/edits/helpers/editCGs/createMissingCGsDialog";
import { createModalToForNotes } from "./parts/createModalToForNotes";
import { createModalToTagFiles } from "./parts/createModalToTagFiles";
import { createMoveAll40s } from "./parts/edits/helpers/editBays/createMoveAll40s";
import { createNotFoundImoDialog } from "../import-staf/createNotFoundImoDialog";
import { createPairingsEdit } from "./parts/edits/createPairingsEdit/createPairingsEdit";
import { createRemoveCGsDialog } from "./parts/edits/helpers/editCGs/createRemoveCGsDialog";
import { createSizeEdit } from "./parts/edits/createSizeEdit";
import { createVersionsDropdown } from "../../helpers/createVersionsDropdown";
import { getGridNodesOptions } from "./parts/bay-boxes/getGridNodesOptions";
import globalStore from "../../../app/stores/globalStore";
import globalUnits from "../../../app/units/globalUnits";
import goSquared from "../../../app/tracking/goSquared";
import onOffLineObserver from "packages/oss-editor/src/app/offline/observer/OnOffLineObserver";
import { openBayEdit } from "./parts/edits/openBayEdit";
import { removeCGsfromJson } from "./serv/removeCGsfromJson";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import { showFileHistory } from "./showFileHistory";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
export class TVDViewJsonComponent extends HTMLElement {
    constructor() {
        super();
        this.confirmChangeStateDialog = undefined;
        this.titleWithActions = undefined;
        this.canOpenDrawer = true;
        this.notifiedStateChanged = false;
        this.intNotifiedStateChanged = 0;
        this.fileTags = { tags: {}, filesByTag: {} };
        this.organizationUsersBySub = {};
        this.viewJsonRender = undefined;
        this.colorModeChanged = (ev) => {
            const cMode = ev.detail.mode;
            if (this.viewJsonRender && cMode)
                this.viewJsonRender.viewD3ClearColor = cMode;
            this.showData(ev);
        };
        this.attachDetachEvents = (doAttach) => {
            if (doAttach) {
                i18nReactive.addEventListener("updateI18Consumers", this.showData);
                ovdJsonStore.addEventListener("jsonUpdated", this.showData);
                ovdJsonStore.addEventListener("stateChanged", this.onStateChanged);
                ovdJsonStore.addEventListener("updatingDB", this.onUpdatingDB);
                ovdJsonStore.addEventListener("error", this.onErrorSaving);
                document.documentElement.addEventListener("colorModeChanged", this.colorModeChanged);
                document.documentElement.addEventListener("globalUnitsChanged", this.showData);
                document.documentElement.addEventListener("bayDisplaySizeChanged", this.redrawBaysWhenDisplayPreferencesChange);
            }
            else {
                document.documentElement.removeEventListener("colorModeChanged", this.colorModeChanged);
                document.documentElement.removeEventListener("globalUnitsChanged", this.showData);
                document.documentElement.removeEventListener("bayDisplaySizeChanged", this.redrawBaysWhenDisplayPreferencesChange);
                i18nReactive.removeEventListener("updateI18Consumers", this.showData);
                ovdJsonStore.removeEventListener("jsonUpdated", this.showData);
                ovdJsonStore.removeEventListener("stateChanged", this.onStateChanged);
                ovdJsonStore.removeEventListener("updatingDB", this.onUpdatingDB);
                ovdJsonStore.removeEventListener("error", this.onErrorSaving);
            }
        };
        this.onStateChanged = ((ev) => {
            this.notifyStateChanged(ev.detail);
        });
        this.onUpdatingDB = ((ev) => {
            this.showSmallLoadingInTitle = ev.detail.isUpdating;
        });
        this.onErrorSaving = ((ev) => {
            const errorDialog = new IntegratedDialog({
                parentNode: this,
                buttonsAttrs: [
                    {
                        label: "general:common.close",
                        value: "close",
                        type: "submit",
                    },
                ],
                preventDefaultClose: true,
                preventClose: true,
                removeDialogWhenHidden: true,
            });
            errorDialog.onButtonClicked = () => {
                ovdJsonStore.restoreOriginalJson();
                this.showData();
            };
            const errorText = onOffLineObserver.isOnline
                ? "errors:errorSavingFile"
                : "errors:errorSavingFileOffline";
            errorDialog.show(getTranslation("errors:errorHasOcurred"), getTranslation(errorText));
        });
        this.showData = (ev) => {
            var _a, _b, _c, _d;
            const json = ovdJsonStore.currentJson;
            if (!json) {
                router.navigate(routeFns.myCloud());
                return;
            }
            globalStore.touchLastUserInteraction();
            const fileId = (_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.fileId;
            const fileOrgId = (_b = ovdJsonStore.tvdId) === null || _b === void 0 ? void 0 : _b.organizationId;
            if (!fileId || !fileOrgId) {
                router.navigate(routeFns.myCloud());
                return;
            }
            const event = ev;
            // Params
            const viewType = getViewTypesFromRouteKey(router.currentRouteKey);
            const version = viewType === ViewJsonTypeEnum.VIEW_VERSION
                ? router.getRouteParams().version || ""
                : undefined;
            // Security
            this.canOpenDrawer =
                securityModule.currentOrganizationId ===
                    ((_c = ovdJsonStore.tvdId) === null || _c === void 0 ? void 0 : _c.organizationId) ||
                    securityModule.userHasPermission(systemRights.ORG.CanDownloadPublicFile);
            // Data
            const lcgVcgTcgAndPairings = getBayLcgVcgTcgAndPairings({
                bls: json.baysData,
                vesselPartsData: json.vesselPartsData || [],
                sizeSummary: json.sizeSummary,
                masterCGs: json.shipData.masterCGs,
            });
            setAppTitle(`${getTranslation(TVDViewJsonComponent.optionsByViewType[viewType].h1Title, {
                organizationName: ((_d = ovdJsonStore.tvdId) === null || _d === void 0 ? void 0 : _d.organizationName) ||
                    securityModule.currentOrganizationName,
            })} : ${[json.shipData.shipName || "?", json.shipData.imoCode || ""].join(" : ")}`);
            // Nodes
            const titleWithActions = this.setHeaderInfo({
                viewType,
                version,
                lcgVcgTcgAndPairings,
            });
            this.titleWithActions = titleWithActions;
            removeChildren(this.titleNode);
            this.titleNode.appendChild(titleWithActions);
            if (!this.viewJsonRender) {
                // Initialize Renderer
                this.viewJsonRender = new ViewJsonRender({
                    json,
                    fileId,
                    fileOrgId,
                    currentOrgId: securityModule.currentOrganizationId,
                    canOpenDrawer: this.canOpenDrawer,
                    readonlyMode: this.readonlyMode,
                    viewType,
                    version,
                    fileTags: this.fileTags,
                    lcgVcgTcgAndPairings,
                    createEditButtonsAndActions: this.createEditButtonsAndActions,
                    colorMode: globalStore.resolvedColorMode,
                });
                this.renderViewNode.appendChild(this.viewJsonRender.node);
            }
            else {
                // Update Renderer
                this.viewJsonRender.updateData({
                    json,
                    event,
                    lcgVcgTcgAndPairings,
                });
            }
            if (!json.shipData.imoCode) {
                this.requestImoCode();
            }
            // DEBUGGING PURPOSES ONLY
            if (process.env.NX_PUBLIC_STAGE === "dev" ||
                process.env.NX_PUBLIC_STAGE === "alpha")
                window.xson = json;
        };
        this.showError = (e, errorStr = "errors:notValidStafFile") => {
            this.dialogError.show(getTranslation("errors:errorHasOcurred"), getTranslation(errorStr));
            console.error(e);
        };
        this.changeFileState = (s) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!((_a = ovdJsonStore.currentJson) === null || _a === void 0 ? void 0 : _a.shipData.imoCode)) {
                this.dialogError.show(getTranslation("view:imoCodeNeeded"), getTranslation("view:imoCodeNeededHelpText"));
                return;
            }
            const inputChangesMade = document.createElement("sl-textarea");
            inputChangesMade.label = getTranslation("view:explainModifications");
            inputChangesMade.rows = 3;
            const changeOkButton = document.createElement("sl-button");
            changeOkButton.slot = "footer";
            changeOkButton.innerHTML = getTranslation(`enums:FileStateActionEnum.${s}`);
            changeOkButton.variant = "primary";
            changeOkButton.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
                this.confirmChangeStateDialog.hide();
                const res = yield ovdJsonStore.setFileState(s, inputChangesMade.value);
                if ((res === null || res === void 0 ? void 0 : res.statusCode) !== 200) {
                    this.dialogError.show(getTranslation(`errors:errorHasOcurred`), (res === null || res === void 0 ? void 0 : res.message)
                        ? getTranslation(res.message)
                        : getTranslation(`errors:fileStateChangeError`));
                }
            }));
            if (!this.confirmChangeStateDialog) {
                this.confirmChangeStateDialog = document.createElement("sl-dialog");
                this.appendChild(this.confirmChangeStateDialog);
            }
            else {
                removeChildren(this.confirmChangeStateDialog);
            }
            this.confirmChangeStateDialog.label = getTranslation(s === FileStateEnum.DRAFT
                ? "view:fileStateChange.title"
                : "view:fileStateChange.titleForPublication");
            this.confirmChangeStateDialog.appendChild(changeOkButton);
            if (s !== FileStateEnum.DRAFT) {
                this.confirmChangeStateDialog.appendChild(inputChangesMade);
            }
            this.confirmChangeStateDialog.show();
        });
        this.notifyStateChanged = (ev) => __awaiter(this, void 0, void 0, function* () {
            const json = ovdJsonStore.currentJson;
            if (this.notifiedStateChanged || !json)
                return;
            const viewType = getViewTypesFromRouteKey(router.currentRouteKey);
            const version = (viewType === ViewJsonTypeEnum.VIEW_VERSION &&
                router.getRouteParams().version) ||
                undefined;
            if (this.titleWithActions) {
                const node = this.titleWithActions;
                const newNode = this.setHeaderInfo({
                    viewType,
                    version,
                    lcgVcgTcgAndPairings: getBayLcgVcgTcgAndPairings({
                        bls: json.baysData,
                        vesselPartsData: json.vesselPartsData || [],
                        sizeSummary: json.sizeSummary,
                        masterCGs: json.shipData.masterCGs,
                    }),
                });
                node.replaceWith(newNode);
            }
            if (ev.newState === ev.oldState || ev.oldState === "DRAFT")
                return;
            this.notifiedStateChanged = true;
            window.clearTimeout(this.intNotifiedStateChanged);
            this.intNotifiedStateChanged = window.setTimeout(() => {
                this.dialogError.show(getTranslation("general:common.attention"), getTranslation("view:notificationStateModified"));
            }, 500);
        });
        this.keepDrawerOpened = (isOpened) => {
            this.editDrawer.shouldNotClose = isOpened;
        };
        this.requestImoCode = () => __awaiter(this, void 0, void 0, function* () {
            globalStore.touchLastUserInteraction();
            const json = ovdJsonStore.currentJson;
            if (!json)
                return;
            let imoApiState = ImoFoundResultEnum.INITIALIZED;
            const dialog = createDialog();
            const imoCodeInput = document.createElement("sl-input");
            imoCodeInput.label = getTranslation("view:imoCode");
            imoCodeInput.id = "imoCode";
            const okButton = document.createElement("sl-button");
            okButton.slot = "footer";
            okButton.innerHTML = getTranslation("general:common.ok");
            okButton.variant = "primary";
            okButton.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                const imoCode = imoCodeInput.value;
                if (!imoCode)
                    return;
                okButton.disabled = true;
                okButton.loading = true;
                // Call Marine Traffic API ********************************************
                const nameInfo = yield Services.vesselsMasterData.searchVesselByImoCode(imoCode);
                if (((_a = nameInfo === null || nameInfo === void 0 ? void 0 : nameInfo.data) === null || _a === void 0 ? void 0 : _a.source) === "error") {
                    if (imoCode) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        imoApiState = ImoFoundResultEnum.NOT_FOUND_CONTINUE;
                        ovdJsonStore.setShipDataGeneralInfo(Object.assign(Object.assign({}, json.shipData), { featuresAllowed: [], imoCode }));
                    }
                    dialog.hide();
                }
                const match = (_b = nameInfo === null || nameInfo === void 0 ? void 0 : nameInfo.data) === null || _b === void 0 ? void 0 : _b.match;
                if (!match) {
                    createNotFoundImoDialog({
                        parentNode: this,
                        imoCode,
                        onButtonClicked: onClickNotFound,
                    });
                }
                else {
                    createFoundImoDialog({
                        parentNode: this,
                        imoCode,
                        lastFetched: String(match.modifiedAt),
                        btnSeeTvlSearchResults: undefined,
                        shipName: match.SHIPNAME,
                        onButtonClicked: onClickFound(match, json, imoCode, match.SHIPNAME),
                    });
                }
            }));
            dialog.appendChild(okButton);
            dialog.appendChild(imoCodeInput);
            this.appendChild(dialog);
            dialog.show();
            setTimeout(() => {
                imoCodeInput.focus();
            }, 500);
            function createDialog() {
                const dialog = document.createElement("sl-dialog");
                dialog.label = getTranslation("view:imoCodeNeeded");
                dialog.setAttribute("style", "--width: 400px");
                dialog.addEventListener("sl-request-close", (event) => {
                    const source = event.detail.source;
                    if (source === "overlay" || source === "close-button") {
                        event.preventDefault();
                    }
                });
                dialog.addEventListener("sl-after-hide", () => {
                    console.log("sl-after-hide");
                });
                return dialog;
            }
            function onClickNotFound(btnSelected) {
                switch (btnSelected) {
                    case "continue":
                        imoApiState = ImoFoundResultEnum.NOT_FOUND_CONTINUE;
                        dialog.hide();
                        break;
                    case "fix":
                        imoApiState = ImoFoundResultEnum.NOT_FOUND_FIX;
                        imoCodeInput.focus();
                        okButton.disabled = false;
                        okButton.loading = false;
                        break;
                }
            }
            function onClickFound(match, json, imoCode, vmdShipNameLatest) {
                return (btnSelected) => {
                    switch (btnSelected) {
                        case "continue":
                            imoApiState = ImoFoundResultEnum.FOUND_CONTINUE;
                            dialog.hide();
                            {
                                const lpp = Number(match.LPP || 0), loa = Number(match.LENGTH || 0);
                                ovdJsonStore
                                    .setShipDataGeneralInfo(Object.assign(Object.assign({}, json.shipData), { featuresAllowed: [], imoCode, callSign: match.CALLSIGN || "", shipName: match.SHIPNAME || "", yearBuilt: Number(match.BUILT || 0), lineOperator: match.MANAGER || "" }))
                                    .setCgOptions(ValuesSourceEnum.ESTIMATED, ValuesSourceEnum.ESTIMATED, ValuesSourceEnum.ESTIMATED, loa, 0, lpp);
                            }
                            break;
                        case "fix":
                            imoApiState = ImoFoundResultEnum.FOUND_FIX_IMO;
                            imoCodeInput.focus();
                            okButton.disabled = false;
                            okButton.loading = false;
                            break;
                        case "doubleCheck":
                            imoApiState = ImoFoundResultEnum.FOUND_MANUAL_SET;
                            Services.vesselsMasterData
                                .searchVesselByImoCode(imoCode || "", true)
                                .then((doucleCheckNameInfo) => {
                                var _a;
                                const newM = (_a = doucleCheckNameInfo === null || doucleCheckNameInfo === void 0 ? void 0 : doucleCheckNameInfo.data) === null || _a === void 0 ? void 0 : _a.match;
                                if (newM) {
                                    {
                                        const lpp = Number(newM.LPP || 0), loa = Number(newM.LENGTH || 0);
                                        ovdJsonStore
                                            .setShipDataGeneralInfo(Object.assign(Object.assign({}, json.shipData), { featuresAllowed: [], imoCode, callSign: newM.CALLSIGN || "", shipName: newM.SHIPNAME || "", yearBuilt: Number(newM.BUILT || 0), lineOperator: newM.MANAGER || "" }))
                                            .setCgOptions(ValuesSourceEnum.ESTIMATED, ValuesSourceEnum.ESTIMATED, ValuesSourceEnum.ESTIMATED, loa, 0, lpp);
                                    }
                                }
                                dialog.hide();
                            });
                            break;
                    }
                };
            }
        });
        this.createEditButtonsAndActions = (lcgVcgTcgAndPairings) => {
            const actions = {
                createLppEdit: () => {
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    const editForm = createLppEdit(json.shipData, this.editDrawer.submitButton);
                    const drawer = this.editDrawer.getEditDrawer({
                        title: getTranslation("view:edit.lpp"),
                        onSave: editForm.submitFunction,
                        readOnlyMode: this.readonlyMode,
                    });
                    drawer.appendChild(editForm.node);
                    drawer.show();
                },
                createMasterCGsEdit: () => {
                    var _a, _b;
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    if (!json.shipData.lcgOptions.lpp &&
                        !(((_b = (_a = json.shipData.lcgOptions) === null || _a === void 0 ? void 0 : _a.originalDataSource) === null || _b === void 0 ? void 0 : _b.reference) ===
                            LcgReferenceEnum.AFT_PERPENDICULAR)) {
                        this.dialogError.show(getTranslation("view:edit.masterCGs"), getTranslation("view:edit.noLppDefinedForCGs"));
                        return;
                    }
                    const editForm = createCGsMasterEdit(json.shipData, json.sizeSummary, this.editDrawer);
                    const drawer = this.editDrawer.getEditDrawer({
                        title: getTranslation("view:edit.masterCGs"),
                        onSave: editForm.submitFunction,
                        readOnlyMode: this.readonlyMode,
                    });
                    drawer.appendChild(editForm.node);
                    drawer.show();
                },
                createCGsEdit: () => {
                    var _a, _b;
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    if (ovdJsonStore.totalSlotsDefined === 0) {
                        this.dialogError.show(getTranslation("view:edit.bayLevelCGs"), getTranslation("view:edit.noSlotsDefinedForCGs"));
                        return;
                    }
                    else if (!json.shipData.lcgOptions.lpp &&
                        !(((_b = (_a = json.shipData.lcgOptions) === null || _a === void 0 ? void 0 : _a.originalDataSource) === null || _b === void 0 ? void 0 : _b.reference) ===
                            LcgReferenceEnum.AFT_PERPENDICULAR)) {
                        this.dialogError.show(getTranslation("view:edit.bayLevelCGs"), getTranslation("view:edit.noLppDefinedForCGs"));
                        return;
                    }
                    const editForm = createCGsEdit(json.shipData, json.sizeSummary, json.baysData, this.editDrawer);
                    const drawer = this.editDrawer.getEditDrawer({
                        title: getTranslation("view:edit.bayLevelCGs"),
                        onSave: editForm.submitFunction,
                        readOnlyMode: this.readonlyMode,
                    });
                    drawer.appendChild(editForm.node);
                    drawer.show();
                },
                viewMissingCGs: () => {
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    if (calculateMissingCGs(json).totalMissingCGs === 0) {
                        this.dialogError.show(getTranslation("view:edit.cgHelpers.viewMissingCGs.title"), getTranslation("view:edit.cgHelpers.viewMissingCGs.noMissingMinimumCGs"));
                        return;
                    }
                    const editForm = createMissingCGsDialog(json);
                    const drawer = this.editDrawer.getEditDrawer({
                        title: getTranslation("view:edit.cgHelpers.viewMissingCGs.title"),
                        onSave: () => true,
                        readOnlyMode: this.readonlyMode,
                    });
                    drawer.appendChild(editForm);
                    drawer.show();
                },
                removeCGs: () => {
                    const json = ovdJsonStore.currentJson;
                    const editForm = createRemoveCGsDialog(json);
                    const drawer = this.editDrawer.getEditDrawer({
                        title: getTranslation("view:importStaf.removeCGs"),
                        onSave: editForm.submitFunction,
                        readOnlyMode: this.readonlyMode,
                    });
                    drawer.appendChild(editForm.node);
                    drawer.show();
                },
                bulkheadsEdit: () => {
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    const editForm = createBulkheadsEdit(json.sizeSummary, json.baysData, this.editDrawer);
                    const drawer = this.editDrawer.getEditDrawer({
                        title: getTranslation("view:edit.bulkheads.title"),
                        onSave: editForm.submitFunction,
                        readOnlyMode: this.readonlyMode,
                    });
                    this.editDrawer.fixedScrollOverflow = true;
                    drawer.appendChild(editForm.node);
                    drawer.show();
                },
                bayPairingsEdit: () => {
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    const editForm = createPairingsEdit({
                        sizeSummary: json.sizeSummary,
                        baysData: json.baysData,
                        editDrawer: this.editDrawer,
                        usingAModal: this.keepDrawerOpened,
                    });
                    const drawer = this.editDrawer.getEditDrawer({
                        title: getTranslation("view:edit.pairings.title"),
                        onSave: editForm.submitFunction,
                        readOnlyMode: this.readonlyMode,
                        showUnits: false,
                    });
                    this.editDrawer.fixedScrollOverflow = true;
                    drawer.appendChild(editForm.node);
                    drawer.show();
                },
                parametrize3DView: () => {
                    var _a;
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    const editForm = create3DViewEdit({
                        sizeSummary: json.sizeSummary,
                        baysData: json.baysData,
                        shipData: json.shipData,
                        vesselPartsData: json.vesselPartsData || [],
                        v3DParams: (_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.v3DParams,
                        lcgVcgTcgAndPairings,
                        submitButton: this.editDrawer.submitButton,
                    });
                    if (editForm) {
                        const drawer = this.editDrawer.getEditDrawer({
                            title: getTranslation("view:view3D.parametrization"),
                            onSave: editForm.submitFunction,
                            readOnlyMode: this.readonlyMode,
                            showUnits: false,
                        });
                        drawer.appendChild(editForm.node);
                        drawer.show();
                    }
                },
                generalEdit: () => {
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    const editForm = createGeneralEdit(json.shipData, json.baysData, this.editDrawer.submitButton);
                    if (editForm) {
                        const drawer = this.editDrawer.getEditDrawer({
                            title: getTranslation("view:edit.shipData"),
                            onSave: editForm.submitFunction,
                            readOnlyMode: this.readonlyMode,
                        });
                        drawer.appendChild(editForm.node);
                        drawer.show();
                    }
                },
                sizeSummaryEdit: () => {
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    const editForm = createSizeEdit(json.sizeSummary, json.baysData, this.editDrawer.submitButton);
                    if (editForm) {
                        const drawer = this.editDrawer.getEditDrawer({
                            title: getTranslation("view:edit.size"),
                            onSave: editForm.submitFunction,
                            readOnlyMode: this.readonlyMode,
                        });
                        drawer.appendChild(editForm.node);
                        drawer.show();
                    }
                },
                editHatchCovers: () => {
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    const editForm = createLidsEdit({
                        sizeSummary: json.sizeSummary,
                        masterCGs: json.shipData.masterCGs,
                        baysData: json.baysData,
                        vesselPartsData: json.vesselPartsData || [],
                        lidsData: validateLidData(json.lidData),
                        lcgVcgTcgAndPairings,
                        usingAModal: this.keepDrawerOpened,
                        editDrawer: this.editDrawer,
                    });
                    const drawer = this.editDrawer.getEditDrawer({
                        title: getTranslation("view:edit.hatchCovers"),
                        onSave: editForm.submitFunction,
                        readOnlyMode: this.readonlyMode,
                    });
                    this.editDrawer.fixedScrollOverflow = true;
                    drawer.appendChild(editForm.node);
                    drawer.show();
                    drawer.addEventListener("sl-after-show", () => {
                        const mainPanel = drawer.drawer.querySelector("[part=body]");
                        if (!mainPanel)
                            return;
                        const hh = mainPanel.getBoundingClientRect();
                        mainPanel.style.height = `${hh.height}px`;
                        mainPanel.style.overflow = "hidden";
                    });
                },
                editVesselParts: () => {
                    var _a;
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    const editForm = create3DViewEdit({
                        sizeSummary: json.sizeSummary,
                        baysData: json.baysData,
                        shipData: json.shipData,
                        vesselPartsData: json.vesselPartsData || [],
                        v3DParams: (_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.v3DParams,
                        lcgVcgTcgAndPairings,
                        submitButton: this.editDrawer.submitButton,
                    });
                    if (editForm) {
                        const drawer = this.editDrawer.getEditDrawer({
                            title: getTranslation("view:view3D.parametrization"),
                            onSave: editForm.submitFunction,
                            readOnlyMode: this.readonlyMode,
                            showUnits: false,
                        });
                        drawer.appendChild(editForm.node);
                        drawer.show();
                    }
                },
                translateLCGs: () => {
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    if (generateTranslatedLCGsExampleData(json, 0).length === 0) {
                        this.dialogError.show(getTranslation("view:edit.cgHelpers.translateLCGs"), getTranslation("view:edit.helpers.noLcgsInFile"));
                        return;
                    }
                    const editForm = createTranslateLCGs(json);
                    const drawer = this.editDrawer.getEditDrawer({
                        title: getTranslation("view:edit.cgHelpers.translateLCGs"),
                        onSave: editForm.submitFunction,
                        readOnlyMode: this.readonlyMode,
                    });
                    drawer.appendChild(editForm.node);
                    drawer.show();
                },
                moveAll40s: () => {
                    const json = ovdJsonStore.currentJson;
                    if (!json)
                        return;
                    const editForm = createMoveAll40s(json);
                    const drawer = this.editDrawer.getEditDrawer({
                        title: getTranslation("view:edit.helpers.move40Definitions.moveAll40sInShip"),
                        onSave: editForm.submitFunction,
                        readOnlyMode: this.readonlyMode,
                    });
                    drawer.appendChild(editForm.node);
                    drawer.show();
                },
            };
            const nodesOptions = getGridNodesOptions();
            const editCGsButton = createMultiEditButton({
                id: `editCgs`,
                className: "bay-edit-btn",
                title: getTranslation("view:edit.cgs"),
                disabled: !this.canOpenDrawer,
                options: [
                    {
                        value: "editLpp",
                        name: getTranslation("view:edit.lpp"),
                        action: actions.createLppEdit,
                    },
                    { divider: true },
                    {
                        value: "editMasterCgs",
                        name: getTranslation("view:edit.masterCGs"),
                        action: actions.createMasterCGsEdit,
                    },
                    {
                        value: "editBayLevelCgs",
                        name: getTranslation("view:edit.bayLevelCGs"),
                        action: actions.createCGsEdit,
                    },
                    { divider: true },
                    {
                        value: "viewMissingCGs",
                        name: getTranslation("view:edit.cgHelpers.viewMissingCGs.title"),
                        action: actions.viewMissingCGs,
                    },
                    { divider: true },
                    {
                        value: "removeCGs",
                        name: getTranslation("view:importStaf.removeCGs"),
                        action: actions.removeCGs,
                    },
                ],
            });
            const editSideViewActions = createMultiEditButton({
                id: `sideViewActions`,
                className: "bay-edit-btn",
                title: getTranslation("view:edit.sideViewActions.title"),
                disabled: !this.canOpenDrawer,
                options: [
                    {
                        value: "bulkheadsEdit",
                        name: getTranslation("view:edit.bulkheads.title"),
                        action: actions.bulkheadsEdit,
                    },
                    {
                        value: "bayPairingsEdit",
                        name: getTranslation("view:edit.pairings.title"),
                        action: actions.bayPairingsEdit,
                    },
                    {
                        value: "parametrize3DView",
                        name: getTranslation("view:view3D.parametrization"),
                        action: actions.parametrize3DView,
                    },
                ],
            });
            const editGeneralButton = createEditButton({
                id: "editGeneral",
                pill: true,
                title: getTranslation("view:edit.shipData"),
                disabled: !this.canOpenDrawer,
                onClick: actions.generalEdit,
            });
            const editSizeSummaryButton = createEditButton({
                id: "editSize",
                pill: true,
                title: getTranslation("view:edit.size"),
                disabled: !this.canOpenDrawer,
                onClick: actions.sizeSummaryEdit,
            });
            const editDeckViewActions = createEditButton({
                id: "editHatchCovers",
                pill: true,
                disabled: !this.canOpenDrawer,
                title: getTranslation("view:edit.hatchCovers"),
                onClick: actions.editHatchCovers,
            });
            const editView3DActions = createEditButton({
                id: "edit3DParts",
                pill: true,
                title: getTranslation("view:view3D.parametrization"),
                disabled: !this.canOpenDrawer,
                onClick: actions.editVesselParts,
            });
            const bayHelpersButton = createMultiEditButton({
                id: `helpersBays`,
                title: getTranslation("general:common.helpers"),
                size: "small",
                variant: "primary",
                showTitleAsTooltip: false,
                iconName: "command",
                disabled: !this.canOpenDrawer,
                options: [
                    {
                        value: "translateLCGs",
                        name: getTranslation("view:edit.cgHelpers.translateLCGs"),
                        action: actions.translateLCGs,
                    },
                    {
                        value: "moveAll40s",
                        name: getTranslation("view:edit.helpers.move40Definitions.moveAll40sInShip"),
                        action: actions.moveAll40s,
                    },
                ],
            });
            const createEditBayButtonFunction = (isoBay, sizeSummary, shipData, bayAbove, bayBelow, enabledCells, slotsDataAbove, slotsDataBelow, createFnSlotCell) => {
                const editBayBtn = createMultiEditButton({
                    id: `editBay-${isoBay}`,
                    className: "bay-edit-btn",
                    disabled: !this.canOpenDrawer,
                    options: [
                        {
                            value: BayLevelEnum.ABOVE,
                            name: getTranslation(`enums:BayLevelEnum.${BayLevelEnum[BayLevelEnum.ABOVE]}`),
                            action: () => {
                                var _a;
                                const gridSize = {
                                    centerLineRow: (_a = bayAbove === null || bayAbove === void 0 ? void 0 : bayAbove.centerLineRow) !== null && _a !== void 0 ? _a : sizeSummary.centerLineRow,
                                    isoBays: sizeSummary.isoBays,
                                    maxAboveTier: sizeSummary.maxAboveTier,
                                    minAboveTier: sizeSummary.minAboveTier,
                                    maxRow: sizeSummary.maxRow,
                                };
                                openBayEdit({
                                    isoBay,
                                    level: BayLevelEnum.ABOVE,
                                    availableLengths: shipData.containersLengths,
                                    bayData: bayAbove,
                                    sizeSummary,
                                    shipData,
                                    editDrawer: this.editDrawer,
                                    readOnlyMode: this.readonlyMode,
                                    gridData: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ maxRow: sizeSummary.maxRow }, gridSize), { nodesOptions }), GRID_OPTIONS_BY_CELL_DIMENSION[SizeSmallMidLargeEnum.MIDDLE]), GRID_OPTIONS_BY_CELL_GAP[CellSpacingEnum.SPACED]), { enabledCells, cellsDrawFunction: createFnSlotCell(SizeSmallMidLargeEnum.MIDDLE), cellsToDraw: slotsDataAbove }),
                                });
                            },
                        },
                        {
                            value: BayLevelEnum.BELOW,
                            name: getTranslation(`enums:BayLevelEnum.${BayLevelEnum[BayLevelEnum.BELOW]}`),
                            action: () => {
                                var _a;
                                const gridSize = {
                                    centerLineRow: (_a = bayBelow === null || bayBelow === void 0 ? void 0 : bayBelow.centerLineRow) !== null && _a !== void 0 ? _a : sizeSummary.centerLineRow,
                                    isoBays: sizeSummary.isoBays,
                                    maxBelowTier: sizeSummary.maxBelowTier,
                                    minBelowTier: sizeSummary.minBelowTier,
                                    maxRow: sizeSummary.maxRow,
                                };
                                openBayEdit({
                                    isoBay,
                                    level: BayLevelEnum.BELOW,
                                    availableLengths: shipData.containersLengths,
                                    bayData: bayBelow,
                                    sizeSummary,
                                    shipData,
                                    editDrawer: this.editDrawer,
                                    readOnlyMode: this.readonlyMode,
                                    gridData: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ maxRow: sizeSummary.maxRow }, gridSize), { nodesOptions }), GRID_OPTIONS_BY_CELL_DIMENSION[SizeSmallMidLargeEnum.MIDDLE]), GRID_OPTIONS_BY_CELL_GAP[CellSpacingEnum.SPACED]), { enabledCells, cellsDrawFunction: createFnSlotCell(SizeSmallMidLargeEnum.MIDDLE), cellsToDraw: slotsDataBelow }),
                                });
                            },
                        },
                    ],
                });
                editBayBtn.setAttribute("part", "edit-btn");
                return editBayBtn;
            };
            return {
                editCGsButton,
                editSideViewActions,
                editGeneralButton,
                editSizeSummaryButton,
                editDeckViewActions,
                editView3DActions,
                bayHelpersButton,
                createEditBayButtonFunction,
            };
        };
        this.redrawBaysWhenDisplayPreferencesChange = (ev) => {
            if (!this.viewJsonRender)
                return;
            const detail = ev.detail;
            this.viewJsonRender.updateDrawPreferences(detail.newBaySize, detail.newCellSeparation);
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.mainNode = new DivWithSpinner();
        this.editDrawer = new EditDrawer();
        this.loadingIndicator = document.createElement("sl-spinner");
        this.showSmallLoadingInTitle = false;
        this.dialogHistory = document.createElement("sl-dialog");
        this.dialogError = new IntegratedDialogError(this);
        this.dialogTagFile = new IntegratedDialog({ parentNode: this });
        this.dialogFileNotes = new IntegratedDialog({ parentNode: this });
        const { planIsReadOnly } = securityModule;
        this.readonlyMode = planIsReadOnly;
        ovdJsonStore.readonlyMode = planIsReadOnly;
        this.titleNode = document.createElement("div");
        this.renderViewNode = document.createElement("div");
        securityModule
            .getOrganizationUsersBySub(securityModule.currentOrganizationId)
            .then((users) => {
            if (users)
                this.organizationUsersBySub = users;
        });
    }
    set showSmallLoadingInTitle(v) {
        this.loadingIndicator.style.display = v ? "inline-block" : "none";
    }
    connectedCallback() {
        const viewType = getViewTypesFromRouteKey(router.currentRouteKey);
        if (viewType === undefined) {
            router.navigate(routeFns.myCloud());
            return;
        }
        const optionsByViewType = TVDViewJsonComponent.optionsByViewType[viewType];
        goSquared.trackPageAndEvent(optionsByViewType.pageTrackTitle, `${optionsByViewType.eventTrackPrefix} - Show page`);
        this.dialogHistory.setAttribute("style", "--width: 80vw");
        this.dialogHistory.label = getTranslation("view:edit.fileHistory.title");
        this.dialogFileNotes.updateOptions({ width: "80vw" });
        const state = router.currentState;
        this.showSmallLoadingInTitle = false;
        // Back to top
        const backToTop = createBackToTopButton(this.titleNode);
        // Append Nodes
        this.mainNode.appendChild(this.titleNode);
        this.mainNode.appendChild(this.renderViewNode);
        this.appendChild(this.editDrawer.drawer);
        this.appendChild(this.mainNode);
        this.appendChild(this.dialogHistory);
        this.mainNode.appendChild(backToTop);
        // Translations
        const topEle = topMessageElement.element;
        if (topEle)
            this.i18nCR.addConsumer(topEle, optionsByViewType.h1Title, "innerHTML", {
                organizationName: (state === null || state === void 0 ? void 0 : state.organizationName) || securityModule.currentOrganizationName,
            });
        // In case a previous session was opened
        this.editDrawer.drawer.hide();
        // Listeners ******
        this.attachDetachEvents(true);
        // Act
        this.handleData(state, viewType);
        function createBackToTopButton(titleNode) {
            const backToTop = document.createElement("sl-button");
            const icon = document.createElement("sl-icon");
            icon.name = "arrow-up";
            icon.slot = "prefix";
            backToTop.appendChild(icon);
            backToTop.variant = "primary";
            backToTop.size = "small";
            backToTop.pill = true;
            backToTop.className = "back-to-top";
            backToTop.addEventListener("click", () => {
                window.scrollTo(0, 0);
            });
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    backToTop.style.marginTop = "calc(100vh + 100px)";
                }
                else {
                    backToTop.style.marginTop = "auto";
                }
            });
            observer.observe(titleNode);
            return backToTop;
        }
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
        this.attachDetachEvents(false);
    }
    handleData(state, viewType) {
        return __awaiter(this, void 0, void 0, function* () {
            const isViewMode = viewType !== ViewJsonTypeEnum.EDIT;
            if (state) {
                ovdJsonStore.tvdId = undefined;
                this.readonlyMode = isViewMode || securityModule.planIsReadOnly;
                ovdJsonStore.readonlyMode =
                    securityModule.planIsReadOnly || (isViewMode && !!state.impersonate);
                const version = (viewType === ViewJsonTypeEnum.VIEW_VERSION &&
                    router.getRouteParams().version) ||
                    undefined;
                switch (state.source) {
                    case "ovdJson":
                        this.mainNode.setLoading(true);
                        Services.ovdJson.readFile
                            .readOVDJsonFile(state.file)
                            .then((json) => __awaiter(this, void 0, void 0, function* () {
                            yield ovdJsonStore
                                .setJson(state.filenameParts.name, json)
                                .saveToCloud("OpenOVD");
                            const tvdId = ovdJsonStore.tvdId;
                            if (tvdId !== undefined) {
                                this.mainNode.setLoading(false);
                                router.navigate(routeFns.ovdEdit(tvdId.fileId), undefined, true);
                            }
                        }))
                            .catch((e) => {
                            this.showError(e, e.message || "errors:notValidOvdFile");
                            this.mainNode.setLoading(false);
                            if (e.statusCode === 402)
                                router.navigate(routeFns.myCloud());
                        });
                        break;
                    case "stafText":
                        this.mainNode.setLoading(true);
                        try {
                            const formFields = state.formFields;
                            const jsonConv = yield Services.importStaf.stafFileConvertToOVD(state.file, VCG_REF_TO_FACTOR[formFields.vcgRef], formFields.lpp, formFields.aboveTier82is);
                            const json = formFields.removeCGs
                                ? removeCGsfromJson(jsonConv)
                                : jsonConv;
                            yield ovdJsonStore
                                .setJson(formFields.shipName, json)
                                .setShipDataGeneralInfo(Object.assign(Object.assign({}, json.shipData), { shipClass: formFields.shipClass, shipName: formFields.shipName, lineOperator: formFields.lineOperator, imoCode: formFields.imoCode, yearBuilt: formFields.yearBuilt, callSign: formFields.callSign, loa: formFields.loa, featuresAllowed: undefined }), false)
                                .saveToCloud("ImportSTAF", state.vmdShipNameLatest);
                            const tvdId = ovdJsonStore.tvdId;
                            if (tvdId !== undefined) {
                                this.mainNode.setLoading(false);
                                router.navigate(routeFns.ovdEdit(tvdId.fileId), undefined, true);
                            }
                        }
                        catch (e) {
                            this.showError(e, e.message || "errors:notValidStafFile");
                            if (e.statusCode === 402)
                                router.navigate(routeFns.myCloud());
                        }
                        break;
                    case "newOvd":
                        try {
                            ovdJsonStore
                                .setJson(state.filenameParts.name, state.json)
                                .saveToCloud("NewOVD", state.vmdShipNameLatest)
                                .then(() => {
                                if (ovdJsonStore.tvdId) {
                                    router.navigate(routeFns.ovdEdit(ovdJsonStore.tvdId.fileId), undefined, true);
                                }
                            });
                        }
                        catch (e) {
                            this.showError(e, e.message || "errors:notValidOvdFile");
                            if (e.statusCode === 402)
                                router.navigate(routeFns.myCloud());
                        }
                        this.showData();
                        break;
                    case "cloudOvdJson":
                        yield getAndShowOvdFromCloud({
                            cloudId: state.cloudId,
                            version,
                            organizationId: state.organizationId,
                            isViewMode,
                            mainNode: this.mainNode,
                            onSuccess: () => this.showData(),
                        });
                        break;
                }
            }
            else {
                const cloudId = router.getRouteParams().id;
                if (!cloudId) {
                    router.navigate(routeFns.myCloud());
                    return;
                }
                yield getAndShowOvdFromCloud({
                    cloudId,
                    version: undefined,
                    organizationId: undefined,
                    isViewMode,
                    mainNode: this.mainNode,
                    onSuccess: () => this.showData(),
                });
            }
            function getAndShowOvdFromCloud(_a) {
                return __awaiter(this, arguments, void 0, function* ({ cloudId, version, organizationId, isViewMode, mainNode, onSuccess, }) {
                    var _b;
                    try {
                        goSquared.addEvent(`${TVDViewJsonComponent.optionsByViewType[viewType].eventTrackPrefix} - Load from cloud`);
                        const dataFromCloud = yield mainNode.withLoading(() => getCloudOvdJson(cloudId, organizationId, version));
                        if (dataFromCloud) {
                            ovdJsonStore.readonlyMode =
                                securityModule.planIsReadOnly ||
                                    (isViewMode && !!(state === null || state === void 0 ? void 0 : state.impersonate));
                            ovdJsonStore.setJson(dataFromCloud.name, dataFromCloud.json);
                            ovdJsonStore.tvdId = {
                                fileId: cloudId,
                                userSub: dataFromCloud.userSub,
                                organizationId: dataFromCloud.organizationId,
                                organizationName: (state === null || state === void 0 ? void 0 : state.organizationName) || securityModule.currentOrganizationName,
                                fileState: dataFromCloud.fileState,
                                createdAt: dataFromCloud.createdAt,
                                updatedAt: dataFromCloud.lastModified,
                                updatedBy: dataFromCloud.lastModifiedBy,
                                fromBvoName: dataFromCloud.fromBvoName,
                                lastComment: dataFromCloud.lastComment,
                                shipNameAkaStr: dataFromCloud.shipNameAkas,
                            };
                            if (!((_b = dataFromCloud.json.shipData.lcgOptions) === null || _b === void 0 ? void 0 : _b.lpp))
                                setLcgRefViewOptionsToAftPerpFwd();
                            onSuccess();
                        }
                        else {
                            throw new Error("File not found");
                        }
                    }
                    catch (e) {
                        console.error(e);
                        document.body.dispatchEvent(new CustomEvent("customError", {
                            detail: {
                                errorCode: getTranslation(e.message),
                                message: "errors:errorHasOcurred",
                                translationKey: getTranslation(e.message),
                            },
                        }));
                        router.navigate(routeFns.myCloud());
                    }
                });
            }
        });
    }
    setHeaderInfo({ viewType, version, lcgVcgTcgAndPairings, }) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        const fileName = String(ovdJsonStore.filenameSanitized);
        const currentFileState = ((_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.fileState) || "DRAFT";
        const titleWithActions = document.createElement("title-with-actions-component");
        titleWithActions.titleNodeName = "h1";
        if (viewType === ViewJsonTypeEnum.VIEW_VERSION && version) {
            const fragment = document.createElement("span");
            const text = document.createTextNode((((_b = ovdJsonStore.currentJson) === null || _b === void 0 ? void 0 : _b.shipData.shipName) || fileName) + "  ");
            fragment.appendChild(text);
            titleWithActions.setHeadingNode(fragment);
            const fileId = ((_c = ovdJsonStore.tvdId) === null || _c === void 0 ? void 0 : _c.fileId) || "";
            if (fileId)
                Services.files
                    .getFileVersions(((_d = ovdJsonStore.tvdId) === null || _d === void 0 ? void 0 : _d.organizationId) ||
                    securityModule.currentOrganizationId, fileId)
                    .then((versionsResponse) => {
                    const select = createVersionsDropdown({
                        selectedVersion: version,
                        addCurrentVersion: true,
                        versionsResponse,
                        onChange: (v, prev) => __awaiter(this, void 0, void 0, function* () {
                            var _a;
                            if (v === prev)
                                return;
                            if (v) {
                                router.navigate(routeFns.ovdViewOnlyVersion(fileId, encodeURI(v)), {
                                    organizationId: (_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.organizationId,
                                    cloudId: fileId,
                                    source: "cloudOvdJson",
                                });
                            }
                            else {
                                router.navigate(routeFns.ovdEdit(fileId));
                            }
                        }),
                    });
                    if (select)
                        fragment.appendChild(select);
                });
        }
        else {
            titleWithActions.titleHtml =
                ((_e = ovdJsonStore.currentJson) === null || _e === void 0 ? void 0 : _e.shipData.shipName) || fileName;
        }
        const actionsNode = document.createElement("div");
        actionsNode.slot = "actions";
        actionsNode.className = "slot-actions";
        // Loading Indicator
        actionsNode.appendChild(this.loadingIndicator);
        this.loadingIndicator.style.setProperty("--size", "0.5rem");
        // Read Only Badge
        if (this.readonlyMode) {
            const readonlyBadge = document.createElement("sl-badge");
            readonlyBadge.variant = "danger";
            readonlyBadge.pulse = true;
            readonlyBadge.innerHTML = getTranslation("general:common.readOnly");
            actionsNode.appendChild(readonlyBadge);
        }
        // File tags
        if (viewType === ViewJsonTypeEnum.EDIT &&
            securityModule.currentOrganizationId ===
                ((_f = ovdJsonStore.tvdId) === null || _f === void 0 ? void 0 : _f.organizationId)) {
            const btnFileTags = document.createElement("sl-button");
            btnFileTags.size = "small";
            btnFileTags.pill = true;
            btnFileTags.variant = "primary";
            btnFileTags.title = getTranslation("general:fileTags.tags");
            btnFileTags.outline = true;
            btnFileTags.disabled = true;
            btnFileTags.addEventListener("click", () => {
                var _a, _b;
                createModalToTagFiles({
                    fileId: (_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.fileId,
                    fileName: ((_b = ovdJsonStore.currentJson) === null || _b === void 0 ? void 0 : _b.shipData.shipName) || fileName,
                    modal: this.dialogTagFile,
                    filesTags: this.fileTags,
                    onUpdated: (fileTags) => {
                        if (fileTags)
                            this.fileTags = fileTags;
                        btnFileTags.outline = false;
                        window.setTimeout(() => {
                            btnFileTags.outline = true;
                        }, 250);
                    },
                });
            });
            const icon = document.createElement("sl-icon");
            icon.name = "tags";
            icon.slot = "prefix";
            btnFileTags.appendChild(icon);
            const tooltip = document.createElement("sl-tooltip");
            tooltip.content = getTranslation("general:fileTags.tags");
            tooltip.appendChild(btnFileTags);
            actionsNode.appendChild(tooltip);
            Services.organizations.getFileTags().then((ft) => {
                if (ft.data)
                    this.fileTags = ft.data;
                btnFileTags.disabled = false;
            });
        }
        // File NOTES
        if (viewType === ViewJsonTypeEnum.EDIT &&
            securityModule.currentOrganizationId ===
                ((_g = ovdJsonStore.tvdId) === null || _g === void 0 ? void 0 : _g.organizationId)) {
            const btnFileNotes = document.createElement("sl-button");
            btnFileNotes.size = "small";
            btnFileNotes.pill = true;
            btnFileNotes.variant = "primary";
            btnFileNotes.title = getTranslation("general:fileNotes.fileNotes");
            btnFileNotes.outline = true;
            btnFileNotes.addEventListener("click", () => {
                var _a, _b, _c;
                createModalToForNotes({
                    organizationId: (_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.organizationId,
                    fileId: (_b = ovdJsonStore.tvdId) === null || _b === void 0 ? void 0 : _b.fileId,
                    fileName: ((_c = ovdJsonStore.currentJson) === null || _c === void 0 ? void 0 : _c.shipData.shipName) || fileName,
                    modal: this.dialogFileNotes,
                    organizationUsersBySub: this.organizationUsersBySub,
                    onUpdated: () => {
                        btnFileNotes.outline = false;
                        window.setTimeout(() => {
                            btnFileNotes.outline = true;
                        }, 250);
                    },
                });
            });
            const icon = document.createElement("sl-icon");
            icon.name = "stickies";
            icon.slot = "prefix";
            btnFileNotes.appendChild(icon);
            const tooltip = document.createElement("sl-tooltip");
            tooltip.content = getTranslation("general:fileNotes.fileNotes");
            tooltip.appendChild(btnFileNotes);
            actionsNode.appendChild(tooltip);
        }
        // File History button. Only for same OrgId or Tedivo Admin. Double checked on Server Side
        if (viewType === ViewJsonTypeEnum.EDIT &&
            (securityModule.currentOrganizationId ===
                ((_h = ovdJsonStore.tvdId) === null || _h === void 0 ? void 0 : _h.organizationId) ||
                securityModule.userIsTedivoAdmin)) {
            const historyAndVersionsBtn = createFileHistoryAndVersionsButton(this.dialogHistory, false);
            const btnTvlMap = document.createElement("sl-button");
            //btnTvlMap.innerHTML = getTranslation("view:fileMap.tvlMap");
            btnTvlMap.size = "small";
            btnTvlMap.pill = true;
            btnTvlMap.variant = "primary";
            btnTvlMap.title = getTranslation("view:fileMap.tvlMap");
            btnTvlMap.outline = true;
            btnTvlMap.addEventListener("click", () => {
                var _a, _b;
                const fileId = ((_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.fileId) || "";
                const fileOrgId = ((_b = ovdJsonStore.tvdId) === null || _b === void 0 ? void 0 : _b.organizationId) || "";
                router.navigate(routeFns.fileMap(fileId), {
                    organizationId: fileOrgId,
                });
            });
            const icon = document.createElement("sl-icon");
            icon.name = "geo";
            icon.slot = "prefix";
            btnTvlMap.appendChild(icon);
            const tooltip = document.createElement("sl-tooltip");
            tooltip.content = getTranslation("view:fileMap.tvlMap");
            tooltip.appendChild(btnTvlMap);
            actionsNode.appendChild(tooltip);
            if (historyAndVersionsBtn)
                actionsNode.appendChild(historyAndVersionsBtn);
        }
        // Download JSON button
        const ovdJsonDownloadBtn = createDownloadOVDJsonLink(viewType, ((_j = ovdJsonStore.tvdId) === null || _j === void 0 ? void 0 : _j.organizationId) || "", ((_k = ovdJsonStore.tvdId) === null || _k === void 0 ? void 0 : _k.fileId) || "", currentFileState, lcgVcgTcgAndPairings.cgsStats);
        if (ovdJsonDownloadBtn)
            actionsNode.appendChild(ovdJsonDownloadBtn);
        // Vote Button (only in Read Only Mode and different Organization ID)
        if (viewType === ViewJsonTypeEnum.VIEW_OVD &&
            this.readonlyMode &&
            securityModule.currentOrganizationId !==
                ((_l = ovdJsonStore.tvdId) === null || _l === void 0 ? void 0 : _l.organizationId)) {
            const btnUpVote = document.createElement("tvd-vote-tvl-component");
            btnUpVote.fileId = ((_m = ovdJsonStore.tvdId) === null || _m === void 0 ? void 0 : _m.fileId) || "";
            btnUpVote.fileOrgId = ((_o = ovdJsonStore.tvdId) === null || _o === void 0 ? void 0 : _o.organizationId) || "";
            actionsNode.appendChild(btnUpVote);
        }
        // File State Button. Only for same OrgId or Tedivo Admin
        if (viewType === ViewJsonTypeEnum.EDIT &&
            (securityModule.currentOrganizationId ===
                ((_p = ovdJsonStore.tvdId) === null || _p === void 0 ? void 0 : _p.organizationId) ||
                securityModule.userIsTedivoAdmin)) {
            const btnFileStatePill = createFileStateButton(FileStateEnum[currentFileState], calculateFileStatePermissions(), this.changeFileState, this.readonlyMode);
            actionsNode.appendChild(btnFileStatePill);
        }
        if (viewType === ViewJsonTypeEnum.VIEW_VERSION &&
            (securityModule.currentOrganizationId ===
                ((_q = ovdJsonStore.tvdId) === null || _q === void 0 ? void 0 : _q.organizationId) ||
                securityModule.userIsTedivoAdmin)) {
            const icon = document.createElement("sl-icon");
            icon.name = "code-slash";
            icon.slot = "prefix";
            const btnCompare = document.createElement("sl-button");
            btnCompare.innerHTML = getTranslation("view:comparer.compareOvd");
            btnCompare.size = "small";
            btnCompare.pill = true;
            btnCompare.variant = "primary";
            btnCompare.title = getTranslation("view:comparer.compareOvd");
            btnCompare.outline = true;
            btnCompare.appendChild(icon);
            btnCompare.addEventListener("click", () => {
                var _a, _b;
                const fileId = ((_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.fileId) || "";
                const fileOrgId = ((_b = ovdJsonStore.tvdId) === null || _b === void 0 ? void 0 : _b.organizationId) || "";
                const version = router.getRouteParams().version;
                if (!version)
                    return;
                const route = routeFns.fileCompareOwnVersion(fileId, version);
                router.navigate(route, { organizationId: fileOrgId });
            });
            actionsNode.appendChild(btnCompare);
        }
        titleWithActions.appendChild(actionsNode);
        return titleWithActions;
    }
}
TVDViewJsonComponent.observedAttributes = [];
TVDViewJsonComponent.optionsByViewType = {
    [ViewJsonTypeEnum.EDIT]: {
        pageTrackTitle: "Define OVD",
        eventTrackPrefix: "Edit-OVD",
        h1Title: "view:pageTitleEdit",
    },
    [ViewJsonTypeEnum.VIEW_OVD]: {
        pageTrackTitle: "View TVL OVD",
        eventTrackPrefix: "VIEW-OVD",
        h1Title: "view:pageTitleView",
    },
    [ViewJsonTypeEnum.VIEW_VERSION]: {
        pageTrackTitle: "View OVD own version",
        eventTrackPrefix: "VIEW-OWN-OVD-VERSION",
        h1Title: "view:pageTitleViewVersion",
    },
};
customElements.define("tvd-view-json-component", TVDViewJsonComponent);
function setLcgRefViewOptionsToAftPerpFwd() {
    globalUnits.setLcgRef(UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD);
    document.body.dispatchEvent(new CustomEvent("globalUnitsChanged"));
}
function getCloudOvdJson(id, orgId, version) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        if (version) {
            const { statusCode, data } = version && orgId
                ? yield Services.files.getFileVersion(orgId, id, version)
                : { statusCode: 404, data: undefined };
            if (statusCode !== 200 || data === undefined) {
                if (statusCode === 402) {
                    throw new Error("errors:planExpired");
                }
                else {
                    throw new Error("errors:fileNotFound");
                }
            }
            return {
                json: data.data,
                name: data.details.name,
                userSub: "",
                organizationId: data.organizationId,
                fileState: data.details.state,
                createdAt: new Date(data.date),
                lastModified: new Date(data.date),
                lastModifiedBy: data.details.lastAuthor,
                fromBvoName: "",
                lastComment: data.details.comments,
                shipNameAkas: "",
            };
        }
        else {
            const { statusCode, data, message } = yield (orgId
                ? Services.files.getAdmin(id, orgId)
                : Services.files.get(id));
            if (statusCode !== 200 || data === undefined)
                throw new Error(message);
            const json = data.data;
            const name = (_b = (_a = json.shipData) === null || _a === void 0 ? void 0 : _a.shipName) !== null && _b !== void 0 ? _b : "";
            return {
                json,
                name,
                userSub: data.userSub,
                organizationId: data.organizationId,
                fileState: data.state,
                createdAt: data.createdAt ? new Date(data.createdAt) : undefined,
                lastModified: new Date(data.lastModified),
                lastModifiedBy: data.lastAuthor,
                fromBvoName: data.fromBvoName,
                lastComment: data.lastComment,
                shipNameAkas: data.shipNameAkas || "",
            };
        }
    });
}
function createFileStateButton(currentState, publishRights, cb, readonlyMode) {
    const availableOptions = [];
    if (!readonlyMode) {
        if (currentState !== FileStateEnum.DRAFT &&
            publishRights.canChangeStateDraft)
            availableOptions.push({
                name: getTranslation(`view:fileStateChange.from.${currentState}.${FileStateEnum.DRAFT}`),
                value: FileStateEnum.DRAFT,
            });
        if (currentState !== FileStateEnum.ORG_READY &&
            publishRights.canChangeStatePublished)
            availableOptions.push({
                name: getTranslation(`view:fileStateChange.from.${currentState}.${FileStateEnum.ORG_READY}`),
                value: FileStateEnum.ORG_READY,
            });
        if (currentState !== FileStateEnum.COMMUNITY &&
            publishRights.canChangeStateCommunity)
            availableOptions.push({
                name: getTranslation(`view:fileStateChange.from.${currentState}.${FileStateEnum.COMMUNITY}`),
                value: FileStateEnum.COMMUNITY,
            });
    }
    const changeStateSelect = createSelectShoelace({
        id: "fileStateBtn",
        caret: false,
        pill: true,
        ommitCheckSign: true,
        size: "small",
        variant: "neutral",
        disabled: availableOptions.length === 0,
        selectedValue: currentState,
        buttonText: getTranslation(`enums:FileStateEnum.${currentState}`),
        title: getTranslation("general:fileState"),
        options: availableOptions,
        onChange: (v) => __awaiter(this, void 0, void 0, function* () {
            cb(v);
        }),
        onNodesCreated: (_, button) => {
            const icon = document.createElement("sl-icon");
            icon.name = "info-circle";
            icon.slot = "prefix";
            button.appendChild(icon);
        },
    });
    return changeStateSelect;
}
function createFileHistoryAndVersionsButton(dialogHistory, onlyIcon = false) {
    const tvdId = ovdJsonStore.tvdId;
    if (!tvdId)
        return undefined;
    const icon = document.createElement("sl-icon");
    icon.name = "clock-history";
    icon.slot = "prefix";
    const fileHistoryBtn = document.createElement("sl-button");
    fileHistoryBtn.pill = true;
    fileHistoryBtn.variant = "primary";
    fileHistoryBtn.outline = true;
    fileHistoryBtn.size = "small";
    fileHistoryBtn.addEventListener("click", () => {
        removeChildren(dialogHistory);
        const node = showFileHistory(tvdId);
        if (node)
            dialogHistory.appendChild(node);
        dialogHistory.show();
    });
    if (!onlyIcon) {
        fileHistoryBtn.innerHTML = getTranslation("view:edit.fileHistory.title");
        fileHistoryBtn.appendChild(icon);
        return fileHistoryBtn;
    }
    else {
        const tooltip = document.createElement("sl-tooltip");
        tooltip.content = getTranslation("view:edit.fileHistory.title");
        tooltip.appendChild(fileHistoryBtn);
        fileHistoryBtn.appendChild(icon);
        return tooltip;
    }
}
function calculateFileStatePermissions() {
    return {
        canChangeStateDraft: securityModule.userHasPermission(systemRights.USER.ChangeFileStatusToDraft),
        canChangeStatePublished: securityModule.userHasPermission(systemRights.ORG.PublishOrganizationFile) &&
            securityModule.userHasPermission(systemRights.USER.ChangeFileStatusToOrganization),
        canChangeStateCommunity: securityModule.userHasPermission(systemRights.ORG.PublishCommunityFile) &&
            securityModule.userHasPermission(systemRights.USER.ChangeFileStatusToCommunity),
    };
}
