import { BayLevelEnum, TBayRowInfo } from "open-vessel-definition";
import {
  EnumHelpers,
  pad3,
  sortNumericAsc,
} from "@baplie-viewer2/tedivo-pure-helpers";
import {
  IBayPattern,
  IRowPattern,
  getSizesFromSlots,
} from "tedivo-bay-grid-pure";
import {
  IFields,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";
import {
  IOpenVesselDefinitionV1,
  TContainerLengths,
} from "open-vessel-definition";
import {
  getPreferencesValue,
  setPreferencesKeyAndValue,
} from "@baplie-viewer2/tedivo-preferences";

import FieldsValuesStore from "../../../../../../../app/stores/FieldsValuesStore";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import { getTranslation } from "../../../../../../../app/i18/i18tn";
import ovdJsonStore from "../../../../../../../app/stores/OVDJsonStore";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { z } from "zod";

export enum CopyValuesEnum {
  ONLY_EMPTIES = 1,
  YES = 5,
  NO = 10,
}

export interface ICopyBayCGsForm extends Record<string, unknown> {
  fromBay: string;
  copyTcgs: CopyValuesEnum;
  copyBottomBase: CopyValuesEnum;
  copyLcgs: CopyValuesEnum;
  copyMaxHeight: CopyValuesEnum;
  copyStackWeight: CopyValuesEnum;
  copyBottomWeight: CopyValuesEnum;
}

export function createCopyBayCGsDialog(
  helpersCommonDialog: SlDialog,
  currentBay: number,
  currentLevel: BayLevelEnum,
  json: IOpenVesselDefinitionV1 | undefined,
  dataStore: FieldsValuesStore,
): boolean {
  if (!json) return false;

  const sizeSummary = json.sizeSummary;

  removeChildren(helpersCommonDialog);

  helpersCommonDialog.label = getTranslation("view:edit.cgHelpers.copyBayCGs");
  helpersCommonDialog.setAttribute("style", "--width: 900px");

  const btn = document.createElement("sl-button");
  btn.innerHTML = getTranslation("general:common.ok");
  btn.slot = "footer";
  btn.variant = "primary";
  btn.type = "submit";
  btn.addEventListener("click", doTheCopy, false);

  const options: { value: string; label: string }[] = [];
  const bayLabel = getTranslation("general:grid.bay");

  for (let b = 1; b <= sizeSummary.isoBays; b += 2) {
    if (
      sizeSummary.minAboveTier !== undefined &&
      sizeSummary.maxAboveTier !== undefined
    )
      options.push({
        value: `${b}-${BayLevelEnum.ABOVE}`,
        label: `${bayLabel} ${pad3(b)}, ${getTranslation(
          "enums:BayLevelEnum.ABOVE",
        )}`,
      });

    if (
      sizeSummary.minBelowTier !== undefined &&
      sizeSummary.maxBelowTier !== undefined
    )
      options.push({
        value: `${b}-${BayLevelEnum.BELOW}`,
        label: `${bayLabel} ${pad3(b)}, ${getTranslation(
          "enums:BayLevelEnum.BELOW",
        )}`,
      });
  }

  const defaultOption = `${
    currentBay === 1 ? 3 : currentBay - 2
  }-${currentLevel}`;

  const copyValuesEnumOptions = EnumHelpers.getNamesAndValues(
    CopyValuesEnum,
  ).map(({ value, name }) => ({
    value,
    label: getTranslation(`enums:CopyValuesEnum.${name}`),
  }));

  const initalValues = JSON.parse(
    (getPreferencesValue("cgHelpers-copyBayCGs") as string) || "{}",
  ) as ICopyBayCGsForm;

  const fields: IFields<ICopyBayCGsForm> = [
    {
      name: "fromBay",
      type: "select",
      label: "view:edit.helpers.fromBay",
      options,
      initialValue: defaultOption,
    },
    [
      {
        name: "copyTcgs",
        type: "radioButtonList",
        label: "view:edit.helpers.copyTcgs",
        fieldset: true,
        options: copyValuesEnumOptions,
        initialValue: initalValues?.copyTcgs || "1",
      },
      {
        name: "copyBottomBase",
        type: "radioButtonList",
        label: "view:edit.helpers.copyBottomBase",
        fieldset: true,
        options: copyValuesEnumOptions,
        initialValue: initalValues?.copyBottomBase || "1",
      },
      {
        name: "copyMaxHeight",
        type: "radioButtonList",
        label: "view:edit.helpers.copyMaxHeight",
        fieldset: true,
        options: copyValuesEnumOptions,
        initialValue: initalValues?.copyMaxHeight || "1",
      },
    ],
    [
      {
        name: "copyLcgs",
        type: "radioButtonList",
        label: "view:edit.helpers.copyLcgs",
        fieldset: true,
        options: copyValuesEnumOptions,
        initialValue: initalValues?.copyLcgs || "1",
      },
      {
        name: "copyStackWeight",
        type: "radioButtonList",
        label: "view:edit.helpers.copyStackWeight",
        fieldset: true,
        options: copyValuesEnumOptions,
        initialValue: initalValues?.copyStackWeight || "1",
      },
      {
        name: "copyBottomWeight",
        type: "radioButtonList",
        label: "view:edit.helpers.copyBottomWeight",
        fieldset: true,
        options: copyValuesEnumOptions,
        initialValue: initalValues?.copyBottomWeight || "1",
      },
    ],
  ];

  const validator: z.Schema<ICopyBayCGsForm> = z.object({
    fromBay: z.string(),
    copyTcgs: z.number(),
    copyBottomBase: z.number(),
    copyLcgs: z.number(),
    copyMaxHeight: z.number(),
    copyStackWeight: z.number(),
    copyBottomWeight: z.number(),
  });

  const tedivoForm = new TedivoForm<ICopyBayCGsForm>({
    fields,
    onSubmit: () => null,
    formValidator: validator,
    submitButton: btn,
  });

  translateTedivoForm<ICopyBayCGsForm>({ tedivoForm, getTranslation });

  helpersCommonDialog.appendChild(tedivoForm.form);
  helpersCommonDialog.appendChild(btn);

  return true;

  function doTheCopy() {
    const options = tedivoForm.getValues();

    doPasteValuesToMemory(
      options,
      ovdJsonStore.currentJson,
      dataStore,
      pad3(currentBay),
      currentLevel,
    );

    helpersCommonDialog.hide();
    dataStore.onChange?.();
    dataStore.updateConsumers();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { fromBay, ...copyOptions } = options;
    setPreferencesKeyAndValue("cgHelpers-copyBayCGs", copyOptions);
  }
}

function doPasteValuesToMemory(
  options: ICopyBayCGsForm,
  json: IOpenVesselDefinitionV1 | undefined,
  dataStore: FieldsValuesStore,
  currentBay: IBayPattern,
  currentLevel: BayLevelEnum,
) {
  if (!json) return false;

  const [bay, level] = options.fromBay.split("-").map(Number);
  const isoBay = pad3(bay);

  const targetBaseKey = `b${currentBay}-${currentLevel}`;
  const sourceBaseKey = `b${isoBay}-${level}`;

  const copyFromBayData = json.baysData.find(
    (bd) => bd.isoBay === isoBay && bd.level === level,
  );

  const targetBayData = json.baysData.find(
    (bd) => bd.isoBay === currentBay && bd.level === currentLevel,
  );

  // 1. Common
  const common = copyFromBayData?.perRowInfo?.common;

  doPasteValue(
    `${targetBaseKey}.common.maxHeight`,
    dataStore.getValueAsNumber(`${sourceBaseKey}.common.maxHeight`) ||
      common?.maxHeight,
    options.copyMaxHeight,
  );

  doPasteValue(
    `${targetBaseKey}.common.bottomBase`,
    dataStore.getValueAsNumber(`${sourceBaseKey}.common.bottomBase`) ||
      common?.bottomBase,
    options.copyBottomBase,
  );

  // 1.1 Common from infoByContLength (LCG, Bottom Weight & Stack Weight)
  const containersLengths = getSizesFromSlots(targetBayData?.perSlotInfo).sizes;
  containersLengths.forEach((size) => {
    doPasteValue(
      `${targetBaseKey}.infoByContLength.${size}.lcg`,
      dataStore.getValueAsNumber(
        `${sourceBaseKey}.infoByContLength.${size}.lcg`,
      ) || copyFromBayData?.infoByContLength?.[size]?.lcg,
      options.copyLcgs,
    );

    doPasteValue(
      `${targetBaseKey}.infoByContLength.${size}.bottomWeight`,
      dataStore.getValueAsNumber(
        `${sourceBaseKey}.infoByContLength.${size}.bottomWeight`,
      ) || copyFromBayData?.infoByContLength?.[size]?.bottomWeight,
      options.copyBottomWeight,
    );

    doPasteValue(
      `${targetBaseKey}.infoByContLength.${size}.rowWeight`,
      dataStore.getValueAsNumber(
        `${sourceBaseKey}.infoByContLength.${size}.rowWeight`,
      ) || copyFromBayData?.infoByContLength?.[size]?.rowWeight,
      options.copyStackWeight,
    );
  });

  const perRowEach = copyFromBayData?.perRowInfo?.each;
  const dataStoreEach = dataStore.getValuesOfParent<TBayRowInfo["each"]>(
    `${sourceBaseKey}.each`,
  );

  // 2. Each rows
  const allRowsUnique = [
    ...Object.keys(perRowEach || {}),
    ...Object.keys(dataStoreEach || {}),
  ]
    .sort(sortNumericAsc)
    .filter((v, idx, arr) => arr.indexOf(v) === idx) as IRowPattern[];

  allRowsUnique.forEach((row) => {
    const rowValues = perRowEach?.[row];

    doPasteValue(
      `${targetBaseKey}.each.${row}.maxHeight`,
      dataStore.getValueAsNumber(`${sourceBaseKey}.each.${row}.maxHeight`) ||
        rowValues?.maxHeight,
      options.copyMaxHeight,
    );

    doPasteValue(
      `${targetBaseKey}.each.${row}.bottomBase`,
      dataStore.getValueAsNumber(`${sourceBaseKey}.each.${row}.bottomBase`) ||
        rowValues?.bottomBase,
      options.copyBottomBase,
    );

    doPasteValue(
      `${targetBaseKey}.each.${row}.tcg`,
      dataStore.getValueAsNumber(`${sourceBaseKey}.each.${row}.tcg`) ||
        rowValues?.tcg,
      options.copyTcgs,
    );

    // 2.1 rowInfoByLength
    const allSizesUnique = [
      ...Object.keys(rowValues?.rowInfoByLength || {}),
      ...Object.keys(dataStoreEach?.[row]?.rowInfoByLength || {}),
    ]
      .sort(sortNumericAsc)
      .filter((v, idx, arr) => arr.indexOf(v) === idx)
      .map(Number) as TContainerLengths[];

    const rowByLengthValues = rowValues?.rowInfoByLength;
    const dataStoreByLenghtValues = dataStoreEach?.[row]?.rowInfoByLength;

    allSizesUnique.forEach((size) => {
      doPasteValue(
        `${targetBaseKey}.each.${row}.rowInfoByLength.${size}.lcg`,
        dataStoreByLenghtValues?.[size]?.lcg || rowByLengthValues?.[size]?.lcg,
        options.copyLcgs,
      );

      doPasteValue(
        `${targetBaseKey}.each.${row}.rowInfoByLength.${size}.rowWeight`,
        dataStoreByLenghtValues?.[size]?.rowWeight ||
          rowByLengthValues?.[size]?.rowWeight,
        options.copyStackWeight,
      );

      doPasteValue(
        `${targetBaseKey}.each.${row}.rowInfoByLength.${size}.bottomWeight`,
        dataStoreByLenghtValues?.[size]?.bottomWeight ||
          rowByLengthValues?.[size]?.bottomWeight,
        options.copyBottomWeight,
      );
    });
  });

  /** Internal: Copies the value following replace criteria */
  function doPasteValue(
    targetKey: string,
    value: number | undefined,
    criteria: CopyValuesEnum,
  ) {
    const numCriteria = Number(criteria);

    if (numCriteria === CopyValuesEnum.NO) return;
    if (
      numCriteria === CopyValuesEnum.YES ||
      (numCriteria === CopyValuesEnum.ONLY_EMPTIES &&
        dataStore.getValue(targetKey) === undefined)
    ) {
      dataStore.setValue(targetKey, value);
    }
  }
}
