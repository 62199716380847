import {
  BayLevelEnum,
  IBayLevelData,
  TContainerLengths,
} from "open-vessel-definition";
import {
  IRowPattern,
  ISlotPattern,
  getRowsAndTiersFromSlotKeys,
} from "tedivo-bay-grid-pure";

import { pad2 } from "@baplie-viewer2/tedivo-pure-helpers";

export function getStackWeightsOfBayLevel(
  bl: IBayLevelData | undefined,
  level: BayLevelEnum,
): Array<IStackWeightInfo> {
  if (!bl) return [];

  // 1. Which rows have slots defined?
  const perSlotInfo = bl.perSlotInfo || {};

  const effectiveSlotKeys = (Object.keys(perSlotInfo) as ISlotPattern[]).filter(
    (sk) =>
      perSlotInfo[sk].restricted !== 1 &&
      Object.keys(perSlotInfo[sk].sizes || {}).length > 0,
  );

  if (effectiveSlotKeys.length === 0) return []; // No slots defined

  const { rows, tiersByRow } = getRowsAndTiersFromSlotKeys(effectiveSlotKeys);

  // 2. Which sizes are defined for each row?
  const sizesByRow = rows.reduce((acc, row) => {
    effectiveSlotKeys
      .filter((sk) => sk.startsWith(pad2(row)))
      .forEach((sk) => {
        const sizes = perSlotInfo[sk].sizes || {};

        Object.keys(sizes).forEach((size) => {
          if (!acc[row]) acc[row] = new Set<number>();
          acc[row].add(Number(size));
        });
      });
    return acc;
  }, {} as Record<IRowPattern, Set<number>>);

  const ifcl = bl?.infoByContLength;
  const perRowInfoEach = bl?.perRowInfo?.each;
  const res: Array<IStackWeightInfo> = [];

  // 3. Get the stack weights for each size
  (Object.keys(sizesByRow) as IRowPattern[]).forEach((row) => {
    const bwOfRow = sizesByRow[row];
    const sws = new Map<number, number>();
    bwOfRow.forEach((size) => {
      const perRowInfo =
        perRowInfoEach?.[row]?.rowInfoByLength?.[size as TContainerLengths];
      const weight =
        perRowInfo?.rowWeight || ifcl?.[size as TContainerLengths]?.rowWeight;
      if (weight) sws.set(size, weight);
    });

    res.push({
      row,
      bottomTier: tiersByRow[row].minTier,
      level,
      sws,
    });
  });

  return res;
}

export interface IStackWeightInfo {
  row: IRowPattern;
  bottomTier: number;
  level: BayLevelEnum;
  sws: Map<number, number>;
}
