/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputWithUnits } from "@baplie-viewer2/tedivo-form";
import SlCheckbox from "@shoelace-style/shoelace/dist/components/checkbox/checkbox";
import SlInput from "@shoelace-style/shoelace/dist/components/input/input";
import SlSwitch from "@shoelace-style/shoelace/dist/components/switch/switch";

export default class FieldsValuesStore {
  public values: Map<string, number | string | undefined> = new Map();
  private prevValues: Map<string, number | string | undefined> = new Map();
  private inputs: Map<
    string,
    InputWithUnits<unknown> | SlCheckbox | SlSwitch | SlInput | HTMLInputElement
  > = new Map();
  private consumers: Array<IConsumer> = [];
  public onChange:
    | ((
        values?: Map<string, number | string | undefined>,
        prevValues?: Map<string, number | string | undefined>,
      ) => void)
    | undefined;

  get inputsRegistered(): number {
    return this.inputs.size;
  }

  registerInputField<T>(input: InputWithUnits<T> | SlInput | HTMLInputElement) {
    const name = input.name;
    if (!name) {
      return;
    }

    this.inputs.set(name, input);
    this.values.set(name, input.value);

    input.addEventListener("sl-blur", this.addOnBlurEventListener, false);
  }

  registerCheckbox(checkbox: SlCheckbox | SlSwitch) {
    if (!checkbox.name) {
      return;
    }

    this.inputs.set(checkbox.name, checkbox);
    this.values.set(checkbox.name, checkbox.checked ? 1 : 0);

    checkbox.addEventListener(
      "sl-change",
      this.addOnChangeCheckboxEventListener,
      false,
    );
  }

  registerDataConsumer(
    consumer: HTMLInputElement | SlInput | InputWithUnits<unknown>,
    attribute: "placeholder" | "value" | "title",
    referenceByName: string,
    recursive = false,
  ) {
    this.consumers.push({ consumer, attribute, referenceByName, recursive });
  }

  cleanUp() {
    this.inputs.forEach((input) => {
      input.removeEventListener("sl-blur", this.addOnBlurEventListener, false);
    });
    this.inputs = new Map();
  }

  private addOnBlurEventListener: EventListenerOrEventListenerObject = (
    ev: Event,
  ) => {
    const target = ev.target as InputWithUnits<unknown>;
    this.annotateChange(target.name, target.value);
  };

  private addOnChangeCheckboxEventListener: EventListenerOrEventListenerObject =
    (ev: Event) => {
      const target = ev.target as SlCheckbox | SlSwitch;
      this.annotateChange(target.name, target.checked ? 1 : 0);
    };

  /** This is the one that mutates the state */
  private annotateChange(name: string, value: number | string | undefined) {
    const prevValue = this.values.get(name);
    if (prevValue === value) return;

    this.prevValues = new Map(this.values);

    this.values.set(name, value);

    this.consumers
      .filter((c) => c.referenceByName === name)
      .forEach(
        (c) =>
          (c.consumer[c.attribute] =
            value === undefined || isNaN(Number(value)) ? "" : String(value)),
      );

    if (this.onChange) this.onChange(this.values, this.prevValues);
  }

  executeChange() {
    this.updateConsumers();
    if (this.onChange) this.onChange(this.values, this.prevValues);
  }

  updateConsumers = () => {
    this.consumers.forEach(({ consumer, attribute, referenceByName }) => {
      const value = this.values.get(referenceByName);
      if (value !== undefined)
        consumer[attribute] = String(this.values.get(referenceByName));
    });
  };

  getValue(key: string) {
    return this.values.get(key);
  }

  getValueAsNumber(key: string) {
    if (this.values.has(key)) {
      const v = Number(this.values.get(key));
      return !isNaN(v) ? v : undefined;
    }
    return undefined;
  }

  getValuesOfParent<T = unknown>(parentKey: string) {
    interface IObject {
      [key: string]: unknown | undefined;
    }

    const getDirectValue = (key: string, parentKey: string, obj: IObject) => {
      const subKey = key.substring(parentKey.length + 1);

      if (subKey.indexOf(".") < 0) {
        obj[subKey] = this.getValue(key);
      } else {
        const subKeyParts = subKey.split(".");
        const firstSubKey = subKeyParts[0];

        let subObj = obj[firstSubKey] as IObject;

        if (subObj === undefined) {
          obj[firstSubKey] = {} as IObject;
          subObj = obj[firstSubKey] as IObject;
        }

        getDirectValue(key, `${parentKey}.${firstSubKey}`, subObj);
      }
    };

    const obj: IObject = {};

    const foundKeys = this.getAllKeys().filter(
      (s) => s.indexOf(parentKey) === 0,
    );

    foundKeys.forEach((key) => {
      getDirectValue(key, parentKey, obj);
    });

    return obj as unknown as T;
  }

  getAllKeys(): string[] {
    return Array.from(this.values.keys()).sort();
  }

  setValue(key: string, value: string | number | undefined) {
    this.values.set(key, value);
    const inp = this.inputs.get(key);
    if (inp) {
      if (!("checked" in inp)) {
        inp.value = value;
      } else {
        inp.checked = !!value;
      }
    }
    return this;
  }
}

interface IConsumer {
  consumer: HTMLInputElement | SlInput | InputWithUnits<unknown>;
  referenceByName: string;
  attribute: "placeholder" | "value" | "title";
  recursive?: boolean;
}
