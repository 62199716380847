import { getTranslation } from "packages/oss-editor/src/app/i18/i18tn";
import { stylesFromStringToTag } from "@baplie-viewer2/tedivo-dom-helpers";
export class DeleteButtonWithConfirm extends HTMLElement {
    constructor() {
        super();
        this.confirmDelete = undefined;
        this.deleteConfirmationDialog = undefined;
        this.buttonText = getTranslation("general:common.delete");
        this.confirmDeleteText = getTranslation("general:common.confirmDelete");
        this.onConfirmDelete = () => { };
    }
    connectedCallback() {
        const confirmDelete = this.slot === "header-actions"
            ? document.createElement("sl-icon-button")
            : document.createElement("sl-button");
        // First button
        if (this.slot === "header-actions") {
            confirmDelete.name = "trash";
            confirmDelete.slot = "header-actions";
            confirmDelete.title = this.buttonText;
            confirmDelete.classList.add("danger");
        }
        else {
            confirmDelete.slot = "footer";
            confirmDelete.innerHTML = this.buttonText;
            confirmDelete.tabIndex = 0;
            confirmDelete.variant = "danger";
            confirmDelete.type = "button";
            const icon = document.createElement("sl-icon");
            icon.name = "trash";
            icon.slot = "prefix";
            confirmDelete.appendChild(icon);
        }
        this.appendChild(confirmDelete);
        // Confirm dialog and confirm button
        const deleteConfirmationDialog = this.createConfirmDeleteDialog();
        confirmDelete.onclick = function () {
            deleteConfirmationDialog.show();
        };
        confirmDelete.onkeydown = function (ev) {
            if (ev.key === " ") {
                confirmDelete.click();
            }
        };
        this.appendChild(stylesFromStringToTag(styles));
    }
    createConfirmDeleteDialog() {
        const deleteConfirmationDialog = document.createElement("sl-dialog");
        deleteConfirmationDialog.classList.add("higher-modal-z-index");
        deleteConfirmationDialog.label = this.buttonText;
        deleteConfirmationDialog.appendChild(document.createTextNode(this.confirmDeleteText));
        const confirmDeleteButton = document.createElement("sl-button");
        confirmDeleteButton.slot = "footer";
        confirmDeleteButton.innerText = getTranslation("general:common.delete");
        confirmDeleteButton.variant = "danger";
        const icon = document.createElement("sl-icon");
        icon.name = "trash";
        icon.slot = "prefix";
        confirmDeleteButton.appendChild(icon);
        deleteConfirmationDialog.appendChild(confirmDeleteButton);
        confirmDeleteButton.onclick = () => {
            this.onConfirmDelete();
            deleteConfirmationDialog.hide();
        };
        const cancelDeleteButton = document.createElement("sl-button");
        cancelDeleteButton.slot = "footer";
        cancelDeleteButton.innerText = getTranslation("general:common.cancel");
        deleteConfirmationDialog.appendChild(cancelDeleteButton);
        cancelDeleteButton.onclick = () => {
            deleteConfirmationDialog.hide();
        };
        document.body.appendChild(deleteConfirmationDialog);
        this.deleteConfirmationDialog = deleteConfirmationDialog;
        return deleteConfirmationDialog;
    }
    disconnectedCallback() {
        var _a, _b;
        (_a = this.confirmDelete) === null || _a === void 0 ? void 0 : _a.remove();
        (_b = this.deleteConfirmationDialog) === null || _b === void 0 ? void 0 : _b.remove();
    }
}
customElements.define("delete-button", DeleteButtonWithConfirm);
const styles = `
  .danger {
    color: var(--sl-color-danger-500);
  }

`;
