/* eslint-disable @typescript-eslint/no-explicit-any */
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
export class TedivoForm {
    constructor({ fields, onSubmit, formValidator, submitButton, formProps, hiddenData, autoFocusOnFirstInput = false, }) {
        var _a;
        this.myId = `id-${Math.round(Math.random() * 10000)}`;
        this.formControlsByName = {};
        this.allFormNodes = [];
        this.firstSubmit = false;
        this.autoFocusOnFirstInput = false;
        this.hiddenData = undefined;
        this.addSectionsAndFieldsToForm = (fields) => {
            let sectionHolder = undefined;
            let autoFocusSet = !this.autoFocusOnFirstInput;
            const setAutofocus = (field) => {
                field.setAttribute("autofocus", "");
                setTimeout(() => {
                    try {
                        if (field !== null)
                            field.focus();
                    }
                    catch (e) {
                        //Do nothing
                    }
                }, 100);
            };
            fields.forEach((fA) => {
                if (fA === undefined)
                    return;
                // Row holder
                const rowHolder = document.createElement("div");
                const createField = (fieldProps) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                    if (fieldProps === undefined)
                        return undefined;
                    let formField = undefined;
                    let titleField = undefined;
                    let nodeField = undefined;
                    const name = fieldProps.name;
                    const stateRecord = {
                        name,
                        value: undefined,
                        type: fieldProps.type,
                        props: fieldProps,
                    };
                    let initVal = undefined;
                    switch (fieldProps.type) {
                        case "title":
                            titleField = document.createElement((_a = fieldProps.tagName) !== null && _a !== void 0 ? _a : "h2");
                            titleField.className = "subtitle";
                            //stateRecord.field = titleField;
                            break;
                        case "node":
                            nodeField = fieldProps.node;
                            break;
                        case "hidden":
                            formField = document.createElement("tf-hidden");
                            formField.style.display = "none";
                            initVal = (_b = fieldProps.initialValue) !== null && _b !== void 0 ? _b : fieldProps.defaultValue;
                            if (initVal !== undefined) {
                                formField.value = String(initVal);
                                stateRecord.value = String(initVal);
                            }
                            stateRecord.field = formField;
                            break;
                        case "color":
                            formField = document.createElement("tf-input-color");
                            if (fieldProps.initialValue) {
                                initVal = fieldProps.initialValue;
                                stateRecord.value = initVal;
                                formField.value = initVal;
                            }
                            stateRecord.field = formField;
                            break;
                        case "textBox":
                        case "date":
                            formField = document.createElement("sl-input");
                            if (fieldProps.type === "date") {
                                formField.type = "date";
                                if (fieldProps.initialValue) {
                                    initVal =
                                        typeof fieldProps.initialValue === "string"
                                            ? new Date(fieldProps.initialValue)
                                            : fieldProps.initialValue;
                                }
                                if (initVal !== undefined) {
                                    formField.valueAsDate = initVal;
                                    stateRecord.value = initVal;
                                }
                            }
                            else {
                                formField.type = !fieldProps.isPassword ? "text" : "password";
                                initVal = (_c = fieldProps.initialValue) !== null && _c !== void 0 ? _c : fieldProps.defaultValue;
                                if (fieldProps.placeholder)
                                    formField.placeholder = fieldProps.placeholder;
                                if (fieldProps.autocapitalize)
                                    formField.autocapitalize = "characters";
                                if (fieldProps.disabled)
                                    formField.disabled = true;
                                if (fieldProps.isPassword)
                                    formField.passwordToggle = true;
                                if (initVal !== undefined) {
                                    formField.value = String(initVal);
                                    stateRecord.value = String(initVal);
                                }
                            }
                            stateRecord.field = formField;
                            formField.autocorrect = "off";
                            if (!autoFocusSet) {
                                autoFocusSet = true;
                                setAutofocus(formField);
                            }
                            break;
                        case "textArea":
                            formField = document.createElement("sl-textarea");
                            stateRecord.field = formField;
                            initVal = (_d = fieldProps.initialValue) !== null && _d !== void 0 ? _d : fieldProps.defaultValue;
                            if (initVal !== undefined) {
                                formField.value = String(initVal);
                                stateRecord.value = String(initVal);
                            }
                            if (fieldProps.placeholder)
                                formField.placeholder = fieldProps.placeholder;
                            if (fieldProps.autocapitalize)
                                formField.autocapitalize = "characters";
                            formField.autocorrect = "off";
                            if (!autoFocusSet) {
                                autoFocusSet = true;
                                setAutofocus(formField);
                            }
                            break;
                        case "number":
                            formField = document.createElement("sl-input");
                            formField.type = "number";
                            stateRecord.field = formField;
                            initVal = (_e = fieldProps.initialValue) !== null && _e !== void 0 ? _e : fieldProps.defaultValue;
                            if (initVal !== undefined) {
                                formField.value = String(initVal);
                                stateRecord.value = initVal;
                            }
                            if (fieldProps.placeholder)
                                formField.placeholder = fieldProps.placeholder;
                            if (fieldProps.step !== undefined)
                                formField.step = fieldProps.step;
                            if (!autoFocusSet) {
                                autoFocusSet = true;
                                setAutofocus(formField);
                            }
                            break;
                        case "file":
                            formField = document.createElement("tf-file");
                            if (fieldProps.accept)
                                formField.accept = fieldProps.accept;
                            stateRecord.value = fieldProps.initialValue;
                            stateRecord.field = formField;
                            break;
                        case "checkbox":
                            formField = document.createElement("tf-checkbox");
                            if (fieldProps.isNumericEnum)
                                formField.isNumericEnum = true;
                            initVal = (_f = fieldProps.initialValue) !== null && _f !== void 0 ? _f : fieldProps.defaultValue;
                            if (initVal !== undefined) {
                                formField.value = !!initVal;
                                stateRecord.value = fieldProps.isNumericEnum
                                    ? initVal
                                    : Boolean(initVal);
                            }
                            if (fieldProps.disabled !== undefined)
                                formField.disabled = fieldProps.disabled;
                            if (fieldProps.padStart)
                                formField.padStart = true;
                            if (fieldProps.required)
                                formField.required = true;
                            stateRecord.field = formField;
                            break;
                        case "radioButtonList":
                            formField = document.createElement("tf-radio-group");
                            formField.items = fieldProps.options;
                            formField.isNumericEnum = !!fieldProps.isNumericEnum;
                            if (fieldProps.fieldset)
                                formField.fieldset = true;
                            initVal = (_g = fieldProps.initialValue) !== null && _g !== void 0 ? _g : fieldProps.defaultValue;
                            if (initVal !== undefined) {
                                formField.value = String(initVal);
                                stateRecord.value = initVal;
                            }
                            if (fieldProps.disabled)
                                formField.disabled = true;
                            stateRecord.field = formField;
                            break;
                        case "checkboxesList":
                            formField = document.createElement("tf-checkbox-group");
                            formField.items = fieldProps.options;
                            formField.isNumericEnum = !!fieldProps.isNumericEnum;
                            if (fieldProps.fieldset)
                                formField.fieldset = true;
                            if (fieldProps.fixedOptionWidth)
                                formField.fixedOptionWidth = fieldProps.fixedOptionWidth;
                            initVal = (_h = fieldProps.initialValue) !== null && _h !== void 0 ? _h : fieldProps.defaultValue;
                            if (initVal !== undefined) {
                                formField.value = initVal;
                                stateRecord.value = initVal;
                            }
                            if (fieldProps.disabled !== undefined)
                                formField.disabled = fieldProps.disabled;
                            if (fieldProps.helpers)
                                formField.helpers = fieldProps.helpers;
                            stateRecord.field = formField;
                            break;
                        case "numberWithUnits":
                            formField = document.createElement("tf-input-units");
                            stateRecord.field = formField;
                            formField.converter = fieldProps.converter;
                            formField.transponser = fieldProps.transponser;
                            formField.addEventListener("keyup", (ev) => {
                                if (ev.key === "Enter")
                                    this.submitForm({});
                            }, false);
                            initVal = (_j = fieldProps.initialValue) !== null && _j !== void 0 ? _j : fieldProps.defaultValue;
                            if (initVal !== undefined) {
                                formField.value = initVal;
                                stateRecord.value = initVal;
                            }
                            if (!autoFocusSet) {
                                autoFocusSet = true;
                                formField.autofocus = true;
                            }
                            break;
                        case "select":
                            formField = document.createElement("sl-select");
                            fieldProps.options.forEach(({ value, label, disabled }) => {
                                const opt = document.createElement("sl-option");
                                opt.value = String(value);
                                opt.innerHTML = label;
                                if (disabled)
                                    opt.disabled = true;
                                formField === null || formField === void 0 ? void 0 : formField.appendChild(opt);
                            });
                            initVal = (_k = fieldProps.initialValue) !== null && _k !== void 0 ? _k : fieldProps.defaultValue;
                            if (initVal !== undefined) {
                                formField.value = String(initVal);
                                stateRecord.value = String(initVal);
                            }
                            if (fieldProps.hoist)
                                formField.hoist = true;
                            stateRecord.field = formField;
                            break;
                    }
                    switch (fieldProps.type) {
                        case "title":
                            // Title (heading)
                            if (titleField)
                                this.allFormNodes.push({
                                    value: undefined,
                                    type: "title",
                                    field: titleField,
                                    props: fieldProps,
                                });
                            return titleField;
                        case "node":
                            if (nodeField) {
                                this.allFormNodes.push({
                                    value: undefined,
                                    type: "node",
                                    props: fieldProps,
                                });
                            }
                            return nodeField;
                        default:
                            if (formField === undefined)
                                return undefined;
                            // Keeps the value of previous rendered fields
                            if (name && this.formControlsByName[name]) {
                                stateRecord.value = this.formControlsByName[name].value;
                            }
                            formField.id = String(fieldProps.name);
                            formField.name = String(fieldProps.name);
                            formField.size =
                                (_o = (_l = fieldProps.size) !== null && _l !== void 0 ? _l : (_m = this.formProps) === null || _m === void 0 ? void 0 : _m.size) !== null && _o !== void 0 ? _o : "medium";
                            if (fieldProps.label)
                                formField.label = fieldProps.label;
                            if (fieldProps.helpText)
                                formField.helpText = fieldProps.helpText;
                            formField.addEventListener("sl-change", this.onChangeValue, false);
                            if (fieldProps.inputListener) {
                                formField.addEventListener("sl-input", (ev) => {
                                    this.onChangeValue(ev);
                                });
                            }
                            if (name !== undefined)
                                this.formControlsByName[name] = stateRecord;
                            this.allFormNodes.push(stateRecord);
                            return formField;
                    }
                };
                if (Array.isArray(fA)) {
                    fA.slice()
                        .map((fB) => createField(fB))
                        .forEach((c) => {
                        if (c)
                            rowHolder.appendChild(c);
                    });
                }
                else {
                    const field = createField(fA);
                    if (fA.type === "title" && fA.createSection) {
                        sectionHolder = document.createElement("section");
                        this.form.appendChild(sectionHolder);
                    }
                    if (field)
                        rowHolder.appendChild(field);
                }
                rowHolder.className = `form-fields-row ${!Array.isArray(fA) ? fA.type : ""} ${(Array.isArray(fA)
                    ? fA.map((fB) => (fB === null || fB === void 0 ? void 0 : fB.holderClassName) || "").join(" ")
                    : fA.holderClassName) || ""}`;
                if (sectionHolder)
                    sectionHolder.appendChild(rowHolder);
                else
                    this.form.appendChild(rowHolder);
            });
        };
        this.onChangeValue = (ev) => {
            const target = ev.target;
            const value = target.value;
            const name = target.id;
            const stateRecord = this.formControlsByName[name];
            if (!stateRecord)
                return;
            if (stateRecord.type === "number" ||
                stateRecord.type === "numberWithUnits") {
                if (value === undefined || value === "") {
                    stateRecord.value = undefined;
                }
                else {
                    stateRecord.value = Number(value);
                }
            }
            else {
                stateRecord.value = value;
            }
            if (this.firstSubmit) {
                const values = this.getValues();
                const validationResult = this.formValidator.safeParse(Object.assign(Object.assign({}, values), this.hiddenData));
                this.markValidFields(validationResult);
            }
            if (this.onDataChange) {
                this.onDataChange(this.getValues(), name);
            }
        };
        this.submitForm = (ev) => {
            if (ev.preventDefault)
                ev.preventDefault();
            this.doSubmitForm();
            return false;
        };
        this.doSubmitForm = () => {
            this.firstSubmit = true;
            const values = this.getValues();
            const validationResult = this.formValidator.safeParse(Object.assign(Object.assign({}, values), this.hiddenData));
            if (validationResult.success) {
                const data = Object.assign(Object.assign({}, this.hiddenData), validationResult.data);
                this.onSubmit(data);
            }
            else {
                this.markValidFields(validationResult);
            }
            return validationResult;
        };
        this.focusOnFirstElement = (delay = 500) => {
            const fieldNames = Object.keys(this.formControlsByName);
            if (!fieldNames.length)
                return;
            const formControl = this.formControlsByName[fieldNames[0]];
            const field = formControl === null || formControl === void 0 ? void 0 : formControl.field;
            if (field) {
                if (delay) {
                    setTimeout(() => field.focus(), delay);
                }
                else {
                    field.focus();
                }
            }
        };
        this.form = document.createElement("form");
        this.form.className = `tedivo-form ${(_a = formProps === null || formProps === void 0 ? void 0 : formProps.className) !== null && _a !== void 0 ? _a : ""}`;
        this.form.method = "POST";
        this.form.noValidate = true;
        this.formProps = formProps;
        this.form.id = (formProps === null || formProps === void 0 ? void 0 : formProps.id) || this.myId;
        this.autoFocusOnFirstInput = autoFocusOnFirstInput;
        this.onSubmit = onSubmit;
        this.formValidator = formValidator;
        this.hiddenData = hiddenData;
        this.addSectionsAndFieldsToForm(fields);
        if (!isElementContainedInParent(submitButton, this.form)) {
            const hiddenSubmit = document.createElement("input");
            hiddenSubmit.type = "submit";
            hiddenSubmit.hidden = true;
            this.form.appendChild(hiddenSubmit);
        }
        this.form.onsubmit = this.submitForm;
    }
    setFields(fields) {
        removeChildren(this.form);
        this.addSectionsAndFieldsToForm(fields);
        return this;
    }
    setFormValidator(newValidator) {
        this.formValidator = newValidator;
        return this;
    }
    markValidFields(validationResult) {
        const validationResultError = validationResult;
        const issues = validationResultError.error
            ? validationResultError.error.issues
            : [];
        const issuesByPath = issues.reduce((acc, v) => {
            const n = String(v.path[0]);
            acc[n] = v;
            return acc;
        }, {});
        const fieldNames = Object.keys(this.formControlsByName);
        let firstError = undefined;
        fieldNames.forEach((fc) => {
            const formControl = this.formControlsByName[fc];
            const field = formControl === null || formControl === void 0 ? void 0 : formControl.field;
            if (formControl && field) {
                if (issuesByPath[formControl.name]) {
                    // has error
                    if (!field.classList.contains("has-error"))
                        field.classList.add("has-error");
                    if (!firstError && formControl.type !== "file")
                        firstError = field;
                }
                else {
                    // no error
                    field.classList.remove("has-error");
                }
            }
        });
        if (firstError) {
            const field = firstError;
            const formControl = this.formControlsByName[field.id];
            field.focus();
            if (formControl &&
                (formControl.type === "number" ||
                    formControl.type === "numberWithUnits" ||
                    formControl.type === "checkbox" ||
                    formControl.type === "textBox") &&
                !formControl.props.inputListener)
                field.select();
        }
    }
    getFormControlsByName() {
        return Object.assign({}, this.formControlsByName);
    }
    getAllFormNodes() {
        return this.allFormNodes;
    }
    getValues() {
        const fieldNames = Object.keys(this.formControlsByName);
        const values = fieldNames.reduce((acc, v) => {
            var _a;
            acc[v] = (_a = this.formControlsByName[v]) === null || _a === void 0 ? void 0 : _a.value;
            return acc;
        }, {});
        return values;
    }
    setValue(key, value) {
        var _a;
        const field = this.formControlsByName[key];
        if (field) {
            const defaultValue = field.props.defaultValue;
            const finalValue = value !== null && value !== void 0 ? value : defaultValue;
            field.value = finalValue;
            field.field.value = finalValue;
        }
        else {
            if (this.hiddenData !== undefined && ((_a = this.hiddenData) === null || _a === void 0 ? void 0 : _a[key]) !== undefined)
                this.hiddenData = Object.assign(Object.assign({}, this.hiddenData), { [key]: value });
        }
        return this;
    }
    execValidation(fields = []) {
        const values = this.getValues();
        const validationResult = this.formValidator.safeParse(values);
        if (fields.length === 0) {
            this.markValidFields(validationResult);
            return validationResult.success;
        }
        else if (!validationResult.success) {
            const error = validationResult.error;
            const subsetErrors = error.errors.reduce((acc, issue) => {
                const isChecked = issue.path.some((p) => fields.indexOf(p) >= 0);
                if (isChecked)
                    acc.push(issue);
                return acc;
            }, []);
            this.markValidFields(Object.assign(Object.assign({}, validationResult), { error: Object.assign(Object.assign({}, error), { errors: subsetErrors }) }));
            return subsetErrors.length === 0;
        }
        else {
            return true;
        }
    }
}
function isElementContainedInParent(e, parent) {
    let p = e.parentElement;
    while (p !== null) {
        if (p === parent)
            return true;
        p = p.parentElement;
    }
    return false;
}
