import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
} from "open-vessel-definition";
import { IBayPattern, ISlotPattern } from "./types/IPositionPatterns";

import { IBaySizesAndCgs } from "./types/IGetBayLcgVcgAndPairingsResult";
import { getSizesFromSlots } from "./getSizesFromSlots";
import { sortByMultipleFields } from "@baplie-viewer2/tedivo-pure-helpers";

export function getAllThePairedBays(
  bls: IBayLevelData[],
  checkOnly40s: boolean,
): IGetPairedBaysOutput {
  return _getAllPaired(false, bls, checkOnly40s);
}

export function getAllThePairedBaysFromBlps(
  bls: IBaySizesAndCgs[],
  checkOnly40s: boolean,
): IGetPairedBaysOutput {
  return _getAllPaired(true, bls, checkOnly40s);
}

function _getAllPaired(
  isBLP: true,
  bls: IBaySizesAndCgs[],
  checkOnly40s: boolean,
): IGetPairedBaysOutput;
function _getAllPaired(
  isBLP: false,
  bls: IBayLevelData[],
  checkOnly40s: boolean,
): IGetPairedBaysOutput;
function _getAllPaired(
  isBLP: boolean,
  bls: (IBayLevelData | IBaySizesAndCgs)[],
  checkOnly40s: boolean,
): IGetPairedBaysOutput {
  const levels = [BayLevelEnum.ABOVE, BayLevelEnum.BELOW];
  const pairedBays: IPairedBays[] = [];
  const unpairedBays: IUnpairedBays[] = [];

  const has40Fn = isBLP
    ? (bl: IBaySizesAndCgs) => bl.sizes.some((size) => size >= 40)
    : (bl: IBayLevelData) => {
        return (Object.keys(bl.perSlotInfo || {}) as ISlotPattern[]).some(
          (slot) =>
            Object.keys(bl.perSlotInfo?.[slot]?.sizes || {})
              .map(Number)
              .some((size) => size >= 40),
        );
      };

  const getDefinedSlots = isBLP
    ? (bl: IBaySizesAndCgs) => bl.definedSlots
    : (bl: IBayLevelData) => getSizesFromSlots(bl.perSlotInfo).definedSlots;

  levels.forEach((level) => {
    const bays = bls
      .filter((a) => a.level === level)
      .sort(sortByMultipleFields([{ name: "isoBay", ascending: true }]));

    for (let i = 0; i < bays.length; i++) {
      const bay = bays[i];

      let has40 = true;

      if (checkOnly40s) {
        has40 = has40Fn(bay as any);
      }

      if (bay.pairedBay === ForeAftEnum.AFT) {
        const nextBay = bays[i + 1];
        if (nextBay && nextBay.pairedBay === ForeAftEnum.FWD) {
          pairedBays.push({
            base: bay.isoBay,
            paired: nextBay.isoBay,
            level,
            allBays: [bay.isoBay, nextBay.isoBay],
          });
          i++;
        } else {
          if (has40 || !checkOnly40s)
            unpairedBays.push({
              base: bay.isoBay,
              level,
              allBays: [bay.isoBay],
              definedSlots: getDefinedSlots(bay as any),
            });
        }
      } else {
        if (has40 || !checkOnly40s)
          unpairedBays.push({
            base: bay.isoBay,
            level,
            allBays: [bay.isoBay],
            definedSlots: getDefinedSlots(bay as any),
          });
      }
    }
  });

  return { pairedBays, unpairedBays };
}

export interface IGetPairedBaysOutput {
  pairedBays: IPairedBays[];
  unpairedBays: IUnpairedBays[];
}

export type IPairedBays = {
  base: IBayPattern;
  paired: IBayPattern;
  level: BayLevelEnum;
  allBays: [IBayPattern, IBayPattern];
};

export type IUnpairedBays = {
  base: IBayPattern;
  level: BayLevelEnum;
  allBays: [IBayPattern];
  definedSlots: number;
};
