import { sortNumericAsc } from "@baplie-viewer2/tedivo-pure-helpers";

export enum DragOrigin {
  TopLeft = 11,
  TopRight = 12,
  BottomLeft = 21,
  BottomRight = 22,
}

/**
 * Class that adds a Dragger to an HTMLElement
 */
export class Dragger {
  width = 0;
  height = 0;
  mouseStart: { x: number; y: number; rect?: DOMRect } = { x: 0, y: 0 };
  prevMouseUp: IDraggerDetails | undefined = undefined;

  node: HTMLElement;
  dragSelector: HTMLDivElement;
  dragOverlay: HTMLDivElement;

  onChange: (event: IDraggerDetails) => void;

  constructor(node: HTMLElement, onChange: (event: IDraggerDetails) => void) {
    this.node = node;
    this.onChange = onChange;

    this.dragSelector = document.createElement("div");
    this.dragSelector.className = "dragSelector";
    this.dragSelector.style.display = "none";

    this.dragOverlay = document.createElement("div");
    this.dragOverlay.className = "dragOverlay";
    this.dragOverlay.style.display = "none";

    node.appendChild(this.dragOverlay);
    node.appendChild(this.dragSelector);

    node.addEventListener("mousedown", this.onMouseDown, false);
    node.addEventListener("touchstart", this.onTouchDown, false);
  }

  remove() {
    this.node.removeChild(this.dragOverlay);
    this.node.removeChild(this.dragSelector);
    this.node.removeEventListener("mousedown", this.onMouseDown);
    this.node.removeEventListener("touchstart", this.onTouchDown);
  }

  // #region BaseHandlersMouse
  private _onPointerDown(clientX: number, clientY: number) {
    const rect = this.node.getBoundingClientRect();
    const internalX = Math.round(clientX - rect.left + this.node.scrollLeft);
    const internalY = Math.round(clientY - rect.top + this.node.scrollTop);

    this.mouseStart.x = internalX;
    this.mouseStart.y = internalY;
    this.mouseStart.rect = rect;

    this.dragOverlay.style.display = "block";

    this.dragSelector.style.display = "block";
    this.dragSelector.style.width = "0";
    this.dragSelector.style.height = "0";
    this.dragSelector.style.left = `${internalX}px`;
    this.dragSelector.style.top = `${internalY}px`;

    // Add the rest of events
    globalThis.addEventListener("mouseup", this.onMouseUp, false);
    globalThis.addEventListener("mousemove", this.onMouseMove, false);
    globalThis.addEventListener("touchend", this.onTouchUp, false);
    globalThis.addEventListener("touchmove", this.onTouchMove, false);
  }

  private _onPointerUp = (
    clientX: number,
    clientY: number,
    isTouch: boolean,
    ev: MouseEvent | TouchEvent,
  ) => {
    globalThis.removeEventListener("mouseup", this.onMouseUp);
    globalThis.removeEventListener("mousemove", this.onMouseMove);
    globalThis.removeEventListener("touchend", this.onTouchUp);
    globalThis.removeEventListener("touchmove", this.onTouchMove);

    this.dragSelector.style.display = "none";
    this.dragOverlay.style.display = "none";

    if (!this.mouseStart.rect) return;

    const endX = Math.round(
      clientX - this.mouseStart.rect.left + this.node.scrollLeft,
    );

    const endY = Math.round(
      clientY - this.mouseStart.rect.top + this.node.scrollTop,
    );

    const xs = [this.mouseStart.x, endX].sort(sortNumericAsc);
    const ys = [this.mouseStart.y, endY].sort(sortNumericAsc);

    const hasDragged =
      Math.abs(this.mouseStart.x - endX) + Math.abs(this.mouseStart.y - endY) >
      10;

    const dragOrigin = hasDragged
      ? (this.mouseStart.x < endX ? 1 : 2) +
        (this.mouseStart.y < endY ? 10 : 20)
      : undefined;

    const event: IDraggerDetails = {
      startX: xs[0],
      startY: ys[0],
      endX: xs[1],
      endY: ys[1],
      isTouch,
      hasDragged,
      dragOrigin,
      timeStamp: Date.now(),
      originalEvent: ev,
    };

    if (
      this.prevMouseUp &&
      Math.abs(this.prevMouseUp.endX - event.endX) < 10 &&
      Math.abs(this.prevMouseUp.endY - event.endY) < 10 &&
      event.timeStamp - this.prevMouseUp.timeStamp < 500
    ) {
      event.isDoubleClick = true;
    }

    this.onChange(event);

    this.prevMouseUp = event;
    this.mouseStart.rect = undefined;
  };

  private _onPointerMove = (clientX: number, clientY: number) => {
    if (!this.mouseStart.rect) return;

    const movingX = Math.round(
      clientX - this.mouseStart.rect.left + this.node.scrollLeft,
    );

    const movingY = Math.round(
      clientY - this.mouseStart.rect.top + this.node.scrollTop,
    );

    const movingWidth = Math.abs(movingX - this.mouseStart.x);
    const movingHeight = Math.abs(movingY - this.mouseStart.y);

    // UI Horizontal
    if (movingX >= this.mouseStart.x) {
      this.dragSelector.style.width = `${Math.min(
        movingWidth,
        Math.abs(this.mouseStart.rect.width - this.mouseStart.x),
      )}px`;
    } else {
      this.dragSelector.style.width = `${Math.min(
        movingWidth,
        this.mouseStart.x,
      )}px`;

      this.dragSelector.style.left = `${Math.max(0, movingX)}px`;
    }

    // UI Vertical
    if (movingY >= this.mouseStart.y) {
      this.dragSelector.style.height = `${Math.min(
        movingHeight,
        Math.abs(
          this.mouseStart.rect.height - this.mouseStart.y + this.node.scrollTop,
        ),
      )}px`;
    } else {
      this.dragSelector.style.height = `${Math.min(
        movingHeight,
        this.mouseStart.y,
      )}px`;

      this.dragSelector.style.top = `${Math.max(0, movingY)}px`;
    }
  };
  // #endregion BaseHandlersMouse

  private onMouseDown = (ev: MouseEvent) => {
    if (ev.buttons !== 1) return;

    this._onPointerDown(ev.clientX, ev.clientY);
  };

  private onTouchDown = (ev: TouchEvent) => {
    if (!ev.targetTouches || !ev.targetTouches[0]) return;

    this._onPointerDown(
      ev.targetTouches[0].clientX,
      ev.targetTouches[0].clientY,
    );
  };

  private onMouseUp = (ev: MouseEvent) => {
    this._onPointerUp(ev.clientX, ev.clientY, false, ev);
  };

  private onTouchUp = (ev: TouchEvent) => {
    if (!ev.targetTouches || !ev.targetTouches[0]) return;

    this._onPointerUp(
      ev.targetTouches[0].clientX,
      ev.targetTouches[0].clientY,
      true,
      ev,
    );
  };

  private onMouseMove = (ev: MouseEvent) => {
    this._onPointerMove(ev.clientX, ev.clientY);
  };

  private onTouchMove = (ev: TouchEvent) => {
    ev.preventDefault();
    if (!ev.targetTouches || !ev.targetTouches[0]) return;

    this._onPointerMove(
      ev.targetTouches[0].clientX,
      ev.targetTouches[0].clientY,
    );
  };
}

export interface IDraggerDetails {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  hasDragged: boolean;
  dragOrigin?: DragOrigin;
  isTouch: boolean;
  timeStamp: number;
  isDoubleClick?: boolean;
  originalEvent?: MouseEvent | TouchEvent;
}
