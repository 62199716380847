import IntegratedDialog, {
  IButtonAttributes,
} from "../../common/IntegratedDialog";

import { getTranslation } from "../../../app/i18/i18tn";
import { routeFns } from "packages/oss-editor/src/app/router/routes";

export function createFoundImoDialog({
  parentNode,
  imoCode,
  shipName,
  lastFetched,
  btnSeeTvlSearchResults,
  onButtonClicked,
}: {
  parentNode: HTMLElement;
  imoCode: string;
  shipName: string;
  lastFetched: string;
  btnSeeTvlSearchResults: boolean | undefined;
  onButtonClicked: (
    v: "fix" | "doubleCheck" | "continue" | "seeSearchResults",
  ) => void;
}): void {
  const dateLastFetched = new Date(lastFetched);
  const dateDiff = Date.now() - dateLastFetched.getTime();
  const MINIMUM_TIME_BEFORE_REQUESTING_AGAIN = 60 * 1000 * 60 * 24; // 1 Day

  const buttonsAttrs: IButtonAttributes<
    "fix" | "doubleCheck" | "continue" | "seeSearchResults"
  >[] = [
    {
      label: "general:imoValidation.found.btnFix",
      value: "fix",
      outlined: true,
      type: "button",
      variant: "warning",
    },
    {
      label: "general:imoValidation.found.btnOk",
      value: "continue",
      type: "submit",
      variant: "primary",
      autofocus: true,
    },
  ];

  if (dateDiff > MINIMUM_TIME_BEFORE_REQUESTING_AGAIN) {
    buttonsAttrs.splice(1, 0, {
      label: "general:imoValidation.found.btnKO",
      value: "doubleCheck",
      outlined: true,
      type: "button",
    });
  }

  const commonDialog: IntegratedDialog<"fix" | "doubleCheck" | "continue"> =
    new IntegratedDialog({
      parentNode,
      preventDefaultClose: true,
      width: "700px",
      buttonsAttrs,
    });

  commonDialog.onButtonClicked = onButtonClicked;

  const messageHolder = document.createElement("div");
  messageHolder.innerHTML = getTranslation(
    "general:imoValidation.found.message",
    {
      imoCode,
      shipName,
    },
  );

  if (btnSeeTvlSearchResults) {
    const a = document.createElement("a");
    a.className = "success";
    a.href = routeFns.communityFiles(imoCode);

    a.addEventListener("click", (ev) => {
      ev.preventDefault();
      onButtonClicked("seeSearchResults");
      return false;
    });

    a.innerHTML = getTranslation("general:search.thereAreTvlFiles");

    messageHolder.appendChild(a);
  }

  commonDialog.show(
    getTranslation("general:imoValidation.found.title"),
    messageHolder,
  );
}
