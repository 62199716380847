import { CraneSideEnum, ForeAftEnum, VesselPartTypeEnum, } from "open-vessel-definition";
import { createSelectShoelace, } from "@baplie-viewer2/tedivo-form";
import { PARTS_COLORS } from "./partsColors";
import { calculateSpaceAvailableNext } from "./helpers/calculateSpaceAvailableNext";
import { createModalForBridge } from "./modals/createModalForBridge";
import { createModalForCranes } from "./modals/createModalForCranes";
import { createModalForSmokeStacks } from "./modals/createModalForSmokeStacks";
import { createModalForSpacer } from "./modals/createModalForSpacer";
import { createSimpleAlert } from "packages/oss-editor/src/components/common/IntegratedDialog";
import { getTranslation } from "packages/oss-editor/src/app/i18/i18tn";
const commonOptions = {
    caret: false,
    ommitCheckSign: true,
    iconPrefix: "plus",
    buttonText: "",
    outlined: true,
    variant: "primary",
    size: "small",
    pill: true,
    hoist: true,
};
export function drawSlotsNodes({ calculatedSlots, lcgVcgTcgAndPairings, applyChanges, }) {
    const mainHolder = document.createDocumentFragment();
    const vesselSlotsHolder = document.createElement("div");
    vesselSlotsHolder.className = "vessel-slots-holder";
    vesselSlotsHolder.dataset.bow = getTranslation("general:ship.bow");
    vesselSlotsHolder.dataset.stern = getTranslation("general:ship.stern");
    mainHolder.appendChild(vesselSlotsHolder);
    const editorActionOptions = [
        {
            name: getTranslation("view:view3D.actions.addBridge"),
            value: "addBridge",
        },
        { name: getTranslation("view:view3D.actions.addCrane"), value: "addCrane" },
        {
            name: getTranslation("view:view3D.actions.addSmokeStack"),
            value: "addSmoke",
        },
        {
            name: getTranslation("view:view3D.actions.addSpacer"),
            value: "addSpacer",
        },
    ];
    const currentLabels = calculatedSlots.reduce((acc, slot) => {
        acc[slot.id] = slot.label;
        return acc;
    }, {});
    const last = calculatedSlots.length - 1;
    calculatedSlots
        .slice()
        .reverse()
        .forEach((slot, idx) => {
        const slotHolder = document.createElement("div");
        const slotBox = document.createElement("div");
        slotHolder.className = `slot-holder ${slot.type === VesselPartTypeEnum.BAY ? "bay" : ""}`;
        slotBox.className = `slot-box`;
        slotBox.style.borderColor = `var(${PARTS_COLORS[slot.type]})`;
        const slotActionAft = createSelectShoelace(Object.assign(Object.assign({}, commonOptions), { id: `${idx}-fwd`, options: editorActionOptions, onChange: (partType) => {
                launchPartLaunchModal({
                    partType,
                    slotRefId: slot.id,
                    posRef: ForeAftEnum.AFT,
                    currentLabels,
                    calculatedSlots,
                    lcgVcgTcgAndPairings,
                    applyChanges,
                });
            } }));
        slotHolder.appendChild(slotActionAft);
        slotHolder.appendChild(slotBox);
        if (slot.type !== VesselPartTypeEnum.BAY) {
            const editBnt = document.createElement("sl-icon-button");
            editBnt.className = "part-slot-edit-btn";
            editBnt.name = "pencil";
            editBnt.addEventListener("click", () => {
                launchPartLaunchModal({
                    partType: slot.type === VesselPartTypeEnum.BRIDGE
                        ? "addBridge"
                        : slot.type === VesselPartTypeEnum.CRANE
                            ? "addCrane"
                            : slot.type === VesselPartTypeEnum.SMOKE
                                ? "addSmoke"
                                : "addSpacer",
                    original: slot.original,
                    slotRefId: slot.original.slotRefId,
                    posRef: slot.original.posRef,
                    currentLabels,
                    calculatedSlots,
                    lcgVcgTcgAndPairings,
                    applyChanges,
                });
            });
            slotBox.innerHTML = slot.label;
            slotBox.appendChild(editBnt);
        }
        else {
            slotBox.innerHTML = slot.label.split("-").join("<br>");
        }
        if (idx === last) {
            const slotActionFwd = createSelectShoelace(Object.assign(Object.assign({}, commonOptions), { id: `${idx}-aft`, options: editorActionOptions, onChange: (partType) => {
                    launchPartLaunchModal({
                        partType,
                        slotRefId: slot.id,
                        posRef: ForeAftEnum.FWD,
                        currentLabels,
                        calculatedSlots,
                        lcgVcgTcgAndPairings,
                        applyChanges,
                    });
                } }));
            slotHolder.appendChild(slotActionFwd);
        }
        vesselSlotsHolder.appendChild(slotHolder);
    });
    return mainHolder;
}
/** Launches modals for creation, updation and deletion of parts */
function launchPartLaunchModal({ partType, original, slotRefId, posRef, currentLabels, calculatedSlots, lcgVcgTcgAndPairings, applyChanges, }) {
    const spaceAvailableCalcFn = (slotRefId, posRef, id) => calculateSpaceAvailableNext({
        slotRefId,
        posRef,
        calculatedSlots,
        lcgVcgTcgAndPairings,
        includeLenOfId: id,
    });
    const spaceAvailable = spaceAvailableCalcFn(slotRefId, posRef, original === null || original === void 0 ? void 0 : original.id);
    if (spaceAvailable !== undefined) {
        if (spaceAvailable <= 0) {
            createSimpleAlert("general:common.error", "view:view3D.alerts.noSpaceAvailable");
            return;
        }
        if (spaceAvailable < 1000) {
            createSimpleAlert("general:common.attention", "view:view3D.alerts.notEnoughSpace", undefined, () => {
                launch();
            });
            return;
        }
    }
    launch();
    function launch() {
        var _a;
        switch (partType) {
            case "addBridge":
                createModalForBridge({
                    bridgeData: {
                        id: original === null || original === void 0 ? void 0 : original.id,
                        label: (original === null || original === void 0 ? void 0 : original.label) ||
                            `Bridge ${calculatedSlots.filter((s) => s.type === VesselPartTypeEnum.BRIDGE).length || ""}`.trim(),
                        slotRefId: (original === null || original === void 0 ? void 0 : original.slotRefId) || slotRefId,
                        posRef: (original === null || original === void 0 ? void 0 : original.posRef) || posRef,
                        len: (original === null || original === void 0 ? void 0 : original.len) || 0,
                        heatSrcBelow: (original === null || original === void 0 ? void 0 : original.heatSrcBelow) || false,
                        type: VesselPartTypeEnum.BRIDGE,
                    },
                    currentLabels,
                    calculatedSlots,
                    maxLen: spaceAvailable,
                    defaultLen: 14000,
                    onSubmitAction: applyChanges,
                    spaceAvailableCalcFn,
                });
                break;
            case "addCrane":
                createModalForCranes({
                    craneData: {
                        id: original === null || original === void 0 ? void 0 : original.id,
                        label: (original === null || original === void 0 ? void 0 : original.label) ||
                            `Cranes ${calculatedSlots.filter((s) => s.type === VesselPartTypeEnum.CRANE).length + 1}`,
                        slotRefId: (original === null || original === void 0 ? void 0 : original.slotRefId) || slotRefId,
                        posRef: (original === null || original === void 0 ? void 0 : original.posRef) || posRef,
                        len: (original === null || original === void 0 ? void 0 : original.len) || 0,
                        cranes: (original === null || original === void 0 ? void 0 : original.cranes) || [
                            CraneSideEnum.PORT,
                        ],
                        type: VesselPartTypeEnum.CRANE,
                    },
                    currentLabels,
                    calculatedSlots,
                    maxLen: spaceAvailable,
                    defaultLen: 6096,
                    onSubmitAction: applyChanges,
                    spaceAvailableCalcFn,
                });
                break;
            case "addSmoke":
                createModalForSmokeStacks({
                    smokeStacksData: {
                        id: original === null || original === void 0 ? void 0 : original.id,
                        label: (original === null || original === void 0 ? void 0 : original.label) ||
                            `Smoke Stack ${calculatedSlots.filter((s) => s.type === VesselPartTypeEnum.SMOKE).length || ""}`,
                        slotRefId: (original === null || original === void 0 ? void 0 : original.slotRefId) || slotRefId,
                        posRef: (original === null || original === void 0 ? void 0 : original.posRef) || posRef,
                        len: (original === null || original === void 0 ? void 0 : original.len) || 0,
                        numberOfSmokeStacks: (_a = original === null || original === void 0 ? void 0 : original.numberOfSmokeStacks) !== null && _a !== void 0 ? _a : 1,
                        type: VesselPartTypeEnum.SMOKE,
                    },
                    currentLabels,
                    calculatedSlots,
                    maxLen: spaceAvailable,
                    defaultLen: 6096,
                    onSubmitAction: applyChanges,
                    spaceAvailableCalcFn,
                });
                break;
            case "addSpacer":
                createModalForSpacer({
                    spacerData: {
                        id: original === null || original === void 0 ? void 0 : original.id,
                        label: (original === null || original === void 0 ? void 0 : original.label) ||
                            `Gap ${calculatedSlots.filter((s) => s.type === VesselPartTypeEnum.SPACER).length + 1}`,
                        slotRefId: (original === null || original === void 0 ? void 0 : original.slotRefId) || slotRefId,
                        posRef: (original === null || original === void 0 ? void 0 : original.posRef) || posRef,
                        len: (original === null || original === void 0 ? void 0 : original.len) || 0,
                        heatSrcBelow: (original === null || original === void 0 ? void 0 : original.heatSrcBelow) || false,
                        type: VesselPartTypeEnum.SPACER,
                    },
                    currentLabels,
                    calculatedSlots,
                    maxLen: spaceAvailable,
                    defaultLen: 6096,
                    onSubmitAction: applyChanges,
                    spaceAvailableCalcFn,
                });
                break;
        }
    }
}
