import { FEET_10_IN_MM, SEPARATION_IN_BETWEEN } from "../consts";
import {
  ForeAftEnum,
  IBayLevelData,
  IVesselParts,
  TContainerLengths,
} from "open-vessel-definition";
import {
  I20LcgsByBay,
  IBayPattern,
  IBaySizesAndCgs,
  IGetPairedBaysOutput,
} from "tedivo-bay-grid-pure";
import {
  feetToMillimeters,
  roundDec,
} from "@baplie-viewer2/tedivo-pure-helpers";

import { getBayBlocks } from "./missing/getBayBlocks";
import { preCalculateCurrentVesselParts } from "../preCalculateCurrentVesselParts";

export function findForMissingCgs(
  bls: IBayLevelData[],
  bayLevelSizesAndCgsBelow: IBaySizesAndCgs[],
  bayLevelSizesAndCgsAbove: IBaySizesAndCgs[],
  vesselParts: IVesselParts[],
  pairedBaysCalc: IGetPairedBaysOutput,
  lcgsBy20Bay: I20LcgsByBay,
) {
  const bpsByBay: { [bay: IBayPattern]: IBaySizesAndCgs[] } = {};

  [...bayLevelSizesAndCgsBelow, ...bayLevelSizesAndCgsAbove].forEach((bl) => {
    if (!bpsByBay[bl.isoBay]) bpsByBay[bl.isoBay] = [];
    bpsByBay[bl.isoBay].push(bl);
  });

  const {
    allIsoBays,
    blockBaysAndSizes,
    blockBaysAndSizesByBlockBay,
    blockBaysAndSizesBy20Bay,
  } = getBayBlocks(bls, pairedBaysCalc);

  const allVesselParts = preCalculateCurrentVesselParts(
    allIsoBays,
    vesselParts,
  );

  /** Contains the remaining distance of non-bay slots */
  let remainingPartsDistance = allVesselParts.reduce((acc, vp) => {
    if (typeof vp === "string") return acc;
    return acc + vp.len;
  }, 0);

  /** Total length of bays with separations */
  const totalBaysLength = blockBaysAndSizes.reduce(
    (acc, b) => acc + feetToMillimeters(b.blockSize),
    (blockBaysAndSizes.length - 1) * SEPARATION_IN_BETWEEN,
  );

  let i = 0;

  while (i < allVesselParts.length) {
    const vp = allVesselParts[i] as IBayPattern;
    if (typeof vp !== "string") {
      i++;
      continue;
    }

    if (blockBaysAndSizesBy20Bay[vp]?.numberOfBays === 2) {
      // Get both bays
      const pBays = blockBaysAndSizesBy20Bay[vp].allBays;
      // Substitute single by double
      allVesselParts[i] = pBays;
      // Find the paired bay
      const bayPaired = pBays.split("-").filter((c) => c !== vp)[0];
      const idx = allVesselParts.findIndex((v) => v === bayPaired);
      // Remove it
      allVesselParts.splice(idx, 1);
      i += 1;
    } else {
      i++;
    }
  }

  let remainingForeLcg = totalBaysLength;
  let prevBlock: string | undefined = undefined;

  allVesselParts.forEach((vp) => {
    if (typeof vp === "string") {
      const block = blockBaysAndSizesByBlockBay[vp];
      if (!block) return;

      const sep =
        prevBlock === undefined || prevBlock === block.allBays
          ? 0
          : SEPARATION_IN_BETWEEN;

      prevBlock = block.allBays;

      if (block.numberOfBays === 2) {
        const blockSize = block.blockSize;
        const blockSizeInMm = feetToMillimeters(blockSize);
        const mid40Lcg =
          remainingForeLcg + remainingPartsDistance - sep - blockSizeInMm * 0.5;
        const fore20Lcg = mid40Lcg + FEET_10_IN_MM;
        const aft20Lcg = mid40Lcg - FEET_10_IN_MM;

        const [vpFore, vpAft] = block.allBays.split("-") as IBayPattern[];

        // Fore 20
        lcgsBy20Bay[vpFore] = {
          lcg: roundDec(fore20Lcg, 2),
          aftLcg: roundDec(fore20Lcg - FEET_10_IN_MM, 2),
          foreLcg: roundDec(fore20Lcg + FEET_10_IN_MM, 2),
          paired: ForeAftEnum.AFT,
          maxSize: (block.maxSizes[vpFore] || 20) as TContainerLengths,
        };

        // Aft 20
        lcgsBy20Bay[vpAft] = {
          lcg: roundDec(aft20Lcg, 2),
          aftLcg: roundDec(aft20Lcg - FEET_10_IN_MM, 2),
          foreLcg: roundDec(aft20Lcg + FEET_10_IN_MM, 2),
          paired: ForeAftEnum.FWD,
          maxSize: (block.maxSizes[vpAft] || 20) as TContainerLengths,
        };

        remainingForeLcg = remainingForeLcg - sep - blockSizeInMm;
      } else {
        const blockSize = block.blockSize;
        const blockSizeInMm = feetToMillimeters(blockSize);
        const mid20Lcg =
          remainingForeLcg + remainingPartsDistance - sep - blockSizeInMm * 0.5;

        lcgsBy20Bay[vp as IBayPattern] = {
          lcg: mid20Lcg,
          aftLcg: roundDec(mid20Lcg - FEET_10_IN_MM, 2),
          foreLcg: roundDec(mid20Lcg + FEET_10_IN_MM, 2),
          paired: ForeAftEnum.FWD,
          maxSize: (block.maxSizes[vp as IBayPattern] ||
            20) as TContainerLengths,
        };

        remainingForeLcg = remainingForeLcg - sep - blockSizeInMm;
      }
    } else {
      remainingPartsDistance -= vp.len;
    }
  });
}
