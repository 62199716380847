import { ForeAftEnum } from "open-vessel-definition";
import { IGetBayLcgVcgAndPairingsResult } from "tedivo-bay-grid-pure";
import { IVesselOneBaySlot } from "../../../../../../vessel3D/types/IVesselOneBaySlot";
import { findComposedRefId } from "./findComposedRefId";
import { findReferenceLcg } from "./findReferenceLcg";
import { getClosestBaySlot } from "./getClosestBaySlot";
import { sortNumericAsc } from "@baplie-viewer2/tedivo-pure-helpers";

export function calculateSpaceAvailableNext({
  slotRefId,
  posRef,
  calculatedSlots,
  lcgVcgTcgAndPairings,
  includeLenOfId,
}: {
  slotRefId: string;
  posRef: ForeAftEnum;
  calculatedSlots: Array<IVesselOneBaySlot>;
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  includeLenOfId: string | undefined;
}) {
  const composedRefId = findComposedRefId(slotRefId, calculatedSlots);

  // Go FWD or AFT looking for the next closest bay
  const closestNextBay = getClosestBaySlot({
    id: composedRefId,
    posRef,
    isSelf: false,
    calculatedSlots,
  });

  if (!closestNextBay) return undefined;

  // Get the closest opposite bay
  const closestOppositeBay = getClosestBaySlot({
    id: composedRefId,
    posRef,
    isSelf: true,
    calculatedSlots,
  });

  if (!closestOppositeBay) return undefined;

  // If we looked for the closest bay in one direction, get the LCG in the opposite direction
  const lcgRefDir = findReferenceLcg(
    closestNextBay.id,
    posRef === ForeAftEnum.AFT ? ForeAftEnum.FWD : ForeAftEnum.AFT,
    lcgVcgTcgAndPairings,
  );

  // The same as above, but for the opposite bay
  const lcgOppDir = findReferenceLcg(
    closestOppositeBay.id,
    posRef,
    lcgVcgTcgAndPairings,
  );

  let ownLen = 0;

  if (includeLenOfId) {
    const ownIdx = calculatedSlots.findIndex((s) => s.id === includeLenOfId);
    const ownIdxAndExtremes = [
      ownIdx,
      closestNextBay.bayIndex,
      closestOppositeBay.bayIndex,
    ].sort(sortNumericAsc);

    const ownIdxPos = ownIdxAndExtremes.indexOf(ownIdx);

    if (ownIdxPos > 0 && ownIdxPos < ownIdxAndExtremes.length - 1) {
      ownLen = calculatedSlots[ownIdx].len || 0;
    }
  }

  const spaceAvailable =
    Math.abs(lcgOppDir - lcgRefDir) -
    closestNextBay.inBetweenLen -
    closestOppositeBay.inBetweenLen +
    ownLen;

  return spaceAvailable;
}
