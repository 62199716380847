/* eslint-disable @typescript-eslint/no-explicit-any */

import { IField, IFieldValues, IFields, TFormTypes } from "./IField";
import { Schema, ZodIssue, z } from "zod";

import { CheckboxGroup } from "../components/checkboxes/checkbox-group";
import { FileElement } from "../components/file/file.element";
import { InputWithUnits } from "../components/inputs/input-with-units.component";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlInput from "@shoelace-style/shoelace/dist/components/input/input";
import SlOption from "@shoelace-style/shoelace/dist/components/option/option";
import SlSelect from "@shoelace-style/shoelace/dist/components/select/select";
import SlTextarea from "@shoelace-style/shoelace/dist/components/textarea/textarea";
import { TedivoColorPicker } from "../components/inputs/color-picker";
import { TfCheckbox } from "../components/checkboxes/checkbox";
import { TfHidden } from "../components/inputs/input-hidden";
import { TfRadioGroup } from "../components/radios/radio-group";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";

export class TedivoForm<TFormSchema extends Record<string, any>> {
  public form: HTMLFormElement;
  public myId = `id-${Math.round(Math.random() * 10000)}`;

  private onSubmit: TOnSubmitFn<TFormSchema>;
  private formControlsByName = {} as ITedivoFormControls<TFormSchema>;
  private allFormNodes: ITedivoFormStateNode<TFormSchema>[] = [];
  private formValidator: Schema<TFormSchema>;
  private formProps?: IFormProps;
  private firstSubmit = false;
  private autoFocusOnFirstInput = false;
  private hiddenData: Partial<TFormSchema> | undefined = undefined;

  public onDataChange?: (
    values: TFormSchema,
    nameChanged?: keyof TFormSchema,
  ) => void;

  constructor({
    fields,
    onSubmit,
    formValidator,
    submitButton,
    formProps,
    hiddenData,
    autoFocusOnFirstInput = false,
  }: ITedivoFormProps<TFormSchema>) {
    this.form = document.createElement("form");
    this.form.className = `tedivo-form ${formProps?.className ?? ""}`;
    this.form.method = "POST";
    this.form.noValidate = true;
    this.formProps = formProps;
    this.form.id = formProps?.id || this.myId;

    this.autoFocusOnFirstInput = autoFocusOnFirstInput;

    this.onSubmit = onSubmit;
    this.formValidator = formValidator;
    this.hiddenData = hiddenData;

    this.addSectionsAndFieldsToForm(fields);

    if (!isElementContainedInParent(submitButton, this.form)) {
      const hiddenSubmit = document.createElement("input");
      hiddenSubmit.type = "submit";
      hiddenSubmit.hidden = true;
      this.form.appendChild(hiddenSubmit);
    }

    this.form.onsubmit = this.submitForm;
  }

  private addSectionsAndFieldsToForm = (fields: IFields<TFormSchema>) => {
    let sectionHolder: HTMLElement | undefined = undefined;
    let autoFocusSet = !this.autoFocusOnFirstInput;

    const setAutofocus = (field: HTMLElement) => {
      field.setAttribute("autofocus", "");
      setTimeout(() => {
        try {
          if (field !== null) field.focus();
        } catch (e) {
          //Do nothing
        }
      }, 100);
    };

    fields.forEach((fA) => {
      if (fA === undefined) return;
      // Row holder
      const rowHolder = document.createElement("div");

      const createField = (
        fieldProps: IField<TFormSchema> | undefined,
      ): IFieldsTypes | HTMLHeadingElement | HTMLElement | undefined => {
        if (fieldProps === undefined) return undefined;

        let formField: IFieldsTypes | undefined = undefined;
        let titleField: HTMLHeadingElement | HTMLParagraphElement | undefined =
          undefined;
        let nodeField: HTMLElement | undefined = undefined;

        const name = fieldProps.name;
        const stateRecord = {
          name,
          value: undefined,
          type: fieldProps.type,
          props: fieldProps,
        } as ITedivoFormStateRecord<TFormSchema>;

        let initVal: any = undefined;

        switch (fieldProps.type) {
          case "title":
            titleField = document.createElement(fieldProps.tagName ?? "h2");
            titleField.className = "subtitle";
            //stateRecord.field = titleField;
            break;

          case "node":
            nodeField = fieldProps.node;
            break;

          case "hidden":
            formField = document.createElement("tf-hidden");
            formField.style.display = "none";

            initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
            if (initVal !== undefined) {
              formField.value = String(initVal);
              stateRecord.value = String(initVal);
            }

            stateRecord.field = formField;

            break;

          case "color":
            formField = document.createElement("tf-input-color");
            if (fieldProps.initialValue) {
              initVal = fieldProps.initialValue;
              stateRecord.value = initVal;
              formField.value = initVal;
            }

            stateRecord.field = formField;

            break;
          case "textBox":
          case "date":
            formField = document.createElement("sl-input");

            if (fieldProps.type === "date") {
              formField.type = "date";
              if (fieldProps.initialValue) {
                initVal =
                  typeof fieldProps.initialValue === "string"
                    ? new Date((fieldProps as any).initialValue)
                    : fieldProps.initialValue;
              }

              if (initVal !== undefined) {
                formField.valueAsDate = initVal;
                stateRecord.value = initVal;
              }
            } else {
              formField.type = !fieldProps.isPassword ? "text" : "password";
              initVal = fieldProps.initialValue ?? fieldProps.defaultValue;

              if (fieldProps.placeholder)
                formField.placeholder = fieldProps.placeholder;
              if (fieldProps.autocapitalize)
                formField.autocapitalize = "characters";
              if (fieldProps.disabled) formField.disabled = true;

              if (fieldProps.isPassword) formField.passwordToggle = true;

              if (initVal !== undefined) {
                formField.value = String(initVal);
                stateRecord.value = String(initVal);
              }
            }

            stateRecord.field = formField;

            formField.autocorrect = "off";

            if (!autoFocusSet) {
              autoFocusSet = true;
              setAutofocus(formField);
            }

            break;

          case "textArea":
            formField = document.createElement("sl-textarea");
            stateRecord.field = formField;

            initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
            if (initVal !== undefined) {
              formField.value = String(initVal);
              stateRecord.value = String(initVal);
            }

            if (fieldProps.placeholder)
              formField.placeholder = fieldProps.placeholder;
            if (fieldProps.autocapitalize)
              formField.autocapitalize = "characters";
            formField.autocorrect = "off";

            if (!autoFocusSet) {
              autoFocusSet = true;
              setAutofocus(formField);
            }
            break;

          case "number":
            formField = document.createElement("sl-input");
            formField.type = "number";
            stateRecord.field = formField;

            initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
            if (initVal !== undefined) {
              formField.value = String(initVal);
              stateRecord.value = initVal;
            }

            if (fieldProps.placeholder)
              formField.placeholder = fieldProps.placeholder;
            if (fieldProps.step !== undefined) formField.step = fieldProps.step;

            if (!autoFocusSet) {
              autoFocusSet = true;
              setAutofocus(formField);
            }

            break;

          case "file":
            formField = document.createElement("tf-file");
            if (fieldProps.accept) formField.accept = fieldProps.accept;
            stateRecord.value = fieldProps.initialValue;
            stateRecord.field = formField;

            break;

          case "checkbox":
            formField = document.createElement("tf-checkbox");
            if (fieldProps.isNumericEnum) formField.isNumericEnum = true;

            initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
            if (initVal !== undefined) {
              formField.value = !!initVal;
              stateRecord.value = fieldProps.isNumericEnum
                ? initVal
                : Boolean(initVal);
            }

            if (fieldProps.disabled !== undefined)
              formField.disabled = fieldProps.disabled;
            if (fieldProps.padStart) formField.padStart = true;
            if (fieldProps.required) formField.required = true;

            stateRecord.field = formField;
            break;

          case "radioButtonList":
            formField = document.createElement("tf-radio-group");
            formField.items = fieldProps.options;
            formField.isNumericEnum = !!fieldProps.isNumericEnum;
            if (fieldProps.fieldset) formField.fieldset = true;

            initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
            if (initVal !== undefined) {
              formField.value = String(initVal);
              stateRecord.value = initVal;
            }

            if (fieldProps.disabled) formField.disabled = true;

            stateRecord.field = formField;

            break;

          case "checkboxesList":
            formField = document.createElement("tf-checkbox-group");
            formField.items = fieldProps.options;
            formField.isNumericEnum = !!fieldProps.isNumericEnum;
            if (fieldProps.fieldset) formField.fieldset = true;

            if (fieldProps.fixedOptionWidth)
              formField.fixedOptionWidth = fieldProps.fixedOptionWidth;

            initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
            if (initVal !== undefined) {
              formField.value = initVal;
              stateRecord.value = initVal;
            }

            if (fieldProps.disabled !== undefined)
              formField.disabled = fieldProps.disabled;

            if (fieldProps.helpers) formField.helpers = fieldProps.helpers;

            stateRecord.field = formField;

            break;

          case "numberWithUnits":
            formField = document.createElement("tf-input-units");
            stateRecord.field = formField;
            formField.converter = fieldProps.converter;
            formField.transponser = fieldProps.transponser;

            formField.addEventListener(
              "keyup",
              (ev: KeyboardEvent) => {
                if (ev.key === "Enter") this.submitForm({} as SubmitEvent);
              },
              false,
            );

            initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
            if (initVal !== undefined) {
              formField.value = initVal;
              stateRecord.value = initVal;
            }

            if (!autoFocusSet) {
              autoFocusSet = true;
              formField.autofocus = true;
            }

            break;

          case "select":
            formField = document.createElement("sl-select");
            fieldProps.options.forEach(({ value, label, disabled }) => {
              const opt = document.createElement("sl-option") as SlOption;
              opt.value = String(value);
              opt.innerHTML = label;
              if (disabled) opt.disabled = true;
              formField?.appendChild(opt);
            });

            initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
            if (initVal !== undefined) {
              formField.value = String(initVal);
              stateRecord.value = String(initVal);
            }

            if (fieldProps.hoist) formField.hoist = true;

            stateRecord.field = formField;

            break;
        }

        switch (fieldProps.type) {
          case "title":
            // Title (heading)
            if (titleField)
              this.allFormNodes.push({
                value: undefined,
                type: "title",
                field: titleField,
                props: fieldProps,
              });

            return titleField;

          case "node":
            if (nodeField) {
              this.allFormNodes.push({
                value: undefined,
                type: "node",
                props: fieldProps,
              } as ITedivoFormStateNode<TFormSchema>);
            }
            return nodeField;

          default:
            if (formField === undefined) return undefined;

            // Keeps the value of previous rendered fields
            if (name && this.formControlsByName[name]) {
              stateRecord.value = this.formControlsByName[name].value;
            }

            formField.id = String(fieldProps.name);
            formField.name = String(fieldProps.name);
            formField.size =
              fieldProps.size ?? this.formProps?.size ?? "medium";

            if (fieldProps.label) formField.label = fieldProps.label;
            if (fieldProps.helpText)
              (formField as any).helpText = fieldProps.helpText;

            formField.addEventListener("sl-change", this.onChangeValue, false);

            if ((fieldProps as any).inputListener) {
              formField.addEventListener("sl-input", (ev) => {
                this.onChangeValue(ev);
              });
            }

            if (name !== undefined) this.formControlsByName[name] = stateRecord;

            this.allFormNodes.push(stateRecord);
            return formField;
        }
      };

      if (Array.isArray(fA)) {
        fA.slice()
          .map((fB) => createField(fB))
          .forEach((c) => {
            if (c) rowHolder.appendChild(c);
          });
      } else {
        const field = createField(fA);
        if (fA.type === "title" && fA.createSection) {
          sectionHolder = document.createElement("section");
          this.form.appendChild(sectionHolder);
        }

        if (field) rowHolder.appendChild(field);
      }

      rowHolder.className = `form-fields-row ${
        !Array.isArray(fA) ? fA.type : ""
      } ${
        (Array.isArray(fA)
          ? fA.map((fB) => fB?.holderClassName || "").join(" ")
          : fA.holderClassName) || ""
      }`;

      if (sectionHolder) sectionHolder.appendChild(rowHolder);
      else this.form.appendChild(rowHolder);
    });
  };

  private onChangeValue = (ev: Event) => {
    const target = ev.target as HTMLInputElement;
    const value = target.value;
    const name = target.id as keyof TFormSchema;

    const stateRecord = this.formControlsByName[name];

    if (!stateRecord) return;

    if (
      stateRecord.type === "number" ||
      stateRecord.type === "numberWithUnits"
    ) {
      if (value === undefined || value === "") {
        stateRecord.value = undefined;
      } else {
        stateRecord.value = Number(value);
      }
    } else {
      stateRecord.value = value;
    }

    if (this.firstSubmit) {
      const values = this.getValues();

      const validationResult = this.formValidator.safeParse({
        ...values,
        ...this.hiddenData,
      });

      this.markValidFields(validationResult);
    }

    if (this.onDataChange) {
      this.onDataChange(this.getValues(), name);
    }
  };

  private submitForm = (ev: SubmitEvent) => {
    if (ev.preventDefault) ev.preventDefault();

    this.doSubmitForm();

    return false;
  };

  public setFields(fields: IFields<TFormSchema>) {
    removeChildren(this.form);
    this.addSectionsAndFieldsToForm(fields);
    return this;
  }

  public setFormValidator(newValidator: Schema<TFormSchema>) {
    this.formValidator = newValidator;
    return this;
  }

  doSubmitForm = ():
    | z.SafeParseSuccess<TFormSchema>
    | z.SafeParseError<TFormSchema> => {
    this.firstSubmit = true;
    const values = this.getValues();
    const validationResult = this.formValidator.safeParse({
      ...values,
      ...this.hiddenData,
    });

    if (validationResult.success) {
      const data: TFormSchema = {
        ...this.hiddenData,
        ...validationResult.data,
      };
      this.onSubmit(data);
    } else {
      this.markValidFields(validationResult);
    }

    return validationResult;
  };

  private markValidFields(
    validationResult:
      | z.SafeParseSuccess<TFormSchema>
      | z.SafeParseError<TFormSchema>,
  ) {
    const validationResultError =
      validationResult as z.SafeParseError<TFormSchema>;

    const issues = validationResultError.error
      ? validationResultError.error.issues
      : [];

    const issuesByPath: { [name: string]: ZodIssue } = issues.reduce(
      (acc, v) => {
        const n = String(v.path[0]);
        acc[n] = v;
        return acc;
      },
      {} as { [name: string]: ZodIssue },
    );

    const fieldNames = Object.keys(
      this.formControlsByName,
    ) as (keyof TFormSchema)[];
    let firstError: HTMLElement | undefined = undefined;

    fieldNames.forEach((fc) => {
      const formControl = this.formControlsByName[fc];
      const field = formControl?.field;

      if (formControl && field) {
        if (issuesByPath[formControl.name as string]) {
          // has error
          if (!field.classList.contains("has-error"))
            field.classList.add("has-error");

          if (!firstError && formControl.type !== "file") firstError = field;
        } else {
          // no error
          field.classList.remove("has-error");
        }
      }
    });

    if (firstError) {
      const field = firstError as HTMLInputElement;
      const formControl = this.formControlsByName[field.id];
      field.focus();
      if (
        formControl &&
        (formControl.type === "number" ||
          formControl.type === "numberWithUnits" ||
          formControl.type === "checkbox" ||
          formControl.type === "textBox") &&
        !(formControl.props as any).inputListener
      )
        field.select();
    }
  }

  getFormControlsByName(): ITedivoFormControls<TFormSchema> {
    return {
      ...this.formControlsByName,
    };
  }

  getAllFormNodes(): ITedivoFormStateNode<TFormSchema>[] {
    return this.allFormNodes;
  }

  getValues(): TFormSchema {
    const fieldNames = Object.keys(
      this.formControlsByName,
    ) as (keyof TFormSchema)[];
    const values: IValuesByName<TFormSchema> = fieldNames.reduce((acc, v) => {
      acc[v] = this.formControlsByName[v]?.value;
      return acc;
    }, {} as IValuesByName<TFormSchema>);
    return values as TFormSchema;
  }

  setValue(key: keyof TFormSchema, value: string | number | undefined) {
    const field = this.formControlsByName[key];

    if (field) {
      const defaultValue = (field.props as any).defaultValue;
      const finalValue = value ?? defaultValue;
      field.value = finalValue;
      (field.field as any).value = finalValue;
    } else {
      if (this.hiddenData !== undefined && this.hiddenData?.[key] !== undefined)
        this.hiddenData = {
          ...this.hiddenData,
          [key]: value,
        };
    }
    return this;
  }

  focusOnFirstElement = (delay = 500) => {
    const fieldNames = Object.keys(
      this.formControlsByName,
    ) as (keyof TFormSchema)[];

    if (!fieldNames.length) return;

    const formControl = this.formControlsByName[fieldNames[0]];
    const field = formControl?.field;

    if (field) {
      if (delay) {
        setTimeout(() => field.focus(), delay);
      } else {
        field.focus();
      }
    }
  };

  execValidation(fields: (keyof TFormSchema)[] = []): boolean {
    const values = this.getValues();
    const validationResult = this.formValidator.safeParse(values);

    if (fields.length === 0) {
      this.markValidFields(validationResult);
      return validationResult.success;
    } else if (!validationResult.success) {
      const error = validationResult.error;
      const subsetErrors = error.errors.reduce((acc, issue) => {
        const isChecked = issue.path.some(
          (p) => fields.indexOf(p as string) >= 0,
        );

        if (isChecked) acc.push(issue);
        return acc;
      }, [] as z.ZodIssue[]);

      this.markValidFields({
        ...validationResult,
        error: {
          ...error,
          errors: subsetErrors,
        } as z.ZodError<TFormSchema>,
      });

      return subsetErrors.length === 0;
    } else {
      return true;
    }
  }
}

export type IFieldsTypes<T = any> =
  | SlInput
  | SlTextarea
  | TfCheckbox
  | SlSelect
  | FileElement
  | TfRadioGroup
  | CheckboxGroup
  | TfHidden
  | TedivoColorPicker
  | InputWithUnits<T>;

export type ITedivoFormControls<TFormSchema extends Record<string, any>> =
  Record<keyof TFormSchema, ITedivoFormStateRecord<TFormSchema>>;

export type ITedivoFormStateRecord<TFormSchema extends Record<string, any>> = {
  name: keyof TFormSchema;
  type: TFormTypes;
  value: IFieldValues | undefined;
  field: IFieldsTypes;
  isNumeric?: boolean;
  props: IField<TFormSchema>;
};

export type ITedivoFormTitleNode<TFormSchema extends Record<string, any>> = {
  name?: never;
  type: "title";
  field: HTMLHeadingElement;
  value?: never;
  isNumeric?: never;
  props: IField<TFormSchema>;
};

export type ITedivoFormStateNode<TFormSchema extends Record<string, any>> =
  | ITedivoFormStateRecord<TFormSchema>
  | ITedivoFormTitleNode<TFormSchema>;

export type IValuesByName<TFormSchema extends Record<string, any>> = Partial<
  Record<keyof TFormSchema, IFieldValues>
>;

interface ITedivoFormProps<FormSchema extends Record<string, any>> {
  fields: IFields<FormSchema>;
  onSubmit: (values: FormSchema) => void;
  formValidator: Schema<FormSchema>;
  submitButton: SlButton | HTMLButtonElement;
  formProps?: IFormProps;
  autoFocusOnFirstInput?: boolean;
  hiddenData?: Partial<FormSchema>;
}

interface IFormProps {
  size?: "small" | "medium" | "large";
  className?: string;
  id?: string;
}

export type TOnSubmitFn<FormSchema> = (values: FormSchema) => void;

function isElementContainedInParent(e: HTMLElement, parent: HTMLElement) {
  let p = e.parentElement;
  while (p !== null) {
    if (p === parent) return true;
    p = p.parentElement;
  }
  return false;
}

export interface IFormReturn<T extends Record<string, any>> {
  node: HTMLElement;
  submitFunction: () => boolean | Promise<boolean>;
  tedivoForm: TedivoForm<T>;
}
