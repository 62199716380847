export function createObserverWithCallback(
  callback: () => void,
  {
    threshold = [0, 1],
    rootMargin = "100px 0px",
    delay = 0,
  }: CreateObserverWithCallbackOptionsProps,
): IntersectionObserver {
  const ioOptions = {
    rootMargin,
    threshold,
  };

  const handleIntersect = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
  ) => {
    for (let e = 0; e < entries.length; e += 1) {
      if (entries[e].isIntersecting) {
        const target = entries[e].target as HTMLElement;

        //Disconnect
        if (target) observer.unobserve(target);

        //Do the stuff
        if (delay) {
          setTimeout(() => {
            callback();
          }, delay);
        } else {
          callback();
        }
      }
    }
  };

  return new IntersectionObserver(handleIntersect, ioOptions);
}

export enum IntersectStatusEnum {
  Initialized = 0,
  WaitingForIntersection = 1,
  Rendered = 2,
}

interface CreateObserverWithCallbackOptionsProps {
  threshold?: number[];
  rootMargin?: string;
  delay?: number;
}
