import {
  I20LcgsByBay,
  IBaySizesAndCgs,
  IGetPairedBaysOutput,
} from "tedivo-bay-grid-pure";
import { IBayLevelData, IVesselParts } from "open-vessel-definition";

import { findForCompleteCGs } from "./generate20Helpers/complete/findForCompleteCGs";
import { findForMissingCgs } from "./generate20Helpers/findForMissingCgs";
import { pad3 } from "@baplie-viewer2/tedivo-pure-helpers";

/**
 * Given a set of LCGs of different sizes, find the LCG for every 20 bay
 * @param sizeSummary
 * @param bayLevelSizesAndCgsAbove
 * @param bayLevelSizesAndCgsBelow
 * @param missingImportantXcgs boolean
 * @returns An object that maps IsoBayNumber to 20's LCG. It doesn't differentiate between ABOVE and BELOW
 */
export default function generate20Lcgs({
  maxIsoBay,
  bls,
  bayLevelSizesAndCgsAbove,
  bayLevelSizesAndCgsBelow,
  vesselParts,
  missingImportantXcgs,
  pairedBaysCalc,
}: {
  maxIsoBay: number;
  bls: IBayLevelData[];
  bayLevelSizesAndCgsAbove: IBaySizesAndCgs[];
  bayLevelSizesAndCgsBelow: IBaySizesAndCgs[];
  vesselParts: IVesselParts[];
  missingImportantXcgs: boolean | undefined;
  pairedBaysCalc: IGetPairedBaysOutput;
}): I20LcgsByBay {
  const lcgsBy20Bay: I20LcgsByBay = {};

  for (let bay = 1; bay <= maxIsoBay; bay += 2) {
    lcgsBy20Bay[pad3(bay)] = {
      aftLcg: 0,
      foreLcg: 0,
      lcg: undefined,
      maxSize: 20,
    };
  }

  if (!missingImportantXcgs) {
    findForCompleteCGs(
      bayLevelSizesAndCgsBelow,
      bayLevelSizesAndCgsAbove,
      lcgsBy20Bay,
    );
  } else {
    findForMissingCgs(
      bls,
      bayLevelSizesAndCgsBelow,
      bayLevelSizesAndCgsAbove,
      vesselParts,
      pairedBaysCalc,
      lcgsBy20Bay,
    );
  }

  return lcgsBy20Bay;
}
