import { I20LcgsByBay, IBayPattern } from "tedivo-bay-grid-pure";

import { ForeAftEnum } from "open-vessel-definition";
import { IBaySizesAndCgs } from "../../../../../../tedivo-bay-grid-pure/src/lib/types/IGetBayLcgVcgAndPairingsResult";
import { createSvgText } from "./createSvgText";
import { sortNumericAsc } from "@baplie-viewer2/tedivo-pure-helpers";

interface ISetTopBayNumbersProps {
  blps: IBaySizesAndCgs[];
  lcgsBy20Bay: I20LcgsByBay;
  svgNode: SVGElement;
  xPos: (x: number, dec?: number) => number;
  fontColor: string | undefined;
  onDoubleClick?: (ev: SVGElement) => void;
}

function handleDoubleClick(node: SVGElement, fn: (ev: SVGElement) => void) {
  let lastClicked = 0;
  node.addEventListener(
    "click",
    () => {
      const diff = Date.now() - lastClicked;

      if (diff < 500) {
        fn(node);
      }

      lastClicked = Date.now();
    },
    false,
  );
}

export function setTopBayNumbers({
  blps,
  lcgsBy20Bay,
  svgNode,
  xPos,
  fontColor,
  onDoubleClick,
}: ISetTopBayNumbersProps): void {
  const blpsByBay: { [bay: IBayPattern]: IBaySizesAndCgs } = {};
  blps.forEach((blp) => {
    blpsByBay[blp.isoBay] = blp;
  });

  const allBays = Object.keys(lcgsBy20Bay).sort(
    sortNumericAsc,
  ) as IBayPattern[];

  allBays.forEach((bay) => {
    const x = lcgsBy20Bay[bay].lcg;

    if (x !== undefined) {
      const bayNumber = createSvgText({
        text: getDecoratedIsoNumber(blpsByBay[bay] || bay),
        x: xPos(x, 2),
        y: 6,
        fontSize: 8,
        textAnchor: "middle",
        textColor: fontColor || "#aaa",
      });

      // bayNumber.setAttribute("font-family", "Arial, Helvetica, sans-serif");
      bayNumber.dataset["bay"] = bay;
      svgNode.appendChild(bayNumber);

      if (onDoubleClick) {
        handleDoubleClick(bayNumber, onDoubleClick);
      }
    }
  });

  function getDecoratedIsoNumber(blp: IBaySizesAndCgs | string): string {
    if (typeof blp === "string") return blp;

    if (blp.pairedBay) {
      return blp.pairedBay === ForeAftEnum.FWD
        ? `${blp.isoBay}&middot;`
        : `&middot;${blp.isoBay}`;
    }
    return blp.isoBay;
  }
}
