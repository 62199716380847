import {
  IVesselOneColorsParametrization,
  IVesselOneLabelParametrization,
  IVesselOneParametrizationFormElements,
  IVesselOneTailParametrization,
} from "../../vessel3D/types/IVesselOneParametrization";

import { IFields } from "@baplie-viewer2/tedivo-form";
import { z } from "zod";

export const vesselOneFormValidator: z.Schema<IVesselOneParametrizationFormElementsTdvFrm> =
  z.object({
    // visible: z.boolean(),
    // shellVisible: z.boolean(),

    // bayNumbersVisible: z.boolean(),
    // deckVisible: z.boolean(),
    // belowSeparatorsVisible: z.boolean(),
    labelVesselName: z
      .string()
      .min(1)
      .transform((v) => v?.toUpperCase()),
    labelIMOCode: z
      .string()
      .min(1)
      .transform((v) => v?.toUpperCase()),
    labelLateral: z
      .string()
      .optional()
      .transform((v) => v?.toUpperCase()),
    labelCountry: z
      .string()
      .optional()
      .transform((v) => v?.toUpperCase()),

    mainColor: z.string(),
    belowSeparatorColor: z.string(),
    bottomColor: z.string(),
    bridgeColor: z.string(),
    cranesColor: z.string(),
    labelsBaysColor: z.string(),
    labelsNoseColor: z.string(),
    labelsSideColor: z.string(),
    labelsTailColor: z.string(),
    separatorColor: z.string(),
    smokeBottomColor: z.string(),
    smokeTopColor: z.string(),

    propellers: z.number().min(1).max(2),
    rudders: z.number().min(1).max(2),

    // aboveSeparators: z.number().min(0).max(4),
  });

export function createVesselOneFormFields(
  params: IVesselOneParametrizationFormElements,
) {
  const formFields: IFields<IVesselOneParametrizationFormElementsTdvFrm> = [
    // [
    //   {
    //     name: "visible",
    //     label: "view:view3D.params.visible",
    //     type: "checkbox",
    //     initialValue: params.visible,
    //   },
    //   {
    //     name: "shellVisible",
    //     label: "view:view3D.params.shellVisible",
    //     type: "checkbox",
    //     initialValue: params.shellVisible,
    //   },
    //   {
    //     name: "deckVisible",
    //     label: "view:view3D.params.deckVisible",
    //     type: "checkbox",
    //     initialValue: params.deckVisible,
    //   },
    //   {
    //     name: "bayNumbersVisible",
    //     label: "view:view3D.params.bayNumbersVisible",
    //     type: "checkbox",
    //     initialValue: params.bayNumbersVisible,
    //   },
    //   {
    //     name: "belowSeparatorsVisible",
    //     label: "view:view3D.params.belowSeparatorsVisible",
    //     type: "checkbox",
    //     initialValue: params.belowSeparatorsVisible,
    //   },
    // ],

    {
      type: "title",
      createSection: true,
      label: "view:view3D.sections.labels",
    },
    [
      {
        name: "labelVesselName",
        label: "view:view3D.params.labelVesselName",
        type: "textBox",
        initialValue: params.labels.labelVesselName,
      },
      {
        name: "labelLateral",
        label: "view:view3D.params.labelLateral",
        type: "textBox",
        initialValue: params.labels.labelLateral,
      },

      {
        name: "labelIMOCode",
        label: "view:view3D.params.labelIMOCode",
        type: "textBox",
        initialValue: params.labels.labelIMOCode,
      },
      {
        name: "labelCountry",
        label: "view:view3D.params.labelCountry",
        type: "textBox",
        initialValue: params.labels.labelCountry,
      },
    ],

    {
      type: "title",
      createSection: true,
      label: "view:view3D.sections.otherOptions",
    },
    [
      {
        name: "propellers",
        label: "view:view3D.params.propellers",
        type: "number",
        initialValue: params.tailOptions.propellers,
      },
      {
        name: "rudders",
        label: "view:view3D.params.rudders",
        type: "number",
        initialValue: params.tailOptions.rudders,
      },
    ],

    {
      type: "title",
      createSection: true,
      label: "view:view3D.sections.colors",
    },
    [
      {
        name: "mainColor",
        label: "view:view3D.params.mainColor",
        type: "color",
        initialValue: params.colors.mainColor,
      },
      {
        name: "bottomColor",
        label: "view:view3D.params.bottomColor",
        type: "color",
        initialValue: params.colors.bottomColor,
      },
      {
        name: "bridgeColor",
        label: "view:view3D.params.bridgeColor",
        type: "color",
        initialValue: params.colors.bridgeColor,
      },
      {
        name: "cranesColor",
        label: "view:view3D.params.cranesColor",
        type: "color",
        initialValue: params.colors.cranesColor,
      },

      {
        name: "separatorColor",
        label: "view:view3D.params.separatorColor",
        type: "color",
        initialValue: params.colors.separatorColor,
      },
      {
        name: "belowSeparatorColor",
        label: "view:view3D.params.belowSeparatorColor",
        type: "color",
        initialValue: params.colors.belowSeparatorColor,
      },

      {
        name: "smokeBottomColor",
        label: "view:view3D.params.smokeBottomColor",
        type: "color",
        initialValue: params.colors.smokeBottomColor,
      },
      {
        name: "smokeTopColor",
        label: "view:view3D.params.smokeTopColor",
        type: "color",
        initialValue: params.colors.smokeTopColor,
      },

      {
        name: "labelsBaysColor",
        label: "view:view3D.params.labelsBaysColor",
        type: "color",
        initialValue: params.colors.labelsBaysColor,
      },
      {
        name: "labelsNoseColor",
        label: "view:view3D.params.labelsNoseColor",
        type: "color",
        initialValue: params.colors.labelsNoseColor,
      },
      {
        name: "labelsSideColor",
        label: "view:view3D.params.labelsSideColor",
        type: "color",
        initialValue: params.colors.labelsSideColor,
      },
      {
        name: "labelsTailColor",
        label: "view:view3D.params.labelsTailColor",
        type: "color",
        initialValue: params.colors.labelsTailColor,
      },
    ],
  ];

  return formFields;
}

export type IVesselOneParametrizationFormElementsTdvFrm =
  IVesselOneLabelParametrization &
    IVesselOneColorsParametrization &
    IVesselOneTailParametrization;
