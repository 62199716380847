import {
  ICreateBayGridPartProps,
  ICreateGridFromConfigProps,
  IGridPartDimensions,
} from "../../types/ICreateBayGridBaseProps";
import { IRowPattern, ISlotCombinedPattern } from "tedivo-bay-grid-pure";

import { sortRowsArray } from "../../../../../../tedivo-bay-grid-pure/src/lib/sortRowsArray";

export function calculateGridPartDimensions<U>({
  tiersArray,
  rowsArray,
  containerWidth,
  containerHeight,
  displayNumbers,
  cellSeparation = 0,
}: Omit<ICreateBayGridPartProps<U>, "nodesOptions">): IGridPartDimensions {
  const addLeftWidth = Number(!!displayNumbers.left) * containerWidth,
    addRightWidth = Number(!!displayNumbers.right) * containerWidth,
    addTopHeight = Number(!!displayNumbers.top) * containerHeight,
    addBottomHeight = Number(!!displayNumbers.bottom) * containerHeight;

  const numberOfTiers = tiersArray.length,
    numberOfRows = rowsArray.length;

  const widthOfCells =
      containerWidth * numberOfRows + cellSeparation * (numberOfRows - 1),
    heightOfCells =
      containerHeight * numberOfTiers + cellSeparation * (numberOfTiers - 1);

  const widthOfGrid = addLeftWidth + widthOfCells + addRightWidth;
  const heightOfGrid = addTopHeight + heightOfCells + addBottomHeight;

  const cellPositions: {
    [pos: ISlotCombinedPattern]: { x: number; y: number };
  } = {};

  const tiersArraySortedDesc = tiersArray.sort(sortDesc);

  // Boxes for each cell
  for (let t = 0; t < numberOfTiers; t += 1) {
    for (let c = 0; c < numberOfRows; c += 1) {
      const name =
        `${rowsArray[c]}|${tiersArraySortedDesc[t]}` as ISlotCombinedPattern;

      const x = addLeftWidth + c * containerWidth + cellSeparation * c;
      const y = addTopHeight + t * containerHeight + cellSeparation * t;

      cellPositions[name] = { x, y };
    }
  }

  return {
    numberOfTiers,
    numberOfRows,
    addLeftWidth,
    addRightWidth,
    addTopHeight,
    addBottomHeight,
    widthOfCells,
    heightOfCells,
    widthOfGrid,
    heightOfGrid,
    cellPositions,
  };
}

export function calculateGridFromConfigDimensions<U>({
  minAboveTier,
  maxAboveTier,
  minBelowTier,
  maxBelowTier,
  centerLineRow,
  maxRow,
  containerWidth,
  containerHeight,
  cellSeparation,
  belowGridSeparation,
}: Omit<
  ICreateGridFromConfigProps<U>,
  "nodesOptions"
>): ICalculatedGridDimensions {
  let aboveTiersArray: Array<IRowPattern> | undefined = undefined;
  let belowTiersArray: Array<IRowPattern> | undefined = undefined;

  let aboveDimensions: IGridPartDimensions | undefined = undefined;
  let belowDimensions: IGridPartDimensions | undefined = undefined;

  const cellPositions: {
    [pos: ISlotCombinedPattern]: { x: number; y: number };
  } = {};

  const minRow = centerLineRow ? 0 : 1;

  // Rows
  const rowsArray = new Array(Number(maxRow) + (centerLineRow ? 1 : 0))
    .fill(1)
    .map((_, idx) => String(idx + minRow).padStart(2, "0") as IRowPattern)
    .sort(sortRowsArray);

  // Above Tiers
  if (minAboveTier !== undefined && maxAboveTier !== undefined) {
    const from = Number(minAboveTier),
      to = Number(maxAboveTier);

    aboveTiersArray = [];
    for (let i = from; i <= to; i += 2)
      aboveTiersArray.push(String(i).padStart(2, "0") as IRowPattern);
  }

  // Below Tiers
  if (minBelowTier !== undefined && maxBelowTier !== undefined) {
    const from = Number(minBelowTier),
      to = Number(maxBelowTier);

    belowTiersArray = [];
    for (let i = from; i <= to; i += 2)
      belowTiersArray.push(String(i).padStart(2, "0") as IRowPattern);
  }

  let width = 0,
    height = 0;

  if (aboveTiersArray) {
    aboveDimensions = calculateGridPartDimensions({
      tiersArray: aboveTiersArray,
      rowsArray,
      containerWidth,
      containerHeight,
      displayNumbers: { left: true, right: true, top: true },
      cellSeparation,
    });

    if (aboveDimensions !== undefined) {
      width = aboveDimensions.widthOfGrid;
      height = aboveDimensions.heightOfGrid;

      Object.assign(cellPositions, aboveDimensions.cellPositions);

      if (belowTiersArray) height += belowGridSeparation;
    }
  }

  if (belowTiersArray) {
    belowDimensions = calculateGridPartDimensions({
      tiersArray: belowTiersArray,
      rowsArray,
      containerWidth,
      containerHeight,
      displayNumbers: { left: true, right: true, bottom: true },
      cellSeparation,
    });

    // Add current height from above to Y
    if (belowDimensions !== undefined) {
      const belowCellPositions = belowDimensions.cellPositions;
      (Object.keys(belowCellPositions) as ISlotCombinedPattern[]).forEach(
        (k) => {
          cellPositions[k] = {
            x: belowCellPositions[k].x,
            y: belowCellPositions[k].y + height,
          };
        },
      );

      width = belowDimensions.widthOfGrid;
      height += belowDimensions.heightOfGrid;
    }
  }

  return {
    width,
    height,
    cellPositions,
    aboveDimensions,
    belowDimensions,
  };
}

function sortDesc(a: string, b: string) {
  return Number(b) - Number(a);
}

export interface ICalculatedGridDimensions {
  width: number;
  height: number;
  cellPositions: ICellPositionsObject;
  aboveDimensions?: IGridPartDimensions;
  belowDimensions?: IGridPartDimensions;
}

export interface ICellPositionsObject {
  [pos: ISlotCombinedPattern]: { x: number; y: number };
}
