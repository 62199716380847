import { I20LcgsByBay, IBaySizesAndCgs } from "tedivo-bay-grid-pure";

import { fillMissingPlausibleData } from "./fillMissingPlausibleData";
import { find20Lcgs } from "./find20Lcgs";

export function findForCompleteCGs(
  bayLevelSizesAndCgsBelow: IBaySizesAndCgs[],
  bayLevelSizesAndCgsAbove: IBaySizesAndCgs[],
  lcgsBy20Bay: I20LcgsByBay,
) {
  [...bayLevelSizesAndCgsBelow, ...bayLevelSizesAndCgsAbove]
    .filter(
      (b) =>
        lcgsBy20Bay[b.isoBay]?.lcg === undefined &&
        b.maxSizeLcg?.size !== undefined,
    )
    .forEach(find20Lcgs(lcgsBy20Bay));

  fillMissingPlausibleData(lcgsBy20Bay);
}
