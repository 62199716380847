import { createRowsFromConfig, } from "tedivo-bay-grid-pure";
export function lidDataValidatorForBays({ sizeSummary, lids, blockBaysAndSizesBy20Bay, rowTcgsByBay, }) {
    if (sizeSummary.maxRow === undefined)
        return [];
    const rowsArraySorted = createRowsFromConfig(!!sizeSummary.centerLineRow, sizeSummary.maxRow);
    return lids.map((lid) => {
        const portIndex = rowsArraySorted.indexOf(lid.portIsoRow);
        const stbdIndex = rowsArraySorted.indexOf(lid.starboardIsoRow);
        let valid = undefined;
        let containersWithTcg = 0;
        const bays = [
            ...blockBaysAndSizesBy20Bay[lid.startIsoBay].allBays.split("-"),
            ...blockBaysAndSizesBy20Bay[lid.endIsoBay].allBays.split("-"),
        ].filter((b, idx, arr) => arr.indexOf(b) === idx);
        for (let i = portIndex; i <= stbdIndex; i++) {
            const row = rowsArraySorted[i];
            const tcg = bays.reduce((acc, bay) => {
                var _a;
                return acc || ((_a = rowTcgsByBay === null || rowTcgsByBay === void 0 ? void 0 : rowTcgsByBay[bay]) === null || _a === void 0 ? void 0 : _a[row]);
            }, undefined);
            if (tcg !== undefined) {
                containersWithTcg++;
            }
        }
        if (containersWithTcg === 0) {
            valid = "danger";
        }
        else if (containersWithTcg < Math.abs(stbdIndex - portIndex) + 1) {
            valid = "warning";
        }
        return Object.assign(Object.assign({}, lid), { valid });
    });
}
