import { BayLevelEnum } from "open-vessel-definition";
import { getSizesFromSlots, } from "tedivo-bay-grid-pure";
import { createDictionary, sortNumericAsc, } from "@baplie-viewer2/tedivo-pure-helpers";
export function getBayBlocks(bls, pairedBaysCalc) {
    const blsDict = createDictionary(bls, (b) => `${b.isoBay}-${b.level}`);
    const { pairedBays, unpairedBays } = pairedBaysCalc;
    const allIsoBays = bls
        .map((b) => b.isoBay)
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort(sortNumericAsc)
        .map((b) => ({ isoBay: b }));
    const blockBaysAndSizesKeys = new Set(unpairedBays
        .map((ub) => (ub.definedSlots ? ub.base : undefined))
        .filter(Boolean));
    pairedBays.forEach((pb) => {
        if (!blockBaysAndSizesKeys.has(pb.base) &&
            !blockBaysAndSizesKeys.has(pb.paired)) {
            blockBaysAndSizesKeys.add(pb.allBays.join("-"));
        }
        else {
            blockBaysAndSizesKeys.add(pb.base);
            blockBaysAndSizesKeys.add(pb.paired);
        }
    });
    const blockBaysAndSizes = Array.from(blockBaysAndSizesKeys)
        .sort()
        .map((b) => ({
        allBays: b,
        base: b.split("-")[0],
        blockSize: 20,
        maxSizes: {},
        numberOfBays: 1,
    }));
    bls.forEach((bl) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const bay = bl.isoBay;
        const paired = (_a = pairedBays.find((pb) => pb.base === bay)) === null || _a === void 0 ? void 0 : _a.paired;
        const maxSizeSameBay = Math.max(...[
            ...(_c = getSizesFromSlots((_b = blsDict[`${bay}-${BayLevelEnum.ABOVE}`]) === null || _b === void 0 ? void 0 : _b.perSlotInfo)) === null || _c === void 0 ? void 0 : _c.sizes,
            ...(_e = getSizesFromSlots((_d = blsDict[`${bay}-${BayLevelEnum.BELOW}`]) === null || _d === void 0 ? void 0 : _d.perSlotInfo)) === null || _e === void 0 ? void 0 : _e.sizes,
        ]);
        const maxSizePairedBay = Math.max(...[
            ...(_g = getSizesFromSlots((_f = blsDict[`${paired}-${BayLevelEnum.ABOVE}`]) === null || _f === void 0 ? void 0 : _f.perSlotInfo)) === null || _g === void 0 ? void 0 : _g.sizes,
            ...(_j = getSizesFromSlots((_h = blsDict[`${paired}-${BayLevelEnum.BELOW}`]) === null || _h === void 0 ? void 0 : _h.perSlotInfo)) === null || _j === void 0 ? void 0 : _j.sizes,
        ]);
        const id = paired ? `${bay}-${paired}` : bay;
        const idx = blockBaysAndSizes.findIndex((b) => b.allBays === id);
        if (idx !== -1) {
            blockBaysAndSizes[idx].maxSizes = {
                [bay]: maxSizeSameBay,
            };
            if (paired)
                blockBaysAndSizes[idx].maxSizes[paired] = maxSizePairedBay;
            blockBaysAndSizes[idx].blockSize = Math.max(...[maxSizeSameBay, maxSizePairedBay]);
            blockBaysAndSizes[idx].numberOfBays = paired ? 2 : 1;
        }
    });
    const blockBaysAndSizesBy20Bay = blockBaysAndSizes.reduce((acc, b) => {
        if (b.blockSize === 0 || b.blockSize === -Infinity)
            return acc;
        b.allBays.split("-").forEach((bay) => {
            acc[bay] = b;
        });
        return acc;
    }, {});
    const blockBaysAndSizesByBlockBay = createDictionary(blockBaysAndSizes, (b) => b.allBays);
    return {
        allIsoBays,
        blockBaysAndSizes,
        blockBaysAndSizesByBlockBay,
        blockBaysAndSizesBy20Bay,
    };
}
