import { getPreferencesValue } from "@baplie-viewer2/tedivo-preferences";

class ScreenColorModeStore {
  get resolvedColorMode() {
    const savedColorMode = getPreferencesValue("color-mode");
    const darkMode =
      savedColorMode === "dark" ||
      ((!savedColorMode || savedColorMode === "auto") &&
        "matchMedia" in globalThis &&
        globalThis.matchMedia("(prefers-color-scheme: dark)").matches);

    return darkMode ? "dark" : "light";
  }

  public screenColorMode: "light" | "dark" =
    "matchMedia" in globalThis &&
    globalThis.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
}

const screenColorModeStore = new ScreenColorModeStore();
export default screenColorModeStore;
