import {
  getPreferencesValue,
  setPreferencesKeyAndValue,
} from "@baplie-viewer2/tedivo-preferences";

class StafSaveOptions {
  isFormattedJsonSaved = false;

  setSaveFormattedJson(u: boolean): boolean {
    this.isFormattedJsonSaved = u;
    setPreferencesKeyAndValue("json-formatted", u ? "true" : "false");
    return false;
  }

  constructor() {
    const isFormattedJsonSaved = getPreferencesValue("json-formatted");

    if (isFormattedJsonSaved)
      this.isFormattedJsonSaved =
        isFormattedJsonSaved === "true" || isFormattedJsonSaved === true;
  }
}

const stafSaveOptions = new StafSaveOptions();
export default stafSaveOptions;
