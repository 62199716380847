import { pad2, pad3 } from "@baplie-viewer2/tedivo-pure-helpers";

import { ILidData } from "open-vessel-definition";
import { ILidDataStore } from "./createLidsFormFields";

export function convertLidDataStoreToILidData(
  lidDStore: ILidDataStore,
): ILidData {
  const lidData: ILidData = {
    label: lidDStore.label || "",
    portIsoRow: pad2(lidDStore.portIsoRow),
    starboardIsoRow: pad2(lidDStore.starboardIsoRow),
    startIsoBay: pad3(lidDStore.startIsoBay),
    endIsoBay: pad3(lidDStore.endIsoBay),
    overlapPort: lidDStore.overlapPort ? 1 : undefined,
    overlapStarboard: lidDStore.overlapStarboard ? 1 : undefined,
    weight: lidDStore.weight,
  };

  if (lidData.weight === 0) delete lidData.weight;
  if (lidData.overlapPort === undefined) delete lidData.overlapPort;
  if (lidData.overlapStarboard === undefined) delete lidData.overlapStarboard;

  return lidData;
}

export function convertILidDataToLidDataStore(
  lidData: ILidData,
  pk: string,
): ILidDataStore {
  return {
    label: lidData.label || "",
    portIsoRow: Number(lidData.portIsoRow),
    starboardIsoRow: Number(lidData.starboardIsoRow),
    startIsoBay: Number(lidData.startIsoBay),
    endIsoBay: Number(lidData.endIsoBay),
    overlapPort: lidData.overlapPort || 0,
    overlapStarboard: lidData.overlapStarboard || 0,
    weight: lidData.weight,
    pk,
  };
}
