import { BayLevelEnum, ForeAftEnum, } from "open-vessel-definition";
import { getSizesFromSlots } from "./getSizesFromSlots";
import { sortByMultipleFields } from "@baplie-viewer2/tedivo-pure-helpers";
export function getAllThePairedBays(bls, checkOnly40s) {
    return _getAllPaired(false, bls, checkOnly40s);
}
export function getAllThePairedBaysFromBlps(bls, checkOnly40s) {
    return _getAllPaired(true, bls, checkOnly40s);
}
function _getAllPaired(isBLP, bls, checkOnly40s) {
    const levels = [BayLevelEnum.ABOVE, BayLevelEnum.BELOW];
    const pairedBays = [];
    const unpairedBays = [];
    const has40Fn = isBLP
        ? (bl) => bl.sizes.some((size) => size >= 40)
        : (bl) => {
            return Object.keys(bl.perSlotInfo || {}).some((slot) => {
                var _a, _b;
                return Object.keys(((_b = (_a = bl.perSlotInfo) === null || _a === void 0 ? void 0 : _a[slot]) === null || _b === void 0 ? void 0 : _b.sizes) || {})
                    .map(Number)
                    .some((size) => size >= 40);
            });
        };
    const getDefinedSlots = isBLP
        ? (bl) => bl.definedSlots
        : (bl) => getSizesFromSlots(bl.perSlotInfo).definedSlots;
    levels.forEach((level) => {
        const bays = bls
            .filter((a) => a.level === level)
            .sort(sortByMultipleFields([{ name: "isoBay", ascending: true }]));
        for (let i = 0; i < bays.length; i++) {
            const bay = bays[i];
            let has40 = true;
            if (checkOnly40s) {
                has40 = has40Fn(bay);
            }
            if (bay.pairedBay === ForeAftEnum.AFT) {
                const nextBay = bays[i + 1];
                if (nextBay && nextBay.pairedBay === ForeAftEnum.FWD) {
                    pairedBays.push({
                        base: bay.isoBay,
                        paired: nextBay.isoBay,
                        level,
                        allBays: [bay.isoBay, nextBay.isoBay],
                    });
                    i++;
                }
                else {
                    if (has40 || !checkOnly40s)
                        unpairedBays.push({
                            base: bay.isoBay,
                            level,
                            allBays: [bay.isoBay],
                            definedSlots: getDefinedSlots(bay),
                        });
                }
            }
            else {
                if (has40 || !checkOnly40s)
                    unpairedBays.push({
                        base: bay.isoBay,
                        level,
                        allBays: [bay.isoBay],
                        definedSlots: getDefinedSlots(bay),
                    });
            }
        }
    });
    return { pairedBays, unpairedBays };
}
