import { __awaiter } from "tslib";
import { ScrollModeEnum } from "../../app/units/units-scroller-element";
import { getTranslation } from "../../app/i18/i18tn";
import globalStore from "../../app/stores/globalStore";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
export default class EditDrawer {
    get fixedScrollOverflow() {
        return this.internalFixedOverflow;
    }
    set fixedScrollOverflow(v) {
        this.internalFixedOverflow = v;
        if (v) {
            if (!this.drawer.classList.contains("fix-overflow"))
                this.drawer.classList.add("fix-overflow");
        }
        else {
            this.drawer.classList.remove("fix-overflow");
        }
    }
    get disabled() {
        return this.internalDisabled;
    }
    set disabled(v) {
        this.internalDisabled = v;
        this.disabledOverlay.style.display = v ? "block" : "none";
    }
    get shouldNotClose() {
        return this.internalShouldNotClose;
    }
    set shouldNotClose(v) {
        this.internalShouldNotClose = v;
    }
    get dataIsDirty() {
        return this.internalDataIsDirty;
    }
    set dataIsDirty(v) {
        this.internalDataIsDirty = v;
    }
    get shouldNotSelectPanel() {
        return this.internalShouldNotSelectPanel;
    }
    set shouldNotSelectPanel(v) {
        if (!v) {
            this.drawer.classList.remove("do-not-selectt-panel");
        }
        else {
            if (!this.drawer.classList.contains("do-not-select-panel"))
                this.drawer.classList.add("do-not-select-panel");
        }
        this.internalShouldNotSelectPanel = v;
    }
    constructor() {
        this.readOnlyMode = false;
        this.internalFixedOverflow = false;
        this.internalDisabled = false;
        this.internalShouldNotSelectPanel = false;
        this.internalShouldNotClose = false;
        this.internalDataIsDirty = false;
        this.internalPanelHeight = 0;
        this.promprUserIfShouldNotClose = () => {
            const dialog = document.createElement("sl-dialog");
            dialog.label = getTranslation("general:dirtyData.title");
            dialog.innerHTML = getTranslation("general:dirtyData.message");
            const btnDiscard = document.createElement("sl-button");
            btnDiscard.slot = "footer";
            btnDiscard.innerHTML = getTranslation("general:dirtyData.btnDiscard");
            btnDiscard.variant = "danger";
            btnDiscard.addEventListener("click", () => {
                this.shouldNotClose = false;
                this.dataIsDirty = false;
                this.drawer.hide();
            });
            dialog.appendChild(btnDiscard);
            if (!this.readOnlyMode) {
                const btnSave = document.createElement("sl-button");
                btnSave.slot = "footer";
                btnSave.innerHTML = getTranslation("general:dirtyData.btnSave");
                btnSave.variant = "primary";
                btnSave.autofocus = true;
                btnSave.addEventListener("click", () => {
                    var _a;
                    (_a = this.onSave) === null || _a === void 0 ? void 0 : _a.call(this);
                    this.drawer.hide();
                });
                dialog.appendChild(btnSave);
            }
            this.drawer.appendChild(dialog);
            dialog.show();
            dialog.addEventListener("sl-after-hide", () => {
                dialog.remove();
            });
        };
        const drawer = document.createElement("sl-drawer");
        drawer.className = "main-edit-drawer";
        drawer.placement = "end";
        drawer.addEventListener("sl-request-close", (ev) => {
            if (ev.target === drawer) {
                if ((this.shouldNotClose || this.dataIsDirty) && !this.readOnlyMode) {
                    ev.preventDefault();
                    this.promprUserIfShouldNotClose();
                }
            }
        }, false);
        drawer.addEventListener("sl-after-hide", (ev) => {
            if (ev.target === drawer) {
                removeChildren(drawer);
                drawer.classList.remove("fix-overflow");
                const mainPanel = drawer.drawer.querySelector("[part=body]");
                if (!mainPanel)
                    return;
                mainPanel.style.height = "auto";
                mainPanel.style.overflow = "auto";
            }
        }, false);
        drawer.addEventListener("sl-after-show", () => {
            var _a;
            const info1 = (_a = drawer.drawer
                .querySelector("[part=body]")) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            this.internalPanelHeight = (info1 === null || info1 === void 0 ? void 0 : info1.height) || 0;
        }, false);
        this.drawer = drawer;
        const saveButton = document.createElement("sl-button");
        saveButton.innerHTML = getTranslation("general:common.save");
        saveButton.variant = "primary";
        saveButton.type = "submit";
        saveButton.slot = "footer";
        saveButton.tabIndex = 10;
        this.submitButton = saveButton;
        const disabledOverlay = document.createElement("div");
        disabledOverlay.className = "drawer-disabled-overlay";
        this.disabledOverlay = disabledOverlay;
        const doSave = () => __awaiter(this, void 0, void 0, function* () {
            if (this.onSave) {
                const result = yield this.onSave();
                if (result)
                    drawer.hide();
            }
            else {
                drawer.hide();
            }
            globalStore.touchLastUserInteraction();
        });
        // Attach Save event
        this.submitButton.addEventListener("click", doSave, false);
        this.submitButton.addEventListener("keyup", (ev) => {
            if (ev.key === "Enter" || ev.key === " ")
                doSave();
        }, false);
    }
    getEditDrawer({ title, onSave, readOnlyMode = false, showUnits = true, }) {
        const drawer = this.drawer;
        this.readOnlyMode = readOnlyMode;
        globalStore.touchLastUserInteraction();
        if (onSave)
            this.onSave = onSave;
        removeChildren(drawer);
        drawer.label = title;
        drawer.appendChild(this.submitButton);
        drawer.appendChild(this.disabledOverlay);
        this.shouldNotClose = false;
        this.dataIsDirty = false;
        this.shouldNotSelectPanel = false;
        if (showUnits) {
            const unitsScroller = document.createElement("units-scroller-element");
            unitsScroller.align = "left";
            unitsScroller.doScroll = ScrollModeEnum.NO_SCROLL;
            unitsScroller.align = "right";
            unitsScroller.className = "block";
            drawer.appendChild(unitsScroller);
        }
        this.submitButton.disabled = readOnlyMode;
        return drawer;
    }
}
