import SlColorPicker from "@shoelace-style/shoelace/dist/components/color-picker/color-picker";
import { addStyles } from "../../helpers/addStyles";

export class TedivoColorPicker extends HTMLElement {
  public static formAssociated = true;
  public static observedAttributes = ["class"];

  public required = false;
  public size: "small" | "medium" | "large" = "medium";

  private internalValue: string | undefined = undefined;
  private internalId = "";
  private inputNode: SlColorPicker;
  private labelNode: HTMLLabelElement;

  constructor() {
    super();
    this.inputNode = document.createElement("sl-color-picker");
    this.labelNode = document.createElement("label");
  }

  get value() {
    return this.internalValue;
  }

  set value(v: string | undefined) {
    this.internalValue = v;
    this.inputNode.value = v || "#ffffff00";
  }

  get label() {
    return this.labelNode.innerHTML;
  }

  set label(v: string) {
    this.labelNode.innerHTML = v;
  }

  get name() {
    return this.internalId;
  }

  set name(v: string) {
    const s = `${v}-color-picker`;
    this.internalId = v;
    this.inputNode.id = s;
    this.inputNode.name = s;
    this.labelNode.htmlFor = s;
  }

  focus() {
    this.inputNode.focus();
  }

  connectedCallback() {
    const shadow = this.attachShadow({ mode: "open" });
    const holder = document.createElement("div");

    this.inputNode.addEventListener("slChange", () => {
      this.internalValue = this.inputNode.getFormattedValue("rgb");
      this.dispatchEvent(new CustomEvent("change"));
    });

    this.labelNode.addEventListener("click", () => {
      this.inputNode.trigger.click();
    });

    holder.appendChild(this.inputNode);
    holder.appendChild(this.labelNode);

    shadow.appendChild(addStyles(styles));
    shadow.appendChild(holder);
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    switch (name) {
      case "class":
        if (newValue.indexOf("has-error") >= 0) {
          if (!this.inputNode.classList.contains("has-error"))
            this.inputNode.classList.add("has-error");
        } else {
          this.inputNode.classList.remove("has-error");
        }
        break;
    }
  }
}

customElements.define("tf-input-color", TedivoColorPicker);
declare global {
  interface HTMLElementTagNameMap {
    "tf-input-color": TedivoColorPicker;
  }
}

const styles = `

  div { 
    display: flex; 
    align-items: center;
    gap: 0.5em;
  }
 
  sl-color-picker.has-error:not([disabled])::part(form-control-label),
  sl-color-picker.has-error:not([disabled])::part(form-control-help-text) {
    color: var(--sl-color-danger-700);
  }

  sl-color-picker.has-error:not([disabled])::part(base) {
    border-color: var(--sl-color-danger-600);
  }

  sl-color-picker.has-error:focus-within::part(base) {
    box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-color-danger-200);
  }
  
`;
