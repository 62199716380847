import { ForeAftEnum, IVesselParts } from "open-vessel-definition";

import { IBayPattern } from "tedivo-bay-grid-pure";

/** Returns a full pre-calculated list of slots including bays */
export function preCalculateCurrentVesselParts(
  baysData: Array<{ isoBay: IBayPattern }>,
  vesselParts: IVesselParts[],
): Array<string | IVesselParts> {
  // Create an array of all bay numbers
  const allBaysNumbers = baysData
    .reduce((acc, bay) => {
      return acc.concat(bay.isoBay);
    }, [] as string[])
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort() as Array<string | IVesselParts>;

  const vesselPartsToPlace = sortByReferences(vesselParts);

  let lastPassN = Infinity;

  // Add those Parts that are referenced to a bay (Fore to Aft)
  while (vesselPartsToPlace.length > 0) {
    let i = 0;
    while (i < vesselPartsToPlace.length) {
      const slot = vesselPartsToPlace[i];
      const slotDashedParts = slot.slotRefId.split("-");

      const idx = allBaysNumbers.findIndex((b) => {
        if (typeof b === "string") {
          // It's a bay
          const bayRef =
            slotDashedParts.length === 1
              ? slotDashedParts[0]
              : slot.posRef === ForeAftEnum.FWD
              ? slotDashedParts[0]
              : slotDashedParts[1];
          return bayRef === b;
        } else {
          // It's a part
          return b.id === slot.slotRefId;
        }
      });

      if (idx >= 0) {
        if (slot.posRef === ForeAftEnum.FWD) {
          allBaysNumbers.splice(idx, 0, slot);
        } else {
          allBaysNumbers.splice(idx + 1, 0, slot);
        }
        vesselPartsToPlace.splice(i, 1);
      } else {
        i++;
      }
    }

    if (lastPassN === vesselPartsToPlace.length) break; // This is to avoid infinite loops
    lastPassN = vesselPartsToPlace.length;
  }

  return allBaysNumbers;
}

export function sortByReferences(arr: IVesselParts[]) {
  const sorted = arr
    .slice()
    .sort((a, b) => a.slotRefId.localeCompare(b.slotRefId));

  let i = 0;

  while (i < sorted.length) {
    const idx = sorted.findIndex((a) => sorted[i].slotRefId === a.id);

    if (idx >= 0) {
      if (sorted[idx].posRef === ForeAftEnum.FWD) {
        sorted.splice(idx, 0, sorted.splice(i, 1)[0]);
      } else {
        sorted.splice(idx + 1, 0, sorted.splice(i, 1)[0]);
      }
    }

    i++;
  }

  return sorted;
}
