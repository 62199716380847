import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
  IFeaturesAllowed,
  ILidData,
  IMasterCGs,
  IOpenVesselDefinitionV1,
  IShipData,
  ISizeSummary,
  PositionFormatEnum,
  TBayRowInfo,
  TContainerLengths,
  TRowInfoByLength,
  ValuesSourceEnum,
} from "open-vessel-definition";
import {
  IBayPattern,
  calculateTeusFromSlots,
  getBayLcgVcgTcgAndPairings,
} from "tedivo-bay-grid-pure";
import {
  IFileState,
  LogEventEntitiesEnum,
  LogEventTypesEnum,
  TSourceFileCreated,
} from "@baplie-viewer2/tedivo-api-models";
import {
  arraysAreEqual,
  cloneObject,
  diffArrays,
  objectsAreEqual,
  pad3,
  roundDec,
  sanitizeString,
  sortByMultipleFields,
} from "@baplie-viewer2/tedivo-pure-helpers";

import BeaconServices from "../beaconServices";
import { IBayLevelAdjustedBottomBase } from "@baplie-viewer2/tedivo-bay-grid-core";
import { IBayLevelPairing } from "../../components/features/view-json/parts/edits/createPairingsEdit/IBayLevelPairing";
import { IResponseModel } from "../services/HttpClient";
import { IVesselOneParametrizationFormElements } from "../../components/features/view-json/vessel3D/types/IVesselOneParametrization";
import { IVesselParts } from "open-vessel-definition";
import OvdValidator from "./validators";
import Services from "../services";
import { errorTracking } from "../tracking/errorTracking";
import globalUnits from "../units/globalUnits";
import { safeSet } from "./safeSet";
import securityModule from "../security/SecurityModule";

export declare interface OVDJsonStore {
  addEventListener(
    event: "stateChanged",
    listener: (
      ev: CustomEvent<{
        detail: { oldState: IFileState; newState: IFileState };
      }>,
    ) => void,
  ): this;
  addEventListener(
    event: "jsonUpdated",
    listener: (ev: CustomEvent<ICustomOVDChangeEvent>) => void,
  ): this;
  addEventListener(
    event: "updatingDB",
    listener: (ev: CustomEvent<{ detail: { isUpdating: boolean } }>) => void,
  ): this;
  addEventListener(
    event: "error",
    listener: (error: CustomEvent<Error>) => void,
  ): this;
  addEventListener(event: string, listener: () => void): this;
}

export class OVDJsonStore extends EventTarget {
  private internalJson: IOpenVesselDefinitionV1 | undefined = undefined;
  private originalInternalJson: IOpenVesselDefinitionV1 | undefined = undefined;
  private internalName: string | undefined = undefined;
  private internalCloudInfo: ITvdCloudFileMeta | undefined = undefined;

  private _timeoutEmitter: number | undefined = undefined;

  public readonlyMode = false;
  public ovdValidator = new OvdValidator();

  setJson(shipName: string, json: IOpenVesselDefinitionV1 | undefined) {
    this.internalName = shipName;

    this.internalJson = json === undefined ? undefined : cloneObject(json);

    this.originalInternalJson =
      json === undefined ? undefined : cloneObject(json);

    if (json !== undefined) {
      Object.freeze(this.originalInternalJson);
      this.ovdValidator.validate(json, globalUnits);
    }

    errorTracking.leaveBreadcrumb(
      `JSON "${this.internalName}" total slots defined: ${this.totalSlotsDefined}`,
    );

    return this;
  }

  setLpp(lpp: number) {
    if (this.internalJson === undefined) return this;

    if (this.internalJson.shipData?.lcgOptions?.lpp === lpp) return this;

    safeSet<IShipData>(this.internalJson.shipData, "lcgOptions.lpp", lpp);
    this.emitChange({ type: OVDChangesEnum.SHIP_SIZE });

    return this;
  }

  setCgOptions(
    lcgValues?: ValuesSourceEnum,
    tcgValues?: ValuesSourceEnum,
    vcgValues?: ValuesSourceEnum,
    loa?: number,
    sternToAftPp?: number,
    lpp?: number,
  ) {
    if (this.internalJson === undefined || !this.internalCloudInfo) return this;

    interface IEditGeneralFields {
      lcgValues?: ValuesSourceEnum;
      tcgValues?: ValuesSourceEnum;
      vcgValues?: ValuesSourceEnum;
      loa?: number;
      sternToAftPp?: number;
      lpp?: number;
    }

    const oldData: IEditGeneralFields = {
      lcgValues: this.internalJson.shipData.lcgOptions.values,
      tcgValues: this.internalJson.shipData.tcgOptions.values,
      vcgValues: this.internalJson.shipData.vcgOptions.values,
      loa: this.internalJson.shipData.loa,
      sternToAftPp: this.internalJson.shipData.sternToAftPp,
      lpp: this.internalJson.shipData.lcgOptions.lpp,
    };

    const newData: IEditGeneralFields = {
      lcgValues,
      tcgValues,
      vcgValues,
      loa,
      sternToAftPp,
      lpp,
    };

    const isDataEqual = objectsAreEqual<IEditGeneralFields>(oldData, newData);

    if (isDataEqual) {
      return this;
    }

    if (lcgValues !== undefined) {
      safeSet<IShipData>(
        this.internalJson.shipData,
        "lcgOptions.values",
        lcgValues,
      );
    }

    if (tcgValues !== undefined) {
      safeSet<IShipData>(
        this.internalJson.shipData,
        "tcgOptions.values",
        tcgValues,
      );
    }

    if (vcgValues !== undefined) {
      safeSet<IShipData>(
        this.internalJson.shipData,
        "vcgOptions.values",
        vcgValues,
      );
    }

    if (loa !== undefined) {
      safeSet<IShipData>(this.internalJson.shipData, "loa", loa);
    }

    if (sternToAftPp !== undefined) {
      safeSet<IShipData>(
        this.internalJson.shipData,
        "sternToAftPp",
        sternToAftPp,
      );
    }

    if (lpp !== undefined)
      safeSet<IShipData>(this.internalJson.shipData, "lcgOptions.lpp", lpp);

    this.emitChange({ type: OVDChangesEnum.FULL });

    BeaconServices.logEvents.notifyXhttp({
      eventEntity: LogEventEntitiesEnum.File,
      eventType: LogEventTypesEnum.Modified,
      subEvent: "CGsOptions",
      itemId: this.internalCloudInfo.fileId,
      ...securityModule.getBeaconMetadata(),
    });

    return this;
  }

  setShipDataGeneralInfo(
    {
      lineOperator,
      shipClass,
      shipName,
      callSign,
      imoCode,
      positionFormat,
      containersLengths,
      featuresAllowed: fA,
      yearBuilt,
      loa,
      shipNameAkaStr,
    }: Partial<IEditGeneralFields>,
    doEmitChange = true,
  ) {
    if (this.internalJson === undefined) return this;

    const featuresAllowed: IFeaturesAllowed = fA
      ? {
          slotCoolStowProhibited: fA.indexOf("slotCoolStowProhibited") >= 0,
          slotHazardousProhibited: fA.indexOf("slotHazardousProhibited") >= 0,
          slotConeRequired: fA.indexOf("slotConeRequired") >= 0,
        }
      : {
          slotCoolStowProhibited: false,
          slotHazardousProhibited: false,
          slotConeRequired: false,
        };

    const shipNameAkas =
      shipNameAkaStr
        ?.replace(/,/i, "\n")
        .split("\n")
        .map((s) => s.trim().toUpperCase()) || [];

    type TSubIShipData = Omit<
      IShipData,
      "lcgOptions" | "vcgOptions" | "tcgOptions" | "masterCGs" | "metaInfo"
    >;

    const newShipData: TSubIShipData = {
      lineOperator,
      shipClass: shipClass || "",
      shipName,
      callSign,
      imoCode,
      positionFormat: positionFormat || PositionFormatEnum.BAY_STACK_TIER,
      containersLengths: containersLengths || [],
      featuresAllowed,
      yearBuilt,
      loa,
      shipNameAkas,
    };

    const oldSubShipData: TSubIShipData = {
      lineOperator: this.internalJson.shipData?.lineOperator,
      shipClass: this.internalJson.shipData?.shipClass || "",
      shipName: this.internalJson.shipData?.shipName,
      callSign: this.internalJson.shipData?.callSign,
      imoCode: this.internalJson.shipData?.imoCode,
      positionFormat:
        this.internalJson.shipData?.positionFormat ||
        PositionFormatEnum.BAY_STACK_TIER,
      containersLengths: this.internalJson.shipData?.containersLengths || [],
      featuresAllowed: this.internalJson.shipData?.featuresAllowed || {
        slotCoolStowProhibited: false,
        slotHazardousProhibited: false,
        slotConeRequired: false,
      },
      yearBuilt: this.internalJson.shipData?.yearBuilt,
      shipNameAkas: this.internalJson.shipData?.shipNameAkas || [],
    };

    const shipDataIsEqual = objectsAreEqual<TSubIShipData>(
      oldSubShipData,
      newShipData,
      true,
    );

    if (shipDataIsEqual) return this;

    const shipData = this.internalJson.shipData;

    safeSet<IShipData>(shipData, "lineOperator", lineOperator);
    safeSet<IShipData>(shipData, "shipClass", shipClass);
    safeSet<IShipData>(shipData, "shipName", shipName);
    safeSet<IShipData>(shipData, "callSign", callSign);
    safeSet<IShipData>(shipData, "imoCode", imoCode);
    safeSet<IShipData>(shipData, "positionFormat", positionFormat);
    safeSet<IShipData>(shipData, "containersLengths", containersLengths);
    safeSet<IShipData>(shipData, "yearBuilt", yearBuilt);
    if (loa !== undefined) safeSet<IShipData>(shipData, "loa", loa);
    safeSet<IShipData>(shipData, "shipNameAkas", shipNameAkas);

    if (fA) {
      const featuresAllowed: IFeaturesAllowed = {
        slotCoolStowProhibited: fA.indexOf("slotCoolStowProhibited") >= 0,
        slotHazardousProhibited: fA.indexOf("slotHazardousProhibited") >= 0,
        slotConeRequired: fA.indexOf("slotConeRequired") >= 0,
      };
      safeSet<IShipData>(shipData, "featuresAllowed", featuresAllowed);
    }

    if (doEmitChange) {
      this.emitChange({ type: OVDChangesEnum.SHIP_DATA_GENERAL_INFO });

      if (!shipDataIsEqual && this.internalCloudInfo)
        BeaconServices.logEvents.notifyXhttp({
          eventEntity: LogEventEntitiesEnum.File,
          eventType: LogEventTypesEnum.Modified,
          subEvent: "VesselInfo",
          itemId: this.internalCloudInfo.fileId,
          ...securityModule.getBeaconMetadata(),
        });
    }

    return this;
  }

  setShipSize(prms: IEditSizeFields) {
    if (this.internalJson === undefined || !this.internalCloudInfo) return this;

    const prevSizeSummary: ISizeSummary = { ...this.internalJson.sizeSummary };

    const createEmptyBayData = (
      isoBay: IBayPattern,
      level: BayLevelEnum,
    ): IBayLevelData => ({
      isoBay,
      level,
      infoByContLength: {},
      perSlotInfo: {},
      centerLineRow: prms.centerLineRow ? 1 : 0,
    });

    const newSizeSummary: ISizeSummary = {
      isoBays: prms.isoBays,
      centerLineRow: prms.centerLineRow ? 1 : 0,
      maxRow: prms.maxRow,
      minBelowTier: prms.minBelowTier,
      maxBelowTier: prms.maxBelowTier,
      minAboveTier: prms.minAboveTier,
      maxAboveTier: prms.maxAboveTier,
    };

    if (objectsAreEqual<ISizeSummary>(prevSizeSummary, newSizeSummary))
      return this;

    // If isoBays changes, create/delete new bayData
    if (prms.isoBays !== prevSizeSummary.isoBays) {
      const currentBaysData = this.internalJson.baysData;

      const baysDataPerIsoBayAndLevel: IBaysDataPerIsoBayAndLevel = {};
      currentBaysData.forEach((bl) => {
        if (!baysDataPerIsoBayAndLevel[bl.isoBay])
          baysDataPerIsoBayAndLevel[bl.isoBay] = {};
        baysDataPerIsoBayAndLevel[bl.isoBay][
          bl.level === BayLevelEnum.ABOVE ? "above" : "below"
        ] = bl;
      });

      const newBaysData: IBayLevelData[] = [];

      for (let i = 1; i <= prms.isoBays; i += 2) {
        const isoBay = pad3(i);

        if (prms.hasAboveDeck) {
          const bayDataAbove = baysDataPerIsoBayAndLevel[isoBay]?.above;
          if (bayDataAbove !== undefined) {
            newBaysData.push(bayDataAbove);
          } else {
            newBaysData.push(createEmptyBayData(isoBay, BayLevelEnum.ABOVE));
          }
        }

        if (prms.hasBelowDeck) {
          const bayDataBelow = baysDataPerIsoBayAndLevel[isoBay]?.below;
          if (bayDataBelow !== undefined) {
            newBaysData.push(bayDataBelow);
          } else {
            newBaysData.push(createEmptyBayData(isoBay, BayLevelEnum.BELOW));
          }
        }
      }

      newBaysData.sort(
        sortByMultipleFields([
          { name: "isoBay", ascending: true },
          { name: "level", ascending: true },
        ]),
      );

      this.internalJson.baysData = newBaysData;
    }

    this.internalJson.sizeSummary = newSizeSummary;

    this.emitChange({ type: OVDChangesEnum.SHIP_SIZE });

    BeaconServices.logEvents.notifyXhttp({
      eventEntity: LogEventEntitiesEnum.File,
      eventType: LogEventTypesEnum.Modified,
      subEvent: "Size",
      itemId: this.internalCloudInfo.fileId,
      ...securityModule.getBeaconMetadata(),
    });

    return this;
  }

  setPairings(pairings: IBayLevelPairing[]) {
    const json = this.internalJson;

    if (json === undefined) return this;

    let emitChange = false;

    const pairingsMap = pairings.reduce((acc, p) => {
      acc.set(p.isoBay, p.pairedBay);
      return acc;
    }, new Map<IBayPattern, ForeAftEnum | undefined>());

    for (let i = 0; i < json.baysData.length; i++) {
      const bayData = json.baysData[i];
      const pairedBay = pairingsMap.get(bayData.isoBay);

      if (pairedBay !== undefined && bayData.pairedBay !== pairedBay) {
        bayData.pairedBay = pairedBay;
        emitChange = true;
      }
    }

    if (emitChange) this.emitChange({ type: OVDChangesEnum.ALL_BAYS });
    return this;
  }

  setLids(lidData: ILidData[]) {
    if (this.internalJson === undefined || !this.internalCloudInfo) return this;

    if (arraysAreEqual<ILidData>(this.internalJson.lidData, lidData, true))
      return this;

    const diffs = diffArrays<ILidData>(
      this.internalJson.lidData,
      lidData,
      true,
    );

    const diffsBays: IBayPattern[] = [
      ...(diffs.added?.flatMap((l) => [l.startIsoBay, l.endIsoBay]) || []),
      ...(diffs.removed?.flatMap((l) => [l.startIsoBay, l.endIsoBay]) || []),
    ].filter((v, i, a) => a.indexOf(v) === i);

    this.internalJson.lidData = lidData;

    this.emitChange({
      type: OVDChangesEnum.LIDS,
      data: diffsBays,
    });

    BeaconServices.logEvents.notifyXhttp({
      eventEntity: LogEventEntitiesEnum.File,
      eventType: LogEventTypesEnum.Modified,
      subEvent: "HatchCovers",
      itemId: this.internalCloudInfo.fileId,
      ...securityModule.getBeaconMetadata(),
    });

    return this;
  }

  setVesselParts(vesselPartsData: IVesselParts[]) {
    if (this.internalJson === undefined || !this.internalCloudInfo) return this;

    if (
      arraysAreEqual<IVesselParts>(
        this.internalJson.vesselPartsData,
        vesselPartsData,
        true,
      )
    )
      return this;

    this.internalJson.vesselPartsData = vesselPartsData;

    BeaconServices.logEvents.notifyXhttp({
      eventEntity: LogEventEntitiesEnum.File,
      eventType: LogEventTypesEnum.Modified,
      subEvent: "VesselParts",
      itemId: this.internalCloudInfo.fileId,
      ...securityModule.getBeaconMetadata(),
    });

    return this;
  }

  findBayInfo(
    isoBay: IBayPattern,
    level: BayLevelEnum,
  ): IBayLevelData | undefined {
    if (this.internalJson === undefined) return undefined;

    return this.internalJson.baysData.find(
      (bl) => bl.isoBay === isoBay && bl.level === level,
    );
  }

  replaceBayInfo(
    isoBay: IBayPattern,
    level: BayLevelEnum,
    bayDataSrc: IBayLevelData,
    doEmitChange = true,
  ) {
    if (this.internalJson === undefined) return this;

    const bayData = cleanUpBay(bayDataSrc);

    const idx = this.internalJson.baysData.findIndex(
      (bl) => bl.isoBay === isoBay && bl.level === level,
    );

    if (idx >= 0) {
      this.internalJson.baysData[idx] = bayData;
    } else {
      this.internalJson.baysData.push(bayData);
      this.internalJson.baysData.sort(
        sortByMultipleFields([
          { name: "isoBay", ascending: true },
          { name: "level", ascending: true },
        ]),
      );
    }

    if (doEmitChange)
      this.emitChange({
        type: OVDChangesEnum.BAY_INFO,
        data: [`${isoBay}-${level}`],
      });

    return this;
  }

  replaceJson(json: IOpenVesselDefinitionV1) {
    if (this.internalJson === undefined) return this;
    this.internalJson = json;
    this.emitChange({ type: OVDChangesEnum.FULL });
    return this;
  }

  setCGsInfo(
    isoBay: IBayPattern,
    level: BayLevelEnum,
    perRowInfo: TBayRowInfo,
    infoByContLength: TRowInfoByLength,
  ) {
    if (this.internalJson === undefined || !this.internalCloudInfo) return this;

    const idx = this.internalJson.baysData.findIndex(
      (bl) => bl.isoBay === isoBay && bl.level === level,
    );

    if (idx < 0) return this;

    if (
      objectsAreEqual(perRowInfo, this.internalJson.baysData[idx].perRowInfo) &&
      objectsAreEqual(
        infoByContLength,
        this.internalJson.baysData[idx].infoByContLength,
      )
    ) {
      return this;
    }

    this.internalJson.baysData[idx].perRowInfo = perRowInfo;
    this.internalJson.baysData[idx].infoByContLength = infoByContLength;

    this.emitChange({
      type: OVDChangesEnum.CGS_INFO,
      data: [`${isoBay}-${level}`],
    });

    BeaconServices.logEvents.notifyXhttp({
      eventEntity: LogEventEntitiesEnum.File,
      eventType: LogEventTypesEnum.Modified,
      subEvent: "BayCGs",
      itemId: this.internalCloudInfo.fileId,
      ...securityModule.getBeaconMetadata(),
    });

    return this;
  }

  setMasterCGs(masterCGs: IMasterCGs) {
    if (this.internalJson === undefined || !this.internalCloudInfo) return this;

    if (
      objectsAreEqual<IMasterCGs>(
        this.internalJson.shipData.masterCGs,
        masterCGs,
      )
    )
      return this;

    this.internalJson.shipData.masterCGs = masterCGs;

    this.emitChange({ type: OVDChangesEnum.MASTER_CGS });

    BeaconServices.logEvents.notifyXhttp({
      eventEntity: LogEventEntitiesEnum.File,
      eventType: LogEventTypesEnum.Modified,
      subEvent: "MasterCGs",
      itemId: this.internalCloudInfo.fileId,
      ...securityModule.getBeaconMetadata(),
    });

    return this;
  }

  async setFileState(
    newState: IFileState,
    comments?: string,
  ): Promise<IResponseModel<void> | undefined> {
    if (this.internalJson === undefined || !this.tvdId) return undefined;

    const oldState = this.tvdId.fileState;

    if (oldState === newState) return undefined;

    this.tvdId.fileState = newState;

    this.dispatchEvent(
      new CustomEvent("updatingDB", { detail: { isUpdating: true } }),
    );

    const resp = await Services.files.changeFileState(
      this.tvdId.fileId,
      newState,
      comments,
    );

    this.dispatchEvent(
      new CustomEvent("updatingDB", { detail: { isUpdating: false } }),
    );

    if (resp.statusCode !== 200) {
      this.tvdId.fileState = oldState;
    } else {
      this.tvdId.fileState = newState;
      if (newState !== "DRAFT") this.tvdId.lastComment = comments;

      this.dispatchEvent(
        new CustomEvent("stateChanged", { detail: { oldState, newState } }),
      );

      this.dispatchEvent(
        new CustomEvent<ICustomOVDChangeEvent>("jsonUpdated", {
          detail: { type: OVDChangesEnum.FILE_STATE },
        }),
      );
    }

    return resp;
  }

  async set3DViewParams(
    params: IVesselOneParametrizationFormElements,
    vesselParts: IVesselParts[] = [],
    adjustedBottomBases: Array<IBayLevelAdjustedBottomBase> = [],
  ): Promise<IResponseModel<void> | undefined> {
    if (this.internalJson === undefined || !this.tvdId) return undefined;

    this.dispatchEvent(
      new CustomEvent("updatingDB", { detail: { isUpdating: true } }),
    );

    const resp = await Services.files.update3DViewData(this.tvdId.fileId, {
      params,
      vesselParts,
      adjustedBottomBases,
    });

    this.tvdId.v3DParams = { params, vesselParts, adjustedBottomBases };
    this.setVesselParts(vesselParts);

    this.dispatchEvent(
      new CustomEvent("updatingDB", { detail: { isUpdating: false } }),
    );

    if (resp.statusCode === 200 || resp.statusCode === 204) {
      this.dispatchEvent(
        new CustomEvent<ICustomOVDChangeEvent>("jsonUpdated", {
          detail: { type: OVDChangesEnum.VESSEL_PARTS },
        }),
      );
    }

    return resp;
  }

  get originalJson() {
    return this.originalInternalJson;
  }

  get currentJson() {
    return this.internalJson;
  }

  get filenameSanitized() {
    return sanitizeString(this.internalName || "");
  }

  get filenameSanitizedForSE() {
    const shipData = this.internalJson?.shipData;
    function snUpp(s: string): string {
      return sanitizeString(s || "").toUpperCase();
    }

    const name = shipData
      ? `${snUpp(shipData.shipClass || "___")}.${snUpp(
          shipData.lineOperator || "___",
        )}.${snUpp(shipData.shipName || "___")}`
      : sanitizeString(this.internalName || "");

    return name;
  }

  get tvdId() {
    return this.internalCloudInfo;
  }

  set tvdId(cloudInfo: ITvdCloudFileMeta | undefined) {
    this.internalCloudInfo = cloudInfo;
  }

  get totalSlotsDefined(): number {
    if (this.internalJson === undefined) return 0;

    return this.internalJson.baysData.reduce(
      (acc, bayData) => acc + Object.keys(bayData.perSlotInfo || {}).length,
      0,
    );
  }

  restoreOriginalJson() {
    this.setJson(
      this.internalName || "",
      cloneObject(this.originalInternalJson),
    );

    return this;
  }

  saveToCloud = async (
    originalSource?: TSourceFileCreated,
    vmdShipNameLatest?: string,
  ) => {
    if (this.readonlyMode) return;

    const json = this.internalJson;
    const shipData = json?.shipData;

    if (!json || !shipData?.shipName) return;

    const { cgsStats: cgs } = getBayLcgVcgTcgAndPairings({
      bls: json.baysData,
      vesselPartsData: json.vesselPartsData || [],
      sizeSummary: json.sizeSummary,
      masterCGs: json.shipData.masterCGs,
    });

    const cgsPercentage = roundDec(
        ((cgs.definedLcgs + cgs.definedBottomBase + cgs.definedTcgs) /
          (cgs.countLcgs + cgs.countBottomBase + cgs.countTcgs)) *
          100,
        2,
      ),
      lcgsPercentage = roundDec((cgs.definedLcgs / cgs.countLcgs) * 100, 2),
      tcgsPercentage = roundDec((cgs.definedTcgs / cgs.countTcgs) * 100, 2),
      vcgsPercentage = roundDec(
        (cgs.definedBottomBase / cgs.countBottomBase) * 100,
        2,
      );

    const dataToSave = {
      name: shipData.shipName,
      vmdShipNameLatest,
      vmdShipNameDiffers: vmdShipNameLatest
        ? shipData.shipName !== vmdShipNameLatest
        : false,
      cgsPercentage,
      lcgsPercentage,
      tcgsPercentage,
      vcgsPercentage,
      imoCode: shipData.imoCode,
      callSign: shipData.callSign,
      lastModified: new Date(),
      shipNameAkas: shipData.shipNameAkas?.join("\n") || "",
      originalSource,
      data: JSON.stringify(json),
    };

    if (this.tvdId !== undefined) {
      // UPDATE;
      const { data } = await Services.files.update({
        fileId: this.tvdId.fileId,
        userSub: this.tvdId.userSub,
        organizationId: this.tvdId.organizationId,
        organizationName: securityModule.currentOrganizationName,
        state: this.tvdId.fileState,
        teus: calculateTeusFromSlots(json.baysData),
        hasHatchCovers: !!json.lidData?.length,
        ...dataToSave,
      });

      if (!data) {
        this.dispatchEvent(new CustomEvent("error"));
      } else {
        if (data?.state) {
          this.tvdId.fileState = data.state;
          this.tvdId.lastComment = data.lastComment;
        }
        this.tvdId.updatedBy = securityModule.userSub;
      }
    } else {
      // CREATE
      const apiResp = await Services.files.create({
        userSub: securityModule.userSub,
        organizationId: securityModule.currentOrganizationId,
        organizationName: securityModule.currentOrganizationName,
        state: "DRAFT",
        hasHatchCovers: !!json.lidData?.length,
        teus: calculateTeusFromSlots(json.baysData),
        ...dataToSave,
      });

      if (apiResp.data?.id !== undefined) {
        this.tvdId = {
          fileId: apiResp.data.id,
          userSub: securityModule.userSub,
          organizationId: securityModule.currentOrganizationId,
          organizationName: securityModule.currentOrganizationName,
          createdAt: new Date(),
          fileState: "DRAFT",
          shipNameAkaStr: "",
        };
      } else {
        this.tvdId = undefined;
        throw apiResp;
      }
    }
  };

  emitChange = async (changeInfo: ICustomOVDChangeEvent, maxAwaitInMs = 10) => {
    window.clearTimeout(this._timeoutEmitter);

    const json = this.internalJson;
    if (!json) return;

    const oldState = this.tvdId?.fileState || "DRAFT";

    this._timeoutEmitter = window.setTimeout(async () => {
      // Set loading
      this.dispatchEvent(
        new CustomEvent("updatingDB", { detail: { isUpdating: true } }),
      );

      // Emit change (OPTIMISTIC SAVE)
      this.dispatchEvent(
        new CustomEvent<ICustomOVDChangeEvent>("jsonUpdated", {
          detail: changeInfo,
        }),
      );

      this.saveToCloud().then(() => {
        const newState: IFileState = this.tvdId?.fileState || "DRAFT";

        // Remove loading
        this.dispatchEvent(
          new CustomEvent("updatingDB", { detail: { isUpdating: false } }),
        );

        if (oldState !== newState)
          this.dispatchEvent(
            new CustomEvent("stateChanged", {
              detail: { oldState, newState },
            }),
          );
      });
    }, 10);

    this.ovdValidator.validate(json, globalUnits);
  };
}

const ovdJsonStore = new OVDJsonStore();
export default ovdJsonStore;

function cleanUpBay(bayData: IBayLevelData) {
  deleteVerboseOptionalFalsyKeysInBayData(bayData, [
    "label20",
    "label40",
    "pairedBay",
    "reeferPlugs",
    "doors",
    "reeferPlugLimit",
    "centerLineRow",
    "athwartShip",
    "foreHatch",
    "ventilated",
    "heatSrcFore",
    "ignitionSrcFore",
    "quartersFore",
    "engineRmBulkFore",
  ]);

  return bayData;

  function deleteVerboseOptionalFalsyKeysInBayData(
    bayData: IBayLevelData,
    keys: (keyof IBayLevelData)[],
  ) {
    keys.forEach((key) => {
      if (!bayData[key]) delete bayData[key];
    });
  }
}

export interface IEditGeneralFields extends Record<string, unknown> {
  lineOperator?: string;
  shipClass: string;
  shipName?: string;
  callSign?: string;
  imoCode?: string;
  yearBuilt?: number;
  positionFormat: PositionFormatEnum;
  containersLengths: Array<TContainerLengths>;
  featuresAllowed: string[];
  shipNameAkaStr?: string;
  loa?: number;
}

export interface IEditSizeFields extends Record<string, unknown> {
  isoBays: number;
  centerLineRow?: boolean;
  maxRow?: number;
  maxAboveTier?: number;
  minAboveTier?: number;
  maxBelowTier?: number;
  minBelowTier?: number;
  hasAboveDeck: boolean;
  hasBelowDeck: boolean;
}

interface IBaysDataPerIsoBayAndLevel {
  [isoBay: IBayPattern]: {
    above?: IBayLevelData;
    below?: IBayLevelData;
  };
}

export interface ITvdCloudFileMeta {
  fileId: string;
  userSub: string;
  organizationId: string;
  organizationName: string;
  fileState: IFileState;
  createdAt?: Date;
  updatedAt?: Date;
  updatedBy?: string;
  fromBvoName?: string;
  lastComment?: string;
  v3DParams?: {
    params: IVesselOneParametrizationFormElements;
    vesselParts: IVesselParts[];
    adjustedBottomBases: Array<IBayLevelAdjustedBottomBase>;
  };
  shipNameAkaStr: string;
}

export interface ICGsInfo {
  lcgValues: ValuesSourceEnum;
  tcgValues: ValuesSourceEnum;
  vcgValues: ValuesSourceEnum;
  lcgDataFilled: number;
  tcgDataFilled: number;
  vcgDataFilled: number;
}

/**
 * Does an execution of an async function, but if it takes more than maxAwaitInMs, it will resolve anyway.
 * @param fn Async function to execute
 * @param maxAwaitInMs Max time to wait for the async function to resolve (default 500ms)
 * @param fnResolved Async fn that will be executed when finally resolved
 * @returns void
 */
async function maxAwait({
  fn,
  maxAwaitInMs = 500,
  fnResolved,
}: {
  fn: () => Promise<void>;
  maxAwaitInMs?: number;
  fnResolved?: () => void;
}) {
  window.setTimeout(async () => {
    return false;
  }, maxAwaitInMs);

  await fn();

  fnResolved?.();
  return true;
}

export enum OVDChangesEnum {
  "SHIP_DATA_GENERAL_INFO" = "SHIP_DATA_GENERAL_INFO",
  "SHIP_SIZE" = "SHIP_SIZE",
  "ALL_BAYS" = "ALL_BAYS",
  "LIDS" = "LIDS",
  "BULKHEADS" = "BULKHEADS",
  "VESSEL_PARTS" = "VESSEL_PARTS",
  "BAY_INFO" = "BAY_INFO",
  "CGS_INFO" = "CGS_INFO",
  "MASTER_CGS" = "MASTER_CGS",
  "FILE_STATE" = "FILE_STATE",
  "FULL" = "FULL",
}

export type ICustomOVDChangeEvent =
  | {
      type: OVDChangesEnum;
      data?: never;
    }
  | {
      type:
        | OVDChangesEnum.BAY_INFO
        | OVDChangesEnum.CGS_INFO
        | OVDChangesEnum.LIDS;
      data: Array<`${IBayPattern}-${BayLevelEnum}`> | Array<IBayPattern>;
    };
