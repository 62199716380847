import { ForeAftEnum, VesselPartTypeEnum } from "open-vessel-definition";

import { IVesselOneBaySlot } from "../../../../../../vessel3D/types/IVesselOneBaySlot";

export function getClosestBaySlot({
  id,
  posRef,
  isSelf,
  calculatedSlots,
}: {
  id: string;
  posRef: ForeAftEnum;
  isSelf: boolean;
  calculatedSlots: Array<IVesselOneBaySlot>;
}):
  | {
      id: string;
      singleBay: string;
      inBetweenIds: string[];
      inBetweenLen: number;
      bayIndex: number;
    }
  | undefined {
  const bayIndex = calculatedSlots.findIndex((s) => s.id === id);
  if (bayIndex < 0) return undefined;

  if (isSelf && calculatedSlots[bayIndex].type === VesselPartTypeEnum.BAY)
    return {
      id,
      inBetweenIds: [],
      inBetweenLen: 0,
      bayIndex,
      singleBay: getClosestSingleBay(id, posRef),
    };

  const directionLookup = isSelf
    ? posRef === ForeAftEnum.AFT
      ? -1 // Look for the Bay Fore
      : 1
    : posRef === ForeAftEnum.AFT
    ? 1 // Look for the Bay Aft
    : -1;

  const inBetweenIds: string[] = [];
  let inBetweenLen = 0;

  for (
    let i = bayIndex + directionLookup;
    i >= 0 && i < calculatedSlots.length;
    i += directionLookup
  ) {
    const slt = calculatedSlots[i];

    if (slt.type === VesselPartTypeEnum.BAY)
      return {
        id: slt.id,
        inBetweenIds,
        inBetweenLen,
        bayIndex: i,
        singleBay: getClosestSingleBay(
          slt.id,
          directionLookup === 1 ? ForeAftEnum.FWD : ForeAftEnum.AFT,
        ),
      };

    inBetweenIds.push(slt.id);
    inBetweenLen += slt.len || 0;
  }

  return undefined;
}

function getClosestSingleBay(id: string, posRef: ForeAftEnum) {
  const [b1, b2] = id.split("-");

  if (b1 && b2) {
    // This is a composed bay
    return posRef === ForeAftEnum.FWD ? b1 : b2;
  } else {
    // This is a single bay
    return b1;
  }
}
