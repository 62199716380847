import { OVDChangesEnum } from "packages/oss-editor/src/app/stores/OVDJsonStore";

export const ALL_SINGLE_RENDER_METHODS: Array<ThisSingleRenderMethodsNames> = [
  "renderGeneral",
  "renderSizeSummary",
  "renderCenterOfGravity",
  "renderSideView",
  "renderDeckView",
  // "renderD3View",
  "renderBays",
];

const ALL_VISUAL_RENDER_METHODS: Array<ThisSingleRenderMethodsNames> = [
  "renderBays",
  "renderSideView",
  "renderDeckView",
  // "renderD3View",
];

export const OVD_EVENT_TO_SECTIONS_MAP: Record<
  OVDChangesEnum,
  Array<ThisSingleRenderMethodsNames>
> = {
  [OVDChangesEnum.SHIP_DATA_GENERAL_INFO]: ["renderGeneral"],

  [OVDChangesEnum.SHIP_SIZE]: [
    "renderSizeSummary",
    ...ALL_VISUAL_RENDER_METHODS,
  ],

  [OVDChangesEnum.MASTER_CGS]: [
    "renderCenterOfGravity",
    ...ALL_VISUAL_RENDER_METHODS,
  ],
  [OVDChangesEnum.CGS_INFO]: [
    "renderCenterOfGravity",
    ...ALL_VISUAL_RENDER_METHODS,
  ],

  [OVDChangesEnum.ALL_BAYS]: ALL_VISUAL_RENDER_METHODS,
  [OVDChangesEnum.BAY_INFO]: ALL_VISUAL_RENDER_METHODS,
  [OVDChangesEnum.LIDS]: ALL_VISUAL_RENDER_METHODS,
  [OVDChangesEnum.BULKHEADS]: ALL_VISUAL_RENDER_METHODS,
  [OVDChangesEnum.VESSEL_PARTS]: ALL_VISUAL_RENDER_METHODS,
  [OVDChangesEnum.FILE_STATE]: ALL_VISUAL_RENDER_METHODS,

  [OVDChangesEnum.FULL]: ALL_SINGLE_RENDER_METHODS,
};

type ThisSingleRenderMethodsNames =
  | "renderGeneral"
  | "renderSizeSummary"
  | "renderCenterOfGravity"
  | "renderSideView"
  | "renderDeckView"
  | "renderD3View"
  | "renderBays";
