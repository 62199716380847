/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BayLevelEnum, } from "open-vessel-definition";
import { CONTAINER_HEIGHT_IN_MMM, CONTAINER_SEPARATION_IN_MMM, TCG_IN_MMM, } from "./consts";
import { getAllThePairedBays, } from "tedivo-bay-grid-pure";
import { getRowsAndTiersFromSlotKeys, } from "./getRowsAndTiersFromSlotKeys";
import { createDictionary, feetToMillimeters, pad2, pad3, roundDec, sortNumericAsc, } from "@baplie-viewer2/tedivo-pure-helpers";
import { createRowsFromConfig } from "./createRowsFromConfig";
import { createTiersFromConfig } from "./createTiersFromConfig";
import { findPaired40 } from "./findPaired40";
import generate20Lcgs from "./generate20Lcgs";
import { getBayBlocks } from "./generate20Helpers/missing/getBayBlocks";
import { getSizesFromSlots } from "./getSizesFromSlots";
const EIGHTANDHALF_FEET_IN_MM = feetToMillimeters(8.5);
const BOTTOM_BASE_IN_MM = 1000;
const MID_HEIGHT_SEPARATION_TO_DECK = 1000;
export function getBayLcgVcgTcgAndPairings({ bls, vesselPartsData, sizeSummary, masterCGs, }) {
    const lcgByBayAbove = [];
    const lcgByBayBelow = [];
    let minVcg = Infinity, maxVcg = -Infinity, minLcg = Infinity, maxLcg = -Infinity, minTcg = Infinity, maxTcg = -Infinity, maxIsoBay = -Infinity, countLcgs = 0, definedLcgs = 0, countTcgs = 0, definedTcgs = 0, countBottomBase = 0, definedBottomBase = 0, totalSlotsCount = 0, minTierAbove = Infinity;
    bls
        .slice()
        .sort(sortByBayAsc)
        .forEach((bl) => {
        const output = {
            isoBay: bl.isoBay,
            level: bl.level,
            bayLabel: bl.label20 || bl.label40,
            pairedBay: bl.pairedBay,
            bulkhead: bl.bulkhead,
            minTier: Infinity,
            maxTier: -Infinity,
            tiers: {},
            rows: {},
            maxSizeLcg: {},
            sizes: [],
            missingImportantLcgs: false,
            missingImportantVcgs: false,
            missingImportantTcgs: false,
            countLcgs: 0,
            definedLcgs: 0,
            countTcgs: 0,
            definedTcgs: 0,
            countBottomBase: 0,
            definedBottomBase: 0,
            pairedHas40: false,
            pairedToBay: undefined,
            definedSlots: 0,
        };
        const tiersBases = {};
        if (maxIsoBay < Number(bl.isoBay))
            maxIsoBay = Number(bl.isoBay);
        const { infoByContLength, perSlotInfo, perRowInfo } = bl;
        const commonRowInfo = perRowInfo === null || perRowInfo === void 0 ? void 0 : perRowInfo.common;
        const perRowInfoEach = perRowInfo === null || perRowInfo === void 0 ? void 0 : perRowInfo.each;
        const slotsKeys = perSlotInfo
            ? Object.keys(perSlotInfo).filter((slotKey) => !(perSlotInfo === null || perSlotInfo === void 0 ? void 0 : perSlotInfo[slotKey].restricted))
            : undefined;
        if (slotsKeys)
            totalSlotsCount += slotsKeys.length;
        // Use perSlot to obtain current lengths used
        const { sizes, definedSlots } = getSizesFromSlots(perSlotInfo);
        output.sizes = sizes;
        output.definedSlots = definedSlots;
        // Expected LCGs count (one for each size)
        output.countLcgs = output.sizes.length;
        // Set LCGs using Bay's infoByContLength
        setLcgsFromInfoByContLength(infoByContLength, output);
        const { rows: rowsFromSlots, tiersByRow: tiersByRowFromSlots, minTier, maxTier, } = getRowsAndTiersFromSlotKeys(slotsKeys);
        output.minTier = Number(minTier);
        output.maxTier = Number(maxTier);
        if (bl.level === BayLevelEnum.ABOVE && minTierAbove > output.minTier) {
            minTierAbove = output.minTier;
        }
        // Initialize rows used in bay
        output.rows = rowsFromSlots.reduce((acc, row) => {
            acc[row] = { tcg: undefined };
            return acc;
        }, {});
        // Set TCGs and BottomBases (former VCGs)
        if (perRowInfoEach !== undefined) {
            setTcgsAndBottomBases(bl, output, rowsFromSlots, tiersByRowFromSlots, perRowInfoEach, commonRowInfo, tiersBases);
        }
        else {
            // If there aren't slots, no need to mark as missing TCGs and VCGs
            if (slotsKeys && slotsKeys.length > 0) {
                output.missingImportantTcgs = true;
                output.missingImportantVcgs = true;
            }
        }
        if (perSlotInfo !== undefined) {
            (slotsKeys || []).forEach((pos) => {
                var _a, _b;
                const tier = pos.substring(2);
                const slotData = perSlotInfo[pos];
                if (slotData.sizes) {
                    // A. Get maximum length from slots for each Tier
                    const maxSize = Math.max(...Object.keys(slotData.sizes).map((s) => Number(s)));
                    const lcgOfMaxSize = (_a = infoByContLength[maxSize]) === null || _a === void 0 ? void 0 : _a.lcg;
                    let tierInfo = output.tiers[tier];
                    let updateDetails = false;
                    // B. Add lcg of maxSize and vcg of the tier
                    if (tierInfo === undefined) {
                        output.tiers[tier] = {
                            maxSize,
                            vcg: tiersBases[tier],
                            lcg: lcgOfMaxSize,
                        };
                        tierInfo = output.tiers[tier];
                        updateDetails = true;
                    }
                    else if (maxSize > output.tiers[tier].maxSize) {
                        tierInfo.maxSize = maxSize;
                        tierInfo.vcg = tiersBases[tier];
                        tierInfo.lcg = lcgOfMaxSize;
                        updateDetails = true;
                    }
                    if (updateDetails) {
                        if ((tierInfo === null || tierInfo === void 0 ? void 0 : tierInfo.vcg) !== undefined) {
                            if (minVcg > tierInfo.vcg)
                                minVcg = tierInfo.vcg;
                            if (maxVcg < tierInfo.vcg)
                                maxVcg = tierInfo.vcg;
                        }
                        output.missingImportantLcgs =
                            output.missingImportantLcgs ||
                                !updateMaxSizeDetailsOfBayAndLevel(output.maxSizeLcg, maxSize, (_b = output.tiers[tier]) === null || _b === void 0 ? void 0 : _b.lcg);
                    }
                }
            });
        }
        if (bl.level === BayLevelEnum.ABOVE) {
            lcgByBayAbove.push(output);
        }
        else if (bl.level === BayLevelEnum.BELOW) {
            lcgByBayBelow.push(output);
        }
        countLcgs += output.countLcgs;
        definedLcgs += output.definedLcgs;
        countTcgs += output.countTcgs;
        definedTcgs += output.definedTcgs;
        countBottomBase += output.countBottomBase;
        definedBottomBase += output.definedBottomBase;
    });
    findPaired40(lcgByBayAbove);
    findPaired40(lcgByBayBelow);
    const missingImportantLcgs = isMissingImportantXcgs(lcgByBayAbove, lcgByBayBelow, "missingImportantLcgs");
    const missingImportantVcgs = isMissingImportantXcgs(lcgByBayAbove, lcgByBayBelow, "missingImportantVcgs");
    const missingImportantTcgs = isMissingImportantXcgs(lcgByBayAbove, lcgByBayBelow, "missingImportantTcgs");
    const isoBaysArray = [];
    for (let i = 1; i <= maxIsoBay; i += 2) {
        isoBaysArray.push(pad3(i));
    }
    const pairedBaysCalc = getAllThePairedBays(bls, false);
    const { allIsoBays, blockBaysAndSizes, blockBaysAndSizesByBlockBay, blockBaysAndSizesBy20Bay, } = getBayBlocks(bls, pairedBaysCalc);
    const lcgsBy20Bay = generate20Lcgs({
        maxIsoBay,
        bls,
        bayLevelSizesAndCgsAbove: lcgByBayAbove,
        bayLevelSizesAndCgsBelow: lcgByBayBelow,
        vesselParts: vesselPartsData,
        missingImportantXcgs: missingImportantLcgs,
        pairedBaysCalc,
    });
    const { calcMaxTcg, calcMinTcg } = rePositionVTCgsIfMissing({
        sizeSummary,
        bscs: [...lcgByBayAbove, ...lcgByBayBelow],
        missingImportantVcgs,
        missingImportantTcgs,
        pairedBaysCalc,
    });
    if (calcMinTcg < minTcg)
        minTcg = calcMinTcg;
    if (calcMaxTcg > maxTcg)
        maxTcg = calcMaxTcg;
    const deckBottomBases = calculateDeckBottomBase({
        blps: [...lcgByBayAbove, ...lcgByBayBelow],
    });
    Object.keys(lcgsBy20Bay).forEach((bay) => {
        const bayLcgs = lcgsBy20Bay[bay];
        if (bayLcgs.lcg !== undefined && minLcg > bayLcgs.lcg)
            minLcg = bayLcgs.lcg;
        if (bayLcgs.lcg !== undefined && maxLcg < bayLcgs.lcg)
            maxLcg = bayLcgs.lcg;
    });
    const first20Bay = isoBaysArray[0];
    const last20Bay = isoBaysArray[isoBaysArray.length - 1];
    const fullLengthBetween20Bays = lcgsBy20Bay[first20Bay].foreLcg - lcgsBy20Bay[last20Bay].aftLcg;
    return {
        missingImportantLcgs,
        missingImportantVcgs,
        missingImportantTcgs,
        bayLevelPositionsAbove: lcgByBayAbove,
        bayLevelPositionsBelow: lcgByBayBelow,
        minLcg,
        maxLcg,
        minVcg,
        maxVcg,
        minTcg,
        maxTcg,
        maxIsoBay,
        minTierAbove,
        isoBaysArray,
        totalSlotsCount,
        cgsStats: {
            countLcgs,
            definedLcgs,
            countTcgs,
            definedTcgs,
            countBottomBase,
            definedBottomBase,
        },
        pairedBaysCalc,
        lcgsBy20Bay,
        deckBottomBases,
        allIsoBays,
        blockBaysAndSizes,
        blockBaysAndSizesByBlockBay,
        blockBaysAndSizesBy20Bay,
        fullLengthBetween20Bays,
    };
    function setTcgsAndBottomBases(bl, output, rowsFromSlots, tiersByRowFromSlots, perRowInfoEach, commonRowInfo, tiersBases) {
        rowsFromSlots.forEach((row) => {
            var _a, _b, _c, _d, _e;
            const currentRowInfo = perRowInfoEach[row];
            output.countTcgs += 1;
            // 1. TCGs
            // TCG is the one defined in the bay or use master CGs
            const tcg = (_a = currentRowInfo === null || currentRowInfo === void 0 ? void 0 : currentRowInfo.tcg) !== null && _a !== void 0 ? _a : (bl.level === BayLevelEnum.ABOVE
                ? masterCGs.aboveTcgs[row]
                : masterCGs.belowTcgs[row]);
            if (tcg === undefined) {
                output.missingImportantTcgs = true;
            }
            else {
                output.definedTcgs += 1;
                output.rows[row] = { tcg };
                if (minTcg > tcg)
                    minTcg = tcg;
                if (maxTcg < tcg)
                    maxTcg = tcg;
            }
            // 2. Bottom Bases
            output.countBottomBase += 1;
            const bottomIsoTier = ((_b = tiersByRowFromSlots[row]) === null || _b === void 0 ? void 0 : _b.minTier)
                ? pad2(tiersByRowFromSlots[row].minTier)
                : undefined, topIsoTier = ((_c = tiersByRowFromSlots[row]) === null || _c === void 0 ? void 0 : _c.maxTier)
                ? pad2(tiersByRowFromSlots[row].maxTier)
                : undefined;
            // Bottom base is the one defined in the row, or in common, or in masterCGs
            const bottomBase = (_e = (_d = currentRowInfo === null || currentRowInfo === void 0 ? void 0 : currentRowInfo.bottomBase) !== null && _d !== void 0 ? _d : commonRowInfo === null || commonRowInfo === void 0 ? void 0 : commonRowInfo.bottomBase) !== null && _e !== void 0 ? _e : (bottomIsoTier !== undefined
                ? masterCGs.bottomBases[bottomIsoTier]
                : undefined);
            if (bottomBase === undefined ||
                bottomIsoTier === undefined ||
                topIsoTier === undefined) {
                output.missingImportantVcgs = true;
            }
            else {
                output.definedBottomBase += 1;
                // 1. Find tiers
                const btmTierM = Number(bottomIsoTier), topTierN = Number(topIsoTier);
                // 2. Calculate VCGs
                for (let t = btmTierM; t <= topTierN; t += 2) {
                    const isoTier = pad2(t);
                    const tierVcg = roundDec(bottomBase + (t - btmTierM) * EIGHTANDHALF_FEET_IN_MM * 0.5, 2);
                    tiersBases[isoTier] = tierVcg;
                    if (minVcg > tierVcg)
                        minVcg = tierVcg;
                    if (maxVcg < tierVcg)
                        maxVcg = tierVcg;
                }
            }
        });
    }
    /** Set LCGs and MaxSize. Mutates output */
    function setLcgsFromInfoByContLength(infoByContLength, output) {
        var _a;
        if (infoByContLength === undefined) {
            return;
        }
        const cLengths = Object.keys(infoByContLength).map(Number).filter((len) => output.sizes.indexOf(len) >= 0); // Only lengths used in the bay (from slots)
        // Defined LCGs count (one for each size)
        output.definedLcgs += cLengths
            .map((len) => { var _a; return (_a = infoByContLength[len]) === null || _a === void 0 ? void 0 : _a.lcg; })
            .filter((lcg) => lcg !== undefined).length;
        const maxSizeDefined = Math.max(...cLengths);
        const lcgOfMaxSizeDefined = (_a = infoByContLength[maxSizeDefined]) === null || _a === void 0 ? void 0 : _a.lcg;
        if (lcgOfMaxSizeDefined !== undefined) {
            if (minLcg > lcgOfMaxSizeDefined)
                minLcg = lcgOfMaxSizeDefined;
            if (maxLcg < lcgOfMaxSizeDefined)
                maxLcg = lcgOfMaxSizeDefined;
            output.missingImportantLcgs =
                output.missingImportantLcgs ||
                    !updateMaxSizeDetailsOfBayAndLevel(output.maxSizeLcg, maxSizeDefined, lcgOfMaxSizeDefined);
        }
    }
}
function updateMaxSizeDetailsOfBayAndLevel(maxSizeLcg, maxSize, lcg) {
    if (lcg === undefined)
        return false;
    maxSizeLcg.lcg = lcg;
    maxSizeLcg.aftLcg = getAftLcg(maxSize, lcg);
    maxSizeLcg.foreLcg = getForeLcg(maxSize, lcg);
    maxSizeLcg.size = maxSize;
    return true;
}
function isMissingImportantXcgs(lcgByBayAbove, lcgByBayBelow, attr) {
    return (lcgByBayAbove.reduce((acc, y) => acc || y[attr], false) ||
        lcgByBayBelow.reduce((acc, y) => acc || y[attr], false));
}
function rePositionVTCgsIfMissing({ sizeSummary, bscs, pairedBaysCalc, missingImportantVcgs, missingImportantTcgs, }) {
    const fnsToApply = [];
    let calcMinTcg = Infinity;
    let calcMaxTcg = -Infinity;
    const blpsByBayAndLevel = createDictionary(bscs, (bsc) => `${bsc.isoBay}-${bsc.level}`);
    // VCGs
    if (missingImportantVcgs) {
        const { mostObservedLowestTierAbove, maxNumberOfTiersBelow } = getMostObservedTiersAboveAndBelow();
        // This is the VCG of the most common below bay stack of containers, plus 2 separations
        const midVcg = calcHeightOfStack(maxNumberOfTiersBelow.n) +
            BOTTOM_BASE_IN_MM +
            MID_HEIGHT_SEPARATION_TO_DECK;
        // Calculate general VCGs for all tiers in COMMON
        const tiersVcgs = {};
        // Below tiers common VCGs
        for (let i = maxNumberOfTiersBelow.lowestTierBelow; i <= maxNumberOfTiersBelow.highestTierBelow; i += 2) {
            const tier = pad2(i);
            tiersVcgs[tier] = calcBaseOfStack(i * 0.5 - 1) + BOTTOM_BASE_IN_MM;
        }
        // Above tiers
        if (mostObservedLowestTierAbove !== undefined) {
            const aboveTiers = createTiersFromConfig(sizeSummary.minAboveTier, sizeSummary.maxAboveTier);
            const tierIdx = aboveTiers.indexOf(pad2(mostObservedLowestTierAbove));
            // Common VCGs for above tiers
            if (tierIdx >= 0) {
                const lowestAboveMostObservedTierVcg = midVcg + MID_HEIGHT_SEPARATION_TO_DECK;
                aboveTiers.forEach((t, idx) => {
                    const diff = idx - tierIdx;
                    const vcg = roundDec(calcBaseOfStack(diff) + lowestAboveMostObservedTierVcg, 2);
                    tiersVcgs[t] = vcg;
                });
            }
            // Apply VCGs to every bay
            bscs.forEach((blp) => {
                const tiersOfBay = Object.keys(blp.tiers);
                tiersOfBay.forEach((tier) => {
                    blp.tiers[tier].vcg = tiersVcgs[tier];
                });
            });
            // Iterate individual bays to fix those VCGs where AboveTier are below the most observed
            const baysBelowToFix = bscs
                .filter((blp) => blp.level === BayLevelEnum.ABOVE &&
                blp.minTier < mostObservedLowestTierAbove)
                .reduce((acc, blp) => {
                if (!acc[blp.isoBay]) {
                    acc[blp.isoBay] = blp.minTier;
                    return acc;
                }
                else {
                    acc[blp.isoBay] = Math.min(acc[blp.isoBay], blp.minTier);
                    return acc;
                }
            }, {});
            for (const [bay, tier] of Object.entries(baysBelowToFix)) {
                const blpBelow = blpsByBayAndLevel[`${bay}-${BayLevelEnum.BELOW}`];
                if (!blpBelow)
                    continue;
                const maxBelowTierCeiling = tiersVcgs[pad2(blpBelow.maxTier)] + calcHeightOfStack(1);
                const aboveLowestTierVcg = tiersVcgs[pad2(tier)];
                if (maxBelowTierCeiling > aboveLowestTierVcg) {
                    const diff = MID_HEIGHT_SEPARATION_TO_DECK * 2 + calcHeightOfStack(1);
                    Object.keys(blpBelow.tiers).forEach((tier) => {
                        blpBelow.tiers[tier].vcg -= diff;
                    });
                }
            }
            // Iterate individual bays to fix those VCGs where AboveTier are above the highest max lower tier
            bscs
                .filter((blpAbove) => blpAbove.level === BayLevelEnum.ABOVE)
                .forEach((blpAbove) => {
                var _a, _b;
                const blpBelow = blpsByBayAndLevel[`${blpAbove.isoBay}-${BayLevelEnum.BELOW}`];
                if (!blpBelow ||
                    blpBelow.maxTier === -Infinity ||
                    (blpAbove === null || blpAbove === void 0 ? void 0 : blpAbove.minTier) === Infinity)
                    return;
                const aboveLowestTierVcg = (_a = blpAbove.tiers[pad2(blpAbove.minTier)]) === null || _a === void 0 ? void 0 : _a.vcg;
                const belowHighestTierVcg = (_b = blpBelow.tiers[pad2(blpBelow.maxTier)]) === null || _b === void 0 ? void 0 : _b.vcg;
                if (aboveLowestTierVcg !== undefined &&
                    belowHighestTierVcg !== undefined &&
                    aboveLowestTierVcg - MID_HEIGHT_SEPARATION_TO_DECK * 2 <
                        belowHighestTierVcg) {
                    for (let i = blpAbove.minTier; i <= blpAbove.maxTier; i += 2) {
                        const tier = pad2(i);
                        if (!blpAbove.tiers[tier])
                            blpAbove.tiers[tier] = { maxSize: 20 };
                        blpAbove.tiers[tier].vcg =
                            belowHighestTierVcg +
                                calcHeightOfStack((i - blpAbove.minTier) * 0.5 + 1) +
                                MID_HEIGHT_SEPARATION_TO_DECK * 2;
                    }
                }
            });
        }
    }
    // TCGs
    if (missingImportantTcgs) {
        const rows = createRowsFromConfig(!!sizeSummary.centerLineRow, sizeSummary.maxRow);
        const iRows = rows.map(Number);
        const maxRow = Math.max(...iRows);
        const minRow = Math.min(...iRows);
        const minIntTcg = -(maxRow - minRow) * 0.5 * TCG_IN_MMM;
        const allRowsTcgs = rows.reduce((acc, t, idx) => {
            acc[t] = roundDec(idx * TCG_IN_MMM + minIntTcg, 2);
            if (calcMinTcg > acc[t])
                calcMinTcg = acc[t];
            if (calcMaxTcg < acc[t])
                calcMaxTcg = acc[t];
            return acc;
        }, {});
        fnsToApply.push((bsc) => {
            const rowInfo = bsc.rows;
            const rowsOfBay = Object.keys(rowInfo);
            rowsOfBay.forEach((row) => {
                if (!bsc.rows[row])
                    bsc.rows[row] = {};
                bsc.rows[row].tcg = allRowsTcgs[row];
            });
        });
    }
    bscs.forEach((blp) => {
        fnsToApply.forEach((fn) => fn(blp));
    });
    return {
        calcMinTcg,
        calcMaxTcg,
    };
    function getMostObservedTiersAboveAndBelow() {
        const minTiersAboveStats = {};
        const maxTiersBelowStats = {};
        const belowTiers = createTiersFromConfig(sizeSummary.minBelowTier, sizeSummary.maxBelowTier);
        let maxNumberOfTiersBelow = 0;
        let lowestTierBelow = Infinity;
        let highestTierBelow = -Infinity;
        [...pairedBaysCalc.pairedBays, ...pairedBaysCalc.unpairedBays].forEach((pb) => {
            if (pb.level === BayLevelEnum.ABOVE) {
                // 0.1 Calculate Most observed lowest tier above
                let lowestTier = Infinity;
                pb.allBays.forEach((b) => {
                    const blp = blpsByBayAndLevel[`${b}-${BayLevelEnum.ABOVE}`];
                    if (blp.minTier < lowestTier)
                        lowestTier = blp.minTier;
                });
                minTiersAboveStats[lowestTier] =
                    (minTiersAboveStats[lowestTier] || 0) + 1;
            }
            else if (pb.level === BayLevelEnum.BELOW) {
                // 0.2 Calculate Most observed highest tier below
                let highestTier = -Infinity;
                pb.allBays.forEach((b) => {
                    const blp = blpsByBayAndLevel[`${b}-${BayLevelEnum.BELOW}`];
                    if (blp.maxTier > highestTier)
                        highestTier = blp.maxTier;
                    if (Object.keys(blp.tiers).length > maxNumberOfTiersBelow) {
                        maxNumberOfTiersBelow = Object.keys(blp.tiers).length;
                        if (blp.minTier < lowestTierBelow)
                            lowestTierBelow = blp.minTier;
                        if (blp.maxTier > highestTierBelow)
                            highestTierBelow = blp.maxTier;
                    }
                    maxNumberOfTiersBelow = Math.max(maxNumberOfTiersBelow, Object.keys(blp.tiers).length);
                });
                maxTiersBelowStats[highestTier] =
                    (maxTiersBelowStats[highestTier] || 0) + 1;
            }
        });
        const mostObservedLowestTierAboveCount = Math.max(...Object.values(minTiersAboveStats));
        const mostObservedHighestTierBelowCount = Math.max(...Object.values(maxTiersBelowStats));
        const mostObservedLowestTierAbove = Object.keys(minTiersAboveStats)
            .map(Number)
            .find((t) => minTiersAboveStats[t] === mostObservedLowestTierAboveCount);
        const mostObservedHighestTierBelow = Object.keys(maxTiersBelowStats)
            .map(Number)
            .find((t) => maxTiersBelowStats[t] === mostObservedHighestTierBelowCount);
        const overallLowestTierBelow = belowTiers
            .map(Number)
            .sort(sortNumericAsc)[0];
        if (lowestTierBelow > overallLowestTierBelow) {
            lowestTierBelow = overallLowestTierBelow;
            maxNumberOfTiersBelow = (highestTierBelow - lowestTierBelow) / 2 + 1;
        }
        return {
            mostObservedLowestTierAbove,
            mostObservedHighestTierBelow,
            maxNumberOfTiersBelow: {
                lowestTierBelow,
                highestTierBelow,
                n: maxNumberOfTiersBelow,
            },
        };
    }
}
function calculateDeckBottomBase({ blps }) {
    const deckBottomBases = {};
    const FULL_CONT_HEIGHT = calcHeightOfStack(1);
    const HALF_CONT_HEIGHT = FULL_CONT_HEIGHT * 0.5;
    // 1. Calculate bottom bases for each bay in the deck
    blps
        .filter((bl) => bl.level === BayLevelEnum.ABOVE)
        .forEach((blp) => {
        const bottomBase = Object.keys(blp.tiers).reduce((acc, t) => { var _a; return Math.min(acc, ((_a = blp.tiers[t]) === null || _a === void 0 ? void 0 : _a.vcg) || Infinity); }, Infinity);
        if (bottomBase !== undefined && bottomBase !== Infinity) {
            deckBottomBases[blp.isoBay] =
                bottomBase - HALF_CONT_HEIGHT - MID_HEIGHT_SEPARATION_TO_DECK;
        }
    });
    // 2. Calculate top of containers for each bay below the deck
    blps
        .filter((bl) => bl.level === BayLevelEnum.BELOW &&
        (deckBottomBases[bl.isoBay] === undefined ||
            deckBottomBases[bl.isoBay] === Infinity))
        .forEach((blp) => {
        const bottomBase = Object.keys(blp.tiers).reduce((acc, t) => { var _a; return Math.max(acc, ((_a = blp.tiers[t]) === null || _a === void 0 ? void 0 : _a.vcg) || -Infinity); }, -Infinity);
        if (bottomBase !== undefined && bottomBase !== -Infinity) {
            deckBottomBases[blp.isoBay] =
                bottomBase + FULL_CONT_HEIGHT + MID_HEIGHT_SEPARATION_TO_DECK;
        }
    });
    return deckBottomBases;
}
function calcHeightOfStack(numTiers) {
    return (numTiers * CONTAINER_HEIGHT_IN_MMM +
        Math.max((numTiers - 1) * CONTAINER_SEPARATION_IN_MMM, 0));
}
function calcBaseOfStack(numTiersBelow) {
    return (numTiersBelow * (CONTAINER_HEIGHT_IN_MMM + CONTAINER_SEPARATION_IN_MMM));
}
const sortByBayAsc = (a, b) => Number(a.isoBay) - Number(b.isoBay);
const getForeLcg = (maxSize, lcg) => lcg !== undefined
    ? roundDec(lcg + feetToMillimeters(maxSize * 0.5), 2)
    : undefined;
const getAftLcg = (maxSize, lcg) => lcg !== undefined
    ? roundDec(lcg - feetToMillimeters(maxSize * 0.5), 2)
    : undefined;
