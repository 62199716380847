import { roundDec, } from "@baplie-viewer2/tedivo-pure-helpers";
import { addStyles } from "../../helpers/addStyles";
export class InputWithUnits extends HTMLElement {
    get value() {
        this.calculateInternalValue();
        return this.internalValue;
    }
    set value(v) {
        this.internalValue = v;
        this.setDisplayValue(v);
    }
    get placeholder() {
        return this.internalPlaceHolder;
    }
    set placeholder(v) {
        this.internalPlaceHolder = v;
        if (v !== undefined)
            this.setPlaceholderValue(v);
    }
    get label() {
        return this.internalLabel;
    }
    set label(v) {
        this.internalLabel = v;
        if (this.input)
            this.input.label = v;
    }
    set helpText(v) {
        if (this.input)
            this.input.helpText = v;
    }
    get name() {
        return this.internalId;
    }
    set name(v) {
        this.internalId = v;
        if (this.input) {
            this.input.id = v;
            this.input.name = v;
        }
    }
    get converterPrecision() {
        return this.internalConverterPrecision;
    }
    set converterPrecision(v) {
        this.internalConverterPrecision = v;
    }
    get disabled() {
        return this.internalDisabled;
    }
    set disabled(s) {
        this.internalDisabled = s;
        if (this.input)
            this.input.disabled = s;
    }
    get min() {
        var _a;
        return Number((_a = this.input) === null || _a === void 0 ? void 0 : _a.min) || undefined;
    }
    set min(s) {
        if (this.input)
            this.input.min = s !== null && s !== void 0 ? s : 0;
    }
    get max() {
        var _a;
        return Number((_a = this.input) === null || _a === void 0 ? void 0 : _a.max) || undefined;
    }
    set max(s) {
        if (this.input)
            this.input.max = s !== null && s !== void 0 ? s : 0;
    }
    get tabIndex() {
        var _a;
        return ((_a = this.input) === null || _a === void 0 ? void 0 : _a.tabIndex) || -1;
    }
    set tabIndex(v) {
        if (this.input)
            this.input.tabIndex = v;
    }
    get noSpinButtons() {
        if (this.input)
            return this.input.noSpinButtons;
        return false;
    }
    set noSpinButtons(v) {
        if (this.input)
            this.input.noSpinButtons = v;
    }
    focus() {
        if (this.input)
            this.input.focus();
    }
    select() {
        if (this.input)
            this.input.select();
    }
    setDisplayValue(v) {
        if (!this.input)
            return;
        if (v === undefined || isNaN(v)) {
            this.input.value = "";
            return;
        }
        // Display value -> First transponse, then convert
        let intValue = this.transponser
            ? this.transponser.fromValueToDisplay(v)
            : v;
        intValue = this.converter
            ? this.converter.fromValueToDisplay(intValue)
            : intValue;
        if (this.internalConverterPrecision !== undefined) {
            intValue = roundDec(intValue, this.internalConverterPrecision);
        }
        this.input.value = String(intValue);
    }
    setPlaceholderValue(v) {
        if (!this.input)
            return;
        if (v === undefined || String(v) === "" || isNaN(v)) {
            this.input.placeholder = "";
            return;
        }
        const val = Number(v);
        // Display value -> First transponse, then convert
        let intValue = this.transponser
            ? this.transponser.fromValueToDisplay(val)
            : val;
        intValue = this.converter
            ? this.converter.fromValueToDisplay(intValue)
            : intValue;
        this.input.placeholder = String(intValue);
    }
    constructor() {
        super();
        this.required = false;
        this.size = "medium";
        this.internalValue = 0;
        this.internalId = "";
        this.internalPlaceHolder = undefined;
        this.internalLabel = "";
        this.internalDisabled = false;
        this.internalUseInputListener = false;
        this.internalConverterPrecision = undefined;
        this.onChange = () => {
            this.calculateInternalValue();
        };
        this.calculateInternalValue = () => {
            const t = this.input;
            if (t.value === "") {
                this.internalValue = undefined;
                return;
            }
            const faceValue = Number(t.value);
            if (isNaN(faceValue))
                return;
            // First convert, then transpose
            let intValue = this.converter
                ? this.converter.fromDisplayToValue(faceValue)
                : faceValue;
            intValue = this.transponser
                ? this.transponser.fromDisplayToValue(intValue)
                : intValue;
            this.internalValue = intValue;
        };
        const inp = document.createElement("sl-input");
        this.input = inp;
    }
    connectedCallback() {
        const shadow = this.attachShadow({ mode: "open" });
        const inp = this.input;
        inp.id = this.name;
        inp.name = this.name;
        inp.size = this.size;
        inp.type = "number";
        if (this.required)
            inp.required = true;
        if (this.label)
            inp.label = this.label;
        if (this.disabled)
            inp.disabled = this.disabled;
        shadow.appendChild(addStyles(styles));
        shadow.appendChild(inp);
        this.setDisplayValue(this.internalValue);
        this.setPlaceholderValue(this.internalPlaceHolder);
        inp.addEventListener("sl-change", this.onChange, false);
    }
    disconnectedCallback() {
        this.input.removeEventListener("sl-change", this.onChange, false);
    }
    attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
            case "class":
                if (newValue.indexOf("has-error") >= 0) {
                    if (!this.input.classList.contains("has-error"))
                        this.input.classList.add("has-error");
                }
                else {
                    this.input.classList.remove("has-error");
                }
                break;
            case "disabled":
                this.disabled = newValue === "true";
                break;
        }
    }
}
InputWithUnits.formAssociated = true;
InputWithUnits.observedAttributes = ["class", "disabled"];
customElements.define("tf-input-units", InputWithUnits);
const styles = `
 
  sl-input.has-error:not([disabled])::part(form-control-label),
  sl-input.has-error:not([disabled])::part(form-control-help-text) {
    color: var(--sl-color-danger-700);
  }

  sl-input.has-error:not([disabled])::part(base) {
    border-color: var(--sl-color-danger-600);
  }

  sl-input.has-error:focus-within::part(base) {
    box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-color-danger-200);
  }
  
`;
export function createInputWithUnits({ name, value, placeholder, converter, transponser, size = "small", disabled = false, noSpinButtons = false, label = "", converterPrecision, }) {
    const inp = new InputWithUnits();
    if (converter)
        inp.converter = converter;
    if (transponser)
        inp.transponser = transponser;
    if (label)
        inp.label = label;
    inp.name = name;
    inp.size = size;
    inp.value = value;
    inp.placeholder = placeholder;
    inp.disabled = disabled;
    inp.noSpinButtons = noSpinButtons;
    inp.converterPrecision = converterPrecision;
    return inp;
}
